import React, { Component } from "react";
import "../../styles/AccountDetails.css";
import SearchIcon from "@mui/icons-material/Search";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { useTranslation, withTranslation } from "react-i18next";
import Pagination from "react-bootstrap/Pagination";
import { View, Text, Image, StyleSheet, TouchableOpacity } from "react-native";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";
import Header from "../../components/Header";
import AccountRoutes from "../../components/AccountRoutes";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import scarpaVuota from "../../assets/wk_products_not_found.png"
class Storico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      listaProdotti: [],
      listaProdottiVenduti: [],
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
      UtenteId: localStorage.getItem(CONSTANTS.ID),
      switch: 0,
      page: 1,
      totalPage: [],
      totalPageVendite: [],
      pageVendite: 1,
      elementPerPage: 9,
    };
  }
  async componentDidMount() {
    await axios
      .get(
        CONSTANTS.API_URL + "/pagamento/buyIds/" + this.state.UtenteId + "/0/9",
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((prodottoRicevuto) => {
        console.log(prodottoRicevuto.data);
        this.setState({
          listaProdotti: prodottoRicevuto.data,
        });
      });

    await axios
      .get(
        CONSTANTS.API_URL +
          "/vendite/buyId-vendite/" +
          this.state.UtenteId +
          "/0/9",
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((prodottoRicevuto) => {
        console.log(prodottoRicevuto.data);
        this.setState({
          listaProdottiVenduti: prodottoRicevuto.data,
        });
      });

    await axios
      .get(
        CONSTANTS.API_URL + "/pagamento/count-filter/" + this.state.UtenteId,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((proposteRicevute) => {
        if (proposteRicevute.data.totalElement > 1) {
          const countPage =
            proposteRicevute.data.totalElement / this.state.elementPerPage;
          console.log("proposteRicevute.data", proposteRicevute.data);
          this.setState({
            totalPage: Array(Math.round(countPage + 0.4)),
          });
        } else {
          this.setState({
            totalPage: Array(1),
          });
        }
      });

    await axios
      .get(CONSTANTS.API_URL + "/vendite/count-filter/" + this.state.UtenteId, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((proposteRicevute) => {
        if (proposteRicevute.data.totalElement > 1) {
          const countPage =
            proposteRicevute.data.totalElement / this.state.elementPerPage;
          console.log(
            "proposteRicevute.data.totalElement",
            proposteRicevute.data.totalElement
          );

          this.setState({
            totalPageVendite: Array(Math.round(countPage + 0.4)),
          });
        } else {
          this.setState({
            totalPageVendite: Array(1),
          });
        }
      });
  }
  handlePageClick = async (page) => {
    const indexOfLastTodo = page * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    console.log("indici", this.state.elementPerPage, indexOfFirstTodo);

    const prodotti = await axios.get(
      CONSTANTS.API_URL +
        "/pagamento/buyIds/" +
        this.state.UtenteId +
        "/" +
        indexOfFirstTodo +
        "/" +
        this.state.elementPerPage,
      {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      }
    );
    console.log("prodotti", prodotti);

    this.setState({
      page: page,
      listaProdotti: prodotti.data,
    });
  };
  handlePageClickProposte = async (page) => {
    console.log("totalPageVendite", this.state.totalPageVendite);

    const indexOfLastTodo = page * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    console.log("indici", this.state.elementPerPage, indexOfFirstTodo);

    const prodotti = await axios.get(
      CONSTANTS.API_URL +
        "/vendite/buyId-vendite/" +
        this.state.UtenteId +
        "/" +
        indexOfFirstTodo +
        "/" +
        this.state.elementPerPage,
      {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      }
    );
    console.log("prodotti", prodotti);

    this.setState({
      pageVendite: page,
      listaProdottiVenduti: prodotti.data,
    });
  };
  MobileRender = () => {
    const { t } = this.props.t;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Others" title={t("history")} />
          <View style={style.HeaderMobileProfile}>
            <TouchableOpacity
              onPress={() => {
                this.setState({ switch: 0 });
              }}
            >
              <Text
                style={{
                  fontWeight: this.state.switch === 0 ? "bold" : 400,
                  color: this.state.switch === 0 ? "#0A09A2" : "black",
                }}
              >
                {t("purch")}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ marginLeft: "10%" }}
              onPress={() => {
                this.setState({ switch: 1 });
              }}
            >
              <Text
                style={{
                  fontWeight: this.state.switch === 1 ? "bold" : 400,
                  color: this.state.switch === 1 ? "#0A09A2" : "black",
                }}
              >
                {t("sold")}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              width: "100%",
              flexGrow: 1,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {this.state.switch === 0 && (
              <>
                {this.state.listaProdotti?.length === 0 && (
                  <label style={{ marginTop: 10, marginLeft: 10 }}>
                    {t("no_product")}
                  </label>
                )}
                {this.state.listaProdotti?.length > 0 &&
                  this.state.listaProdotti.map((prodotto, key) => {
                    return (
                      <View style={style.containerProposte}>
                        <img
                          style={{
                            width: "30%",
                            resizeMode: "center",
                            height: "100%",
                            marginRight: "3%",
                          }}
                          src={prodotto.imageProdotto}
                          onError={(e) => {
                            e.target.src = scarpaVuota;
                          }}
                        />
                        <View style={style.containerLabelProdotto}>
                          <Text style={style.titleContProd}>
                            {prodotto.nome}
                          </Text>
                          <Text style={style.SubTitleContProd}>
                            {prodotto.tag}
                          </Text>
                          <View style={style.containerShipping}>
                            <View>
                              <Text>{t("stock_n")}</Text>
                              <Text>{prodotto.lotto.id}</Text>
                            </View>
                            <View>
                              <Text>{t("status")}</Text>
                              <Text style={{ marginBottom: 3 }}>
                                {t(prodotto.StatoNome)}
                              </Text>
                              <Text>{t("total")}</Text>
                              <Text>
                                {parseFloat(prodotto.lotto.payin)
                                  .toFixed(2)
                                  .toString()
                                  .replace(".", ",") + " €"}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    );
                  })}

                {this.state.listaProdotti.length > 0 ? (
                  <Pagination>
                    {this.state.page > 1 && (
                      <Pagination.Prev
                        onClick={() =>
                          this.handlePageClick(this.state.page - 1)
                        }
                      />
                    )}
                    {this.state.page > 1 && (
                      <Pagination.Item onClick={() => this.handlePageClick(1)}>
                        1
                      </Pagination.Item>
                    )}
                    {this.state.page > 1 && <Pagination.Ellipsis />}
                    {this.state.page - 1 > 1 && (
                      <Pagination.Item
                        active={this.state.page === this.state.page - 1}
                        key={"_pagination_" + this.state.page - 1}
                        onClick={() =>
                          this.handlePageClick(this.state.page - 1)
                        }
                      >
                        {this.state.page !== 1 ? this.state.page - 1 : null}
                      </Pagination.Item>
                    )}

                    <Pagination.Item
                      active={this.state.page === this.state.page}
                      key={"_pagination_" + this.state.page}
                      onClick={() => this.handlePageClick(this.state.page)}
                    >
                      {this.state.page}
                    </Pagination.Item>
                    {this.state.page !== this.state.totalPage.length && (
                      <Pagination.Item
                        active={this.state.page + 1 === this.state.page}
                        key={"_pagination_" + this.state.page + 1}
                        onClick={() =>
                          this.handlePageClick(this.state.page + 1)
                        }
                      >
                        {this.state.page !== this.state.totalPage.length
                          ? this.state.page + 1
                          : null}
                      </Pagination.Item>
                    )}
                    {this.state.page + 1 < this.state.totalPage.length && (
                      <Pagination.Ellipsis />
                    )}
                    {this.state.page + 1 < this.state.totalPage.length && (
                      <Pagination.Item
                        onClick={() =>
                          this.handlePageClick(this.state.totalPage.length)
                        }
                      >
                        {this.state.totalPage.length}
                      </Pagination.Item>
                    )}
                    {this.state.page != this.state.totalPage.length && (
                      <Pagination.Next
                        onClick={() =>
                          this.handlePageClick(this.state.page + 1)
                        }
                        disabled={
                          this.state.page + 1 === this.state.totalPage.length ||
                          this.state.page === this.state.totalPage.length ||
                          this.state.page > this.state.totalPage.length
                        }
                      />
                    )}
                  </Pagination>
                ) : (
                  <></>
                )}
              </>
            )}
            {this.state.switch === 1 && (
              <>
                {this.state.listaProdottiVenduti?.length === 0 && (
                  <label style={{ marginTop: 10, marginLeft: 10 }}>
                    {t("no_product")}
                  </label>
                )}
                {this.state.listaProdottiVenduti?.length > 0 &&
                  this.state.listaProdottiVenduti.map((prodotto, key) => {
                    return (
                      <View style={style.containerProposte}>
                        <img
                          style={{
                            width: "30%",
                            resizeMode: "center",
                            height: "100%",
                            marginRight: "3%",
                          }}
                          src={prodotto.imageProdotto}
                          onError={(e) => {
                            e.target.src = scarpaVuota;
                          }}
                        />
                        <View style={style.containerLabelProdotto}>
                          <Text style={style.titleContProd}>
                            {prodotto.nome}
                          </Text>
                          <Text style={style.SubTitleContProd}>
                            {prodotto.modello}
                          </Text>
                          <View style={style.containerShipping}>
                            <View style={{ marginRight: "12%" }}>
                              <Text style={style.boldLabel}>
                                {t("stock_n")}
                              </Text>
                              <Text
                                style={{ ...style.NoboldLabel, color: "#f00" }}
                              >
                                {prodotto.lotto.id}
                              </Text>
                            </View>
                            <View>
                              <Text style={style.boldLabel}>{t("status")}</Text>
                              <Text style={style.NoboldLabel}>
                                {t(prodotto.StatoNome)}
                              </Text>
                              <Text style={style.boldLabel}>{t("total")}</Text>
                              <Text style={style.NoboldLabel}>
                                {parseFloat(prodotto.lotto.payout)
                                  .toFixed(2)
                                  .toString()
                                  .replace(".", ",") + " €"}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    );
                  })}
                {this.state.listaProdottiVenduti.length > 0 ? (
                  <Pagination>
                    {this.state.pageVendite > 1 && (
                      <Pagination.Prev
                        onClick={() =>
                          this.handlePageClickProposte(
                            this.state.pageVendite - 1
                          )
                        }
                        disabled={this.state.pageVendite === 1}
                      />
                    )}
                    {this.state.pageVendite > 1 && (
                      <Pagination.Item
                        onClick={() => this.handlePageClickProposte(1)}
                      >
                        1
                      </Pagination.Item>
                    )}
                    {this.state.pageVendite > 1 && <Pagination.Ellipsis />}
                    {this.state.pageVendite - 1 > 1 && (
                      <Pagination.Item
                        active={
                          this.state.pageVendite === this.state.pageVendite - 1
                        }
                        key={"_pagination_" + this.state.pageVendite - 1}
                        onClick={() =>
                          this.handlePageClickProposte(
                            this.state.pageVendite - 1
                          )
                        }
                      >
                        {this.state.pageVendite !== 1
                          ? this.state.pageVendite - 1
                          : null}
                      </Pagination.Item>
                    )}

                    <Pagination.Item
                      active={this.state.pageVendite === this.state.pageVendite}
                      key={"_pagination_" + this.state.pageVendite}
                      onClick={() =>
                        this.handlePageClickProposte(this.state.pageVendite)
                      }
                    >
                      {this.state.pageVendite}
                    </Pagination.Item>
                    {this.state.pageVendite !==
                      this.state.totalPageVendite.length && (
                      <Pagination.Item
                        active={
                          this.state.pageVendite + 1 === this.state.pageVendite
                        }
                        key={"_pagination_" + this.state.pageVendite + 1}
                        onClick={() =>
                          this.handlePageClickProposte(
                            this.state.pageVendite + 1
                          )
                        }
                      >
                        {this.state.pageVendite !==
                        this.state.totalPageVendite.length
                          ? this.state.pageVendite + 1
                          : null}
                      </Pagination.Item>
                    )}
                    {this.state.pageVendite + 1 <
                      this.state.totalPageVendite.length && (
                      <Pagination.Ellipsis />
                    )}
                    {this.state.pageVendite + 1 <
                      this.state.totalPageVendite.length && (
                      <Pagination.Item
                        onClick={() =>
                          this.handlePageClickProposte(
                            this.state.totalPageVendite.length
                          )
                        }
                      >
                        {this.state.totalPageVendite.length}
                      </Pagination.Item>
                    )}
                    {this.state.pageVendite !=
                      this.state.totalPageVendite.length && (
                      <Pagination.Next
                        onClick={() =>
                          this.handlePageClickProposte(
                            this.state.pageVendite + 1
                          )
                        }
                        disabled={
                          this.state.pageVendite + 1 ===
                            this.state.totalPageVendite.length ||
                          this.state.pageVendite ===
                            this.state.totalPageVendite.length ||
                          this.state.pageVendite >
                            this.state.totalPageVendite.length
                        }
                      />
                    )}
                  </Pagination>
                ) : (
                  <></>
                )}
              </>
            )}
          </View>
        </View>
      </div>
    );
  };
  DesktopRender = () => {
    const { t } = this.props.t;
    return (
      <div className="container-account-general">
        <Header />
        <div className="container-account-route">
          <AccountRoutes />
          <div className="right-column-account">
            <div className="header-account-pages">
              <div className="buttons-header-account-pages-div">
                <button
                  onClick={() => {
                    this.setState({ switch: 0 });
                  }}
                  className={
                    this.state.switch === 0 ? "btn-switch-0" : "btn-switch-1"
                  }
                >
                  {t("purch")}
                </button>
                <button
                  onClick={() => {
                    this.setState({ switch: 1 });
                  }}
                  className={
                    this.state.switch === 1 ? "btn-switch-0" : "btn-switch-1"
                  }
                >
                  {t("sold")}
                </button>
              </div>
              {/*  <div className="search-bar-div-account">
          <label className="search-bar-account">
            <SearchIcon className="search-icon" />
            <input
              type="search"
              placeholder="Search"
              className="search-bar-input"
            />
          </label>
        </div>*/}
            </div>
            <div className="account-list">
              {this.state.switch === 0 && (
                <>
                  {this.state.listaProdotti?.length === 0 && (
                    <label style={{ marginTop: 10, marginLeft: 10 }}>
                      {t("no_product")}
                    </label>
                  )}
                  {this.state.listaProdotti?.length > 0 &&
                    this.state.listaProdotti.map((prodotto, key) => {
                      return (
                        <div className="account-list-detail">
                          <div className="scarpa-img-list">
                            <img
                              src={prodotto.imageProdotto}
                              alt="scarpa"
                              onError={(e) => {
                                e.target.src = scarpaVuota;
                              }}
                            />
                          </div>
                          <div className="modello-account-detail">
                            <p className="title-modello">{t("model")}</p>
                            <p className="modello-mod">{prodotto.nome}</p>
                            <p className="tag-modello">{prodotto.tag}</p>
                          </div>
                          <div className="n-lotto-detail">
                            <p className="title-div">{t("stock")} </p>
                            <p className="qty-div stock-n-account">
                              {prodotto.lotto.id}
                            </p>
                          </div>
                          <div className="qty-account-detail">
                            <p className="title-div">{t("qty")}</p>
                            <p className="qty-div">{prodotto.lotto.quantita}</p>
                          </div>
                          <div className="qty-account-detail">
                            <p className="title-div">{t("price")}</p>
                            <p className="qty-div">
                              {" "}
                              <b>
                                {prodotto.lotto.payin
                                  .toFixed(2)
                                  .toString()
                                  .replace(".", ",") + " €"}
                              </b>
                            </p>
                          </div>
                          <div className="proposta-account-detail">
                            <p className="title-div">{t("status")}</p>
                            <p className="qty-div">{t(prodotto.StatoNome)}</p>
                          </div>
                        </div>
                      );
                    })}

                  {this.state.listaProdotti.length > 0 ? (
                    <Pagination>
                      {this.state.page > 1 && (
                        <Pagination.Prev
                          onClick={() =>
                            this.handlePageClick(this.state.page - 1)
                          }
                        />
                      )}
                      {this.state.page > 1 && (
                        <Pagination.Item
                          onClick={() => this.handlePageClick(1)}
                        >
                          1
                        </Pagination.Item>
                      )}
                      {this.state.page > 1 && <Pagination.Ellipsis />}
                      {this.state.page - 1 > 1 && (
                        <Pagination.Item
                          active={this.state.page === this.state.page - 1}
                          key={"_pagination_" + this.state.page - 1}
                          onClick={() =>
                            this.handlePageClick(this.state.page - 1)
                          }
                        >
                          {this.state.page !== 1 ? this.state.page - 1 : null}
                        </Pagination.Item>
                      )}

                      <Pagination.Item
                        active={this.state.page === this.state.page}
                        key={"_pagination_" + this.state.page}
                        onClick={() => this.handlePageClick(this.state.page)}
                      >
                        {this.state.page}
                      </Pagination.Item>
                      {this.state.page !== this.state.totalPage.length && (
                        <Pagination.Item
                          active={this.state.page + 1 === this.state.page}
                          key={"_pagination_" + this.state.page + 1}
                          onClick={() =>
                            this.handlePageClick(this.state.page + 1)
                          }
                        >
                          {this.state.page !== this.state.totalPage.length
                            ? this.state.page + 1
                            : null}
                        </Pagination.Item>
                      )}
                      {this.state.page + 1 < this.state.totalPage.length && (
                        <Pagination.Ellipsis />
                      )}
                      {this.state.page + 1 < this.state.totalPage.length && (
                        <Pagination.Item
                          onClick={() =>
                            this.handlePageClick(this.state.totalPage.length)
                          }
                        >
                          {this.state.totalPage.length}
                        </Pagination.Item>
                      )}
                      {this.state.page != this.state.totalPage.length && (
                        <Pagination.Next
                          onClick={() =>
                            this.handlePageClick(this.state.page + 1)
                          }
                          disabled={
                            this.state.page + 1 ===
                              this.state.totalPage.length ||
                            this.state.page === this.state.totalPage.length ||
                            this.state.page > this.state.totalPage.length
                          }
                        />
                      )}
                    </Pagination>
                  ) : (
                    <></>
                  )}
                </>
              )}

              {this.state.switch === 1 && (
                <>
                  {this.state.listaProdottiVenduti?.length === 0 && (
                    <label style={{ marginTop: 10, marginLeft: 10 }}>
                      {t("no_product")}
                    </label>
                  )}
                  {this.state.listaProdottiVenduti?.length > 0 &&
                    this.state.listaProdottiVenduti.map((prodotto, key) => {
                      return (
                        <div className="account-list-detail">
                          <div className="scarpa-img-list">
                            <img
                              src={prodotto.imageProdotto}
                              alt="scarpa"
                              onError={(e) => {
                                e.target.src = scarpaVuota;
                              }}
                            />
                          </div>
                          <div className="modello-account-detail">
                            <p className="title-modello">{t("model")}</p>
                            <p className="modello-mod">{prodotto.nome}</p>
                            <p className="tag-modello">{prodotto.tag}</p>
                          </div>
                          <div className="n-lotto-detail">
                            <p className="title-div">{t("stock")} n°</p>
                            <p className="qty-div stock-n-account">
                              {prodotto.lotto.id}
                            </p>
                          </div>
                          <div className="qty-account-detail">
                            <p className="title-div">{t("qty")}</p>
                            <p className="qty-div">{prodotto.lotto.quantita}</p>
                          </div>
                          <div className="qty-account-detail">
                            <p className="title-div">{t("Price")}</p>
                            <p className="qty-div">
                              <b>
                                {parseFloat(prodotto.lotto.payout)
                                  .toFixed(2)
                                  .toString()
                                  .replace(".", ",") + " €"}
                              </b>
                            </p>
                          </div>
                          <div className="proposta-account-detail">
                            <p className="title-div">{t("status")}</p>
                            <p className="qty-div">{t(prodotto.StatoNome)}</p>
                          </div>
                        </div>
                      );
                    })}
                  {this.state.listaProdottiVenduti.length > 0 ? (
                    <Pagination>
                      {this.state.pageVendite > 1 && (
                        <Pagination.Prev
                          onClick={() =>
                            this.handlePageClickProposte(
                              this.state.pageVendite - 1
                            )
                          }
                          disabled={this.state.pageVendite === 1}
                        />
                      )}
                      {this.state.pageVendite > 1 && (
                        <Pagination.Item
                          onClick={() => this.handlePageClickProposte(1)}
                        >
                          1
                        </Pagination.Item>
                      )}
                      {this.state.pageVendite > 1 && <Pagination.Ellipsis />}
                      {this.state.pageVendite - 1 > 1 && (
                        <Pagination.Item
                          active={
                            this.state.pageVendite ===
                            this.state.pageVendite - 1
                          }
                          key={"_pagination_" + this.state.pageVendite - 1}
                          onClick={() =>
                            this.handlePageClickProposte(
                              this.state.pageVendite - 1
                            )
                          }
                        >
                          {this.state.pageVendite !== 1
                            ? this.state.pageVendite - 1
                            : null}
                        </Pagination.Item>
                      )}

                      <Pagination.Item
                        active={
                          this.state.pageVendite === this.state.pageVendite
                        }
                        key={"_pagination_" + this.state.pageVendite}
                        onClick={() =>
                          this.handlePageClickProposte(this.state.pageVendite)
                        }
                      >
                        {this.state.pageVendite}
                      </Pagination.Item>
                      {this.state.pageVendite !==
                        this.state.totalPageVendite.length && (
                        <Pagination.Item
                          active={
                            this.state.pageVendite + 1 ===
                            this.state.pageVendite
                          }
                          key={"_pagination_" + this.state.pageVendite + 1}
                          onClick={() =>
                            this.handlePageClickProposte(
                              this.state.pageVendite + 1
                            )
                          }
                        >
                          {this.state.pageVendite !==
                          this.state.totalPageVendite.length
                            ? this.state.pageVendite + 1
                            : null}
                        </Pagination.Item>
                      )}
                      {this.state.pageVendite + 1 <
                        this.state.totalPageVendite.length && (
                        <Pagination.Ellipsis />
                      )}
                      {this.state.pageVendite + 1 <
                        this.state.totalPageVendite.length && (
                        <Pagination.Item
                          onClick={() =>
                            this.handlePageClickProposte(
                              this.state.totalPageVendite.length
                            )
                          }
                        >
                          {this.state.totalPageVendite.length}
                        </Pagination.Item>
                      )}
                      {this.state.pageVendite !=
                        this.state.totalPageVendite.length && (
                        <Pagination.Next
                          onClick={() =>
                            this.handlePageClickProposte(
                              this.state.pageVendite + 1
                            )
                          }
                          disabled={
                            this.state.pageVendite + 1 ===
                              this.state.totalPageVendite.length ||
                            this.state.pageVendite ===
                              this.state.totalPageVendite.length ||
                            this.state.pageVendite >
                              this.state.totalPageVendite.length
                          }
                        />
                      )}
                    </Pagination>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>{this.props.desktop ? <this.DesktopRender /> : <this.MobileRender />}</>
    );
  }
}
const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  HeaderMobileProfile: {
    flexDirection: "row",
    width: "90%",
    marginHorizontal: "5%",
    paddingHorizontal: "5%",
    paddingBottom: 15,
    borderBottomColor: "#7A79CD",
    borderBottomWidth: 1.5,
  },
  containerProposte: {
    width: "90%",
    marginTop: 10,
    marginVertical: 20,
    flexDirection: "row",
    borderBottomColor: "#7A79CD",
    borderBottomWidth: 1.5,
  },

  containerLabelProdotto: {
    width: "60%",
  },

  titleContProd: {
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
  containerShipping: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
    marginVertical: 10,
  },
  NoboldLabel: {
    fontSize: "0.9rem",
  },
  boldLabel: {
    fontWeight: 700,
    fontSize: "0.9rem",
    marginTop: "5%",
  },
});

export default function (props) {
  const translation = useTranslation();
  const desktop = useDesktopChecker();

  return <Storico {...props} desktop={desktop} t={translation} />;
}
