import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from "react-native";

const PasswordInput = ({ handleChange, value, placeholder }) => {
  const { t } = useTranslation();
  const [visible, setvisible] = useState(false);
  const onPressEye = () => {
    setvisible(!visible);
  };
  return (
    <View style={style.container}>
      <TextInput
        style={style.passInput}
        placeholder={placeholder ? placeholder : "Password"}
        onChange={(e) => handleChange(e)}
        secureTextEntry={!visible}
      />
      <TouchableOpacity onPress={onPressEye} style={style.buttonEye}>
        <Text>{visible ? t("hide") : t("show")}</Text>
      </TouchableOpacity>
    </View>
  );
};
const style = StyleSheet.create({
  container: {
    height: 50,
    width: "90%",
    borderWidth: 1,
    borderColor: "#7A79CD",
    padding: 10,
    marginVertical: 10,
    fontSize: "1.1rem",
    flexDirection: "row",
    borderRadius: 7,
  },
  passInput: {
    width: "85%",
  },
  buttonEye: {
    width: "15%",
    justifyContent: "center",
    alignItems: "center",
  },
});
export default PasswordInput;
