import React, { Component } from "react";
import "../../styles/CheckoutStyles.css";
import { Link } from "react-router-dom";
import logo from "../../assets/wk-logo.png";
import Footer from "../../components/Footer";
import { withTranslation } from "react-i18next";
class CheckoutPause extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container-form-check">
          <div className="div-logo-form">
            <Link to="/">
              <img className="logo-login" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="checkout-final-div">
            <p className="conferma-acquisto-lotto-p">{t("conf_purch")}</p>
            <span>
              <span style={{ fontSize: 20, fontWeight: 650 }}>
                La procedura è andata a buon fine, siamo in attesa del responso
                di PausePay, il lotto fino ad allora rimmarrà in sospeso, verrai
                aggiornato via email quando ci saranno novità, grazie per aver
                scelto Wholekicks.
              </span>
            </span>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default withTranslation()(CheckoutPause);
