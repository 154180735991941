import React, { Component } from "react";
import Header from "../components/Header";
import HeaderMobile from "../pagesMobile/components/HeaderMobile";
import homeImg from "../assets/whole-home-page.png";
import homeImgNoFees from "../assets/BANNER-NO-FEES.png";
import modelloImg from "../assets/add-model.png";
import "../styles/Home.css";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import CONSTANTS from "../CONSTANTS";
import { Formik, Field, Form } from "formik";
import useHorizontalScroll from "../components/useHorizontalScroll";
import scrollIcon from "../assets/scroll-icon.png";
import { useTranslation } from "react-i18next";
import Loading from "../components/Loading";
import { isAutenticated } from "../components/utils/validatorActions";
import Carousel from "../pagesMobile/home/Carousel";
import { View, Image, StyleSheet } from "react-native";
import FooterHome from "../pagesMobile/home/FooterHome";
import ModalAdd from "../pagesMobile/home/ModalAdd";
import Menu from "../pagesMobile/home/Menu";
import AppBanner from "smart-app-banner-react";
import useDesktopChecker from "../components/utils/useDesktopChecker";
import {
  marcaMostClicked,
  prodottoHomePage,
  prodottoOffset,
} from "./chiamateApi/ApiProdotto";
import { piuCercate } from "../pagesMobile/api/service";
import scarpaVuote from "../assets/wk_products_not_found.png";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      showModal: false,
      loading: false,
      showPropostaConfermata: false,
      listaProdotti: [],
      listaPiuCercati: [],
      valueIncrement: 0,
      listaMarche: [],
      listaProdottiTEMP: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
      proposta: {
        nome: "",
        modello: "",
        colorazione: "",
        style: "",
        marca: "",
      },
    };
    this.appInfo = {
      imageUrl:
        "https://app.wholekicks.eu/static/media/logone-uolkicks.d1915ca428cd611c0463.png",
      name: "Wholekicks",
      publisher: "Download our app",
      infoText: "",
      linkButtonText: "VIEW",
      linkUrl:
        this.getMobileOperatingSystem() === "iOS"
          ? "https://apps.apple.com/it/app/wholekicks/id1673024243"
          : "https://play.google.com/store/apps/details?id=com.pura.wholekicks",
    };
  }

  async componentDidMount() {
    this.setState({ loading: true }, () => {
      console.log("loading is true ");
    });
    const prodottoRicevuto = await prodottoOffset(0, 19);
    const prodottoHome = await prodottoHomePage();
    const marca = await marcaMostClicked();
    console.log("prodottoRicevuto", prodottoRicevuto.data, prodottoHome);
    this.setState(
      {
        listaProdotti: prodottoRicevuto.data.produ,
        listaPiuCercati: prodottoHome.data,
        listaMarche: marca.data,
        loading: false,
      },
      () => {
        console.log("loading is false ");
      }
    );
  }
  upgradeClick = async (data) => {
    if (isAutenticated()) {
      console.log(data);
      const body = {
        ProdottoId: data,
      };
      await piuCercate(body);
    } else {
      const { t } = this.props.translation;
      alert(t("must_be_logged_product"));
    }
  };
  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }
  onClickFirstCarouselArrow(direction) {
    if (direction === "r") {
      document.getElementById("carouselFirst").scrollTo(1000, 0);
    } else {
      document.getElementById("carouselFirst").scrollTo(0, 0);
    }
  }
  onClickSecondCarouselArrow(direction) {
    if (direction === "r") {
      document.getElementById("carouselSecond").scrollTo(1000, 0);
    } else {
      document.getElementById("carouselSecond").scrollTo(0, 0);
    }
  }

  onClickLink() {
    if (!isAutenticated()) {
      const { t } = this.props.translation;
      alert(t("must_be_logged_to_do_this"));
    }
  }
  onPressModalCancel = () => {
    this.setState({ showModal: false });
  };
  onPressAggiungiModel = () => {
    this.setState({ showModal: true });
  };

  DesktopRender = () => {
    const { t } = this.props.translation;

    return (
      <>
        <Header />
        <Modal
          centered
          show={this.state.showPropostaConfermata}
          onHide={() => this.showPropostaConfermata(false)}
          dialogClassName="aggiungi-modello-modal"
        >
          <div className="modal-header-text">
            <p className="modello-title-modal"> {t("prop_sent")} </p>
            <p className="modello-sub-title-modal-proposta">
              {t("prop_sent_text")}
            </p>
          </div>
          <div className="modal-footer-button">
            <button
              className="chiudi-proposta"
              onClick={() => this.setState({ showPropostaConfermata: false })}
            >
              {t("keep_on_shopping")}
            </button>
          </div>
        </Modal>

        <div style={{ marginBottom: "10%" }} className="container-home">
          <div>
            <img src={homeImg} className="home-img" alt="home" />
          </div>
          <div className="home-section">
            <p className="home-title">{t("most_popular")}</p>
            <div
              // id="carouselFirst"
              className="new-in-prodotti-home-1-gridded"
              // ref={this.props.scrollHorizontal}
            >
              {this.state.listaPiuCercati.length > 0 ? (
                this.state.listaPiuCercati.map((prodotto, key) => {
                  const prezzo = prodotto.prezzo;
                  const prezzoFinale = parseFloat(prezzo).toFixed(2);
                  const finalPrice = prezzoFinale.toString().replace(".", ",");
                  if (key < 30) {
                    return (
                      <Link
                        key={prodotto.id}
                        className="product-home-gridded"
                        to={isAutenticated() ? `/product/${prodotto.id}` : "#"}
                        onClick={() => this.upgradeClick(prodotto.id)}
                      >
                        <div>
                          <div
                          // className="sneaker-cell"
                          >
                            {" "}
                            <img
                              // className="img-prodotto-home-slider"
                              className="product-home-gridded-image"
                              src={prodotto.imageProdotto}
                              alt="scarpa immagine"
                              onError={(e) => {
                                e.target.src = scarpaVuote;
                              }}
                            />
                            {finalPrice !== "NaN" ? (
                              <div className="order-align-text">
                                <span className="title-sn-homepage">
                                  {prodotto.nome}
                                </span>
                                <div
                                  className="sneaker-prezzo-lotto-div"
                                  style={{ flexDirection: "column" }}
                                >
                                  <p style={{ margin: 0, fontSize: "0.8rem" }}>
                                    {" "}
                                    {t("average_price")}{" "}
                                  </p>
                                  <span className="prezzo-prod">
                                    € {finalPrice}
                                  </span>
                                  <span className="lotto-prod">
                                    {prodotto.nLotto}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="order-align-text">
                                <span className="title-sn-homepage">
                                  {prodotto.nome} {prodotto?.modello}
                                </span>

                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: "0.8rem",
                                    alignSelf: "end",
                                  }}
                                >
                                  {t("No_batches_listed")}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <p className="lista-vuota">{t("empty_list")}</p>
              )}
            </div>
            <div className="container-show-more"></div>

            {/* AXIOS TESTING */}
            <p className="home-title">New In</p>
            <div
              // id="carouselSecond"
              className="new-in-prodotti-home-gridded"
              // ref={this.props.scrollHorizontal}
            >
              {this.state.listaProdotti.map((prodotto, key) => {
                const prezzo = prodotto?.prezzo;
                const prezzoFinale = parseFloat(prezzo).toFixed(2);
                const finalPrice = prezzoFinale.toString().replace(".", ",");
                if (key < 16) {
                  return (
                    <Link
                      key={prodotto?.id}
                      className="product-home-gridded"
                      to={isAutenticated() ? `/product/${prodotto?.id}` : "#"}
                      onClick={() => this.upgradeClick(prodotto?.id)}
                    >
                      <div
                        key={key}
                        // className="sneaker-cell"
                      >
                        <div>
                          {" "}
                          <img
                            className="product-home-gridded-image"
                            src={prodotto?.imageProdotto}
                            alt="scarpa immagine"
                            onError={(e) => {
                              e.target.src = scarpaVuote;
                            }}
                          />
                          {finalPrice !== "NaN" ? (
                            <div className="order-align-text">
                              <div>
                                <span className="title-sn-homepage">
                                  {prodotto.nome + " " + prodotto?.modello}
                                </span>
                              </div>
                              <div
                                className="sneaker-prezzo-lotto-div"
                                style={{ flexDirection: "column" }}
                              >
                                <p style={{ margin: 0, fontSize: "0.8rem" }}>
                                  {" "}
                                  {t("average_price")}{" "}
                                </p>
                                <span className="prezzo-prod">
                                  € {finalPrice}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="order-align-text">
                              <span className="title-sn-homepage">
                                {" "}
                                {prodotto.nome} {prodotto?.modello}
                              </span>
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: "0.8rem",
                                  alignSelf: "end",
                                }}
                              >
                                {t("No_batches_listed")}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <div className="container-show-more">
              <Link
                to={isAutenticated() ? "/new-in/1" : "#"}
                onClick={this.onClickLink}
                className="button-show-more"
              >
                {t("more")}
              </Link>
            </div>
          </div>
        </div>

        <Footer />
        <Modal
          centered
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          dialogClassName="aggiungi-modello-modal"
        >
          <div className="modal-footer-button"></div>
        </Modal>
      </>
    );
  };

  MobileRender = () => {
    return (
      <>
        <AppBanner
          appInfo={this.appInfo}
          position="top"
          onClose={() => {
            console.log("onClose called");
          }}
          hidden={false}
          style={{
            position: "fixed",
            zIndex: 10,
          }}
        />
        <div className="Container-Common">
          <View style={style.container}>
            <HeaderMobile context="Home" />
            <Menu />
            <Image
              source={require("../assets/home-img.png")}
              style={{
                width: "90%",
                aspectRatio: 1.79,
                borderRadius: 8,
              }}
            />

            <Carousel context="NewInNEW" data={this.state.listaProdotti} />
            <Footer />
          </View>
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        {this.state.loading === true ? (
          <Loading />
        ) : (
          <>
            {this.props.desktop ? (
              <this.DesktopRender />
            ) : (
              <this.MobileRender />
            )}
          </>
        )}
      </>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});
export default function (props) {
  const t = useTranslation();
  const desktop = useDesktopChecker();
  const scrollHorizontal = useHorizontalScroll();
  return (
    <Home
      {...props}
      translation={t}
      scrollHorizontal={scrollHorizontal}
      desktop={desktop}
    />
  );
}
