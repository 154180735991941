import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import "../styles/ModificaPage.css";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";

import useDesktopChecker from "../components/utils/useDesktopChecker";
import HeaderMobile from "../pagesMobile/components/HeaderMobile";
import {
  isLittleDev,
  isNotMobile,
} from "../pagesMobile/components/utils/constants";
import Footer from "../components/Footer";

function Terms() {
  const { t } = useTranslation();
  const desktop = useDesktopChecker();
  const navigate = useNavigate();

  return (
    <div className="container-account-general">
      {desktop && <Header />}
      {!desktop && (
        <HeaderMobile context="Others" title={"Terms and Conditions"} />
      )}
      <div className="container-account-route">
        <p
          className="modifica-pass-title"
          style={{
            paddingInline: isNotMobile() ? 80 : 10,
            fontSize: isNotMobile() ? 16 : 12,
            textAlign: "justify",
          }}
        >
          <br />
          <b style={{ fontSize: "x-large" }}>Terms and conditions</b>
          <br />
          <br />
          <b>1. GENERALITY</b>
          <br />
          The offer and sale of products on the website{" "}
          <a href="http://app.wholekicks.eu">"http://app.wholekicks.eu"</a> are
          governed by these General Conditions of Sale. The products offered on{" "}
          <a href="http://app.wholekicks.eu">"http://app.wholekicks.eu"</a> are
          sold directly by Wholekicks (hereinafter the Company) with registered
          office in Sofia, Bulgaria Okolovrasten pat 42, 1415 VAT number
          BG206815177. You can request any information from Wholekicks Ltd. by
          writing to us at email address info@wholekicks.eu. Wholekicks may
          change the Terms of Sale from time to time, at its sole discretion.
          Those who intend to use the website will be deemed, by virtue of the
          use made, to have accepted to be bound by our Terms of use of the
          website and our Privacy Policy.
          <br />
          <b>2. WHO CAN MAKE PURCHASES</b>
          <br />
          The online platform connects buyers and sellers of wholesale shoes. By
          placing an order through the website, the buyer declares that in good
          faith and that they will purchase these products for commercial
          purposes or to gain other commercial advantage. If despite our best
          efforts, the product is no longer available or if the company
          believes, for legitimate reasons, that an order violates these Terms
          of Sale, the company may refuse the order.
          <br />
          <b>3. PRODUCT AVAILABILITY: QUANTITY</b>
          <br />
          All orders stipulated through the website are subject to the relative
          availability and acceptance by the company. The company reserves the
          right to change the range of items offered on the site and may limit,
          from time to time, the quantity of products that the customer can
          order for each purchase session. The product pages on the website
          provide all information relating to the products currently on sale
          through these channels. Products with the "Add to cart" button are
          available for sale through the website. By clicking on the “Request
          information” button, the company will provide you with further
          information on the availability of the products and will assist you in
          purchasing.
          <br />
          <b>4. ACCOUNT REGISTRATION; PERSONAL INFORMATIONS</b>
          <br />
          In order to place an order from the website, the Customer must
          register and create an internet account. To register, you will be
          required to provide valid and updated company information. After
          registering your personal account on the website, you will receive an
          e-mail message that will allow you to access your account on the
          website and create an account and a permanent password. The Customer
          is responsible for the confidentiality and security of the password
          and access data to his / her account. The company is not responsible
          for any abuse of the Customer's account resulting from the access and
          use of your password and account access data by third parties. The
          company reserves the right, at any time, to change the information
          required for placing a new order or registration requirements. Any
          changes will be indicated on the website. If the information provided
          on the website changes, please log into your account and update this
          information directly on the website. Furthermore, where the Company
          reasonably believes that a third party has violated a Client's
          registration, password and / or unauthorized access has been made, it
          will immediately delete and close that account and notify the Client
          by email or by telephone.
          <br />
          <b>5. ORDERS</b>
          <br />
          Once you have chosen a product, you can add it to your cart. It will
          then be possible to purchase additional products and add them to the
          cart (depending on availability and quantity limits). It will also be
          possible to delete one or more products in the cart. You will be asked
          to provide personal information, including the shipping address,
          billing address and payment details. The Client guarantees that all
          personal information provided to the Company is true and correct. For
          security reasons and to prevent any fraud, the company may request
          further information. In the event that the items ordered are not
          available, or if for some reason the order cannot be processed as
          requested by the Customer, the Company will promptly notify the
          Customer.
          <br />
          <b>6. PRICES, TAXES</b>
          <br />
          All prices indicated on the product pages of the website are inclusive
          of VAT, but net of other taxes, unless otherwise indicated. The
          currency used will be updated based on the shipping destination after
          receiving the delivery address. The company reserves the right to
          change the prices of the products at any time and without notice. The
          actual VAT applied to purchases will be calculated at the time your
          order is actually shipped, at which time you receive an Order
          Confirmation (as defined in Clause 10).
          <br />
          <b>7. VERIFICATION AND CONFIRMATION BY THE CUSTOMER</b>
          <br />
          The Customer is required to carefully check all the details before
          proceeding with the order from the website, including, without
          limitation, the details of each purchase. Once the order verification
          is complete, check the box “I have read and accept the terms and
          conditions of the website” and then click on “Place order” or a
          similar button on the Website to place the order. Unless otherwise
          stated in these Terms of Sale, the Company reserves the right to
          refuse, cancel and cancel orders at any time for legitimate reasons.
          For example, the Company may refuse, cancel or cancel an order in the
          event of an ongoing dispute relating to a payment for a previous
          order, or if it believes that the Customer has violated the conditions
          of sale.
          <br />
          <b>8. PAYMENT</b>
          <br />
          At the time of purchase, customers can pay through their Stripe
          account or by bank transfer. Payment by bank transfer Orders placed
          through the website can be paid by bank transfer. Always indicate the
          order reference number in the bank transfer form. After placing the
          order and selecting the bank transfer as the payment method, the
          Company will wait for the receipt of the payment before proceeding to
          process the order. If the amount to be paid is not credited to our
          bank account within seven (7) working days following the order, the
          order will be canceled. The Company will not charge any fees for
          payments made by bank transfer. Please make sure that the Company
          receives the full amount to be paid specified in the Order Receipt
          before fulfilling the order placed. Please note that some financial
          institutions may charge fees for making bank transfers. Invoices When
          ordering products on the site, as a customer, you will have the option
          to choose to receive an invoice to your billing address. Safety The
          Company takes precautions, including administrative and technical
          measures, for the protection of personal information against loss,
          theft, abuse and against unauthorized access, disclosure, alteration
          and destruction. The company uses the encryption system ………………. for
          all internet pages where the inclusion of personal information is
          required. The company undertakes to protect the Customer's personal
          information for the entire online sales process. However, it is not
          possible to guarantee the security and integrity of the data
          transmitted to the website and therefore the Company cannot be held
          responsible for damages or losses deriving from the use of the
          internet, as in the case of hacking (illegal intrusion into computer
          systems).
          <br />
          <b>9. NOTICE OF RECEIPT ORDER</b>
          <br />
          Once you have placed your order through the website, you will receive
          an order notice by email containing the details of the order,
          containing an order reference number assigned by the Company. This
          acknowledgment of receipt of order does not constitute acceptance of
          the order placed. After the transmission of the notice of receipt of
          the order, the Company will carry out the normal usual security checks
          and then proceed with the management of the order placed.
          <br />
          <b>10. ORDER CONFIRMATION</b>
          <br />
          Upon shipment of the product you have ordered and when the company has
          completed the security checks, it will send a written Order
          Confirmation by email. The Company will take care to keep the
          information relating to all orders as required by current legislation.
          These documents are accessible to the customer on the website in his
          personal area.
          <br />
          <b>11. PROOF OF CONSENT</b>
          <br />
          The receipt of the order and the confirmation of the order that are
          sent by e-mail are considered received when it is possible to access
          them from the computer, mobile device, or any other devices owned by
          the customer. The Order Confirmation is proof of a binding agreement
          with the Company.
          <br />
          12. MANUFACTURER'S WARRANTY AND RETURNS
          <br />
          The company takes extreme care that each product rigorously complies
          with the company's quality criteria and that it has passed all tests,
          both in a functional and aesthetic sense. The products chosen are
          covered by the Company Guarantee. For this reason it will not be
          possible to make any returns.
          <br />
          <b>13. LEGAL WARRANTY</b>
          <br />
          The consumer has legal rights according to the national legislation in
          force which regulates the sale of consumer goods; these legal rights
          are not affected by these Conditions of Sale.
          <br />
          <b>14. PRICE ACCURACY, PRODUCT DESCRIPTIONS</b>
          <br />
          The company pays the utmost attention to ensuring that the information
          published on the website is accurate. However, the Company makes no
          warranties, either expressed or implied, as to the accuracy,
          reliability and completeness of this information. The Company does not
          confirm the price of a product until the order confirmation has been
          issued, which confirms the existence of a binding sales contract.
          Every effort will be made to ensure that the descriptions, photographs
          or graphic representations of the products on the website are as
          accurate as possible. The Company declines all responsibility for the
          possibility that, due to a particular configuration of the computer
          used by the Customer or its malfunction, the colors of the products
          displayed on the site may be slightly different from the original
          ones.
          <br />
          <b>15. FINAL PROVISIONS</b>
          <br />
          Information and complaints.
          <br />
          In the event of a complaint, we invite the customer to contact us by
          email info@wholekicks.eu. Online dispute resolution. As a consumer
          resident in the European Union, the Customer has the right to lodge a
          complaint with an alternative dispute resolution body. To find a list
          of such bodies in your country, you can refer to the European
          Commission's online dispute resolution platform. Indivisibility of the
          contract. The Conditions of Sale, including the policies contained
          therein, constitute the whole of the contract between the Customer and
          the Company for the purchase of products from the website, and cancel
          and replace all the previous conditions of sale. Severability In the
          event that a provision of the Conditions of Sale is held partially
          null or inapplicable by a Court for any reason, it will be null or
          inapplicable exclusively to this extent only, and the validity and
          enforceability of any of the other provisions of the Conditions of
          Sale they will not be altered.
          <br />
          <b>16. JURISDICTION CLAUSE</b>
          <br />
          These Conditions of Sale are governed and interpreted in accordance
          with Bulgarian laws without reference to the conflict of legal
          provisions, and with the exclusion of the International Sale of Goods
          Convention.
          <br />
          <b>17. JURISDICTION</b>
          <br />
          You can initiate proceedings against the Company in the country in
          which you reside. The Company may also initiate proceedings against
          the Client in the courts of the country in which the Client resides.
          Without any limitation to the right to initiate proceedings before a
          Court, the Company or the customer will first of all put all the
          efforts necessary for a period of thirty (30) days to settle in an
          amicable way any dispute or disagreement that may arise on the
          product. , on the Conditions of Sale for any violation of the latter.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
