import React, { Component } from "react";
import { Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";
import HeaderMobile from "../components/HeaderMobile";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import CONSTANTS from "../../CONSTANTS";
import LOGOUTFUNCTION from "../../LOGOUTFUNCTION";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UtenteId: localStorage.getItem(CONSTANTS.ID),
      nomeUtente: localStorage.getItem(CONSTANTS.NOME),
      cognomeUtente: localStorage.getItem(CONSTANTS.COGNOME),
      emailUtente: localStorage.getItem(CONSTANTS.EMAIL),
    };
  }

  componentDidMount() {}
  render() {
    const { t } = this.props;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Others" title="Account" />
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              paddingVertical: 10,
              backgroundColor: "#7A79CD",
            }}
          >
            <View
              style={{
                padding: 5,
                borderRadius: 5,
                width: "20%",
                backgroundColor: "white",
                marginLeft: "10%",
              }}
            >
              <Image
                source={require("../../assets/mobile/logo_wholekicks.png")}
                style={{ height: 50, width: "100%", resizeMode: "contain" }}
              />
            </View>
            <View style={{ marginLeft: "5%" }}>
              <Text style={{ color: "white", fontWeight: "bold" }}>
                {this.state.nomeUtente} {this.state.cognomeUtente}
              </Text>
              <Text style={{ color: "white" }}>{this.state.emailUtente}</Text>
            </View>
          </View>
          <View
            style={{ width: "100%", paddingHorizontal: "5%", marginTop: 10 }}
          >
            <Link style={{ textDecoration: "none" }} to="/account/vendite">
              <TouchableOpacity
                style={{
                  marginTop: 15,
                  color: "black",
                  backgroundColor: "#DEE4FD",
                  padding: 12,
                  borderRadius: 5,
                }}
              >
                <Text style={{ fontSize: "1.2rem" }}>{t("sell")}</Text>
              </TouchableOpacity>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/account/acquisti">
              <TouchableOpacity
                style={{
                  marginTop: 15,
                  color: "black",
                  backgroundColor: "#DEE4FD",
                  padding: 12,
                  borderRadius: 5,
                  fontSize: "1.2rem",
                }}
              >
                <Text style={{ fontSize: "1.2rem" }}>{t("purchase")}</Text>
              </TouchableOpacity>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/account/storico">
              <TouchableOpacity
                style={{
                  marginTop: 15,
                  color: "black",
                  backgroundColor: "#DEE4FD",
                  padding: 12,
                  borderRadius: 5,
                  fontSize: "1.2rem",
                }}
              >
                <Text style={{ fontSize: "1.2rem" }}>{t("history")}</Text>
              </TouchableOpacity>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/account/impostazioni">
              <TouchableOpacity
                style={{
                  marginTop: 15,
                  color: "black",
                  backgroundColor: "#DEE4FD",
                  padding: 12,
                  borderRadius: 5,
                  fontSize: "1.2rem",
                }}
              >
                <Text style={{ fontSize: "1.2rem" }}>{t("setting")}</Text>
              </TouchableOpacity>
            </Link>
          </View>
        </View>
        <TouchableOpacity style={style.btnBottom} onPress={LOGOUTFUNCTION}>
          <Text style={style.textBtn}>Logout</Text>
        </TouchableOpacity>
      </div>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  btnBottom: {
    position: "absolute",
    bottom: "5%",
    width: "80%",
    paddingHorizontal: "10%",
    backgroundColor: "#0A09A2",

    textAlign: "center",
    height: 50,
    marginHorizontal: "10%",
    justifyContent: "center",
    borderRadius: 7,
  },
  textBtn: {
    fontWeight: "400",
    fontSize: "1.3rem",
    color: "white",
  },
});

export default withTranslation()(Profile);
