import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import AccountRoutes from "../components/AccountRoutes";
import Header from "../components/Header";
import "../styles/Account.css";
import HeaderMobile from "../pagesMobile/components/HeaderMobile";
import { View, Text, TouchableOpacity, StyleSheet, Image } from "react-native";
import { Link } from "react-router-dom";
import LOGOUTFUNCTION from "../LOGOUTFUNCTION";
import { useTranslation } from "react-i18next";
import useDesktopChecker from "../components/utils/useDesktopChecker";
import CONSTANTS from "../CONSTANTS";
class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UtenteId: localStorage.getItem(CONSTANTS.ID),
      nomeUtente: localStorage.getItem(CONSTANTS.NOME),
      cognomeUtente: localStorage.getItem(CONSTANTS.COGNOME),
      emailUtente: localStorage.getItem(CONSTANTS.EMAIL),
    };
  }
  MobileRender = () => {
    const { t } = this.props.t;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Others" title="Account" />
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              paddingVertical: 10,
            }}
          >
            <View
              style={{
                padding: 5,
                borderRadius: 5,
                width: "20%",
                backgroundColor: "white",
                marginLeft: "10%",
                shadowRadius: 8,
                shadowOpacity: 0.1,
              }}
            >
              <Image
                source={require("../assets/mobile/logo_wholekicks.png")}
                style={{ height: 50, width: "100%", resizeMode: "contain" }}
              />
            </View>
            <View style={{ marginLeft: "5%" }}>
              <Text style={{ color: "#222", fontWeight: "bold" }}>
                {this.state.nomeUtente} {this.state.cognomeUtente}
              </Text>
              <Text style={{ color: "white" }}>{this.state.emailUtente}</Text>
            </View>
          </View>
          <View
            style={{ width: "100%", paddingHorizontal: "5%", marginTop: 10 }}
          >
            <Link style={{ textDecoration: "none" }} to="/account/vendite">
              <TouchableOpacity
                style={{
                  marginTop: 15,
                  color: "black",
                  backgroundColor: "#f2f2f2",
                  padding: 12,
                  borderRadius: 5,
                }}
              >
                <Text style={{ fontSize: "1.2rem" }}>{t("sell")}</Text>
              </TouchableOpacity>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/account/acquisti">
              <TouchableOpacity
                style={{
                  marginTop: 15,
                  color: "black",
                  backgroundColor: "#f2f2f2",
                  padding: 12,
                  borderRadius: 5,
                  fontSize: "1.2rem",
                }}
              >
                <Text style={{ fontSize: "1.2rem" }}>{t("purchase")}</Text>
              </TouchableOpacity>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/account/storico">
              <TouchableOpacity
                style={{
                  marginTop: 15,
                  color: "black",
                  backgroundColor: "#f2f2f2",
                  padding: 12,
                  borderRadius: 5,
                  fontSize: "1.2rem",
                }}
              >
                <Text style={{ fontSize: "1.2rem" }}>{t("history")}</Text>
              </TouchableOpacity>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/account/impostazioni">
              <TouchableOpacity
                style={{
                  marginTop: 15,
                  color: "black",
                  backgroundColor: "#f2f2f2",
                  padding: 12,
                  borderRadius: 5,
                  fontSize: "1.2rem",
                }}
              >
                <Text style={{ fontSize: "1.2rem" }}>{t("setting")}</Text>
              </TouchableOpacity>
            </Link>
          </View>
        </View>
        <TouchableOpacity style={style.btnBottom} onPress={LOGOUTFUNCTION}>
          <Text style={style.textBtn}>Logout</Text>
        </TouchableOpacity>
      </div>
    );
  };
  render() {
    return (
      <>
        {this.props.desktop ? (
          <div className="container-account-general">
            <Header />
            <div className="container-account-route">
              <AccountRoutes />
              <Outlet />
            </div>
          </div>
        ) : (
          <this.MobileRender />
        )}
      </>
    );
  }
}
const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  btnBottom: {
    position: "absolute",
    bottom: "5%",
    width: "80%",
    paddingHorizontal: "10%",
    backgroundColor: "#0A09A2",

    textAlign: "center",
    height: 50,
    marginHorizontal: "10%",
    justifyContent: "center",
    borderRadius: 7,
  },
  textBtn: {
    fontWeight: "400",
    fontSize: "1.3rem",
    color: "white",
  },
});
export default function (props) {
  const t = useTranslation();
  const desktop = useDesktopChecker();

  return <Account t={t} desktop={desktop} />;
}
