import React, { Component } from "react";
import Loading from "../components/Loading";
import CONSTANTS from "../CONSTANTS";
import { useLocation, useNavigate, useParams } from "react-router-dom";

class SocialRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
    };
  }

  componentDidMount() {
    console.log("device", this.getMobileOperatingSystem());
    this.getMobileOperatingSystem() === "iOS" ||
    this.getMobileOperatingSystem() === "MacOS"
      ? (window.location.href =
          "https://apps.apple.com/it/app/wholekicks/id1673024243")
      : (window.location.href =
          "https://play.google.com/store/apps/details?id=com.pura.wholekicks");
  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    var platform = navigator.platform;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    } else if (/android/i.test(userAgent)) {
      return "Android";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    } else if (/win/i.test(platform)) {
      return "Windows";
    } else if (/mac/i.test(platform)) {
      return "MacOS";
    } else if (/linux/i.test(platform)) {
      return "Linux";
    } else {
      return "Unknown";
    }
  }

  render() {
    return (
      <>
        <Loading />
      </>
    );
  }
}

export default function (props) {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <SocialRedirect
      props={props}
      params={params}
      location={location}
      navigate={navigate}
    />
  );
}
