import React, { Component } from "react";
import HeaderMobile from "../../components/HeaderMobile";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
} from "react-native";
import { withTranslation } from "react-i18next";
import { changeEmail } from "./FunctionProfile";
class ModificaMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldEmail: "",
      newEmail: "",
      confemail: "",
    };
  }
  render() {
    const { t } = this.props;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Others" title={t("edit_mail")} />
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
            }}
          >
            <Text
              style={{
                textAlign: "center",
                fontSize: "1.2rem",
                marginTop: 10,
                marginBottom: "20%",
              }}
            >
              {t("fill_fields")}
            </Text>
            {/*<View
              style={{
                width: "90%",
                borderWidth: 1,
                borderColor: "#7A79CD",
                borderRadius: 7,
                marginVertical: 10,
                padding: 10,
              }}
            >
             <TextInput
                placeholder="Old Email"
                style={{
                  width: "100%",
                  fontSize: "1.2rem",
                }}
              />
              </View>*/}
            <View
              style={{
                width: "90%",
                borderWidth: 1,
                borderColor: "#7A79CD",
                borderRadius: 7,
                marginVertical: 10,
                padding: 10,
              }}
            >
              <TextInput
                style={{
                  width: "100%",
                  fontSize: "1.2rem",
                }}
                placeholder="New Mail"
                onChange={(e) => {
                  this.setState({ newEmail: e.target.value });
                }}
              />
            </View>
            <View
              style={{
                width: "90%",
                borderWidth: 1,
                borderColor: "#7A79CD",
                borderRadius: 7,
                marginVertical: 10,
                padding: 10,
              }}
            >
              <TextInput
                style={{
                  width: "100%",
                  fontSize: "1.2rem",
                }}
                placeholder="Confirm Mail"
                onChange={(e) => {
                  this.setState({ confEmail: e.target.value });
                }}
              />
            </View>
          </View>
          <TouchableOpacity
            style={style.btnConf}
            onPress={() =>
              changeEmail(this.state.newEmail, this.state.confEmail)
            }
          >
            <Text style={style.btnText}>Confirm</Text>
          </TouchableOpacity>
        </View>
      </div>
    );
  }
}
const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "80%",
  },
  btnConf: {
    width: "90%",
    borderRadius: 7,
    backgroundColor: "#0A09A2",
    paddingVertical: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  btnText: {
    color: "white",
    fontSize: "1.2rem",
  },
});

export default withTranslation()(ModificaMail);
