import React, { Component } from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  Modal,
  TextInput,
  TouchableOpacity,
} from "react-native";
import HeaderMobile from "../components/HeaderMobile";
import * as api from "../api/service";
import { Link, useNavigate } from "react-router-dom";
import PasswordInput from "../login/PasswordInput";
import { withTranslation } from "react-i18next";
import Nazioni from "../../Nazioni";
import CONSTANTS from "../../CONSTANTS";
import { sendMail } from "../../ServicesApi";
import axios from "axios";
import uploadPng from "../../assets/mobile/upload.png";
import "./style.css";

class Registrazione extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSelector: 0,
      visura: "",
      registrationValue: {
        nome: "",
        cognome: "",
        indirizzo: "",
        numeroTelefono: "",
        paese: "",
        city: "",
        cap: "",
        civico: "",
        visura: {},
        ragioneSociale: "",
        sedeLegale: "",
        partitaIva: "",
        iban: "",
        ruolo: "USER",
        email: "",
        password: "",
        confermaPassword: "",
      },
    };
  }

  changePass = (e) => {
    this.setState({
      registrationValue: {
        ...this.state.registrationValue,
        password: e.target.value,
      },
    });
    console.log(this.state.registrationValue.password);
  };

  ConfchangePass = (e) => {
    this.setState({
      registrationValue: {
        ...this.state.registrationValue,
        confermaPassword: e.target.value,
      },
    });
    console.log(this.state.registrationValue.confermaPassword);
  };

  onClickBack = () => {
    this.setState({ pageSelector: this.state.pageSelector - 1 });
  };
  onClickContinua = (e) => {
    e.preventDefault();
    this.setState({ pageSelector: this.state.pageSelector + 1 });
    console.log(this.state.registrationValue);
  };

  onClickRegistrati = async (data1) => {
    if (this.state.registrationValue.email != "") {
      const data = this.state.registrationValue;
      console.log("data2", data);
      console.log(this.state.visura.File);
      let formData = new FormData();
      formData.append("nome", data.nome);
      formData.append("cognome", data.cognome);
      formData.append("indirizzo", data.indirizzo);
      formData.append("numeroTelefono", data.numeroTelefono);
      formData.append("paese", data.paese);
      formData.append("cap", data.cap);
      formData.append("civico", data.civico);
      formData.append("ragioneSociale", data.ragioneSociale);
      formData.append("sedeLegale", data.sedeLegale);
      formData.append("partitaIva", data.partitaIva);
      formData.append("iban", data.iban);
      formData.append("email", data.email);
      formData.append("password", data.password);
      formData.append("confermaPassword", data.confermaPassword);
      formData.append("visura", this.state.visura);
      formData.append("ruolo", "USER");

      await axios
        .post(CONSTANTS.API_URL + "/account", formData)
        .then((res) => {
          console.log(res.data.successo);

          const body = {
            email: data.email,
            subject: "Registration",
            text:
              "<div></div> <br> We successfully received your request. <br><br> Our team will analyse your data and you will receive an email as soon as possible. <br><br> " +
              "If we'll need no further information about your company, you will receive a confirmation email<br> and will be able to start buying and selling on our platform.<br><br>" +
              "<br>Thank you,<br> Stephan,<br> Customer Service Wholekicks LTD<br> ",
          };
          sendMail(body);
          //  this.routeChange("registrazione/conferma-registrazione");
        })
        .catch((e) => {
          console.log(e.message);
        });
      this.setState({ pageSelector: this.state.pageSelector + 1 });
    } else {
      alert("Insert email");
    }
  };
  onSubmitField = (e) => {
    console.log("e.target.file[0].name", e);
    this.setState({
      visura: e,
    });
  };

  RegistrazioneFirst = () => {
    const { t } = this.props;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Home" />
          <form
            onSubmit={(e) => this.onClickContinua(e)}
            style={{ width: "100%" }}
          >
            <View style={style.containerInput}>
              <Text style={style.title}>{t("registration")}</Text>
              <input
                onChange={(e) => {
                  this.setState({
                    registrationValue: {
                      ...this.state.registrationValue,
                      nome: e.target.value,
                    },
                  });
                  // console.log(this.state.registrationValue.nome);
                }}
                className="input-text"
                required
                placeholder={t("name")}
              />
              <input
                onChange={(e) => {
                  this.setState({
                    registrationValue: {
                      ...this.state.registrationValue,
                      cognome: e.target.value,
                    },
                  });
                  // console.log(this.state.registrationValue.cognome);
                }}
                className="input-text"
                required
                placeholder={t("surname")}
              />
              <input
                onChange={(e) => {
                  this.setState({
                    registrationValue: {
                      ...this.state.registrationValue,
                      paese: e.target.value,
                    },
                  });
                  // console.log(this.state.registrationValue.paese);
                }}
                className="input-text"
                required
                placeholder={t("country")}
              />
              <input
                onChange={(e) => {
                  this.setState({
                    registrationValue: {
                      ...this.state.registrationValue,
                      city: e.target.value,
                    },
                  });
                  // console.log(this.state.registrationValue.paese);
                }}
                className="input-text"
                required
                placeholder={t("city")}
              />
              <input
                onChange={(e) => {
                  this.setState({
                    registrationValue: {
                      ...this.state.registrationValue,
                      indirizzo: e.target.value,
                    },
                  });
                  // console.log(this.state.registrationValue.indirizzo);
                }}
                className="input-text"
                required
                placeholder={t("address")}
              />
              <input
                onChange={(e) => {
                  this.setState({
                    registrationValue: {
                      ...this.state.registrationValue,
                      civico: e.target.value,
                    },
                  });
                  // console.log(this.state.registrationValue.civico);
                }}
                type="number"
                className="input-text"
                required
                placeholder={t("address_nr")}
              />
              <input
                onChange={(e) => {
                  this.setState({
                    registrationValue: {
                      ...this.state.registrationValue,
                      cap: e.target.value,
                    },
                  });
                  // console.log(this.state.registrationValue.cap);
                }}
                className="input-text"
                required
                placeholder="Cap"
              />
              <input
                onChange={(e) => {
                  this.setState({
                    registrationValue: {
                      ...this.state.registrationValue,
                      numeroTelefono: e.target.value,
                    },
                  });
                  // console.log(this.state.registrationValue.numeroTelefono);
                }}
                className="input-text"
                required
                placeholder={t("tel")}
              />
            </View>

            <button type="submit" className="btn-login-reg">
              <Text style={style.titleBtn}>{t("next")}</Text>
            </button>
          </form>
        </View>
      </div>
    );
  };

  RegistrazioneSecond = () => {
    const { t } = this.props;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Home" />
          <form
            onSubmit={(e) => this.onClickContinua(e)}
            style={{ width: "100%", height: "85%" }}
          >
            <View style={style.containerInput}>
              <Text style={style.title}>{t("registration")}</Text>
              <input
                required
                onChange={(e) => {
                  this.setState({
                    registrationValue: {
                      ...this.state.registrationValue,
                      ragioneSociale: e.target.value,
                    },
                  });
                  // console.log(this.state.registrationValue.ragioneSociale);
                }}
                className="input-text"
                placeholder={t("comp_name")}
              />
              <input
                required
                onChange={(e) => {
                  this.setState({
                    registrationValue: {
                      ...this.state.registrationValue,
                      sedeLegale: e.target.value,
                    },
                  });
                  // console.log(this.state.registrationValue.sedeLegale);
                }}
                className="input-text"
                placeholder={t("head_office")}
              />
              <input
                required
                onChange={(e) => {
                  this.setState({
                    registrationValue: {
                      ...this.state.registrationValue,
                      partitaIva: e.target.value,
                    },
                  });
                  // console.log(this.state.registrationValue.partitaIva);
                }}
                className="input-text"
                placeholder={t("vat_number")}
              />
              <input
                onChange={(e) => {
                  this.setState({
                    registrationValue: {
                      ...this.state.registrationValue,
                      iban: e.target.value,
                    },
                  });
                  // console.log(this.state.registrationValue.iban);
                }}
                className="input-text"
                required
                placeholder="Iban"
              />
              <label
                htmlFor="file-certificate"
                className="reg-mobile-upload-png"
              >
                <img src={uploadPng} alt="upload icon" />
                Upload certificate Of Incorporation:{" "}
                <input
                  name="visura"
                  type="file"
                  id="file-certificate"
                  required
                  className={"form-no-margin"}
                  onChange={(e) => this.onSubmitField(e.target.files[0])}
                />
              </label>
            </View>

            <button className="btn-login-reg" type="submit">
              <Text style={style.titleBtn}>{t("next")}</Text>
            </button>
          </form>
        </View>
      </div>
    );
  };

  RegistrazioneThird = () => {
    const { t } = this.props;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Home" />
          <View style={style.containerInputSubmit}>
            <Text style={style.title}>{t("registration")}</Text>
            <TextInput
              onChange={(e) => {
                this.setState({
                  registrationValue: {
                    ...this.state.registrationValue,
                    email: e.target.value,
                  },
                });
                // console.log(this.state.registrationValue.email);
              }}
              style={style.inputText}
              placeholder="Email"
            />
            <PasswordInput
              value={this.state.registrationValue.password}
              handleChange={this.changePass}
            />
            <PasswordInput
              value={this.state.registrationValue.confermaPassword}
              handleChange={this.ConfchangePass}
              placeholder="Confirm Password"
            />
          </View>

          <TouchableOpacity
            style={style.btnLogin}
            onPress={this.onClickRegistrati}
          >
            <Text style={style.titleBtn}>{t("confirm")}</Text>
          </TouchableOpacity>
        </View>
      </div>
    );
  };

  RegistrazioneFourth = () => {
    const { t } = this.props;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Home" />
          <View style={style.containerInputSubmit}>
            <Text style={style.title}>
              {t("confirm")} {t("registration")}
            </Text>
            <Text style={{ width: "90%", textAlign: "center" }}>
              {t("conf_reg_1")}
              {t("conf_reg_2")}
            </Text>
          </View>
          <Link
            to="/"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <TouchableOpacity
              style={style.btnLogin}
              onPress={this.onClickContinua}
            >
              <Text style={style.titleBtn}>{t("back_home")}</Text>
            </TouchableOpacity>
          </Link>
        </View>
      </div>
    );
  };

  render() {
    return (
      <div className="Container-Common">
        {this.state.pageSelector === 0 && <this.RegistrazioneFirst />}
        {this.state.pageSelector === 1 && <this.RegistrazioneSecond />}
        {this.state.pageSelector === 2 && <this.RegistrazioneThird />}
        {this.state.pageSelector === 3 && <this.RegistrazioneFourth />}
      </div>
    );
  }
}

//  Registrazione =() => {
//   return (
//     <div className="Container-Common">
//       <View style={style.container}>
//         <HeaderMobile context="Home" />
//         <View style={style.containerInput}>
//           <Text style={style.title}>Registrazione</Text>
//           <TextInput style={style.inputText} placeholder="Nome" />
//           <TextInput style={style.inputText} placeholder="Cognome" />
//           <TextInput style={style.inputText} placeholder="Indirizzo" />
//           <TextInput style={style.inputText} placeholder="Numero di telefono" />
//           <TextInput style={style.inputText} placeholder="Paese" />
//           <TextInput style={style.inputText} placeholder="Cap" />
//         </View>
//         <Link
//           style={{
//             width: "100%",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             textDecoration: "none",
//           }}
//           to="/checkout-step-2"
//         >
//           <TouchableOpacity style={style.btnLogin}>
//             <Text style={style.titleBtn}>Continua</Text>
//           </TouchableOpacity>
//         </Link>
//       </View>
//     </div>
//   );
// }

export default withTranslation()(Registrazione);
const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  containerInput: {
    paddingTop: "2%",
    height: "93%",
    alignItems: "center",
    width: "100%",
  },

  containerInputSubmit: {
    paddingTop: "2%",
    height: "83%",
    alignItems: "center",
    width: "100%",
  },

  containerInputDue: {
    paddingTop: "10%",
    marginTop: "2%",
    alignItems: "center",
    width: "100%",
    borderTopColor: "#7A79CD",
    borderTopWidth: 2,
  },

  inputText: {
    height: 50,
    width: "90%",
    borderWidth: 1,
    borderColor: "#7A79CD",
    padding: 10,
    marginVertical: 10,
    borderRadius: 7,
  },
  title: {
    fontSize: "1.3rem",
    width: "90%",
    marginBottom: "1%",
    fontWeight: "bold",
    textAlign: "center",
  },

  btnLogin: {
    width: "90%",

    height: 40,
    backgroundColor: "#0a09a2",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 7,
    marginBottom: "5%",
  },
  titleBtn: {
    color: "white",
    fontSize: 20,
  },

  btnTouch: {
    width: "90%",
  },
});
