import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableWithoutFeedback,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
  Keyboard,
  TextInput,
  StyleSheet,
  Modal,
  Image,
} from "react-native";

import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate, useParams } from "react-router-dom";
import { colors, dim, isLittleDev } from "../components/utils/constants";
import * as api from "../api/service";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Accordion } from "react-bootstrap";
import "./style.css";
import {
  isAutenticated,
  getCallerName,
} from "../../components/utils/validatorActions";

const ModalSearchMobile = ({
  modalAdd,
  onModalDismiss,
  utente,
  onPressModalTools,
  onPressVisualizzatore,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [prodotti, setProdotti] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {}, [prodotti]);
  const searchFunctionKeyUp = async (parola) => {
    if (parola === "") {
      setProdotti([]);
    } else {
      await axios
        .get(
          CONSTANTS.API_URL +
            "/prodotto/lotto-listato-search/" +
            parola +
            "/" +
            utente,
        )
        .then((prodotti) => {
          console.log(prodotti);
          setProdotti(prodotti.data);
        });
    }
  };
  const closeModal = (prodotto) => {
    onModalDismiss();
    const item = {
      AccountId: utente,
      ProdottoId: prodotto.prodottoId,
      id: prodotto.id,
      payout: prodotto.payout,
      prezzo: prodotto.prezzo,
      prodotto: { id: prodotto.prodottoId },
      quantita: prodotto.quantita,
      riferimento: prodotto.riferimento,
    };
    onPressModalTools(item);
  };
  const deleteLotto = async (id, key) => {
    const isAvailable = await axios.get(
      CONSTANTS.API_URL + "/lotto/lotto-is-available/" + id,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY,
          )}`,
        },
      },
    );
    console.log("isAv", isAvailable);
    if (isAvailable.data) {
      axios
        .delete(CONSTANTS.API_URL + "/lotto/delete-lotto/" + id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              CONSTANTS.ACCESS_TOKEN_KEY,
            )}`,
          },
        })
        .then((prodottoRicevuto) => {
          const prodNew = prodotti.filter((p) => p.id != id);
          console.log("Eccolo", prodNew);
          setProdotti(prodNew);
        });
    } else {
      alert(t("you_not_delete"));
    }
  };
  return (
    <>
      {!loading && (
        <>
          <Modal
            visible={modalAdd}
            transparent
            animationType="slide"
            className="modale-ricerca-vendite"
          >
            <View
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!isLittleDev() && (
                <TouchableWithoutFeedback onPress={onModalDismiss}>
                  <View style={{ height: "6%", width: "100%" }} />
                </TouchableWithoutFeedback>
              )}
              <ScrollView
                style={{
                  height: isLittleDev() ? "100%" : "94%",
                  maxHeight: "70vh",
                  width: "90vw",
                  backgroundColor: "#fff",
                  borderRadius: isLittleDev() ? 15 : 30,
                  shadowRadius: 10,
                  shadowOpacity: 0.5,
                  paddingHorizontal: 10,
                  paddingVertical: 20,
                }}
                contentContainerStyle={{
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <View style={{ width: "100%", alignItems: "flex-end" }}>
                  <TouchableOpacity onPress={onModalDismiss}>
                    <Text style={{ fontWeight: "bold", color: colors.blue }}>
                      {t("close")}
                    </Text>
                  </TouchableOpacity>
                </View>
                <div
                  style={{
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <label className="search-bar-mobile">
                    <SearchIcon className="search-icon" />

                    <input
                      type="search"
                      placeholder={t("search_stock")}
                      onKeyUp={(e) => searchFunctionKeyUp(e.target.value)}
                      className="search-bar-input"
                    />
                  </label>
                </div>
                {prodotti.length > 0 ? (
                  prodotti.map((item, key) => {
                    return (
                      <View style={style.containerProposte}>
                        <Image
                          style={{
                            width: "30%",
                            resizeMode: "center",
                            height: "100%",
                            marginRight: "3%",
                          }}
                          source={{ uri: item.imageProdotto }}
                        />
                        <View style={style.containerLabelProdotto}>
                          <Text style={style.titleContProd}>{item.nome}</Text>
                          <Text style={style.SubTitleContProd}>
                            {item.modello}
                          </Text>
                          <View style={style.containerShipping}>
                            <View>
                              <Text style={style.boldLabel}>
                                {t("stock_n")} <br />
                              </Text>
                              <Text style={{ color: "red" }}>{item.id}</Text>
                              <Text style={style.boldLabel}>
                                {t("price")} <br />
                              </Text>
                              <Text>{item.payout}€</Text>
                            </View>
                            <View style={{ marginLeft: 30 }}>
                              <Text style={style.boldLabel}>
                                {t("qty")}
                                <br />
                              </Text>
                              <Text>{item.quantita}</Text>
                            </View>
                            <View
                              style={{
                                flexGrow: 1,
                                alignItems: "flex-end",
                                paddingRight: 10,
                              }}
                            >
                              <TouchableOpacity
                                onPress={() => closeModal(item)}
                              >
                                <Text
                                  style={{
                                    marginRight: 10,
                                    alignSelf: "flex-end",
                                    color: "rgb(10, 9, 162)",
                                    fontSize: "1.2rem",
                                    fontWeight: "900",
                                    lineHeight: 8,
                                  }}
                                >
                                  .<br />.<br />.
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    );
                  })
                ) : (
                  <></>
                )}
              </ScrollView>
            </View>
          </Modal>
        </>
      )}
    </>
  );
};

const style = StyleSheet.create({
  containerView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  textBold: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  textNotBold: {
    fontSize: "1rem",
    marginLeft: 5,
  },
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  HeaderMobileProfile: {
    flexDirection: "row",
    width: "90%",
    height: "fit-content",
    paddingVertical: 13,
    borderBottomColor: "#7A79CD",
    flexGrow: "none",
    borderBottomWidth: 1.5,
  },
  containerProposte: {
    width: "90%",
    marginTop: 10,
    paddingBottom: 8,
    marginVertical: 20,
    flexDirection: "row",
    borderBottomColor: "#7A79CD",
    borderBottomWidth: 1.5,
  },

  containerLabelProdotto: {
    width: "60%",
  },

  titleContProd: {
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
  containerShipping: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 10,
    alignItems: "baseline",
  },
  labelModalTools: {
    fontSize: "1.1rem",
    fontWeight: "800",
    marginBottom: 6,
  },
  buttonModalTools: {
    paddingVertical: 18,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderTopWidth: 1,
    borderColor: "#DEE4FD",
  },
  labelBtnTools: {
    fontWeight: "700",
    color: "#0A09A2",
  },
  NoboldLabel: {
    fontSize: "0.9rem",
  },
  boldLabel: {
    fontWeight: 700,
    fontSize: "0.9rem",
    marginTop: "5%",
  },
  mobileCompletaCheckoutButton: {
    borderWidth: 0,
    backgroundColor: "#0A09A2",
    color: "#fff",
    borderRadius: 8,
  },
});

export default ModalSearchMobile;
