import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Modal,
  Image,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
  Keyboard,
  TextInput,
  StyleSheet,
  TouchableWithoutFeedback,
} from "react-native";
import { useNavigate } from "react-router-dom";
import { colors, isLittleDev } from "../components/utils/constants";
import { useTranslation } from "react-i18next";
import "./style.css";

const ModalVisualizzaListati = ({
  modalAdd,
  onModalDismiss,
  lotto,
  taglie,
  t_backup,
  updateTaglie,
  deleteTaglie,
  addEmpty,
  edit,
  checkEdit,
  save,
  updateAll,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [add, setadd] = useState(false);
  const [size, setsize] = useState("3,5");
  const navigate = useNavigate();

  return (
    <>
      {!loading && (
        <>
          <Modal visible={modalAdd} transparent animationType="slide">
            <View
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              {!isLittleDev() && (
                <TouchableWithoutFeedback onPress={onModalDismiss}>
                  <View style={{ height: "10%", width: "100%" }} />
                </TouchableWithoutFeedback>
              )}
              <ScrollView
                style={{
                  height: isLittleDev() ? "100%" : "90%",
                  width: "100%",
                  backgroundColor: "#fff",
                  borderTopRightRadius: isLittleDev() ? 15 : 30,
                  borderTopLeftRadius: isLittleDev() ? 15 : 30,
                  shadowRadius: 10,
                  shadowOpacity: 0.5,
                  paddingHorizontal: 10,
                  paddingTop: 20,
                }}
              >
                <View style={{ width: "100%", alignItems: "flex-end" }}>
                  <Text style={style.labelModalTools}>
                    Stock n° {lotto?.id}
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {edit && (
                      <input
                        placeholder={t("average_price")}
                        type="number"
                        min="0"
                        onChange={(e) => {
                          updateAll(e.target.value);
                        }}
                      />
                    )}
                  </View>
                  <TouchableOpacity onPress={onModalDismiss}>
                    <Text style={{ fontWeight: "bold", color: colors.blue }}>
                      {t("close")}
                    </Text>
                  </TouchableOpacity>
                </View>
                {taglie.map((l, index) => (
                  <View
                    key={l.taglia}
                    style={{
                      width: "100%",
                      backgroundColor: "#f5f5f5",
                      borderRadius: 7,
                      padding: 10,
                      marginVertical: 5,
                    }}
                  >
                    <View
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: 7,
                        padding: 5,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          backgroundColor: "white",
                          borderRadius: 7,
                        }}
                      >
                        <View style={{ width: "50%", float: "left" }}>
                          <Text style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                            US {l.taglia}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "50%",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <TouchableOpacity
                            style={{ marginRight: 10 }}
                            onPress={(e) =>
                              updateTaglie(
                                l,
                                "quantita",
                                l.numero === 0 ? 0 : l.numero - 1,
                                "taglia" + "_" + index + "_",
                                index
                              )
                            }
                          >
                            <Text style={{ fontSize: "1.3rem" }}>-</Text>
                          </TouchableOpacity>
                          <TextInput
                            style={{
                              width: "30%",
                              fontSize: "1.3rem",
                              borderWidth: 1,
                              borderColor: "c4c4c4",
                              textAlign: "center",
                            }}
                            editable={edit}
                            value={l.numero}
                            onChange={(e) =>
                              updateTaglie(
                                l,
                                "quantita",
                                e.target.value,
                                "taglia" + "_" + index + "_",
                                index
                              )
                            }
                          />
                          <TouchableOpacity
                            style={{ marginLeft: 10 }}
                            onPress={(e) =>
                              updateTaglie(
                                l,
                                "quantita",
                                l.numero + 1,
                                "taglia" + "_" + index + "_",
                                index
                              )
                            }
                          >
                            <Text style={{ fontSize: "1.3rem" }}>+</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View style={{ marginTop: 10 }}>
                        <input
                          placeholder={t("price")}
                          style={{
                            width: "100%",
                            fontSize: "1.1rem",
                            paddingVertical: 5,
                            borderWidth: 1,
                            borderColor: "c4c4c4",
                            textAlign: "left",
                            paddingLeft: 10,
                          }}
                          disabled={!edit}
                          min={"0"}
                          value={l.prezzo}
                          onChange={(e) =>
                            updateTaglie(
                              l,
                              "prezzo",
                              e.target.value,
                              "taglia" + "_" + index + "_",
                              index
                            )
                          }
                        />
                      </View>
                      {edit && (
                        <TouchableOpacity
                          onPress={() => deleteTaglie(l)}
                          style={{ width: "100%", alignItems: "flex-end" }}
                        >
                          <Text
                            onPressIn={() => deleteTaglie(l)}
                            style={{
                              paddingVertical: 4,
                              fontSize: "0.85rem",
                              color: "#f00",
                            }}
                          >
                            Delete
                          </Text>
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>
                ))}
                {edit && (
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <View style={{ paddingVertical: 30, width: "30%" }}>
                      <Text
                        onPress={() => setadd(!add)}
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          color: "#0A09A2",
                        }}
                      >
                        Add new size +
                      </Text>
                      <View style={{ width: "100%" }}>
                        {add && (
                          <View style={{ marginTop: 10 }}>
                            <select
                              value={size}
                              style={{
                                fontSize: "0.8rem",
                                marginRight: 5,
                                width: "100%",
                                alignSelf: "center",
                              }}
                              onChange={(e) => setsize(e.target.value)}
                            >
                              {t_backup.map((item1, indx) => (
                                <option
                                  key={
                                    "taglia" + "_" + item1.taglia + "_" + indx
                                  }
                                  value={item1.taglia}
                                >
                                  US{" " + item1.taglia}
                                </option>
                              ))}
                            </select>
                            <button
                              style={{
                                borderWidth: 0,
                                backgroundColor: "#0A09A2",
                                fontSize: "0.8rem",
                                alignSelf: "center",
                                width: "100%",
                                paddingTop: "1%",
                                paddingBottom: "1%",
                                color: "#fff",
                                marginBottom: 12,
                                marginTop: "3%",
                                borderRadius: 6,
                              }}
                              onClick={() => addEmpty(size)}
                            >
                              Add
                            </button>
                          </View>
                        )}
                      </View>
                    </View>
                    <View style={{ paddingVertical: 30, width: "70%" }}>
                      <p style={{ textAlign: "right" }}>
                        {t("tot_pay")}:{" "}
                        {checkEdit()
                          ? lotto?.payout + " €"
                          : " will be calculated on save of your edit"}
                      </p>
                      {!checkEdit() != 0 && (
                        <button
                          onClick={() => save()}
                          className="mvl-buttonSaveEdit"
                          style={{ borderRadius: 6 }}
                        >
                          Save
                        </button>
                      )}
                    </View>
                  </View>
                )}
              </ScrollView>
            </View>
          </Modal>
        </>
      )}
    </>
  );
};

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  labelModalTools: {
    alignSelf: "center",
    fontSize: "1.1rem",
    fontWeight: "800",
    marginBottom: 6,
  },
  board: {
    height: 160,
    width: "100%",
    backgroundColor: colors.secondary,
    padding: 30,
  },
  containerRule: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "8%",
    width: "90%",
    marginHorizontal: "5%",
  },
  imageRule: {
    width: 60,
    height: 60,
    backgroundColor: "#e4e4e4",
    marginRight: 10,
  },
  textRule: {
    fontSize: "1.1rem",
  },
  btnContinua: {
    backgroundColor: "#0A09A2",
    marginTop: "1%",
    width: "100%",
    padding: 10,
    textAlign: "center",
    borderRadius: 4,
  },

  btnText: {
    color: "#fff",
    fontSize: "1.1rem",
    fontWeight: "bold",
  },

  inputCerca: {
    borderWidth: 1,
    borderColor: "#f5f5f5",
    width: "100%",
    height: 30,
    marginTop: 10,
    paddingHorizontal: 15,
    paddingVertical: 20,
  },

  btnCrea: {
    width: "90%",
    height: 40,
    backgroundColor: "#0a09a2",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 7,
    marginBottom: "5%",
    position: "absolute",
  },
  titleBtn: {
    color: "white",
    fontSize: 20,
  },
  btnAltroLotto: {
    backgroundColor: "transparent",
    marginTop: "1%",
    width: "100%",
    padding: 10,
    textAlign: "center",
    borderRadius: 4,
  },

  btnTextAltro: {
    color: "#0a09a2",
    fontSize: 15,
    fontWeight: 600,
  },
  containerLottoListato: {
    width: "100%",
    backgroundColor: "#f5f5f5",
    padding: 5,
    borderRadius: 7,
    marginTop: 20,
  },
});

export default ModalVisualizzaListati;
