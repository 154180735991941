import React, { Component, useEffect, useReducer, useState } from "react";
import { Modal, Accordion, Tooltip, OverlayTrigger } from "react-bootstrap";
import styles from "../styles/Filters.module.css";
import logo from "../assets/wk-logo.png";
import { useTranslation } from "react-i18next";
import { ResponsiveNeeded } from "../pagesMobile/components/utils/constants";

const Filters = ({ filterPrezzo, marca, filtriAttuali, update }) => {
  const [statoProd, setStatoProd] = useState(1);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [prezzoFilter, setPrezzoFilter] = useState("0");

  const { t } = useTranslation();

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Product verified and ready to be shipped.
    </Tooltip>
  );
  const valueSetter = async (valore, tipo) => {
    console.log("MOBILE");
    let filtriAtt = { ...filtriAttuali };
    if (tipo === "marca") {
      if (filtriAtt.marca.find((element) => element === valore)) {
        const V = filtriAtt.marca.filter((v) => v !== valore);
        filtriAtt.marca = V.sort().reverse();
      } else {
        if (filtriAtt.marca.length > 0) {
          const array = [...filtriAtt.marca.sort().reverse(), valore];
          filtriAtt.marca = array;
        } else {
          const array = [...filtriAtt.marca.sort().reverse(), valore];
          filtriAtt.marca = array;
        }
      }
    } else if (tipo === "prezzo") {
      disabledCheckedFunction(valore);

      if (valore !== filtriAtt.prezzo) {
        filtriAtt.prezzo = valore;
      } else {
        filtriAtt.prezzo = "";
      }
    } else if (tipo === "utente") {
      if (document.getElementById("user").checked !== true) {
        filtriAtt.utente = false;
      } else {
        filtriAtt.utente = valore;
      }
      console.log("UTENTE FILTRO: ", filtriAtt.utente);
    }
    update(filtriAtt);
    setStatoProd(2);

    // if(type instanceof Array){
    //   setType("");
    //   }

    // if (value.length > 0 && tipo === "marca") {
    //   if (value.find((element) => element === valore)) {
    //     const index = value.indexOf(valore, 1);
    //     const V = value.filter((v) => v !== valore);
    //     var array = [...type];
    //     setValue(V.sort().reverse());
    //      //array.splice(index, 1);

    //     if(type instanceof Array){
    //     setType("");
    //     }else{
    //       setType(String(array.sort()));
    //     }
    //     if(type === ""){
    //       setType("");
    //       }else{
    //         setType(String(array.sort()));
    //       }
    //   } else if (tipo === "marca") {
    //     setValue((value) => [...value.sort().reverse(), valore]);
    //   }
    // } else if (type === valore && tipo === "prezzo") {
    //   setType("");
    // } else if (type !== "" && tipo === "prezzo") {
    //   document.getElementById(type).defaultChecked = false;
    //   setType(valore);
    // }else if (type != []  && tipo === "prezzo") {
    //   document.getElementById(type).defaultChecked = false;
    //   setType(valore);
    // }

    //  else if (user === valore && tipo === "utente") {
    //   setUser(0);
    // } else if (user === 0 && tipo === "utente") {
    //   setUser(valore);
    // } else if (value.length <= 0 && tipo === "marca") {
    //   setValue((value) => [...value.sort().reverse(), valore]);
    // } else if (type === "" && tipo === "prezzo") {
    //   setType(valore);
    // }
    // else if (type == [] && tipo === "prezzo") {
    //   setType(valore);
    // }
    // else if (user === 0 && tipo === "utente") {
    //   setUser(valore);
    // }
  };
  const disabledCheckedFunction = (valore) => {
    console.log("valore", valore);
    if (document.getElementById(valore).checked) {
      for (let i = 1; i <= 6; i++) {
        if (valore != i) {
          console.log("CI SONO", i);
          document.getElementById(i.toString()).checked = false;
        }
      }
    }
  };
  useEffect(() => {
    filtriFunzione();
    console.log("ECCOLOOOOOOO", filtriAttuali);
    if (filtriAttuali.prezzo === "1") {
      setPrezzoFilter(0);
    } else if (filtriAttuali.prezzo === "2") {
      setPrezzoFilter(100);
    } else if (filtriAttuali.prezzo === "3") {
      setPrezzoFilter(200);
    } else if (filtriAttuali.prezzo === "4") {
      setPrezzoFilter(300);
    } else if (filtriAttuali.prezzo === "5") {
      setPrezzoFilter(400);
    } else if (filtriAttuali.prezzo === "6") {
      setPrezzoFilter(500);
    }
  }, [filtriAttuali]);

  const filtriFunzione = async () => {
    if (statoProd == 2) {
      await filterPrezzo(
        filtriAttuali.prezzo,
        filtriAttuali.marca,
        filtriAttuali.utente,
        statoProd,
      );
    }
  };

  return (
    <>
      <div>
        <div className={styles.divFiltri}>
          {/* <div className={styles.filtroContainer}>
              <p className={styles.titleFiltri}>Brand</p>
              {marca.map((value) => {
                return (
                  <label
                    className={
                      filtriAttuali.marca.includes(value.nomeMarca)
                        ? "activeFilter"
                        : "notActiveFilter"
                    }
                  >
                    {value.nomeMarca}
                    <input
                      type="checkbox"
                      defaultChecked={filtriAttuali.marca.includes(
                        value.nomeMarca
                      )}
                      onChange={() => valueSetter(value.nomeMarca, "marca")}
                    />
                  </label>
                );
              })}
            </div> */}

          <div className={styles.filtroContainer}>
            <p className={styles.titleFiltri}>{t("average_price")}</p>
            <label>
              {t("under")} 100 €
              <input
                id={"1"}
                type="checkbox"
                onClick={() => valueSetter("1", "prezzo")}
              />
            </label>
            <label>
              100 - 200 €
              <input
                id={"2"}
                type="checkbox"
                onClick={() => valueSetter("2", "prezzo")}
              />
            </label>
            <label>
              200 - 300 €
              <input
                id={"3"}
                type="checkbox"
                onClick={() => valueSetter("3", "prezzo")}
              />{" "}
            </label>
            <label>
              300 - 400 €
              <input
                id={"4"}
                type="checkbox"
                onClick={() => valueSetter("4", "prezzo")}
              />
            </label>
            <label>
              400 - 500 €
              <input
                id={"5"}
                type="checkbox"
                onClick={() => valueSetter("5", "prezzo")}
              />
            </label>
            <label>
              {t("over")} 500 €
              <input
                id={"6"}
                type="checkbox"
                onClick={() => valueSetter("6", "prezzo")}
              />
            </label>
          </div>

          <div className={styles.filtroContainer}>
            <p className={styles.titleFiltri}>{t("fast_ship")}</p>
            <div className={styles.spedizioneVeloce}>
              <img
                src={logo}
                alt="wholekicks-logo"
                className="wk-logo-filter"
              />
              <input
                id={"user"}
                type="checkbox"
                defaultChecked={filtriAttuali.user > 0}
                onChange={() => valueSetter(true, "utente")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Filters;
