import React, { Component } from "react";
import "../../styles/CheckoutStyles.css";
import { Link } from "react-router-dom";
import logo from "../../assets/wk-logo.png";
import Footer from "../../components/Footer";
import { withTranslation } from "react-i18next";
class CheckoutFour extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container-form-check">
          <div className="div-logo-form">
            <Link to="/">
              <img className="logo-login" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="checkout-final-div">
            <p className="conferma-acquisto-lotto-p">{t("conf_purch")}</p>
            <span>
              <span style={{ fontSize: 20, fontWeight: 650 }}>
                {" "}
                {t("conf_purch_1")}
              </span>{" "}
              <br /> <br /> {t("conf_purch_2")} <br /> {t("conf_purch_3")}
              . <br /> <br />
              {t("conf_purch_4")}
              {t("conf_purch_5")}.
            </span>
          </div>
          <div className="btn-footer-checkout">
            <Link to="/">{t("back_home")}</Link>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default withTranslation()(CheckoutFour);
