import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/wk-logo.png";
import "../styles/Header.css";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { Modal } from "react-bootstrap";
import CONSTANTS from "../CONSTANTS";
import { useTranslation } from "react-i18next";
import { isAutenticated } from "./utils/validatorActions";
import scarpaVuote from "../assets/wk_products_not_found.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showPropostaConfermata: false,
      listaProdotti: [],
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
      UtenteId: localStorage.getItem(CONSTANTS.ID),
      nomeUtente: localStorage.getItem(CONSTANTS.NOME),
      cognomeUtente: localStorage.getItem(CONSTANTS.COGNOME),
      wordSearch: "",
      proposta: {
        nome: "",
        modello: "",
        colorazione: "",
        style: "",
        marca: "",
      },
      showModal: false,
    };
  }

  componentDidMount() {}
  searchFunctionKeyUp = async (word) => {
    console.log("word", word);
    this.setState({ wordSearch: word });
    if (word) {
      await axios
        .get(CONSTANTS.API_URL + "/prodotto/search/" + word + " /9/0")
        .then((res) => {
          this.setState({ listaProdotti: res.data.prodottoConteggio });
          console.log("res.data", res.data);
        })
        .catch((e) => {
          console.log(e.message);
        });
    } else {
      this.setState({
        listaProdotti: [],
      });
    }
  };
  onSubmitProposta = async (e) => {
    console.log(this.state.proposta);
    const data = this.state.proposta;

    await axios
      .post(CONSTANTS.API_URL + "/proposta", data, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        console.log("brv");

        this.setState({ showModal: false });
        this.setState({ showPropostaConfermata: true });
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  onClickLink = (x) => {
    if (!isAutenticated()) {
      const { t } = this.props.translation;
      alert(t("must_be_logged_to_do_this"));
    }
  };
  onClickLinkProd = (x) => {
    if (!isAutenticated()) {
      const { t } = this.props.translation;
      alert(t("must_be_logged_to_do_this"));
    } else {
      window.location.href = "/product/" + x;
    }
  };
  onClickLinkSubmit = (e) => {
    e.preventDefault();
    if (!isAutenticated()) {
      const { t } = this.props.translation;
      alert(t("must_be_logged_to_do_this"));
    } else {
      if (window.location.pathname.split("/")[1] === "search-page") {
        window.location.href = `/search-page/${this.state.wordSearch}`;
      } else {
        this.props.navigate(
          isAutenticated() ? `/search-page/${this.state.wordSearch}` : "#"
        );
      }
    }
  };
  onClickSubmitAnewProposal = () => {
    if (isAutenticated()) {
      this.setState({ showModal: true });
    } else {
      const { t } = this.props.translation;
      alert(t("must_be_logged_to_do_this"));
    }
  };
  render() {
    const { t } = this.props.translation;
    return (
      <div className="header-container">
        <Link to="/">
          <img src={logo} alt="wholekicks-logo" className="logo" />
        </Link>
        <div className="link-container">
          <Link
            className="header-link"
            to={"/new-in/1"}
            style={{ marginRight: "10%" }}
          >
            New In
          </Link>
          <Link className="header-link" to={"/about"}>
            {t("about")}
          </Link>
          {/* <Link className="header-link" to={isAutenticated()?"/shop-all/1":"#"} onClick={this.onClickLink}>
            Shop All
          </Link> */}

          {/* <Link className="header-link" to={isAutenticated()?"/vendi":"#"} onClick={this.onClickLink}>
            {t("sell")}
          </Link> */}
        </div>
        <label className="search-bar">
          <SearchIcon className="search-icon" />
          <form onSubmit={this.onClickLinkSubmit}>
            <input
              type="search"
              placeholder={t("search")}
              onKeyUp={(e) => this.searchFunctionKeyUp(e.target.value)}
              className="search-bar-input"
            />
          </form>
        </label>
        {/* <Link className="btn-search-header" to={isAutenticated()?`/search-page`:"#"} state={{word:this.state.wordSearch}} onClick={this.onClickLink}>Search</Link> */}
        <div>
          <ul className="ul-cerca">
            {this.state.listaProdotti.map((prodotto, index) => {
              if (index < 5 && prodotto?.id) {
                return (
                  <>
                    <div onClick={() => this.onClickLinkProd(prodotto.id)}>
                      <div
                        onClick={(e) =>
                          this.setState({ id: prodotto.id, valore: 1 })
                        }
                      >
                        <img
                          src={prodotto.imageProdotto}
                          alt="scarpa-immagine"
                          onError={(e) => {
                            e.target.src = scarpaVuote;
                          }}
                        />
                        <div
                          id="myLi"
                          className="testo-cerca"
                          value={prodotto.nome}
                        >
                          <a
                            className="nome-elemento-cerca"
                            id="myA"
                            value={prodotto.id}
                          >
                            {prodotto.nome}
                          </a>
                          <p>{prodotto.style}</p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              } else {
                return null;
              }
            })}
            {/* {this.state.listaProdotti?.length>0&&
            <div className="bottone-finale" onClick={this.onClickSubmitAnewProposal}>
                <p>Can't find a product? <b style={{color:'#0a09a2'}}>Submit a proposal</b></p>
              </div>} */}
          </ul>
        </div>
        {!this.state.nomeUtente ? (
          <div
            onClick={() => {
              window.location.href = "/login";
            }}
            className="header-link account-link"
          >
            {t("login")}
          </div>
        ) : (
          <div
            className="header-link account-link"
            onClick={() => {
              window.location.href = "/account/vendite";
            }}
          >
            {this.state.nomeUtente} {this.state.cognomeUtente}
          </div>
        )}
        <Modal
          centered
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          dialogClassName="aggiungi-modello-modal"
        >
          <div className="modal-header-text">
            <p className="modello-title-modal">{t("add_new_model")}</p>
            <p className="modello-text-modal">{t("add_model_sub_text")}</p>
            <div className="input-modal-home">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.onSubmitProposta(e);
                }}
              >
                <label className="text-bar">
                  <input
                    required
                    name="nome"
                    onChange={(e) =>
                      this.setState({
                        proposta: {
                          ...this.state.proposta,
                          nome: e.target.value,
                        },
                      })
                    }
                    type="text"
                    placeholder={t("name")}
                    className="text-bar-input text-inp"
                  />
                </label>
                {/* <label className="text-bar">
                      <input
                        required
                        name="modello"
                        onChange={(e) =>
                          this.setState({
                            proposta: {
                              ...this.state.proposta,
                              modello: e.target.value,
                            },
                          })
                        }
                        type="text"
                        placeholder={t("model")}
                        className="text-bar-input text-inp"
                      />
                    </label> */}
                <label className="text-bar">
                  <input
                    required
                    name="colorazione"
                    type="text"
                    placeholder={t("color")}
                    className="text-bar-input text-inp"
                    onChange={(e) =>
                      this.setState({
                        proposta: {
                          ...this.state.proposta,
                          colorazione: e.target.value,
                        },
                      })
                    }
                  />
                </label>
                <label className="text-bar">
                  <input
                    required
                    name="style"
                    type="text"
                    placeholder="SKU"
                    className="text-bar-input text-inp"
                    onChange={(e) =>
                      this.setState({
                        proposta: {
                          ...this.state.proposta,
                          style: e.target.value,
                        },
                      })
                    }
                  />
                </label>
                <label className="text-bar">
                  <input
                    required
                    name="marca"
                    type="text"
                    placeholder={t("brand")}
                    className="text-bar-input text-inp"
                    onChange={(e) =>
                      this.setState({
                        proposta: {
                          ...this.state.proposta,
                          marca: e.target.value,
                        },
                      })
                    }
                  />
                </label>
                <button
                  type="submit"
                  className="invia-proposta modal-invia-prop"
                >
                  {t("submit_proposal")}
                </button>
              </form>
              <button
                className="chiudi-proposta"
                onClick={() => this.setState({ showModal: false })}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
          <div className="modal-footer-button"></div>
        </Modal>
        <Modal
          centered
          show={this.state.showPropostaConfermata}
          onHide={() => this.showPropostaConfermata(false)}
          dialogClassName="aggiungi-modello-modal"
        >
          <div className="modal-header-text">
            <p className="modello-title-modal">{t("prop_sent")}</p>
            <p className="modello-sub-title-modal-proposta">
              {t("prop_sent_text")}
            </p>
          </div>
          <div className="modal-footer-button">
            <button
              className="chiudi-proposta"
              onClick={() => this.setState({ showPropostaConfermata: false })}
            >
              {t("keep_on_shopping")}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
export default function (props) {
  const t = useTranslation();
  const navigate = useNavigate();
  return <Header {...props} navigate={navigate} translation={t} />;
}
