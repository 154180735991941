import React, { useEffect, useState } from "react";
import "./styles.css";
import { View, StyleSheet, Text, TouchableOpacity } from "react-native";
import HeaderMobile from "../components/HeaderMobile";
import Menu from "../home/Menu";
import * as api from "../api/service";
import ProductGrid from "../newin/ProductGrid";
import ModalFilters from "./FiltersModal";
import Paginator from "../components/Paginator";

const prezzoDispatcher = {
  0: { prezzo1: 0, prezzo2: 1000000 },
  1: { prezzo1: 0, prezzo2: 100 },
  2: { prezzo1: 100, prezzo2: 200 },
  3: { prezzo1: 200, prezzo2: 300 },
  4: { prezzo1: 300, prezzo2: 400 },
  5: { prezzo1: 400, prezzo2: 500 },
  6: { prezzo1: 500, prezzo2: 100000 },
};

export default function ShopAll() {
  //prodotti da filtrare
  const [shopAllProd, setShopAllProd] = useState([]);
  const [modal, setModal] = useState(false);
  const [marca, setMarca] = useState([]);
  const [countFilter, setcountFilter] = useState([]);
  const [CountAllShopAll, setCountAllShopAll] = useState(0);

  const [filtri, setFiltri] = useState({
    prezzo: "0",
    marca: [],
    utente: 0,
  });

  useEffect(() => {
    const getProducts = async () => {
      const shopAll = await api.shopAll(0, 9);
      const countAllShopAll = await api.countAllshopAll();
      console.log("shop all prod: ", shopAll);
      console.log("shop all prod count: ", countAllShopAll);
      const marcaList = await api.getMarca();
      console.log("lista marche shop all: ", marcaList);
      await setMarca(marcaList);

      console.log("lista marche", marca);
      setCountAllShopAll(countAllShopAll);
      setShopAllProd(shopAll);
    };

    getProducts();
  }, []);
  const onUpdatePagination = async (limit, offset) => {
    const shopAll = await api.shopAll(offset, limit);
    setShopAllProd(shopAll);
  };
  const filtriFunction = async (prezzo, marche, utente, stato) => {
    const body = {
      marche: marche,
      prezzo: prezzoDispatcher[prezzo],
      utente: utente,
      offset: 9,
      limit: 0,
    };
    console.log("filtri body: ", body);

    const filterProductList = await api.productFilter(body);
    console.log("prodotti filtrati: ", filterProductList.prodottos);
    setShopAllProd(filterProductList.prodottos);
    setCountAllShopAll(filterProductList.countFinalFilter);
    setModal(false);
  };

  const onPressModalCancel = () => {
    setModal(false);
  };

  const onPressAggiungiModel = () => {
    console.log("openModal");
    setModal(true);
  };

  return (
    <>
      <View style={style.container}>
        <HeaderMobile context="Home" />
        <Menu />
        <View
          style={{
            width: "90%",
            justifyContent: "center",
            paddingTop: 10,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setModal(true);
            }}
          >
            <Text
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                color: "#0A09A2",
              }}
            >
              Filters
            </Text>
          </TouchableOpacity>
        </View>
        <ProductGrid newInTotal={shopAllProd} />
        {filtri.marca.length == 0 &&
        filtri.prezzo === "0" &&
        filtri.utente == 0 ? (
          <Paginator
            elemPerPage={9}
            data={shopAllProd}
            onClickPageNumber={onUpdatePagination}
            onClickNext={onUpdatePagination}
            onClickPrev={onUpdatePagination}
            countAll={Math.round(CountAllShopAll.totalElement / 9 + 0.4)}
          />
        ) : (
          <Paginator
            elemPerPage={9}
            data={shopAllProd}
            onClickPageNumber={onUpdatePagination}
            onClickNext={onUpdatePagination}
            onClickPrev={onUpdatePagination}
            countAll={Math.round(CountAllShopAll.totalElement / 9 + 0.4)}
          />
        )}
      </View>
      <ModalFilters
        modalAdd={modal}
        onModalDismiss={onPressModalCancel}
        filterPrezzo={filtriFunction}
        marca={marca}
        filtriAttuali={filtri}
      />
    </>
  );
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});
