import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Modal,
  TouchableWithoutFeedback,
  TouchableOpacity,
  KeyboardAvoidingView,
  Keyboard,
  TextInput,
  StyleSheet,
} from "react-native";
import { colors, dim, isLittleDev } from "../components/utils/constants";
import {
  lottoDefault,
  lottoVettore,
} from "../../components/utils/defaultObject";

import { useTranslation } from "react-i18next";

const ModalProposal = ({
  modalAdd,
  onModalDismiss,
  data,
  onModalConfirm,
  handleChange,
}) => {
  const { t } = useTranslation();
  const [lottoList, setLottoList] = useState([]);

  useEffect(() => {
    console.log("DATAMODAL", data);
    setLottoList(data.taglie);
  }, [data]);

  return (
    <>
      <Modal visible={modalAdd} transparent animationType="slide">
        <View
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          {!isLittleDev() && (
            <TouchableWithoutFeedback onPress={onModalDismiss}>
              <View style={{ height: "8%", width: "100%" }} />
            </TouchableWithoutFeedback>
          )}
          <View
            style={{
              height: isLittleDev() ? "80%" : "72%",
              width: "100%",
              alignItems: "center",
              backgroundColor: "#fff",
              borderTopRightRadius: isLittleDev() ? 15 : 30,
              borderTopLeftRadius: isLittleDev() ? 15 : 30,
              shadowRadius: 10,
              shadowOpacity: 0.5,
              paddingHorizontal: 10,
              paddingTop: 20,
            }}
          >
            <View style={{ width: "100%", alignItems: "flex-end" }}>
              <TouchableOpacity onPress={onModalDismiss}>
                <Text style={{ fontWeight: "bold", color: colors.blue }}>
                  {t("close")}
                </Text>
                <Text></Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Text
                style={{
                  width: "70%",
                  textAlign: "center",
                  alignSelf: "center",
                  fontWeight: "400",
                  fontSize: "1.6rem",
                }}
              >
                {t("stock")} N° {data.id}
              </Text>
            </View>
            <View style={style.containerView}>
              <View>
                <Text style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  {data.nome}
                </Text>
                <Text>{data.modello}</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                <Text style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  Qty
                </Text>
                <Text>{"  " + data.quantita}</Text>
              </View>
            </View>
            <View style={style.containerLotto}>
              {lottoList?.map((l) => (
                <View style={style.containerL}>
                  <Text>US {l.taglia}</Text>
                  <Text>Qty {l.quantita}</Text>
                </View>
              ))}
              <View
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "baseline",
                  flexDirection: "row",
                  marginTop: 10,
                }}
              >
                <Text style={{ fontSize: "1.4rem" }}>{t("total_price")}: </Text>
                <Text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {data.payout} €
                </Text>
              </View>
            </View>
            {/* <View
              style={{
                width: "80%",
                justifyContent: "space-between",
                alignItems: "baseline",
                flexDirection: "row",
                marginTop: 20,
              }}
            >
              <TouchableOpacity
                style={{
                  backgroundColor: "#7A79CD",
                  width: "40%",
                  textAlign: "center",
                  paddingVertical: 5,
                  borderRadius: 5,
                }}
              >
                <Text style={{ fontSize: "1.4rem", color: "white", fontWeight: '300' }}>
                  Rifiuta
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  backgroundColor: "#0A09A2",
                  width: "40%",
                  textAlign: "center",
                  paddingVertical: 5,
                  borderRadius: 5,
                  
                }}
              >
                <Text style={{ fontSize: "1.4rem", color: "white", fontWeight: '300' }}>
                  Accetta
                </Text>
              </TouchableOpacity>
            </View> */}
          </View>
        </View>
      </Modal>
    </>
  );
};

const style = StyleSheet.create({
  containerView: {
    flexDirection: "row",
    alignItems: "baceline",
    marginTop: 10,
    width: "90%",
    justifyContent: "space-between",
  },
  textBold: {
    fontSize: "1.1rem",
    fontWeight: 600,
  },
  textNotBold: {
    fontSize: "1.1rem",
    marginLeft: 5,
  },
  containerLotto: {
    backgroundColor: "#DEE4FD",
    width: "90%",
    padding: 10,
    marginTop: 10,
    borderRadius: 12,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    paddingLeft: "7%",
  },
  containerL: {
    backgroundColor: "white",

    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    margin: 5,
    width: "25%",
    borderWidth: 1.5,
    borderColor: "black",
    borderRadius: 7,
    textAlign: "center",
  },
});

export default ModalProposal;
