const paths = {
  NEW_IN: "/prodotto/limit/0/8",
  MOST_SEARCH: "/prodotto/prodotto-home-page",
  POPULAR_BRAND: "/marca/most-clicked",
  SINGLE_PRODUCT: "/prodotto/productById/",
  NEW_IN_PAGE: "/prodotto/limit/0/30",
  RELATED_PRODUCT: "/tag/",
  GET_LOTTO: "/lotto/",
  PROPOSTA_MODELLO: "/proposta",
  TAGLIA: "/taglia/",
  SHOP_ALL: "/prodotto/random",
  SHOP_ALL_PAGINATOR: "/prodotto/rand",
  COUNT_ALL_SHOP_ALL: "/prodotto/count-all",
  LOGIN: "/account/login",
  GET_ACCOUNT: "/account/",
  REGISTRATION: "/account/",
  LOTTO_LOTTOID: "/lotto/lottoId/",
  PRODUCT_BY_ID: "/prodotto/productById/",
  SEARCH_GET: "/prodotto/search/",
  GET_BRAND: "/marca/",
  FILTERED_PROD: "/prodotto/filter",
  NEW_PASSWORD: "/account/update/password",
  NEW_EMAIL: "/account/update/mail",
  NEW_IBAN: "/account/update/iban",
  GET_USER_BY_ID: "/account/utente/",
  NEW_INDIRIZZO: "/account/update/indirizzo",
  ACQUISTI_GET: "/pagamento/buyId/",
  CONTEGGIO_PAGAMENTO: "/pagamento/count-all/",
  OFFERTA_FATTA: "/offertaFatta/",
  OFFERTA_FATTA_ACQUISTI_COUNT: "/offertaFatta/acquisti-count/",
};
export default paths;
