import React, { Component } from "react";
import { Text, View, StyleSheet, Image, Modal } from "react-native";
import HeaderMobile from "../components/HeaderMobile";
import "./style.css";
import Carousel from "./Carousel";
import Menu from "./Menu";
import FooterHome from "./FooterHome";
import * as api from "../api/service";
import ModalAdd from "./ModalAdd";
import Footer from "../../components/Footer";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newIn: [],
      piuCercate: [],
      popBrand: [],
      modal: false,
      drawer: false,
    };
  }
  async componentDidMount() {
    const newIn = await api.newIn();
    const piuCercateCall = await api.piuCercate();
    // const popBrandCall = await api.popBrand();
    console.log("più cercate mobile: ", piuCercateCall);
    console.log("newin", newIn);

    // this.setState({ popBrand: popBrandCall });
    this.setState({ newIn: newIn.produ });
    this.setState({ piuCercate: piuCercateCall });
  }
  onPressModalCancel = () => {
    this.setState({ modal: false });
  };
  onPressAggiungiModel = () => {
    this.setState({ modal: true });
  };
  onPressItem = (item, type) => {};

  render() {
    return (
      <>
        <div className="Container-Common">
          <View style={style.container}>
            <HeaderMobile context="Home" />
            <Menu />
            <Image
              source={require("../../assets/home-img.png")}
              style={{
                width: "90%",
                height: 220,
                marginBottom: 20,
                resizeMode: "contain",
              }}
            />
            <Carousel
              onPressItem={this.onPressItem}
              context="MostPopular"
              data={this.state.piuCercate}
            />
            <Image
              source={require("../../assets/mobile/img-stacco.jpeg")}
              style={{
                width: "100%",
                height: 300,
                marginBottom: 50,
                marginTop: "8vh",
              }}
            />
            <Carousel context="NewIn" data={this.state.newIn} />
            {/* <Carousel context="BrandPopular" data={this.state.popBrand} /> */}
            <FooterHome onPress={this.onPressAggiungiModel} />
            <ModalAdd
              modalAdd={this.state.modal}
              onModalDismiss={this.onPressModalCancel}
            />
            <Footer />
          </View>
        </div>
      </>
    );
  }
}
const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

export default Home;
