import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Toast } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Alert } from "react-native";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../CONSTANTS";
import "../../styles/ModificaPage.css";
import Header from "../../components/Header";
import AccountRoutes from "../../components/AccountRoutes";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";
import { modificaPassword } from "../chiamateApi/ApiModifyUser";

function ModificaPassword() {
  const [accountData, setAccountData] = useState({});
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confermaPass, setConfermaPass] = useState("");
  const [showToast, setShowToast] = useState(false);

  const { t } = useTranslation();
  const desktop = useDesktopChecker();
  const navigate = useNavigate();
  const id = localStorage.getItem(CONSTANTS.ID);
  const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);

  useEffect(() => {}, []);

  const SubmitNewPassword = async (e) => {
    e.preventDefault();
    console.log(oldPassword);
    console.log(newPassword);
    if (newPassword === confermaPass) {
      console.log("password uguali");
      const data = {
        id: id,
        password: newPassword,
        oldPassword: oldPassword,
      };
      const res = await modificaPassword(data, token);
      console.log("res", res);
      if (res === "Password errata") {
        setTimeout(() => {
          alert(t("incorrect_password"));
          navigate("/account/impostazioni");
        }, 2000);
      } else {
        console.log(res);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
          navigate("/account/impostazioni");
        }, 2000);
      }
    } else {
      console.log("ritenta");
      alert(t("incorrect_password"));
    }
  };

  return (
    <div className="container-account-general">
      {desktop && <Header />}
      {!desktop && <HeaderMobile context="Others" title={"Settings"} />}
      <div className="container-account-route">
        {desktop && <AccountRoutes />}
        <div className="modifica-password-container">
          <p className="modifica-pass-title">{t("edit")} Password</p>
          <p className="modifica-pass-subtitle">{t("fill_fields")}</p>
          <form onSubmit={SubmitNewPassword} className="form-modifica">
            <input
              type="password"
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
              required
              value={accountData.password}
              placeholder={t("old_pass")}
            />
            <input
              type="password"
              onChange={(e) => {
                console.log(e.target.value);
                setNewPassword(e.target.value);
              }}
              required
              placeholder={t("new_pass")}
            />
            <input
              type="password"
              required
              onChange={(e) => setConfermaPass(e.target.value)}
              placeholder={t("conf_pass")}
            />
            <button>{t("confirm")} Password</button>
          </form>
          <Toast show={showToast} className="toast-confirm">
            <span>{t("success_pass")}</span>
          </Toast>
        </div>
      </div>
    </div>
  );
}

export default ModificaPassword;
