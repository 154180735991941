import CONSTANTS from "../../../CONSTANTS";
import {
  changePassword,
  changeMail,
  changeIban,
  changeIndirizzo,
} from "../../api/service";

let idUser = localStorage.getItem(CONSTANTS.ID);

export const changePass = (oldPass, newPass, confirmPass) => {
  const body = {
    id: idUser,
    password: confirmPass,
    oldPassword: oldPass,
  };

  if (newPass === confirmPass) {
    changePassword(body)
      .then((valore) => {
        window.location.href = "/";
        return valore;
      })
      .catch((e) => {
        alert("La password inserita non è corretta");
      });
  } else {
    alert("Le due password non corrispondono");
  }
};

export const changeEmail = (newEmail, confirmEmail) => {
  const body = {
    id: idUser,
    email: confirmEmail,
  };

  if (newEmail === confirmEmail) {
    changeMail(body)
      .then((valore) => {
        window.location.href = "/";
        return valore;
      })
      .catch((e) => {
        alert("Le mail inserita non è corretta");
      });
  } else {
    alert("Le mail non corrispondono");
  }
};

export const changeMyIban = (newIban, confirmIban) => {
  const body = {
    id: idUser,
    email: confirmIban,
  };

  if (newIban === confirmIban) {
    changeIban(body)
      .then((valore) => {
        window.location.href = "/";
        return valore;
      })
      .catch((e) => {
        alert("L'iban inserito non è corretto");
      });
  } else {
    alert("iban non corrispondenti");
  }
};

export const changeSpedizione = (
  newIndirizzo,
  confirmIndirizzo,
  newCap,
  confirmCap,
  newCivico,
  confirmCivico,
) => {
  const body = {
    id: idUser,
    indirizzo: confirmIndirizzo,
    cap: confirmCap,
    civico: confirmCivico,
  };
  console.log(
    newIndirizzo,
    confirmIndirizzo,
    newCap,
    confirmCap,
    newCivico,
    confirmCivico,
  );
  if (
    newIndirizzo === confirmIndirizzo &&
    newCap == confirmCap &&
    newCivico == confirmCivico
  ) {
    changeIndirizzo(body)
      .then((valore) => {
        window.location.href = "/";
        return valore;
      })
      .catch((e) => {
        alert("qualcosa è andato storto i dati non sono corretti ");
      });
  } else {
    alert("Ricontrolla i dati qualcosa è andato storto");
  }
};
