import React, { useState } from "react";
import Pagination from "react-bootstrap/Pagination";

function Paginator({
  data,
  elemPerPage,
  countAll,
  onClickPageNumber,
  onClickNext,
  onClickPrev,
}) {
  const [page, setpage] = useState(1);
  return (
    <>
      {console.log(countAll)}
      {data?.length != 0 && (
        <Pagination>
          {page > 1 && (
            <Pagination.Prev
              key={"_pagination_prev"}
              onClick={() => {
                console.log("state_page", page);
                console.log("state_offset", page - 1);
                onClickPrev(elemPerPage, elemPerPage * (page - 1));
                setpage(page - 1);
              }}
            />
          )}
          {page > 1 && (
            <Pagination.Item
              key={"_pagination_" + "-1"}
              onClick={() => {
                console.log("state_page", page - 1);
                console.log("state_offset", elemPerPage * (page - 2));
                onClickPageNumber(elemPerPage, elemPerPage * (page - 2));
                setpage(page - 1);
              }}
            >
              {page - 1}
            </Pagination.Item>
          )}
          <Pagination.Item
            key={"_pagination_" + page - 1}
            disabled={true}
            active
          >
            {page}
          </Pagination.Item>
          {page < countAll && (
            <Pagination.Item
              key={"_pagination_" + "+1"}
              onClick={() => {
                console.log("state_page", page);
                console.log("state_offset", elemPerPage * (page - 1));
                onClickPageNumber(elemPerPage, elemPerPage * page);
                setpage(page + 1);
              }}
            >
              {page < countAll ? page + 1 : null}
            </Pagination.Item>
          )}
          {page < countAll && (
            <Pagination.Next
              key={"_pagination_next"}
              onClick={() => {
                onClickNext(elemPerPage, elemPerPage * page);
                setpage(page + 1);
              }}
            />
          )}
        </Pagination>
      )}
    </>
  );
}

export default Paginator;
