import React from "react";
import { View, Text, ScrollView, Image, TouchableOpacity } from "react-native";
import "./style.css";
import CONSTANTS from "../../CONSTANTS";
import { colors } from "../components/utils/constants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProductGrid from "../newin/ProductGrid";

const Carousel = ({ context, data, onPressItem }) => {
  const { t } = useTranslation();
  console.log("newIN: ", data);
  if (context === "MostPopular")
    return (
      <View
        style={{
          height: 300,
          width: "100%",
          backgroundColor: "#fff",
          paddingLeft: "5%",
        }}
      >
        <p className="carousel-label">{t("most_search")}</p>
        <ScrollView
          style={{ height: "98%", width: "100%" }}
          horizontal
          contentContainerStyle={{ height: "100%" }}
          showsHorizontalScrollIndicator={false}
        >
          {data?.map((item, index) => {
            console.log(item.prezzo);
            const finalPrice = item.prezzo
              ? Number.parseFloat(item?.prezzo).toString().replace(".", ",") +
                "€"
              : 0;
            return (
              <View
                key={index}
                style={{
                  height: "100%",
                  flexDirection: "row",
                  paddingVertical: 5,
                }}
              >
                <Link to={`/product/${item.id}`} className="link-settings">
                  <TouchableOpacity
                    style={{
                      height: "95%",
                      aspectRatio: 0.8,
                      borderWidth: 1,
                      borderColor: "#f5f5f5",
                      borderRadius: 8,
                      shadowRadius: 5,
                      paddingBottom: 6,
                      shadowOpacity: 0.5,
                      shadowOffset: { width: 0, height: 2 },
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={{
                        uri: item.imageProdotto,
                      }}
                      style={{
                        height: "50%",
                        width: "90%",
                        resizeMode: "contain",
                      }}
                    />
                    <Text
                      style={{
                        width: "90%",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      {item.nome}
                    </Text>
                    <Text
                      style={{
                        width: "90%",
                        textAlign: "left",
                      }}
                    >
                      {item.modello}
                    </Text>
                    {finalPrice != 0 && (
                      <>
                        <Text
                          style={{
                            alignSelf: "flex-end",
                            marginRight: "5%",
                            marginTop: "1%",
                          }}
                        >
                          {t("average_price")}
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            paddingHorizontal: "5%",
                            paddingTop: "1%",
                          }}
                        >
                          <Text style={{ fontWeight: "bold" }}>
                            {finalPrice}
                          </Text>
                        </View>
                      </>
                    )}
                    {finalPrice === "NaN" && (
                      <p
                        style={{
                          margin: 0,
                          fontSize: "0.8rem",
                          alignSelf: "end",
                          color: "#111",
                          fontWeight: "600",
                        }}
                      >
                        {t("No_batches_listed")}
                      </p>
                    )}
                  </TouchableOpacity>
                </Link>
                <View style={{ width: 30 }} />
              </View>
            );
          })}
        </ScrollView>
      </View>
    );
  else if (context === "NewIn")
    return (
      <View
        style={{
          height: 300,
          width: "100%",
          backgroundColor: "#fff",
          paddingLeft: "5%",
        }}
      >
        <p className="carousel-label">New In</p>
        <ScrollView
          style={{ height: "98%", width: "100%" }}
          horizontal
          contentContainerStyle={{ height: "100%" }}
          showsHorizontalScrollIndicator={false}
        >
          {data?.map((item, index) => {
            const prezzo = item.prezzo;
            const prezzoFinale = parseFloat(prezzo).toFixed(2);
            const finalPrice =
              Number.parseFloat(prezzoFinale).toString().replace(".", ",") +
              "€";
            return (
              <View
                key={index}
                style={{
                  height: "100%",
                  flexDirection: "row",
                  paddingVertical: 5,
                }}
              >
                <Link to={`/product/${item.id}`} className="link-settings">
                  <TouchableOpacity
                    style={{
                      height: "97%",
                      aspectRatio: 0.8,
                      borderWidth: 1,
                      borderColor: "#f5f5f5",
                      borderRadius: 8,
                      shadowRadius: 5,
                      shadowOpacity: 0.5,
                      shadowOffset: { width: 0, height: 2 },
                      justifyContent: "flex-start",
                      justifyContent: "space-between",
                      paddingBottom: 6,
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={{
                        uri: item.imageProdotto,
                      }}
                      style={{
                        height: "50%",
                        width: "90%",
                        resizeMode: "contain",
                      }}
                    />
                    <Text
                      style={{
                        width: "90%",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                      numberOfLines={2}
                    >
                      {item.modello}
                    </Text>
                    <Text
                      style={{ width: "90%", textAlign: "left" }}
                      numberOfLines={2}
                    >
                      {item.nome}
                    </Text>
                    {finalPrice === "NaN€" ? (
                      <Text
                        style={{
                          alignSelf: "flex-end",
                          marginRight: "5%",
                          marginTop: "1%",
                        }}
                      >
                        {t("No_batches_listed")}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          alignSelf: "flex-end",
                          marginLeft: "5%",
                          marginTop: 20,
                        }}
                      >
                        {t("average_price")}
                      </Text>
                    )}
                    <View
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        paddingHorizontal: "5%",
                        paddingTop: 4,
                      }}
                    >
                      {finalPrice !== "NaN€" ? (
                        <Text style={{ fontWeight: "bold" }}>
                          {finalPrice}{" "}
                        </Text>
                      ) : (
                        <Text style={{ fontWeight: "bold" }}> </Text>
                      )}
                    </View>
                  </TouchableOpacity>
                </Link>
                <View style={{ width: 30 }} />
              </View>
            );
          })}
        </ScrollView>
      </View>
    );
  else if (context === "BrandPopular")
    return (
      <View
        style={{
          height: 300,
          width: "100%",
          backgroundColor: "#fff",
          paddingLeft: "5%",
        }}
      >
        <p className="carousel-label">Brand più popolari</p>
        <ScrollView
          style={{ height: "98%", width: "100%" }}
          horizontal
          contentContainerStyle={{ height: "100%" }}
          showsHorizontalScrollIndicator={false}
        >
          {data?.map((item, index) => {
            const prezzo = item.prezzo ? item.prezzo : 0;
            const prezzoFinale = parseFloat(prezzo).toFixed(2);
            const finalPrice =
              Number.parseFloat(prezzoFinale).toString().replace(".", ",") +
              "€";
            return (
              <View
                key={index}
                style={{ height: "100%", flexDirection: "row" }}
              >
                <TouchableOpacity
                  style={{
                    height: "95%",
                    aspectRatio: 1,
                    borderRadius: 8,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    shadowRadius: 12,
                    shadowOpacity: 0.3,
                  }}
                >
                  <Image
                    source={{
                      uri: CONSTANTS.API_URL + "/routes" + item?.imageMarca,
                    }}
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: 8,
                      resizeMode: "contain",
                    }}
                  />
                </TouchableOpacity>
                <View style={{ width: 30 }} />
              </View>
            );
          })}
        </ScrollView>
      </View>
    );
  else if (context === "RelatedProduct")
    return (
      <View
        style={{
          height: 300,
          width: "100%",
          backgroundColor: "#fff",
          paddingLeft: "5%",
        }}
      >
        <ScrollView
          style={{ height: "98%", width: "100%", paddingVertical: 5 }}
          horizontal
          contentContainerStyle={{ height: "100%" }}
          showsHorizontalScrollIndicator={false}
        >
          {data.map((item, index) => {
            const prezzo = item.prezzo;
            const prezzoFinale = parseFloat(prezzo).toFixed(2);
            const finalPrice =
              Number.parseFloat(prezzoFinale).toString().replace(".", ",") +
              "€";
            console.log("PREZZO", finalPrice);
            return (
              <View
                key={index}
                style={{ height: "100%", flexDirection: "row" }}
              >
                <Link to={`/product/${item.id}`} className="link-settings">
                  <TouchableOpacity
                    style={{
                      height: "95%",
                      aspectRatio: 0.8,
                      borderWidth: 1,
                      borderColor: "#f5f5f5",
                      borderRadius: 8,
                      shadowRadius: 5,
                      shadowOpacity: 0.5,
                      shadowOffset: { width: 0, height: 2 },
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={{
                        uri: item.imageProdotto,
                      }}
                      style={{
                        height: "50%",
                        width: "90%",
                        resizeMode: "contain",
                      }}
                    />
                    <Text
                      style={{ width: "90%", textAlign: "left" }}
                      numberOfLines={2}
                    >
                      {item.nome}
                    </Text>
                    <Text
                      style={{ width: "90%", textAlign: "left" }}
                      numberOfLines={2}
                    >
                      {item.colore}
                    </Text>
                    {finalPrice === "NaN€" ? (
                      <Text
                        style={{
                          alignSelf: "flex-end",
                          marginRight: "5%",
                          marginTop: "1%",
                        }}
                      >
                        {t("No_batches_listed")}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          alignSelf: "flex-end",
                          marginLeft: "5%",
                          marginTop: 20,
                        }}
                      >
                        {t("average_price")}
                      </Text>
                    )}
                    <View
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        paddingHorizontal: "5%",
                        paddingTop: 4,
                      }}
                    >
                      {finalPrice !== "NaN€" ? (
                        <Text style={{ fontWeight: "bold" }}>
                          {finalPrice}{" "}
                        </Text>
                      ) : (
                        <Text style={{ fontWeight: "bold" }}> </Text>
                      )}
                    </View>
                  </TouchableOpacity>
                </Link>
                <View style={{ width: 30 }} />
              </View>
            );
          })}
        </ScrollView>
      </View>
    );
  else if (context === "NewInNEW")
    return (
      <View
        style={{
          width: "100%",
          paddingHorizontal: "2%",
          paddingBottom: "4%",
        }}
      >
        <Text
          style={{
            fontSize: "1rem",
            fontWeight: "bold",
            color: "#363636",
            paddingTop: 20,
            paddingHorizontal: "5%",
          }}
        >
          New In
        </Text>
        <View
          style={{
            width: "100%",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <ProductGrid newInTotal={data} />
        </View>
      </View>
    );
  else return <View></View>;
};

export default Carousel;
