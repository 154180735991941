import React, { Component } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import HeaderMobile from "../components/HeaderMobile";
import { Link, useLocation, useParams } from "react-router-dom";
import "./style.css";
import { useTranslation } from "react-i18next";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "statoLink",
    };
  }
  render() {
    const { t } = this.props.translation;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Others" title={t("setting")} />
          <View style={style.viewTitle}>
            <Text style={style.titleSettings}>{t("security")}</Text>
          </View>
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={style.parentView}>
              <View style={style.viewBtnTitle}>
                <Text style={style.subTitleSettings}>{t("edit")} password</Text>
                <Link
                  className="link-settings"
                  to="/account/impostazioni/modifica-password"
                >
                  <TouchableOpacity style={style.btnTitle}>
                    {t("edit")}
                  </TouchableOpacity>
                </Link>
              </View>
            </View>
            {/* <View style={style.parentView}>
              <View style={style.viewBtnTitle}>
                <Text style={style.subTitleSettings}>{t("two_fact")}</Text>
                <TouchableOpacity style={style.btnTitle}>
                  {t("edit")}
                </TouchableOpacity>
              </View>
            </View> */}
            <View style={style.parentView}>
              <View style={style.viewBtnTitle}>
                <Text style={style.subTitleSettings}>{t("change_email")}</Text>
                <Link
                  className="link-settings"
                  to="/account/impostazioni/modifica-mail"
                >
                  <TouchableOpacity style={style.btnTitle}>
                    {t("edit")}
                  </TouchableOpacity>
                </Link>
              </View>
            </View>
            <View style={style.viewTitle}>
              <Text style={style.titleSettings}>{t("pur_man")}</Text>
            </View>
            <View style={style.parentView}>
              <View style={style.viewBtnTitle}>
                <Text style={style.subTitleSettings}>{t("ship_add")}</Text>
                <Link
                  className="link-settings"
                  to="/account/impostazioni/modifica-spedizione"
                >
                  <TouchableOpacity style={style.btnTitle}>
                    {t("edit")}
                  </TouchableOpacity>
                </Link>
              </View>
            </View>
            <View style={style.viewTitle}>
              <Text style={style.titleSettings}>{t("comp_info")}</Text>
            </View>
            <View style={style.parentView}>
              <View style={style.viewBtnTitle}>
                <Text style={style.subTitleSettings}>{t("vat_number")}</Text>
                <TouchableOpacity style={style.btnTitle}>
                  {t("edit")}
                </TouchableOpacity>
              </View>
            </View>
            <View style={style.parentView}>
              <View style={style.viewBtnTitle}>
                <Text style={style.subTitleSettings}>Iban</Text>
                <Link
                  className="link-settings"
                  to="/account/impostazioni/modifica-iban"
                >
                  <TouchableOpacity style={style.btnTitle}>
                    {t("edit")}
                  </TouchableOpacity>
                </Link>
              </View>
            </View>
            <View style={style.parentView}>
              <View style={style.viewBtnTitle}>
                <Text style={style.subTitleSettings}>{t("ship_info")}</Text>
                <TouchableOpacity style={style.btnTitle}>
                  {t("edit")}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </div>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  parentView: {
    width: "90%",
    marginTop: 10,
    paddingHorizontal: "2%",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: "5%",
    paddingBottom: 10,
  },

  viewTitle: {
    width: "90%",
    borderBottomColor: "#7A79CD",
    borderBottomWidth: 2,
    paddingBottom: 2,
    paddingHorizontal: "2.5%",
    marginTop: 10,
  },

  titleSettings: {
    fontSize: "1.2rem",
    fontWeight: 700,
    paddingBottom: 5,
    width: "100%",
  },

  subTitleSettings: {
    width: "100%",
    fontSize: "0.9rem",
    fontWeight: 400,
  },

  descriptionSettings: {
    width: "90%",
    fontSize: "1rem",
    marginTop: 5,
  },

  btnTitle: {
    width: "30%",
    color: "#7A79CD",
    textAlign: "right",
    fontWeight: "bold",
  },

  viewBtnTitle: {
    width: "90%",
    justifyContent: "center",
    flexDirection: "row",
  },
});

export default function (props) {
  const t = useTranslation();
  const params = useParams();
  return <Settings translation={t} props={props} params={params} />;
}
