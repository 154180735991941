import React, { Component } from "react";
import { Text, View, StyleSheet, TouchableOpacity, Image } from "react-native";
import HeaderMobile from "../components/HeaderMobile";
import { withTranslation } from "react-i18next";
import axios from "axios";
import CONSTANTS from "../../CONSTANTS";

class Storico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statoProfile: 0,
    };
  }

  componentDidMount() {
    this.init();
  }
  init() {
    this.getAcquistati(9, 0, true);
    this.getVenduti(9, 0, true);
  }
  async getAcquistati(limit, offset, withCount) {
    await axios
      .get(
        CONSTANTS.API_URL +
          "/pagamento/buyIds/" +
          localStorage.getItem(CONSTANTS.ID) +
          "/" +
          offset +
          "/" +
          limit,
        {
          HeaderMobiles: {
            Authorization: `Bearer ${localStorage.getItem(
              CONSTANTS.ACCESS_TOKEN_KEY,
            )}`,
          },
        },
      )
      .then((prodottoRicevuto) => {
        console.log("acquistati", prodottoRicevuto.data);
        this.setState({
          listaProdotti: prodottoRicevuto.data,
        });
      });
    if (withCount) {
      await axios
        .get(
          CONSTANTS.API_URL +
            "/pagamento/count-filter/" +
            localStorage.getItem(CONSTANTS.ID),
          {
            HeaderMobiles: {
              Authorization: `Bearer ${localStorage.getItem(
                CONSTANTS.ACCESS_TOKEN_KEY,
              )}`,
            },
          },
        )
        .then((proposteRicevute) => {
          if (proposteRicevute.data[0]) {
            const countPage =
              proposteRicevute.data[0].totalCount / this.state.elementPerPage;
            console.log("acquistatiCount", proposteRicevute.data);
            this.setState({
              totalPage: Array(parseInt(countPage) + 1),
            });
          } else {
            this.setState({
              totalPage: 1,
            });
          }
        });
    }
  }
  async getVenduti(limit, offset, withCount) {
    axios
      .get(
        CONSTANTS.API_URL +
          "/vendite/buyId-vendite/" +
          localStorage.getItem(CONSTANTS.ID) +
          "/" +
          offset +
          "/" +
          limit,
        {
          HeaderMobiles: {
            Authorization: `Bearer ${localStorage.getItem(
              CONSTANTS.ACCESS_TOKEN_KEY,
            )}`,
          },
        },
      )
      .then((prodottoRicevuto) => {
        console.log("venduti", prodottoRicevuto.data);
        this.setState({
          listaProdottiVenduti: prodottoRicevuto.data,
        });
      });

    if (withCount) {
      axios
        .get(
          CONSTANTS.API_URL +
            "/vendite/count-filter/" +
            localStorage.getItem(CONSTANTS.ID),
          {
            HeaderMobiles: {
              Authorization: `Bearer ${localStorage.getItem(
                CONSTANTS.ACCESS_TOKEN_KEY,
              )}`,
            },
          },
        )
        .then((proposteRicevute) => {
          if (proposteRicevute.data[0]) {
            const countPage =
              proposteRicevute.data.totalElement / this.state.elementPerPage;
            console.log("vendutiCount", proposteRicevute.data.totalElement);
            this.setState({
              totalPageVendite: Array(parseInt(countPage) + 1),
            });
          } else {
            this.setState({
              totalPage: 1,
            });
          }
        });
    }
  }
  render() {
    const { t } = this.props;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Others" title={t("history")} />
          <View style={style.HeaderMobileProfile}>
            <TouchableOpacity
              onPress={() => {
                this.setState({ statoProfile: 0 });
              }}
            >
              <Text
                style={{
                  fontWeight: this.state.statoProfile === 0 ? "bold" : 400,
                  color: this.state.statoProfile === 0 ? "#0A09A2" : "black",
                }}
              >
                Acquistati
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ marginLeft: "10%" }}
              onPress={() => {
                this.setState({ statoProfile: 1 });
              }}
            >
              <Text
                style={{
                  fontWeight: this.state.statoProfile === 1 ? "bold" : 400,
                  color: this.state.statoProfile === 1 ? "#0A09A2" : "black",
                }}
              >
                Venduti
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.statoProfile === 0 && (
              <View style={style.containerProposte}>
                <Image
                  style={{ width: "40%", resizeMode: "center", height: "100%" }}
                  source={require("../../assets/image-scarpa.png")}
                />
                <View style={style.containerLabelProdotto}>
                  <Text style={style.titleContProd}>Nike Air Max 1/97</Text>
                  <Text style={style.SubTitleContProd}>
                    Sean Wotherspoon (Extra ...
                  </Text>
                  <View style={style.containerShipping}>
                    <View>
                      <Text>N. Lotto</Text>
                      <Text>001002</Text>
                    </View>
                    <View>
                      <Text>Stato Spedizione</Text>
                      <Text>In transito</Text>
                    </View>
                  </View>
                </View>
              </View>
            )}
            {this.state.statoProfile === 1 && (
              <View style={style.containerProposte}>
                <Text>Proposte in corso</Text>
              </View>
            )}
          </View>
        </View>
      </div>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  HeaderMobileProfile: {
    flexDirection: "row",
    width: "90%",
    marginHorizontal: "5%",
    paddingHorizontal: "5%",
    paddingBottom: 15,
    borderBottomColor: "#7A79CD",
    borderBottomWidth: 1.5,
  },
  containerProposte: {
    width: "90%",
    marginTop: 10,
    height: 120,
    marginVertical: 20,
    flexDirection: "row",
    borderBottomColor: "#7A79CD",
    borderBottomWidth: 1.5,
  },

  containerLabelProdotto: {
    width: "60%",
  },

  titleContProd: {
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
  containerShipping: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginVertical: 10,
  },
});

export default withTranslation()(Storico);
