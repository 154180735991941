import { Dimensions } from "react-native";
export const colors = {
  primary: "#fff",
  secondary: "#7A79CD",
  blue: "#0A09A2",
};
export const dim = {
  height: Dimensions.get("screen").height,
  width: Dimensions.get("screen").width,
};
export const isLittleDev = () => {
  return dim.width <= 320;
};
export const isNotMobile = () => {
  return window.innerWidth >= 1200;
};
export const ResponsiveNeeded = () => {
  return window.innerWidth <= 992;
};
