import React, { Component } from "react";
import Header from "../components/Header";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import CONSTANTS from "../CONSTANTS";
import Filters from "../components/Filters";
import { DataArraySharp } from "@mui/icons-material";
import { Modal, Accordion, Tooltip, OverlayTrigger } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import logo from "../assets/wk-logo.png";
import scarpaVuote from "../assets/wk_products_not_found.png";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, Text, TouchableOpacity } from "react-native";
import Menu from "../pagesMobile/home/Menu";
import HeaderMobile from "../pagesMobile/components/HeaderMobile";
import ProductGrid from "../pagesMobile/newin/ProductGrid";
import ModalFilters from "../pagesMobile/newin/FiltersModal";
import PageItem from "react-bootstrap/PageItem";
import { isEmpty, map, set } from "lodash";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import useDesktopChecker from "../components/utils/useDesktopChecker";

class NewInNoReplace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      listaProdotti: [],
      ListaProdottiDaFiltrare: [],
      ListaProdottiDaFiltrarePage: [],
      listaPiuCercati: [],
      PagFilter: [],
      prezzo1: 0,
      prezzo2: 0,
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
      listaMarca: [],
      filtri: { prezzo: "", marca: [], user: 0 },
      array: [],
      modalShow: false,
      statoProd: 1,
      count: 2,
      countList: 2,
      numberElem: 2,
      Prezzo: 0,
      Marche: 0,
      limit: 0,
      offset: 0,
      elementPerPage: 30,
      page: 1,
      utente: "",
      totalPage: [],
      countFilter: [],
      timing: false,
      loading: false,
      modal: false,
    };
    this.prezzoDispatcher = {
      0: { prezzo1: 0, prezzo2: 1000000 },
      1: { prezzo1: 0, prezzo2: 100 },
      2: { prezzo1: 100, prezzo2: 200 },
      3: { prezzo1: 200, prezzo2: 300 },
      4: { prezzo1: 300, prezzo2: 400 },
      5: { prezzo1: 400, prezzo2: 500 },
      6: { prezzo1: 500, prezzo2: 100000 },
    };
  }
  async componentDidMount() {
    this.setState({ loading: true }, () => {
      console.log("loading is true ");
    });
    const pagesCustom = Number.parseInt(this.props.params.page);

    if (this.props.location?.state?.marca) {
      this.setState({
        filtri: {
          prezzo: "",
          marca: [this.props.location?.state?.marca],
          user: 0,
        },
      });
      await axios.get(CONSTANTS.API_URL + "/marca/").then((marca) => {
        this.setState({
          listaMarca: marca.data,
        });
      });

      await this.filtriFunction(
        "",
        [this.props.location?.state?.marca],
        undefined,
        1
      );
      this.setState({ loading: false }, () => {
        console.log("loading is false ");
      });
    }
    console.log("gg", this.props.desktop);
    const off = window.innerWidth >= 900 ? 0 : (pagesCustom - 1) * 30;
    const lim = window.innerWidth >= 900 ? pagesCustom * 30 : 30;
    if (window.innerWidth <= 900) {
      await axios
        .get(CONSTANTS.API_URL + "/prodotto/offset/" + off + "/" + lim)
        .then((prodottoRicevuto) => {
          console.log(
            "prodottoRicevuto",
            prodottoRicevuto.data.count.conteggio
          );
          const conteggio = prodottoRicevuto.data.count.conteggio;
          this.setState(
            {
              listaProdotti: prodottoRicevuto.data.produ,
              page: Number.parseInt(pagesCustom),
              totalPage: Math.round(conteggio / 30 + 0.49),
            },
            () => {
              if (pagesCustom > this.state.totalPage) {
                console.log("TTP", this.state.totalPage);
                this.setState(
                  {
                    page: this.state.totalPage,
                  },
                  () => {
                    this.props.navigate("/new-in/" + this.state.totalPage);
                  }
                );
              }
              console.log("TOTALCAZZOCOUNT", this.state.totalPage);
            }
          );
        });
    } else {
      await axios
        .get(CONSTANTS.API_URL + "/prodotto/offset/" + off + "/" + lim)
        .then((prodottoRicevuto) => {
          console.log(
            "prodottoRicevuto",
            prodottoRicevuto.data.count.conteggio
          );
          const conteggio = prodottoRicevuto.data.count.conteggio;
          this.setState(
            {
              listaProdotti: prodottoRicevuto.data.produ,
              page: Number.parseInt(pagesCustom),
              totalPage: Math.round(conteggio / 30 + 0.49),
            },
            () => {
              if (pagesCustom > this.state.totalPage) {
                console.log("TTP", this.state.totalPage);
                this.setState(
                  {
                    page: this.state.totalPage,
                  },
                  () => {
                    this.props.navigate("/new-in/" + this.state.totalPage);
                  }
                );
              }
              console.log("TOTALCAZZOCOUNT", this.state.totalPage);
            }
          );
        });
    }
    await axios.get(CONSTANTS.API_URL + "/marca/").then((marca) => {
      this.setState({
        listaMarca: marca.data,
      });
    });
    await axios
      .get(CONSTANTS.API_URL + "/prodotto/random")
      .then((prodottoRicevuto) => {
        console.log(prodottoRicevuto);
        this.setState({
          PagFilter: prodottoRicevuto.data,
          ListaProdottiDaFiltrare: prodottoRicevuto.data,
        });
      });
    await axios.get(CONSTANTS.API_URL + "/prodotto/count-all").then((res) => {
      console.log("sono qui");

      const countPage = res.data.totalElement / this.state.elementPerPage;
      console.log("countPage", countPage);
      this.setState({
        timing: true,
      });

      this.setState({ loading: false }, () => {
        console.log("loading is false ");
      });
    });
  }

  upgradeClick = async (data) => {
    console.log(data);
    const body = {
      ProdottoId: data,
    };
    await axios
      .post(CONSTANTS.API_URL + "/piucercate/click", body)
      .then((res) => {
        console.log(res.data.successo);
        this.routeChange("registrazione/conferma-registrazione");
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  filtriFunction = async (prezzo, marche, utente, stato) => {
    console.log("SONO ENTRATO QUA DENTROooo");
    console.log(stato);
    const indexOfLastTodo = 1 * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    console.log("indici", this.state.elementPerPage, indexOfFirstTodo);
    console.log(
      "prezzo",
      prezzo,
      "marche",
      marche,
      "utente",
      utente,
      "stato",
      stato
    );
    if (marche.length == 0 && !utente && prezzo === "") {
      console.log("SONO ENTRATO QUA DENTRO");
      this.setState(
        {
          offset: 0,
          limit: 30,
          statoProd: 1,
          page: 1,
        },
        () => {
          this.props.navigate("/new-in/" + 1);
        }
      );
    } else {
      console.log("SONO ENTRATO QUA DENTRO 2");
      this.setState(
        {
          offset: 0,
          limit: 30,
          statoProd: stato,
          page: 1,
        },
        () => {
          this.props.navigate("/new-in/" + 1);
        }
      );

      const body = {
        marche: marche,
        prezzo: this.prezzoDispatcher[prezzo],
        utente: utente,
        offset: 30,
        limit: 0,
      };
      console.log("BODYYYYY", body);
      await axios
        .post(CONSTANTS.API_URL + "/prodotto/filter", body)
        .then((res) => {
          console.log(res.data);
          const countPage =
            res.data.countFinalFilter / this.state.elementPerPage;
          this.setState({
            ListaProdottiDaFiltrarePage: res.data.prodottos,
            statoProd: 2,
            Prezzo: prezzo,
            Marche: marche,
            Utente: utente,
            countFilter: Array(parseInt(countPage) + 1),
          });
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  };

  handlePageClickFilter = async (page) => {
    const indexOfLastTodo = page * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    console.log("indici", this.state.elementPerPage, indexOfFirstTodo);
    const body = {
      marche: this.state.Marche,
      prezzo: this.prezzoDispatcher[this.state.Prezzo],
      utente: this.state.utente,
      offset: page * this.state.elementPerPage,
      limit: 0,
    };
    const prodotti = await axios.post(
      CONSTANTS.API_URL + "/prodotto/filter",
      body
    );
    console.log("prodotti", prodotti);

    this.setState(
      {
        page: page,
        ListaProdottiDaFiltrarePage: prodotti.data.prodottos,
      },
      () => {
        this.props.navigate("/new-in/" + page);
      }
    );
  };
  handlePageClick = async (page) => {
    // const indexOfLastTodo = page * this.state.elementPerPage;
    // const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    this.setState({ page: page });
    console.log("indici", this.state.elementPerPage);

    const prodotti = await axios.get(
      CONSTANTS.API_URL + "/prodotto/offset/" + 0 + "/" + page * 30
    );
    console.log("prodotti", prodotti);

    this.setState(
      {
        page: page,
        listaProdotti: prodotti.data.produ,
      },
      () => {
        this.props.navigate("/new-in/" + page);
      }
    );
  };

  handlePageClickFilterM = async (page) => {
    const indexOfLastTodo = page * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    console.log("indici", this.state.elementPerPage, indexOfFirstTodo);
    const body = {
      marche: this.state.Marche,
      prezzo: this.prezzoDispatcher[this.state.Prezzo],
      utente: this.state.utente,
      offset: page * this.state.elementPerPage,
      limit: (page - 1) * this.state.elementPerPage,
    };
    const prodotti = await axios.post(
      CONSTANTS.API_URL + "/prodotto/filter",
      body
    );
    console.log("prodotti", prodotti);

    this.setState(
      {
        page: page,
        ListaProdottiDaFiltrarePage: prodotti.data.prodottos,
      },
      () => {
        this.props.navigate("/new-in/" + page);
      }
    );
  };
  handlePageClickM = async (page) => {
    // const indexOfLastTodo = page * this.state.elementPerPage;
    // const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    this.setState({ page: page });
    console.log("indici", this.state.elementPerPage);

    const prodotti = await axios.get(
      CONSTANTS.API_URL + "/prodotto/offset/" + (page - 1) * 30 + "/" + 30
    );
    console.log("prodotti", prodotti);

    this.setState(
      {
        page: page,
        listaProdotti: prodotti.data.produ,
      },
      () => {
        this.props.navigate("/new-in/" + page);
      }
    );
  };
  updateFiltri = async (filtri) => {
    this.setState({ filtri: filtri }, () => {
      console.log("CHIAMATO", this.state.filtri);
    });
  };
  onPressModalCancel = () => {
    this.setState({ modal: false });
  };

  DesktopRender = () => {
    const { t } = this.props.translation;
    return (
      <div>
        <Header />

        <div className="container-newIn">
          <div className="filtri-container">
            <Filters
              filterPrezzo={this.filtriFunction}
              marca={this.state.listaMarca}
              filtriAttuali={this.state.filtri}
              update={this.updateFiltri}
            />
          </div>
          <div style={{ paddingTop: "5%" }}>
            <div className="new-in-prodotti-wrapped-gridded">
              {this.state.statoProd == 2 &&
              this.state.ListaProdottiDaFiltrarePage?.length > 0 ? (
                this.state.ListaProdottiDaFiltrarePage.map((prodotto, key) => {
                  const prezzo = prodotto.prezzo;
                  const prezzoFinale = parseFloat(prezzo).toFixed(2);
                  const finalPrice = prezzoFinale.toString().replace(".", ",");
                  return (
                    <Link
                      // className="container-sn-wrapped"
                      to={`/product/${prodotto.id}`}
                      onClick={() => this.upgradeClick(prodotto.id)}
                      className="product-gridded"
                    >
                      <div key={key}>
                        <div
                        // className="sneaker-cell"
                        >
                          {" "}
                          <img
                            src={prodotto.imageProdotto}
                            alt="scarpa immagine"
                            onError={(e) => {
                              e.target.src = scarpaVuote;
                            }}
                            className="product-gridded-image"
                            // className="img-prodotto-home-slider"
                            // style={{
                            //   height: CONSTANTS.dimensions.width * 0.09,
                            // }}
                          />
                          {finalPrice !== "NaN" ? (
                            <div className="order-align-text">
                              <span className="title-sn-homepage">
                                {prodotto.nome}
                              </span>
                              <div
                                className="sneaker-prezzo-lotto-div"
                                style={{ flexDirection: "column" }}
                              >
                                <p style={{ margin: 0, fontSize: "0.8rem" }}>
                                  {t("average_price_two")}
                                </p>
                                <span className="prezzo-prod">
                                  € {finalPrice}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="order-align-text">
                              <span>{prodotto.nome} </span>
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: "0.8rem",
                                  alignSelf: "end",
                                }}
                              >
                                {t("no_stock")}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                  );
                })
              ) : this.state.statoProd == 2 &&
                this.state.ListaProdottiDaFiltrarePage?.length < 1 ? (
                <div>
                  <label>{t("products_not_found")}</label>
                </div>
              ) : (
                this.state.listaProdotti.map((prodotto, key) => {
                  if (prodotto?.id) {
                    const prezzo = prodotto.prezzo;
                    const prezzoFinale = parseFloat(prezzo).toFixed(2);
                    const finalPrice = prezzoFinale
                      .toString()
                      .replace(".", ",");
                    return (
                      <Link
                        // className="container-sn-wrapped"
                        to={`/product/${prodotto.id}`}
                        onClick={() => this.upgradeClick(prodotto.id)}
                        className="product-gridded"
                        // style={{
                        //   minWidth: CONSTANTS.dimensions.width * 0.2,
                        //   width: CONSTANTS.dimensions.width * 0.2,
                        // }}
                      >
                        <div key={key}>
                          <div
                          // className="sneaker-cell"
                          >
                            {" "}
                            <img
                              src={prodotto.imageProdotto}
                              onError={(e) => {
                                e.target.src = scarpaVuote;
                              }}
                              alt="scarpa immagine"
                              className="product-gridded-image"
                            />
                            {finalPrice !== "NaN" ? (
                              <div className="order-align-text">
                                <span className="title-sn-homepage">
                                  {prodotto?.nome + " " + prodotto?.modello}
                                </span>
                                <div
                                  className="sneaker-prezzo-lotto-div"
                                  style={{ flexDirection: "column" }}
                                >
                                  <p style={{ margin: 0, fontSize: "0.75rem" }}>
                                    {t("average_price_two")}
                                  </p>
                                  <span className="prezzo-prod">
                                    € {finalPrice}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="order-align-text">
                                <span>{prodotto.nome} </span>
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: "0.8rem",
                                    alignSelf: "end",
                                  }}
                                >
                                  {t("no_stock")}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    );
                  }
                })
              )}

              <Modal
                centered
                show={this.state.modalShow}
                onHide={() => this.setState({ modalShow: false })}
                dialogClassName="aggiungi-modello-modal"
              >
                <div className="modal-header-text">
                  <p className="modello-title-modal">{t("no_stock")}</p>
                </div>
                <div className="modal-footer-button">
                  <button
                    className="chiudi-proposta"
                    onClick={() => this.setState({ modalShow: false })}
                  >
                    Close
                  </button>
                </div>
              </Modal>
            </div>
            {this.state.statoProd === 2 &&
            this.state.ListaProdottiDaFiltrarePage?.length > 0 ? (
              <Pagination>
                {this.state.page > 1 && (
                  <Pagination.Prev
                    onClick={() =>
                      this.handlePageClickFilter(this.state.page - 1)
                    }
                  />
                )}
                {this.state.page > 1 && (
                  <Pagination.Item
                    onClick={() => this.handlePageClickFilter(1)}
                  >
                    1
                  </Pagination.Item>
                )}
                {this.state.page > 1 && <Pagination.Ellipsis />}
                {this.state.page - 1 > 1 && (
                  <Pagination.Item
                    active={this.state.page === this.state.page - 1}
                    key={"_pagination_" + this.state.page - 1}
                    onClick={() =>
                      this.handlePageClickFilter(this.state.page - 1)
                    }
                  >
                    {this.state.page !== 1 ? this.state.page - 1 : null}
                  </Pagination.Item>
                )}

                <Pagination.Item
                  active={this.state.page === this.state.page}
                  key={"_pagination_" + this.state.page}
                  onClick={() => this.handlePageClickFilter(this.state.page)}
                >
                  {this.state.page}
                </Pagination.Item>
                {this.state.page != this.state.countFilter.length && (
                  <Pagination.Item
                    active={this.state.page + 1 === this.state.page}
                    key={"_pagination_" + this.state.page + 1}
                    onClick={() =>
                      this.handlePageClickFilter(this.state.page + 1)
                    }
                  >
                    {this.state.page + 1}
                  </Pagination.Item>
                )}
                {this.state.page + 1 < this.state.countFilter.length && (
                  <Pagination.Ellipsis />
                )}
                {this.state.page + 1 < this.state.countFilter.length && (
                  <Pagination.Item
                    onClick={() =>
                      this.handlePageClickFilter(this.state.countFilter.length)
                    }
                  >
                    {this.state.countFilter.length}
                  </Pagination.Item>
                )}
                {this.state.page < this.state.countFilter.length && (
                  <Pagination.Next
                    onClick={() =>
                      this.handlePageClickFilter(this.state.page + 1)
                    }
                    disabled={
                      this.state.page + 1 === this.state.countFilter.length ||
                      this.state.page === this.state.countFilter.length ||
                      this.state.page > this.state.countFilter.length
                    }
                  />
                )}
              </Pagination>
            ) : this.state.statoProd === 2 &&
              this.state.ListaProdottiDaFiltrarePage?.length < 1 ? (
              <div>
                <label></label>
              </div>
            ) : (
              <Pagination>
                {this.state.page > 1 && (
                  <Pagination.Prev
                    onClick={() => this.handlePageClick(this.state.page - 1)}
                  ></Pagination.Prev>
                )}
                {this.state.page > 1 && (
                  <Pagination.Item onClick={() => this.handlePageClick(1)}>
                    1
                  </Pagination.Item>
                )}
                {this.state.page > 1 && <Pagination.Ellipsis />}
                {this.state.page - 1 > 1 && (
                  <Pagination.Item
                    active={this.state.page === this.state.page - 1}
                    key={"_pagination_" + this.state.page - 1}
                    onClick={() => this.handlePageClick(this.state.page - 1)}
                  >
                    {this.state.page !== 1 ? this.state.page - 1 : null}
                  </Pagination.Item>
                )}
                <Pagination.Item
                  active={this.state.page === this.state.page}
                  key={"_pagination_" + this.state.page}
                  onClick={() => this.handlePageClick(this.state.page)}
                >
                  {this.state.page}
                </Pagination.Item>
                {this.state.page + 1 < this.state.totalPage && (
                  <Pagination.Item
                    key={"_pagination_" + this.state.page + 1}
                    onClick={() => this.handlePageClick(this.state.page + 1)}
                  >
                    {Number.parseInt(this.state.page) + 1}
                  </Pagination.Item>
                )}
                {this.state.page != this.state.totalPage && (
                  <>
                    {!(this.state.page + 1 == this.state.totalPage) && (
                      <Pagination.Ellipsis />
                    )}
                    <Pagination.Item
                      active={this.state.page + 1 === this.state.page}
                      key={"_pagination_" + this.state.page + 1}
                      onClick={() => this.handlePageClick(this.state.totalPage)}
                    >
                      {this.state.totalPage}
                    </Pagination.Item>
                  </>
                )}
                {this.state.page < this.state.totalPage && (
                  <Pagination.Next
                    onClick={() => this.handlePageClick(this.state.page + 1)}
                  ></Pagination.Next>
                )}
              </Pagination>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  };
  MobileRender = () => {
    const { t } = this.props.translation;
    return (
      <>
        <div className="Container-Common">
          <View style={style.container}>
            <HeaderMobile context="Home" />
            <Menu />
            <View style={style.board}>
              <Text
                style={{
                  fontSize: "1.3rem",
                  color: "#363636",
                  fontWeight: "500",
                  width: "90%",
                  textAlign: "left",
                }}
              >
                New In
              </Text>
            </View>
            <View
              style={{
                width: "90%",
                justifyContent: "center",
                paddingTop: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.setState({ modal: true });
                }}
              >
                <Text
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    color: "#0A09A2",
                  }}
                >
                  Filters
                </Text>
              </TouchableOpacity>
            </View>
            <ProductGrid
              newInTotal={
                this.state.statoProd == 2 &&
                this.state.ListaProdottiDaFiltrarePage?.length > 0
                  ? this.state.ListaProdottiDaFiltrarePage
                  : this.state.listaProdotti
              }
            />
            {this.state.statoProd === 2 &&
            this.state.ListaProdottiDaFiltrarePage?.length > 0 ? (
              <Pagination>
                {this.state.page > 1 && (
                  <Pagination.Prev
                    onClick={() =>
                      this.handlePageClickFilterM(this.state.page - 1)
                    }
                  />
                )}
                {this.state.page > 1 && (
                  <Pagination.Item
                    onClick={() => this.handlePageClickFilterM(1)}
                  >
                    1
                  </Pagination.Item>
                )}
                {this.state.page > 1 && <Pagination.Ellipsis />}
                {this.state.page - 1 > 1 && (
                  <Pagination.Item
                    active={this.state.page === this.state.page - 1}
                    key={"_pagination_" + this.state.page - 1}
                    onClick={() =>
                      this.handlePageClickFilterM(this.state.page - 1)
                    }
                  >
                    {this.state.page !== 1 ? this.state.page - 1 : null}
                  </Pagination.Item>
                )}

                <Pagination.Item
                  active={this.state.page === this.state.page}
                  key={"_pagination_" + this.state.page}
                  onClick={() => this.handlePageClickFilterM(this.state.page)}
                >
                  {this.state.page}
                </Pagination.Item>
                {this.state.page != this.state.countFilter.length && (
                  <Pagination.Item
                    active={this.state.page + 1 === this.state.page}
                    key={"_pagination_" + this.state.page + 1}
                    onClick={() =>
                      this.handlePageClickFilterM(this.state.page + 1)
                    }
                  >
                    {this.state.page + 1}
                  </Pagination.Item>
                )}
                {this.state.page + 1 < this.state.countFilter.length && (
                  <Pagination.Ellipsis />
                )}
                {this.state.page + 1 < this.state.countFilter.length && (
                  <Pagination.Item
                    onClick={() =>
                      this.handlePageClickFilterM(this.state.countFilter.length)
                    }
                  >
                    {this.state.countFilter.length}
                  </Pagination.Item>
                )}
                {this.state.page < this.state.countFilter.length && (
                  <Pagination.Next
                    onClick={() =>
                      this.handlePageClickFilterM(this.state.page + 1)
                    }
                    disabled={
                      this.state.page + 1 === this.state.countFilter.length ||
                      this.state.page === this.state.countFilter.length ||
                      this.state.page > this.state.countFilter.length
                    }
                  />
                )}
              </Pagination>
            ) : this.state.statoProd === 2 &&
              this.state.ListaProdottiDaFiltrarePage?.length < 1 ? (
              <div>
                <label></label>
              </div>
            ) : (
              <Pagination>
                {this.state.page > 1 && (
                  <Pagination.Prev
                    onClick={() => this.handlePageClickM(this.state.page - 1)}
                  ></Pagination.Prev>
                )}
                {this.state.page > 1 && (
                  <Pagination.Item onClick={() => this.handlePageClickM(1)}>
                    1
                  </Pagination.Item>
                )}
                {this.state.page > 1 && <Pagination.Ellipsis />}
                {this.state.page - 1 > 1 && (
                  <Pagination.Item
                    active={this.state.page === this.state.page - 1}
                    key={"_pagination_" + this.state.page - 1}
                    onClick={() => this.handlePageClickM(this.state.page - 1)}
                  >
                    {this.state.page !== 1 ? this.state.page - 1 : null}
                  </Pagination.Item>
                )}
                <Pagination.Item
                  active={this.state.page === this.state.page}
                  key={"_pagination_" + this.state.page}
                  onClick={() => this.handlePageClickM(this.state.page)}
                >
                  {this.state.page}
                </Pagination.Item>
                {this.state.page + 1 < this.state.totalPage && (
                  <Pagination.Item
                    key={"_pagination_" + this.state.page + 1}
                    onClick={() => this.handlePageClickM(this.state.page + 1)}
                  >
                    {Number.parseInt(this.state.page) + 1}
                  </Pagination.Item>
                )}
                {this.state.page != this.state.totalPage && (
                  <>
                    {!(this.state.page + 1 == this.state.totalPage) && (
                      <Pagination.Ellipsis />
                    )}
                    <Pagination.Item
                      active={this.state.page + 1 === this.state.page}
                      key={"_pagination_" + this.state.page + 1}
                      onClick={() =>
                        this.handlePageClickM(this.state.totalPage)
                      }
                    >
                      {this.state.totalPage}
                    </Pagination.Item>
                  </>
                )}
                {this.state.page < this.state.totalPage && (
                  <Pagination.Next
                    onClick={() => this.handlePageClickM(this.state.page + 1)}
                  ></Pagination.Next>
                )}
              </Pagination>
            )}
            <View style={{ height: 40 }} />
            <Footer />
          </View>
        </div>
        <ModalFilters
          filterPrezzo={this.filtriFunction}
          marca={this.state.listaMarca}
          update={this.updateFiltri}
          filtriAttuali={this.state.filtri}
          modalAdd={this.state.modal}
          onModalDismiss={this.onPressModalCancel}
        />
      </>
    );
  };
  render() {
    return (
      <>
        {this.state.loading === true ? (
          <Loading />
        ) : (
          <>
            {this.props.desktop ? (
              <this.DesktopRender />
            ) : (
              <this.MobileRender />
            )}
          </>
        )}
      </>
    );
  }
}
const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  board: {
    width: "90%",
    paddingBottom: 30,
  },
});
export default function (props) {
  const params = useParams();
  const translation = useTranslation();
  const navigate = useNavigate();
  const desktop = useDesktopChecker();
  const location = useLocation();
  return (
    <NewInNoReplace
      {...props}
      params={params}
      translation={translation}
      navigate={navigate}
      desktop={desktop}
      location={location}
    />
  );
}
