import React, { Component } from "react";
import HeaderMobile from "../components/HeaderMobile";
import { View, Text, TouchableOpacity, Image } from "react-native";
import { Link } from "react-router-dom";
import { colors, dim, isLittleDev } from "../components/utils/constants";
import { withTranslation } from "react-i18next";

export class CheckoutThree extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <HeaderMobile context="Home" />
        <View style={{ padding: 10, height: "100%" }}>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              textAlign: "center",
              marginBottom: 10,
            }}
          >
            {t("conf_purch")}
          </Text>
          <Text style={{ marginVertical: 5, textAlign: "center" }}>
            {t("conf_purch_1")}
          </Text>
          <Text style={{ marginVertical: 5, textAlign: "center" }}>
            {t("conf_purch_2")}
          </Text>
          <Text style={{ marginVertical: 5, textAlign: "center" }}>
            {t("conf_purch_3")}
          </Text>
          <Text style={{ marginVertical: 5, textAlign: "center" }}>
            {t("conf_purch_4")}
            {t("conf_purch_5")}
          </Text>
        </View>
        <View style={{ marginTop: 15 }}>
          <Link to="/" className="link-checkout">
            <TouchableOpacity
              style={{
                backgroundColor: colors.blue,
                color: "white",
                fontWeight: "bold",
                fontSize: "1.1rem",
                width: "90%",
                paddingVertical: 7,
                textAlign: "center",
                borderRadius: 3,
              }}
            >
              {t("back_home")}
            </TouchableOpacity>
          </Link>
        </View>
      </>
    );
  }
}

export default withTranslation()(CheckoutThree);
