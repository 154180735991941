import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import "../../styles/ModificaPage.css";
import Header from "../../components/Header";
import AccountRoutes from "../../components/AccountRoutes";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";
import { useTranslation, withTranslation } from "react-i18next";
import { getUser, modificaIndirizzo } from "../chiamateApi/ApiModifyUser";

function ModificaSpedizione() {
  const [oldIndirizzo, setOldIndirizzo] = useState("");
  const [newIndirizzo, setNewIndirizzo] = useState("");
  const [oldCap, setOldCap] = useState(0);
  const [newCap, setNewCap] = useState(0);
  const [confermaCap, setConfermaCap] = useState(0);
  const [oldCivic, setOldCivic] = useState(0);
  const [newCivic, setNewCivic] = useState(0);
  const [confermaCivic, setConfermaCivic] = useState(0);
  const [accountData, setAccountData] = useState({});
  const [password, setPassword] = useState("");
  const [confermaIndirizzo, setConfermaIndirizzo] = useState("");
  const [showToast, setShowToast] = useState(false);
  const { t } = useTranslation();
  const id = localStorage.getItem(CONSTANTS.ID);
  const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);
  const navigate = useNavigate();
  const desktop = useDesktopChecker();

  useEffect(() => {
    async function getIban() {
      const data = await getUser(id, token);
      setOldIndirizzo(data.indirizzo);
      setOldCap(data.cap);
      setOldCivic(data.civico);
    }
    getIban();
  }, []);

  const SubmitNewIndirizzo = async (e) => {
    e.preventDefault();

    console.log(oldIndirizzo);
    console.log(newIndirizzo);
    if (oldIndirizzo.length > 5 && oldCap.length > 4 && oldCivic > 0) {
      console.log("mail uguali");
      const data = {
        id: id,
        indirizzo: oldIndirizzo,
        oldMail: oldIndirizzo,
        cap: oldCap,
        civico: oldCivic,
        oldPassword: password,
      };

      const res = await modificaIndirizzo(data, token);
      if (res.data.error) {
        setTimeout(() => {
          alert(t("incorrect_password"));
          navigate("/account/impostazioni");
        }, 2000);
      } else {
        console.log(res);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
          navigate("/account/impostazioni");
        }, 2000);
      }
    } else {
      alert(t("wrong_address"));
    }
  };

  return (
    <div className="container-account-general">
      {desktop && <Header />}
      {!desktop && <HeaderMobile context="Others" title={"Settings"} />}
      <div className="container-account-route">
        {desktop && <AccountRoutes />}
        <div className="modifica-password-container">
          <p className="modifica-pass-title">{t("edit_shipment")}</p>
          <p className="modifica-pass-subtitle">{t("fill_the_fields_below")}</p>
          <form onSubmit={SubmitNewIndirizzo}>
            <b>{t("address")}:</b>
            <input
              onChange={(e) => {
                setOldIndirizzo(e.target.value);
              }}
              required
              value={oldIndirizzo}
              type="text"
              placeholder={t("address")}
            />

            <b>{t("civic")}:</b>
            <input
              onChange={(e) => {
                setOldCivic(e.target.value);
              }}
              required
              value={oldCivic}
              type="number"
              placeholder={t("civic")}
            />

            <b>{t("postal_code")}:</b>
            <input
              onChange={(e) => {
                setOldCap(e.target.value);
              }}
              required
              value={oldCap}
              type="number"
              placeholder={t("postal_code")}
            />

            <b>Password:</b>
            <input
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
              type="password"
              placeholder="Password"
            />
            <button type="submit">{t("confirm_address")}</button>
          </form>
          <Toast show={showToast} className="toast-confirm">
            <span>{t("success_address")}</span>
          </Toast>
        </div>
      </div>
    </div>
  );
}

export default ModificaSpedizione;
