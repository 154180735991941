import CONSTANTS from "../../CONSTANTS";

export const isAutenticated = () => {
  let bool = false;

  const c = localStorage.getItem(CONSTANTS.NOME);
  if (c) {
    bool = true;
  }
  return bool;
};
export const getCallerName = () => {
  return localStorage.getItem(CONSTANTS.ID);
};
