import React, { Component } from "react";
import Header from "../components/Header";
import "../styles/About.css";
import backgroundAbout from "../assets/scrittone-about.png";
import WKLogo from "../assets/logone-uolkicks.png";
import scarpaDue from "../assets/scarpa-about.png";
import numeroUno from "../assets/numero-uno.png";
import numeroDue from "../assets/numero-2.png";
import numeroTre from "../assets/numero-tre.png";
import numeroQuattro from "../assets/numero-quattro.png";
import numeroCinque from "../assets/numero-cinque.png";
import scatola from "../assets/scatola-wk.png";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import Loading from "../components/Loading";
import useDesktopChecker from "../components/utils/useDesktopChecker";
import { View, Image, Text, StyleSheet } from "react-native";
import Menu from "../pagesMobile/home/Menu";
import HeaderMobile from "../pagesMobile/components/HeaderMobile";
import videoFile from "../assets/video/about-us.mp4";
import videoFileMobile from "../assets/video/about-us-mobile.mp4";
import useDynamicWidth from "../components/utils/useDynamicWidth";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    setInterval(() => {
      this.setState({ loading: false });
    }, 700);
  }
  DesktopRender = () => {
    const { t } = this.props.translation;
    return (
      <>
        <div>
          <Header />
        </div>
        <div className="container-about">
          <div className="video-about">
            <video width="100%" height="100%" muted loop autoPlay>
              <source src={videoFile} type="video/mp4" />
            </video>
          </div>
          <div class="bg-1-about">
            {/* <img
            src={backgroundAbout}
            className="bg-about-img"
            alt="bg-about"
          /> */}
            <div className="row-1-about">
              <div>
                <img className="logone-wk" src={WKLogo} alt="logo-wk" />
              </div>
              <div className="testo-right-about">
                <h3 className="chi-e">{t("who_is")}?</h3>
                <h3 className="title-wk">Wholekicks</h3>
                <p>
                  Wholekicks
                  {t("about_1")} <br />
                  {t("about_2")} <br />
                  {t("about_3")} <br />
                  {t("about_4")}
                  {t("about_5")}
                  {t("about_6")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row-2-about">
          <div className="col-left">
            <img src={scarpaDue} alt="scarpa-about" className="scarpa-about" />
          </div>
          <div className="col-right">
            <h3 className="title-row-2">{t("how_to_buy")}</h3>
            <div className="list-text-about">
              <div className="list-text-left">
                <div className="list-testo-numero">
                  <img
                    src={numeroUno}
                    alt="numero-uno"
                    className="img-bullet"
                  />
                  <p>{t("how_to_buy_1")}</p>
                </div>
                <div className="list-testo-numero">
                  <img
                    src={numeroDue}
                    alt="numero-due"
                    className="img-bullet"
                  />
                  <p>{t("how_to_buy_2")}</p>
                </div>
                <div className="list-testo-numero">
                  <img
                    src={numeroTre}
                    alt="numero-tre"
                    className="img-bullet"
                  />
                  <p>{t("how_to_buy_3")}</p>
                </div>
                <div className="list-testo-numero">
                  <img
                    src={numeroQuattro}
                    alt="numero-4"
                    className="img-bullet"
                  />
                  <p>{t("how_to_buy_4")}</p>
                </div>
                <div className="list-testo-numero">
                  <img
                    src={numeroCinque}
                    alt="numero-5"
                    className="img-bullet"
                  />
                  <p>{t("how_to_buy_5")}</p>
                </div>
              </div>
              {/* <div className="list-text-right">
            
          </div> */}
            </div>
          </div>
        </div>
        <div className="row-3-about">
          <div className="row-3-left">
            <h3 className="title-row-3">{t("how_to_sell")}</h3>
            <div className="list-testo-numero">
              <img src={numeroUno} alt="numero-uno" className="img-bullet" />
              <p className="testo-row-3">{t("how_to_buy_1")}</p>
            </div>
            <div className="list-testo-numero">
              <img src={numeroDue} alt="numero-due" className="img-bullet" />
              <p className="testo-row-3">{t("how_to_sell_2")}</p>
            </div>
            <div className="list-testo-numero">
              <img src={numeroTre} alt="numero-tre" className="img-bullet" />
              <p className="testo-row-3">
                <br /> {t("how_to_sell_3")}
              </p>
            </div>
            <div className="list-testo-numero">
              <img src={numeroQuattro} alt="numero-4" className="img-bullet" />
              <p className="testo-row-3">{t("how_to_sell_4")}</p>
            </div>
          </div>
          <div className="scatola-wk-cont">
            <img src={scatola} alt="scatola-wk" className="scatola-img" />
          </div>
        </div>
        <Footer />
      </>
    );
  };
  MobileRender = () => {
    const { t } = this.props.translation;
    const width = useDynamicWidth();
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Home" />
          <Menu />
          <View style={{ width: "100%", backgroundColor: "#fff" }}>
            {width >= 650 ? (
              <div key="desktop" className="video-about">
                <video width="100%" height="100%" muted loop autoPlay>
                  <source src={videoFile} type="video/mp4" />
                </video>
              </div>
            ) : (
              <div key="mobile" className="video-about">
                <video width="100%" height="auto" muted loop autoPlay>
                  <source src={videoFileMobile} type="video/mp4" />
                </video>
              </div>
            )}

            <Image
              source={require("../assets/logone-uolkicks.png")}
              style={{
                width: "50%",
                height: 200,
                resizeMode: "contain",
                marginTop: 20,
                marginLeft: 20,
              }}
            />
            <View
              style={{
                width: "100%",
                paddingRight: "5%",
                paddingLeft: "20%",
                marginBottom: "10%",
              }}
            >
              <Text
                style={{
                  fontSize: "2.5rem",
                  textAlign: "right",
                  color: "#363636",
                  fontWeight: "bold",
                }}
              >
                Wholekicks
              </Text>
              <Text
                style={{
                  fontSize: "1.5rem",

                  color: "#363636",
                  fontWeight: "bold",
                  transform: [{ rotateX: "0deg" }, { rotateZ: "270deg" }],
                  position: "absolute",
                  left: 0,
                  top: 70,
                }}
              >
                {t("who_is")}
              </Text>
              <Text style={{ color: "#363636" }}>
                {t("about_1")} {t("about_2")}
                {t("about_3")}
                {t("about_4")}
                {t("about_5")}
                {t("about_6")}
              </Text>
            </View>
            <View
              style={{
                backgroundColor: "#f5f5f5",
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontSize: "2.5rem",
                  textAlign: "right",
                  color: "#363636",
                  fontWeight: "bold",
                  paddingRight: "5%",
                }}
              >
                {t("how_to_buy")}
              </Text>
              <Image
                source={require("../assets/scarpa-about.png")}
                style={{
                  width: "70%",
                  height: 200,
                  resizeMode: "contain",
                  marginTop: 20,
                }}
              />
              <View
                style={{
                  width: "100%",
                  paddingHorizontal: "5%",
                  flexDirection: "row",
                  marginVertical: 10,
                }}
              >
                <Image
                  source={require("../assets/numero-uno.png")}
                  style={{
                    width: 25,
                    height: 25,
                    resizeMode: "contain",
                    marginRight: 10,
                  }}
                />
                <Text style={{ color: "#363636" }}>{t("how_to_buy_1")}</Text>
              </View>
              <View
                style={{
                  width: "100%",
                  paddingHorizontal: "5%",
                  flexDirection: "row",
                  marginVertical: 10,
                }}
              >
                <Image
                  source={require("../assets/numero-2.png")}
                  style={{
                    width: 25,
                    height: 25,
                    resizeMode: "contain",
                    marginRight: 10,
                  }}
                />
                <Text style={{ color: "#363636" }}>{t("how_to_buy_2")}</Text>
              </View>
              <View
                style={{
                  width: "100%",
                  paddingHorizontal: "5%",
                  flexDirection: "row",
                  marginVertical: 10,
                }}
              >
                <Image
                  source={require("../assets/numero-tre.png")}
                  style={{
                    width: 25,
                    height: 25,
                    resizeMode: "contain",
                    marginRight: 10,
                  }}
                />
                <Text style={{ color: "#363636" }}>{t("how_to_buy_3")}</Text>
              </View>
              <View
                style={{
                  width: "100%",
                  paddingHorizontal: "5%",
                  flexDirection: "row",
                  marginVertical: 10,
                }}
              >
                <Image
                  source={require("../assets/numero-quattro.png")}
                  style={{
                    width: 25,
                    height: 25,
                    resizeMode: "contain",
                    marginRight: 10,
                  }}
                />
                <Text style={{ color: "#363636" }}>{t("how_to_buy_4")}</Text>
              </View>
              <View
                style={{
                  width: "100%",
                  paddingHorizontal: "5%",
                  flexDirection: "row",
                  marginVertical: 10,
                }}
              >
                <Image
                  source={require("../assets/numero-cinque.png")}
                  style={{
                    width: 25,
                    height: 25,
                    resizeMode: "contain",
                    marginRight: 10,
                  }}
                />
                <Text style={{ color: "#363636" }}>{t("how_to_buy_5")}</Text>
              </View>
            </View>
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontSize: "2.5rem",
                  textAlign: "right",
                  color: "#363636",
                  fontWeight: "bold",
                  paddingRight: "5%",
                }}
              >
                {t("how_to_sell")}
              </Text>
              <Image
                source={require("../assets/scatola-wk.png")}
                style={{
                  width: "100%",
                  height: 300,
                  resizeMode: "contain",
                  marginTop: 20,
                }}
              />
              <View
                style={{
                  width: "100%",
                  paddingHorizontal: "5%",
                  flexDirection: "row",
                  marginVertical: 10,
                }}
              >
                <Image
                  source={require("../assets/numero-uno.png")}
                  style={{
                    width: 25,
                    height: 25,
                    resizeMode: "contain",
                    marginRight: 10,
                  }}
                />
                <Text style={{ color: "#363636" }}>{t("how_to_sell_1")}</Text>
              </View>
              <View
                style={{
                  width: "100%",
                  paddingHorizontal: "5%",
                  flexDirection: "row",
                  marginVertical: 10,
                }}
              >
                <Image
                  source={require("../assets/numero-2.png")}
                  style={{
                    width: 25,
                    height: 25,
                    resizeMode: "contain",
                    marginRight: 10,
                  }}
                />
                <Text style={{ color: "#363636" }}>{t("how_to_sell_2")}</Text>
              </View>
              <View
                style={{
                  width: "100%",
                  paddingHorizontal: "5%",
                  flexDirection: "row",
                  marginVertical: 10,
                }}
              >
                <Image
                  source={require("../assets/numero-tre.png")}
                  style={{
                    width: 25,
                    height: 25,
                    resizeMode: "contain",
                    marginRight: 10,
                  }}
                />
                <Text style={{ color: "#363636" }}>{t("how_to_sell_3")}</Text>
              </View>
              <View
                style={{
                  width: "100%",
                  paddingHorizontal: "5%",
                  flexDirection: "row",
                  marginVertical: 10,
                }}
              >
                <Image
                  source={require("../assets/numero-quattro.png")}
                  style={{
                    width: 25,
                    height: 25,
                    resizeMode: "contain",
                    marginRight: 10,
                  }}
                />
                <Text style={{ color: "#363636" }}>{t("how_to_sell_4")}</Text>
              </View>
            </View>
            <Footer />
          </View>
        </View>
      </div>
    );
  };
  render() {
    const t = this.props.translation;
    const desktop = this.props.desktop;
    return (
      <>
        {this.state.loading ? (
          <Loading />
        ) : (
          <>{desktop ? <this.DesktopRender /> : <this.MobileRender />}</>
        )}
      </>
    );
  }
}
const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

export default function (props) {
  const translation = useTranslation();
  const desktop = useDesktopChecker();
  return <About desktop={desktop} const translation={translation} />;
}
