import { useState, useEffect } from "react";

export default function useDynamicWidth() {
  const [width, setwidth] = useState(window.innerWidth);
  const onResize = () => {
    setwidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", () => onResize());
    return () => {
      window.removeEventListener("resize", () => onResize());
    };
  }, []);

  return width;
}
