import React, { useEffect, useState } from "react";
import logo from "../assets/wk-logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CONSTANTS from "../CONSTANTS";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";

function ReimpostaPassword() {
  const navigate = useNavigate();
  const [firstPassowrd, setFirstPassword] = useState("");
  const [secondPassowrd, setSecondPassword] = useState("");
  const [idAccount, setidAccount] = useState("");
  const [stato, setStato] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);
  const email = localStorage.getItem(CONSTANTS.EMAILRESETPASSWORD);
  const location = useLocation();
  const { t } = useTranslation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (firstPassowrd === secondPassowrd) {
      const data = {
        password: secondPassowrd,
        email: email,
        path: location.pathname.replace("/reimposta-password/", ""),
      };
      console.log("DATA", data);
      await axios
        .post(CONSTANTS.API_URL + "/account/update/new-password", data)
        .then((res) => {
          console.log("RES: ", res);
          if (res.data === "Link Scaduto") {
            alert(t("link_expired"));
          } else {
            setTimeout(() => {
              alert(t("Password_succ"));
              window.location.replace("/login");
            }, 2000);
          }
        })
        .catch(async (e) => {
          await axios
            .post(CONSTANTS.API_URL + "/reimpostapassword/", data)
            .then((res) => {
              console.log(res);

              setTimeout(() => {
                alert(t("link_expired"));
              }, 2000);
            });

          console.log(e.message);
        });
    } else {
      alert(t("incorrect_password"));
    }
  };

  return (
    <>
      {stato == false ? (
        <div className="container-form">
          <div className="div-logo-form">
            <Link to="/">
              <img className="logo-login" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="form-div">
            <div>
              <p>{t("reset_password")}</p>

              <div className="login-form-div">
                <form onSubmit={handleSubmit} className="login-form-div">
                  <div>
                    <input
                      type="password"
                      required
                      placeholder="Nuova Password"
                      value={firstPassowrd}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFirstPassword(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <input
                      type="password"
                      required
                      placeholder="Conferma Password"
                      value={secondPassowrd}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setSecondPassword(e.target.value);
                      }}
                    />
                  </div>
                  <button type="submit" className="login-btn-s">
                    {t("sent")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <label>{t("link_expired")}</label>
      )}
    </>
  );
}

export default ReimpostaPassword;
