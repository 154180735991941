import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/wk-logo.png";

function ConfermaRegistrazione() {
  let navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <>
      <div className="container-conferma-form-reg">
        <p className="descrizione-conferma-reg">
          {t("conf_reg_1")} <br />
          {t("conf_reg_2")}
        </p>
        <div>
          <button className="login-btn-reg" onClick={() => navigate("/")}>
            Torna alla Home
          </button>
        </div>
      </div>
    </>
  );
}

export default ConfermaRegistrazione;
