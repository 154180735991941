import React, { Component } from "react";
import Header from "../components/Header";
import axios from "axios";
import CONSTANTS from "../CONSTANTS";
import { Link } from "react-router-dom";
import Filters from "../components/Filters";

export default class MostClicked extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      listaProdotti: [],
      ListaProdottiDaFiltrare: [],
      listaPiuCercati: [],
      prezzo1: 0,
      prezzo2: 0,
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
      listaMarca: [],
      filtri: { prezzo: "0", marca: [] },
      array: [],
      modalShow: false,
    };
    this.prezzoDispatcher = {
      0: { prezzo1: 0, prezzo2: 1000000 },
      1: { prezzo1: 0, prezzo2: 100 },
      2: { prezzo1: 100, prezzo2: 200 },
      3: { prezzo1: 200, prezzo2: 300 },
      4: { prezzo1: 300, prezzo2: 400 },
      5: { prezzo1: 400, prezzo2: 500 },
      6: { prezzo1: 500, prezzo2: 100000 },
    };
  }
  componentDidMount() {
    axios.get(CONSTANTS.API_URL + "/piucercate").then((prodottoRicevuto) => {
      console.log(prodottoRicevuto.data[0].imageProdotto);
      this.setState({
        listaPiuCercati: prodottoRicevuto.data,
        ListaProdottiDaFiltrare: prodottoRicevuto.data,
      });
    });
    axios.get(CONSTANTS.API_URL + "/marca/").then((marca) => {
      this.setState({
        listaMarca: marca.data,
      });
    });
  }
  upgradeClick = async (data) => {
    console.log(data);
    const body = {
      ProdottoId: data,
    };
    await axios
      .post(CONSTANTS.API_URL + "/piucercate/click", body)
      .then((res) => {
        console.log(res.data.successo);
        this.routeChange("registrazione/conferma-registrazione");
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  filtriFunction = (type1, value1) => {
    const value = value1.sort().reverse();
    const type = type1.sort();
    console.log("type", type);
    console.log("value", value);
    let arrayTemp = [];

    this.setState({
      ListaProdottiDaFiltrare: this.state.listaProdotti,
    });

    type.map((tipo, index) => {
      console.log("value[index]", value[index]);
      console.log("tipo", tipo);
      if (tipo === "prezzo" && index === 0) {
        const valore = this.state.listaProdotti.filter(
          (prod) =>
            prod.prezzo <= this.prezzoDispatcher[value[index]].prezzo2 &&
            prod.prezzo >= this.prezzoDispatcher[value[index]].prezzo1,
        );
        arrayTemp = valore;
      } else if (tipo === "marca" && index === 0) {
        const valore = this.state.listaProdotti.filter(
          (prod) => prod.marca === value[index],
        );
        console.log("sono qui", valore);
        arrayTemp = valore;
      } else if (tipo === "prezzo" && index !== 0) {
        const valore = this.state.ListaProdottiDaFiltrare.filter(
          (prod) =>
            prod.prezzo <= this.prezzoDispatcher[value[index]].prezzo2 &&
            prod.prezzo >= this.prezzoDispatcher[value[index]].prezzo1,
        );
        arrayTemp = valore;
      } else if (tipo === "marca" && index !== 0) {
        const valore = this.state.ListaProdottiDaFiltrare.filter(
          (prod) => prod.marca === value[index],
        );
        console.log("sono qui2", valore);
        arrayTemp = valore;
      } else {
        arrayTemp = this.state.ListaProdottiDaFiltrare;
      }
    });
    console.log(arrayTemp);
    if (arrayTemp == []) {
      this.setState({ modalShow: true });
    } else {
      this.setState({ ListaProdottiDaFiltrare: arrayTemp });
    }
  };
  render() {
    return (
      <div>
        <Header />
        <div className="container">
          <div className="filtri-container">
            <Filters
              filterPrezzo={this.filtriFunction}
              marca={this.state.listaMarca}
              filtriAttuali={this.state.filtri}
            />
          </div>
          <div className="new-in-prodotti-wrapped">
            {this.state.ListaProdottiDaFiltrare.length > 0
              ? this.state.ListaProdottiDaFiltrare.map((prodotto, key) => {
                  const prezzo = prodotto.prezzo;
                  const prezzoFinale = parseFloat(prezzo).toFixed(2);
                  const finalPrice = prezzoFinale.toString().replace(".", ",");
                  return (
                    <Link
                      className="container-sn-wrapped"
                      to={`/product/${prodotto.id}`}
                      onClick={() => this.upgradeClick(prodotto.id)}
                      style={{
                        minWidth: CONSTANTS.dimensions.width * 0.2,
                        width: CONSTANTS.dimensions.width * 0.2,
                      }}
                    >
                      <div key={key}>
                        <div className="sneaker-cell">
                          {" "}
                          <img
                            src={prodotto.imageProdotto}
                            alt="scarpa immagine"
                            className="img-prodotto-home-slider"
                            style={{
                              height: CONSTANTS.dimensions.width * 0.09,
                            }}
                          />
                          {finalPrice !== "NaN" ? (
                            <div>
                              <span>{prodotto.dettaglio}</span>

                              <p>Average price</p>
                              <div className="sneaker-prezzo-lotto-div">
                                <span className="prezzo-prod">
                                  € {finalPrice}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <span>Product Detail: </span>
                              <p>No Lot Listed</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                  );
                })
              : this.state.listaProdotti.map((prodotto, key) => {
                  const prezzo = prodotto.prezzo;
                  const prezzoFinale = parseFloat(prezzo).toFixed(2);
                  const finalPrice = prezzoFinale.toString().replace(".", ",");
                  return (
                    <Link
                      className="container-sn"
                      to={`/product/${prodotto.id}`}
                      onClick={() => this.upgradeClick(prodotto.id)}
                    >
                      <div key={key}>
                        <div className="sneaker-cell">
                          {" "}
                          <img
                            src={prodotto.imageProdotto}
                            alt="scarpa immagine"
                          />
                          {finalPrice !== "NaN" ? (
                            <div>
                              <span>{prodotto.dettaglio}</span>

                              <p>Average price</p>
                              <div className="sneaker-prezzo-lotto-div">
                                <span className="prezzo-prod">
                                  € {finalPrice}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <span>Product Detail: </span>
                              <p>No Lot Listed</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                  );
                })}
          </div>
        </div>
      </div>
    );
  }
}
