/* eslint-disable import/no-anonymous-default-export */
import React, { Component } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../styles/Account.css";
import CONSTANTS from "../CONSTANTS";
import { Modal } from "react-bootstrap";
import { WindowSharp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

class AccountRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  logOut = async () => {
    console.log(localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY, ""));
    window.sessionStorage.removeItem("accessToken");
    window.sessionStorage.removeItem("nama");
    localStorage.clear();
    this.setState(
      (state) => ({
        isLogined: false,
        token: localStorage.setItem(CONSTANTS.ACCESS_TOKEN_KEY, ""),
      }),
      console.log(localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY, "")),
    );
    window.location.href = "/";
    this.setState({ showModal: false });
  };
  render() {
    const { location } = this.props;
    console.log(location.pathname);

    const { t } = this.props.translation;

    return (
      <>
        <Modal
          centered
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          dialogClassName="aggiungi-modello-modal"
        >
          <div className="modal-header-text">
            <p className="modello-title-modal">{t("confirm_logout")}</p>
          </div>
          <div className="modal-footer-button">
            <button className="invia-proposta" onClick={this.logOut}>
              {t("confirm")}
            </button>
            <button
              className="chiudi-proposta"
              onClick={() => this.setState({ showModal: false })}
            >
              {t("close")}
            </button>
          </div>
        </Modal>
        <div className="account-route-container">
          <div>
            <Link
              className={
                location.pathname === "/account/vendite"
                  ? "account-links-active"
                  : "account-links"
              }
              to="/account/vendite"
            >
              {t("sell")}
            </Link>
            <Link
              className={
                location.pathname === "/account/acquisti"
                  ? "account-links-active"
                  : "account-links"
              }
              to="/account/acquisti"
            >
              {t("purchase")}
            </Link>
            <Link
              className={
                location.pathname === "/account/storico"
                  ? "account-links-active"
                  : "account-links"
              }
              to="/account/storico"
            >
              {t("history")}
            </Link>
            <Link
              className={
                location.pathname === "/account/impostazioni"
                  ? "account-links-active"
                  : "account-links"
              }
              to="/account/impostazioni"
            >
              {t("setting")}
            </Link>
            <button
              className="logout-button"
              onClick={() => this.setState({ showModal: true })}
            >
              Logout
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default function (props) {
  const location = useLocation();
  const t = useTranslation();
  return <AccountRoutes translation={t} {...props} location={location} />;
}
