import React, { Component } from "react";
import "../../styles/CheckoutStyles.css";
import { Link } from "react-router-dom";
import logo from "../../assets/wk-logo.png";
import Footer from "../../components/Footer";
import { withTranslation } from "react-i18next";
class CheckoutNexiKo extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container-form-check">
          <div className="div-logo-form">
            <Link to="/">
              <img className="logo-login" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="checkout-final-div">
            <p className="conferma-acquisto-lotto-p">{t("title_ko")}</p>
            <span>
              <span style={{ fontSize: 20, fontWeight: 650 }}>
                {t("body_ko_nexi")}
              </span>
            </span>
          </div>
          <div className="btn-footer-checkout" style={{ width: "50%" }}>
            <Link to="/">{t("back_home")}</Link>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
export default withTranslation()(CheckoutNexiKo);
