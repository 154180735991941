import React from "react";
import {
  View,
  Text,
  Modal,
  TouchableWithoutFeedback,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
} from "react-native";
import { colors, isLittleDev } from "../components/utils/constants";
import { useTranslation } from "react-i18next";
import "./style.css";
import CloseIcon from "@mui/icons-material/Close";

const ModalNewLotto = ({
  modalAdd,
  onModalDismiss,
  taglie,
  t_riferimento,
  nuovoPrezzo,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal visible={modalAdd} transparent animationType="slide">
        <View
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          {!isLittleDev() && (
            <TouchableWithoutFeedback onPress={onModalDismiss}>
              <View style={{ height: "6%", width: "100%" }} />
            </TouchableWithoutFeedback>
          )}
          <ScrollView
            style={{
              height: isLittleDev() ? "100%" : "94%",
              width: "100%",
              backgroundColor: "#fff",
              borderTopRightRadius: isLittleDev() ? 15 : 30,
              borderTopLeftRadius: isLittleDev() ? 15 : 30,
              shadowRadius: 10,
              shadowOpacity: 0.5,
              paddingHorizontal: 10,
              paddingTop: 20,
            }}
            contentContainerStyle={{
              width: "100%",
              alignItems: "center",
            }}
          >
            <View style={{ width: "100%", alignItems: "flex-end" }}>
              <TouchableOpacity onPress={() => onModalDismiss()}>
                <CloseIcon />
              </TouchableOpacity>
            </View>
            <View
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Text
                style={{
                  width: "70%",
                  textAlign: "center",
                  alignSelf: "center",
                  fontWeight: "400",
                  fontSize: "1.2rem",
                }}
              >
                {t("new_stock")}
              </Text>
              <View
                style={{
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div className="scroll-div-mobile">
                  <View style={style.containerView}>
                    <Text style={style.textBold}>{t("new_price")}:</Text>
                    <Text style={style.textNotBold}>{nuovoPrezzo}€</Text>
                  </View>
                  <View
                    style={{
                      backgroundColor: "#f5f5f5",
                      marginTop: 2,
                      borderRadius: 12,
                      width: "100%",
                      padding: 6,
                      flexDirection: "row",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {t_riferimento?.map((t) => {
                      const tagliaCustom = taglie?.find(
                        (f) => f.taglia === t.taglia
                      );
                      console.log("TAGLIA CUSTOM in mobile", tagliaCustom);
                      let nuovoNumero = 0;
                      nuovoNumero = tagliaCustom?.numero
                        ? t.numero - tagliaCustom?.numero
                        : t.numero;

                      return (
                        <>
                          {nuovoNumero > 0 && (
                            <View
                              style={{
                                borderWidth: 0.5,
                                borderColor: "black",
                                width: "35%",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                paddingVertical: 2,
                                paddingHorizontal: "2%",
                                borderRadius: 7,
                                backgroundColor: "white",
                                margin: 5,
                              }}
                            >
                              <View
                                style={{
                                  width: "100%",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  marginBottom: 3,
                                  textAlign: "center",
                                }}
                              >
                                <Text
                                  style={{
                                    textAlign: "center",
                                    width: 40,
                                    fontWeight: "700",
                                  }}
                                >
                                  US
                                </Text>
                                <Text
                                  style={{
                                    backgroundColor: colors.blue,
                                    padding: 4,
                                    borderRadius: 5,
                                    width: 40,
                                    textAlign: "center",
                                    color: "#fff",
                                  }}
                                >
                                  {t.taglia}
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: "100%",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  marginBottom: 3,
                                }}
                              >
                                <Text
                                  style={{
                                    textAlign: "center",
                                    width: 40,
                                    fontWeight: "700",
                                  }}
                                >
                                  Qty
                                </Text>
                                <Text
                                  style={{
                                    backgroundColor: colors.blue,
                                    padding: 4,
                                    borderRadius: 5,
                                    width: 40,
                                    textAlign: "center",
                                    color: "#fff",
                                  }}
                                >
                                  {nuovoNumero}
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: "100%",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  marginBottom: 3,
                                }}
                              >
                                <Text
                                  style={{
                                    textAlign: "center",
                                    width: 40,
                                    fontWeight: "700",
                                  }}
                                >
                                  €
                                </Text>
                                <Text
                                  style={{
                                    backgroundColor: colors.blue,
                                    padding: 4,
                                    borderRadius: 5,
                                    width: 40,
                                    textAlign: "center",
                                    color: "#fff",
                                  }}
                                >
                                  {t.prezzo}
                                </Text>
                              </View>
                            </View>
                          )}
                        </>
                      );
                    })}
                  </View>
                </div>
              </View>
              <View style={{ height: isLittleDev() ? 20 : 30 }} />
            </View>
          </ScrollView>
        </View>
      </Modal>
    </>
  );
};

const style = StyleSheet.create({
  containerView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  textBold: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  textNotBold: {
    fontSize: "1rem",
    marginLeft: 5,
  },
});

export default ModalNewLotto;
