import axios from "axios";
import CONSTANTS from "../../CONSTANTS";

export const getUser = async (id, token) => {
  return await axios
    .get(CONSTANTS.API_URL + "/account/utente/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (res) => {
      console.log("DATI EMAIL 2", res.data);
      return res.data;
    });
};
export const modificaMail = async (data, token) => {
  return await axios
    .post(CONSTANTS.API_URL + "/account/update/email", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log("res", res);
      return res;
    })
    .catch((e) => {
      console.log(e.message);
    });
};

export const modificaIban = async (data, token) => {
  return await axios
    .post(CONSTANTS.API_URL + "/account/update/iban", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log("res", res);
      return res;
    })
    .catch((e) => {
      console.log(e.message);
    });
};

export const modificaIndirizzo = async (data, token) => {
  return await axios
    .post(CONSTANTS.API_URL + "/account/update/indirizzo", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log("res", res);
      return res;
    })
    .catch((e) => {
      console.log(e.message);
    });
};
export const modificaPassword = async (data, token) => {
  return await axios
    .post(CONSTANTS.API_URL + "/account/update/password", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log("res", res);
      return res;
    })
    .catch((e) => {
      return "Password errata";
    });
};

export const sendMail = async (body) => {
  console.log(body);
  await axios
    .post(CONSTANTS.API_URL + "/mail/", body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          CONSTANTS.ACCESS_TOKEN_KEY,
        )}`,
      },
    })
    .then((res) => {
      console.log("success");
      console.log(res);
    })
    .catch((e) => {
      console.log(e.message);
    });
};
