import React, { Component } from "react";
import { Text, View, StyleSheet } from "react-native";
import HeaderMobile from "../components/HeaderMobile";
import { dim } from "../components/utils/constants";
import DrawerContent from "./DrawerContent";

export class Drawer extends Component {
  render() {
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Drawer" />
          <View style={style.containerInner}>
            <DrawerContent />
          </View>
        </View>
      </div>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  containerInner: {
    height: "100%",
    width: "100%",
  },
});

export default Drawer;
