import "./App.css";
import { useEffect, useState } from "react";
import AppContent from "./routes/AppContent";
import AppMobileContent from "./routes/AppMobileContent";
import logo from "./assets/wk-logo.png";
import ReactGA from "react-ga";
import { isNotMobile } from "./pagesMobile/components/utils/constants";
function App() {
  const [isNotMobileState, setIsNotMobileState] = useState(false);
  const onResize = () => {
    setIsNotMobileState(isNotMobile());
  };
  useEffect(() => {
    window.addEventListener("resize", () => onResize());

    return () => {
      window.removeEventListener("resize", () => onResize());
    };
  });

  return (
    <div className="App">
      <AppContent />

      {/* {
      isNotMobile()?
      <AppContent />
      :
      // <div style={{display:'flex',flex:1,height:'95vh',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      //   <img src={logo} style={{width:'30%',height:'auto'}}/>
      //   <p style={{width:'85%',fontSize:'1.1rem',fontWeight:'600',textAlign:'center'}}>
      //   Responsive version of the website will be released soon!
      //   WK Team
      //   </p>
        
      // </div>
      //  <AppMobileContent/>
      } */}
    </div>
  );
}

export default App;
