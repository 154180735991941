import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";

function AziendaInfo({
  initialValues,
  onSubmitRegistrazione,
  onSubmitField,
  validationSchema,
}) {
  const { t } = useTranslation();

  const [fileName, setFileName] = useState("");
  return (
    <>
      <div className="login-form-div">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitRegistrazione}
          validationSchema={validationSchema}
        >
          <Form>
            <div>
              <Field
                name="ragioneSociale"
                type="text"
                placeholder={t("comp_name")}
                required
                className={"form-no-margin"}
              />
            </div>
            <ErrorMessage name="ragioneSociale" component="span" />
            <div>
              <Field
                name="sedeLegale"
                type="text"
                placeholder={t("head_office")}
                required
                className={"form-no-margin"}
              />
            </div>
            <ErrorMessage name="sedeLegale" component="span" />
            <div>
              <Field
                name="partitaIva"
                type="text"
                placeholder={t("vat_number")}
                required
                className={"form-no-margin"}
              />
            </div>
            <ErrorMessage name="partitaIva" component="span" />
            <div>
              <Field
                name="iban"
                type="text"
                placeholder="IBAN"
                required
                className={"form-no-margin"}
              />
            </div>
            <div
              style={{
                border: "none",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "fit-content",
                paddingBottom: 10,
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <div>
                <Field
                  name="termandcondition"
                  type="checkbox"
                  required
                  style={{ marginBottom: 4 }}
                />
                <span>
                  {t("accept")}{" "}
                  <a href="https://app.wholekicks.eu/static/media/termini.2781ba0281e4d535aa0c.pdf">
                    {t("Term_and_condition")}
                  </a>
                </span>
              </div>
              <div>
                <Field
                  name="termandcondition"
                  type="checkbox"
                  required
                  style={{ marginBottom: 4 }}
                />
                <span>
                  {t("accept")}{" "}
                  <a href="https://app.wholekicks.eu/static/media/cookie.6b7770bd1ad46b85ba46.pdf">
                    Cookie policy
                  </a>
                </span>
              </div>
              <div>
                <Field
                  name="termandcondition"
                  type="checkbox"
                  required
                  style={{ marginBottom: 4 }}
                />
                <span>
                  {t("accept")}{" "}
                  <a href="https://app.wholekicks.eu/static/media/infoprivacy.9b9daf7d1ac76c02b007.pdf">
                    Privacy policy
                  </a>
                </span>
              </div>
            </div>
            <ErrorMessage name="iban" component="span" />
            {/* <input type="file" /> */}
            <label
              for="file-certificate"
              className="lable-for-input"
              style={{ marginTop: 50 }}
            >
              {t("Upload_certificate")} <br />{" "}
              <p className="choose-file">{t("Upload_file")} </p>
            </label>
            <input
              name="visura"
              type="file"
              id="file-certificate"
              required
              className="form-no-margin input-file-visibility"
              onChange={(e) => {
                console.log(e.target.files[0].name);
                setFileName(e.target.files[0].name);
                onSubmitField(e.target.files[0]);
              }}
            />
            {fileName}

            {fileName !== "" ? (
              <button className="login-btn" type="submit">
                {t("next")}
              </button>
            ) : (
              <button
                className="login-btn"
                onClick={() => alert(t("Insert_visura"))}
              >
                {t("next")}
              </button>
            )}
          </Form>
        </Formik>
      </div>
    </>
  );
}

export default AziendaInfo;
