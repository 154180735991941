import React from "react";
import wa from "../assets/whatsappIcon.png";
import useDesktopChecker from "./utils/useDesktopChecker";

function WhatsappContact() {
  const desktop = useDesktopChecker();
  return (
    <a
      style={{ position: "fixed", bottom: 4, left: 0 }}
      href="https://wa.link/fv5ocl"
    >
      <img
        style={{ height: desktop ? 80 : 50, width: desktop ? 80 : 50 }}
        src={wa}
      />
    </a>
  );
}

export default WhatsappContact;
