import React, { Component } from "react";
import "../../styles/Form.css";
import logo from "../../assets/wk-logo.png";
import { Link } from "react-router-dom";
import axios from "axios";
import CONSTANTS from "../../CONSTANTS";
import { withTranslation } from "react-i18next";
class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      user: {
        email: props.email,
        password: props.password,
      },
      auth: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY) ? true : false,
    };
  }
  routeChange = (string) => {
    window.location.href = "/" + string;
  };
  handleEmailChanged(event) {
    var user = this.state.user;
    user.email = event.target.value;
    this.setState({ user: user, error: "" });
  }

  handlePasswrdChanged(event) {
    var user = this.state.user;
    user.password = event.target.value;
    this.setState({ user: user, error: "" });
  }
  onSubmitPost = async (data) => {
    await axios
      .post(CONSTANTS.API_URL + "/account/login", data)
      .then(async (res) => {
        if (res.data.error) {
          this.setState({ error: res.data.error });
        } else {
          localStorage.setItem(CONSTANTS.ACCESS_TOKEN_KEY, res.data);
          await axios
            .get(CONSTANTS.API_URL + "/account/" + data.email)
            .then((utente) => {
              console.log("Utente: ", utente);
              localStorage.setItem(CONSTANTS.NOME, utente.data.nome);
              localStorage.setItem(CONSTANTS.COGNOME, utente.data.cognome);
              localStorage.setItem(CONSTANTS.ID, utente.data.id);
              localStorage.setItem(CONSTANTS.P_IVA, utente.data.partitaIva);
              localStorage.setItem(CONSTANTS.NAZIONE, utente.data.paese);
              localStorage.setItem(
                CONSTANTS.R_SOCIALE,
                utente.data.ragioneSociale
              );
              localStorage.setItem(CONSTANTS.EMAIL, utente.data.email);
              this.routeChange("new-in/1");
            });
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  render() {
    const { t } = this.props;

    //auto login
    if (this.state.auth) {
      window.location.href = "/new-in/1";
    }
    return (
      <div className="container-form login-setup">
        <div className="div-logo-form">
          <Link to="/">
            <img className="logo-login" src={logo} alt="logo" />
          </Link>
        </div>
        <div className="form-div">
          <div>
            <p>{t("login")}</p>
            {this.state.error.length !== 0 && (
              <p className="error-login-form">{t(this.state.error)}</p>
            )}
            <div className="login-form-div">
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  value={this.state.user.email}
                  onChange={this.handleEmailChanged.bind(this)}
                />
              </div>
              <div>
                <input
                  type="password"
                  placeholder="Password"
                  value={this.state.user.password}
                  onChange={this.handlePasswrdChanged.bind(this)}
                />
              </div>
              <Link className="span-pD" to="/password-dimenticata">
                <span>{t("forgot_pass")}</span>
              </Link>
              <Link to="/registrazione" className="span-registratiqui">
                <span>{t("sign_up")}</span>
              </Link>
            </div>
            <button
              className="login-btn-s"
              onClick={() => this.onSubmitPost(this.state.user)}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LoginForm);
