import React, { Component, useEffect, useState, useReducer } from "react";
import logo from "../../assets/wk-logo.png";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import scarpaVuota from "../../assets/wk_products_not_found.png";
import {
  View,
  Text,
  TextInput,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { colors } from "../../pagesMobile/components/utils/constants";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";
import "../../styles/NuovoLotto.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CONSTANTS from "../../CONSTANTS";
import {
  lottoDefault,
  lottoVettore,
} from "../../components/utils/defaultObject";
import { useTranslation } from "react-i18next";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import { getUser } from "../chiamateApi/ApiModifyUser";

const LottoCustomOne = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [prodotto, setprodotto] = useState({});
  const [lotti, setlotti] = useState([...lottoVettore]);
  const [lottoAppoggio, setlottoAppoggio] = useState({ ...lottoDefault });
  const [totale, settotale] = useState(0);
  const [lottoGet, setLottoGet] = useState({});
  const [backup, setbackup] = useState({});
  const [taglie, setTaglie] = useState([]);
  const [shipfee, setshipfee] = useState(0);
  const [qty, setqty] = useState(0);
  const [iva, setIva] = useState(0);
  const nazione = localStorage.getItem(CONSTANTS.NAZIONE);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [wkfee, setwkfee] = useState(0);
  const { t } = useTranslation();
  const desktop = useDesktopChecker();
  const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);
  const [venditore, setVenditore] = useState({});

  const updateValueArray = (taglia, value, field) => {
    console.log("taglia e value", taglia + " " + value + " " + field);
    let array = [...lotti];
    let index = array.findIndex((a) => a.taglia === taglia);
    let item = { ...array[index] };
    item[field] = Number.parseFloat(value);
    array[index] = item;
    console.log(array);
    setlotti(array);
  };
  function calcoloIva(somma) {
    return (somma * 22) / 100;
  }
  function calcoloIvaNew(tot, paese) {
    return !lottoGet.ivaMargine ? (paese === "Italy" ? tot * 0.22 : 0) : 0;
  }
  const updateValueTotalArray = (obj) => {
    console.log("OGGETTO DELL'INFAMIA", obj);
    let array = [];
    for (let i = 0; i < lotti.length; i++) {
      if (obj[lotti[i].taglia]) array.push(obj[lotti[i].taglia]);
      // let index=array.findIndex((a) => a.taglia === obj[]);
      // let item={...array[index]};
      // item[field]=Number.parseFloat(value);
      // array[index]=item;
      // console.log(array)
    }
    setlotti(array);
  };

  function calcPrezSpedizione() {
    let sum = 0;
    for (let i = 0; i < lotti.length; i++) {
      sum += lotti[i].quantita;
    }
    const module = sum % 6;
    let division = sum / 6;
    division = Math.round(division - 0.5);
    console.log(division);
    if (module > 0) {
      return (division + 1) * CONSTANTS.PRICESPEDIZIONE;
    } else {
      return division * CONSTANTS.PRICESPEDIZIONE;
    }
  }

  function sumAllPricesLotto() {
    let sum = 0;
    for (let i = 0; i < lotti.length; i++) {
      sum += lotti[i].prezzo * lotti[i].quantita;
    }
    return sum;
  }

  function sumAllQtyLotto() {
    let sum = 0;
    for (let i = 0; i < lotti.length; i++) {
      sum += lotti[i].quantita;
    }
    return sum;
  }

  //   function updateAllPrices(price) {
  //     let array = lotti;
  //     let lottoApp = lottoAppoggio;
  //     for (let i = 0; i < lotti.length; i++) {
  //       array[i].prezzo = price;
  //       lottoApp[array[i].taglia].prezzo = price;
  //     }
  //     setlotti(array);
  //     setlottoAppoggio(lottoApp);
  //     forceUpdate();
  //   }

  useEffect(() => {
    console.log(lotti);

    const somma = sumAllPricesLotto();
    const estShip = calcPrezSpedizione();
    const fee = somma * 0.025;
    let iva = 0;
    //const fee = 0
    console.log("LOTTO GET: ", lottoGet);
    if (nazione === "Italy" && !lottoGet.ivaMargine) {
      iva = calcoloIva(somma + estShip + fee);
    }
    setIva(iva);
    const qty = sumAllQtyLotto();
    settotale(somma);
    setshipfee(estShip);
    setwkfee(fee);
    setqty(qty);
  }, [lottoAppoggio]);

  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
    init();
  }, []);

  const init = async () => {
    console.log("params", params);
    await axios
      .get(CONSTANTS.API_URL + "/prodotto/productById/" + params.idProd)
      .then(async (res) => {
        console.log("res", res.data);
        setprodotto(res.data);
      });
    await axios
      .get(CONSTANTS.API_URL + "/lotto/lottoId/" + params.id)
      .then(async (res) => {
        setLottoGet(res.data);
        console.log("data", res.data);
        const v = await getUser(res.data.AccountId, token);
        setVenditore(v);
      });
    const taglie = await axios.get(CONSTANTS.API_URL + "/taglia/" + params.id, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          CONSTANTS.ACCESS_TOKEN_KEY
        )}`,
      },
    });
    const lottoAppoggioValue = {};
    setbackup(taglie.data);
    console.log(taglie);
    console.log("LottoDerivato", lottoAppoggioValue);
    taglie.data.map((item) => {
      lottoAppoggioValue[item.taglia] = {
        taglia: item.taglia,
        prezzo: item.prezzo,
        quantita: item.numero,
      };
    });
    console.log("logo", lottoAppoggioValue);
    for (let i = 0; i < lottoVettore.length; i++) {
      if (
        lottoAppoggioValue[lottoVettore[i].taglia]?.prezzo == 0 &&
        lottoAppoggioValue[lottoVettore[i].taglia]?.quantita == 0
      ) {
        delete lottoAppoggioValue[lottoVettore[i].taglia];
        console.log("afterDel", lottoAppoggioValue);
      }
      updateValueTotalArray(lottoAppoggioValue);
    }

    console.log("out2", lottoAppoggioValue);
    console.log("backup", backup);
    setlottoAppoggio(lottoAppoggioValue);

    //  taglie.map(item=>lottoAppoggioValue[])
  };

  const clearForm = () => {
    setlotti(lottoVettore);
    setlottoAppoggio(lottoDefault);
    console.log("LOTTO DEFAULT VETTORE", lottoVettore);
    console.log("LOTTO DEFAULT OGGETTO", lottoDefault);
  };

  return (
    <>
      {desktop ? (
        <>
          <div className="container-form-check">
            <div className="div-logo-form">
              <Link to="/">
                <img className="logo-login" src={logo} alt="logo" />
              </Link>
            </div>
            <div className="checkout-form-div">
              {" "}
              <div className="image-prod-in-lotto">
                <img
                  src={prodotto.imageProdotto}
                  alt="immagine-prod"
                  onError={(e) => {
                    e.target.src = scarpaVuota;
                  }}
                />
                {/* <p>{this.state.prodotto.modello}</p> */}
              </div>
              <p className="checkout-title"> {t("new_stock")} </p>
              <p>{t("custom_prop")}</p>
              <div className="nuovolotto-div-tuttoforte griglia-div-taglie">
                {lotti.map((item, index) => {
                  return (
                    <div key={index} className="taglia-nuovolotto">
                      <div className="nuovo-lotto-container">
                        <span>US {item.taglia}</span>
                        <div className="nuovo-lotto-taglia-counter">
                          <input
                            type="number"
                            min={0}
                            max={backup[index]?.numero}
                            value={item.quantita}
                            onChange={(e) => {
                              if (isNaN(Number.parseInt(e.target.value))) {
                                setlottoAppoggio({
                                  ...lottoAppoggio,
                                  [item.taglia]: {
                                    ...[item.taglia],
                                    quantita: 0,
                                  },
                                });
                                updateValueArray(item.taglia, 0, "quantita");
                              } else {
                                if (
                                  Number.parseInt(e.target.value) >
                                  backup[index]?.numero
                                ) {
                                  setlottoAppoggio({
                                    ...lottoAppoggio,
                                    [item.taglia]: {
                                      ...[item.taglia],
                                      quantita: backup[index]?.numero,
                                    },
                                  });
                                  updateValueArray(
                                    item.taglia,
                                    backup[index]?.numero,
                                    "quantita"
                                  );
                                } else {
                                  setlottoAppoggio({
                                    ...lottoAppoggio,
                                    [item.taglia]: {
                                      ...[item.taglia],
                                      quantita: e.target.value,
                                    },
                                  });
                                  updateValueArray(
                                    item.taglia,
                                    e.target.value,
                                    "quantita"
                                  );
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="nuovo-lotto-container-ai">
                        <input
                          type="number"
                          min={0}
                          max={backup[index]?.prezzo}
                          value={item.prezzo}
                          placeholder="Prezzo"
                          onChange={(e) => {
                            if (isNaN(Number.parseFloat(e.target.value))) {
                              setlottoAppoggio({
                                ...lottoAppoggio,
                                [item.taglia]: {
                                  ...[item.taglia],
                                  prezzo: 1,
                                },
                              });
                              updateValueArray(item.taglia, 1, "prezzo");
                            } else {
                              if (
                                Number.parseFloat(e.target.value) >
                                backup[index]?.prezzo
                              ) {
                                setlottoAppoggio({
                                  ...lottoAppoggio,
                                  [item.taglia]: {
                                    ...[item.taglia],
                                    prezzo: backup[index]?.prezzo,
                                  },
                                });
                                updateValueArray(
                                  item.taglia,
                                  backup[index]?.prezzo,
                                  "prezzo"
                                );
                              } else {
                                setlottoAppoggio({
                                  ...lottoAppoggio,
                                  [item.taglia]: {
                                    ...[item.taglia],
                                    prezzo: e.target.value,
                                  },
                                });
                                updateValueArray(
                                  item.taglia,
                                  e.target.value,
                                  "prezzo"
                                );
                              }
                            }
                          }}
                        />
                        <p
                          style={{
                            fontSize: "0.6rem",
                            textAlign: "center",
                            verticalAlign: "center",
                            margin: 0,
                            marginLeft: 3,
                            height: "fit-content",
                          }}
                        >
                          {" "}
                          €
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="prezzi-nuovo-lotto"></div>
              <div className="prezzi-nuovo-lotto-2">
                {!(totale === 0 || totale === NaN) && (
                  <p
                    className="text-right-lotto"
                    style={{ width: "100%", marginRight: "6%" }}
                  >
                    {t("total")}
                    <b>
                      {" "}
                      {parseFloat(totale)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",") + " €"}
                    </b>
                    <br />
                    {t("shipping_fee")} ({t("pair_of_shoes")}):{" "}
                    <b>
                      {" "}
                      {"+" +
                        parseFloat(shipfee)
                          .toFixed(2)
                          .toString()
                          .replace(".", ",") +
                        " €"}
                    </b>
                    <br />
                    {t("transaction_fees")} (2,5%):
                    <b>
                      {" "}
                      {"+" +
                        parseFloat(wkfee)
                          .toFixed(2)
                          .toString()
                          .replace(".", ",") +
                        " €"}
                    </b>
                    <br />
                    {!lottoGet.ivaMargine ? (
                      <div>
                        V.A.T.{nazione === "Italy" ? "22% " : "0% "}
                        <b>
                          {" "}
                          {"+" +
                            parseFloat(iva)
                              .toFixed(2)
                              .toString()
                              .replace(".", ",") +
                            " €"}
                        </b>
                      </div>
                    ) : (
                      <> {t("marginal_vat_checked")}: ✔</>
                    )}
                    <br />
                    {t("tot_pay")}:
                    <b>
                      {(
                        totale +
                        shipfee +
                        wkfee +
                        calcoloIvaNew(totale + shipfee + wkfee, nazione)
                      )
                        .toFixed(2)
                        .toString()
                        .replace(".", ",") + " €"}
                    </b>
                    <br />
                  </p>
                )}
                {(totale === 0 || totale === NaN) && (
                  <p
                    className="text-right-lotto"
                    style={{ width: "100%", marginRight: "6%" }}
                  >
                    {t("check_fields")}
                  </p>
                )}
              </div>
            </div>
            <div
              className="btn-footer-checkout"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {
                <div
                  className="invia-proposta"
                  style={{ width: "fit-content", paddingInline: "1.5rem" }}
                  onClick={() => {
                    if (qty > 3) {
                      const lv = lotti;
                      const ld = lottoAppoggio;
                      clearForm();
                      console.log("lottoState", lottoAppoggio);
                      console.log("lottoVettState", lotti);
                      console.log("lottoGet", lottoGet);
                      navigate("/lotto-custom-two/", {
                        replace: true,
                        state: {
                          lotti: lotti.filter(
                            (t) => t.prezzo > 0 && t.quantita > 0
                          ),
                          lottoAppoggio: lottoAppoggio,
                          total: totale,
                          fees: { wk: wkfee, ship: shipfee },
                          qty: qty,
                          riferimento: params.id,
                          accountId: localStorage.getItem(CONSTANTS.ID),
                          prodottoId: params.idProd,
                          payout: parseFloat(
                            totale -
                              (wkfee + shipfee) +
                              calcoloIvaNew(
                                totale - (wkfee + shipfee),
                                venditore.paese
                              )
                          )
                            .toFixed(2)
                            .toString(),
                          payin: parseFloat(
                            totale +
                              (wkfee + shipfee) +
                              calcoloIvaNew(totale + (wkfee + shipfee), nazione)
                          )
                            .toFixed(2)
                            .toString(),
                          ivaMargine: lottoGet.ivaMargine,
                        },
                      });
                    } else {
                      alert(t("order_at_least_4_pairs"));
                    }
                  }}
                >
                  {t("next")}
                </div>
              }
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <View style={style.container}>
          <HeaderMobile context="Home" />
          {/* <Menu /> */}
          <View
            style={{
              width: "90%",
              height: "75%",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 10,
            }}
          >
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <div onClick={this.onClickBack}>Indietro</div> */}
              <p style={{ fontWeight: "bold", width: "100%" }}>
                {t("add_size")}
              </p>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  marginBottom: 10,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={prodotto.imageProdotto}
                  style={{
                    width: "30%",
                    aspectRatio: 1,
                    resizeMode: "contain",
                    borderRadius: 7,
                    backgroundColor: "white",
                  }}
                  onError={(e) => {
                    e.target.src = scarpaVuota;
                  }}
                />
                <View style={{ width: "60%", marginLeft: 16 }}>
                  <Text style={{ fontWeight: "bold" }}>
                    {"stock_n"} {params.id}
                  </Text>
                  <Text style={{ fontWeight: "bold" }}>{prodotto.nome}</Text>
                  <Text>{prodotto.modello}</Text>

                  <Text>
                    {"qty"}: {qty}
                  </Text>
                </View>
              </View>

              <View style={{ height: 25 }} />
              <ScrollView
                style={{ height: "100%", paddingBottom: "60%", width: "80%" }}
              >
                {lotti.map((item, index) => (
                  <View
                    key={item.taglia}
                    style={{
                      width: "100%",
                      backgroundColor: "#f5f5f5",
                      borderRadius: 7,
                      padding: 10,
                      marginVertical: 5,
                    }}
                  >
                    <View
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: 7,
                        padding: 5,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          backgroundColor: "white",

                          borderRadius: 7,
                        }}
                      >
                        <View style={{ width: "50%", float: "left" }}>
                          <Text style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                            US {item.taglia}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "50%",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <TouchableOpacity
                            style={{ marginRight: 10, fontSize: "1.3rem" }}
                            onPress={() => {
                              if (lottoAppoggio[item.taglia].quantita === 0) {
                                setlottoAppoggio({
                                  ...lottoAppoggio,
                                  [item.taglia]: {
                                    ...[item.taglia],
                                    quantita: 0,
                                  },
                                });
                                updateValueArray(item.taglia, 0, "quantita");
                              } else {
                                if (
                                  Number.parseInt(
                                    lottoAppoggio[item.taglia].quantita
                                  ) -
                                    1 >
                                  backup[index]?.numero
                                ) {
                                  setlottoAppoggio({
                                    ...lottoAppoggio,
                                    [item.taglia]: {
                                      ...[item.taglia],
                                      quantita: backup[index]?.numero,
                                    },
                                  });
                                  updateValueArray(
                                    item.taglia,
                                    backup[index]?.numero,
                                    "quantita"
                                  );
                                } else {
                                  setlottoAppoggio({
                                    ...lottoAppoggio,
                                    [item.taglia]: {
                                      ...[item.taglia],
                                      quantita:
                                        lottoAppoggio[item.taglia].quantita - 1,
                                    },
                                  });
                                  updateValueArray(
                                    item.taglia,
                                    lottoAppoggio[item.taglia].quantita - 1,
                                    "quantita"
                                  );
                                }
                              }
                            }}
                          >
                            <Text style={{ fontSize: "1.3rem" }}>-</Text>
                          </TouchableOpacity>
                          <TextInput
                            style={{
                              width: "30%",
                              fontSize: "1.3rem",
                              borderWidth: 1,
                              borderColor: "c4c4c4",
                              textAlign: "center",
                            }}
                            value={item.quantita === "NaN" ? 0 : item.quantita}
                            onChange={(e) => {
                              if (isNaN(Number.parseInt(e.target.value))) {
                                setlottoAppoggio({
                                  ...lottoAppoggio,
                                  [item.taglia]: {
                                    ...[item.taglia],
                                    quantita: 0,
                                  },
                                });
                                updateValueArray(item.taglia, 0, "quantita");
                              } else {
                                if (
                                  Number.parseInt(e.target.value) >
                                  backup[index]?.numero
                                ) {
                                  setlottoAppoggio({
                                    ...lottoAppoggio,
                                    [item.taglia]: {
                                      ...[item.taglia],
                                      quantita: backup[index]?.numero,
                                    },
                                  });
                                  updateValueArray(
                                    item.taglia,
                                    backup[index]?.numero,
                                    "quantita"
                                  );
                                } else {
                                  setlottoAppoggio({
                                    ...lottoAppoggio,
                                    [item.taglia]: {
                                      ...[item.taglia],
                                      quantita: e.target.value,
                                    },
                                  });
                                  updateValueArray(
                                    item.taglia,
                                    e.target.value,
                                    "quantita"
                                  );
                                }
                              }
                            }}
                          />
                          <TouchableOpacity
                            style={{ marginLeft: 10, fontSize: "1.3rem" }}
                            onPress={() => {
                              if (
                                Number.parseInt(
                                  lottoAppoggio[item.taglia].quantita
                                ) +
                                  1 >
                                backup[index]?.numero
                              ) {
                                setlottoAppoggio({
                                  ...lottoAppoggio,
                                  [item.taglia]: {
                                    ...[item.taglia],
                                    quantita: backup[index]?.numero,
                                  },
                                });
                                updateValueArray(
                                  item.taglia,
                                  backup[index]?.numero,
                                  "quantita"
                                );
                              } else {
                                setlottoAppoggio({
                                  ...lottoAppoggio,
                                  [item.taglia]: {
                                    ...[item.taglia],
                                    quantita:
                                      lottoAppoggio[item.taglia].quantita + 1,
                                  },
                                });
                                updateValueArray(
                                  item.taglia,
                                  lottoAppoggio[item.taglia].quantita + 1,
                                  "quantita"
                                );
                              }
                            }}
                          >
                            <Text style={{ fontSize: "1.3rem" }}>+</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View style={{ marginTop: 10 }}>
                        <input
                          placeholder={t("price")}
                          style={{
                            width: "100%",
                            fontSize: "1.1rem",
                            paddingVertical: 5,
                            borderWidth: 1,
                            borderColor: "c4c4c4",
                            textAlign: "left",
                            paddingLeft: 10,
                          }}
                          min={"0"}
                          value={item.prezzo === "NaN" ? 0 : item.prezzo}
                          onChange={(e) => {
                            if (isNaN(Number.parseFloat(e.target.value))) {
                              setlottoAppoggio({
                                ...lottoAppoggio,
                                [item.taglia]: {
                                  ...[item.taglia],
                                  prezzo: 1,
                                },
                              });
                              updateValueArray(item.taglia, 1, "prezzo");
                            } else {
                              if (
                                Number.parseFloat(e.target.value) >
                                backup[index]?.prezzo
                              ) {
                                setlottoAppoggio({
                                  ...lottoAppoggio,
                                  [item.taglia]: {
                                    ...[item.taglia],
                                    prezzo: backup[index]?.prezzo,
                                  },
                                });
                                updateValueArray(
                                  item.taglia,
                                  backup[index]?.prezzo,
                                  "prezzo"
                                );
                              } else {
                                setlottoAppoggio({
                                  ...lottoAppoggio,
                                  [item.taglia]: {
                                    ...[item.taglia],
                                    prezzo: e.target.value,
                                  },
                                });
                                updateValueArray(
                                  item.taglia,
                                  e.target.value,
                                  "prezzo"
                                );
                              }
                            }
                          }}
                        />
                        {console.log(item.prezzo)}
                      </View>
                    </View>
                  </View>
                ))}
              </ScrollView>
            </View>
            <TouchableOpacity
              onPress={() => {
                if (qty > 3) {
                  const lv = lotti;
                  const ld = lottoAppoggio;
                  clearForm();
                  console.log("lottoState", lottoAppoggio);
                  console.log("lottoVettState", lotti);
                  navigate("/lotto-custom-two/", {
                    replace: true,
                    state: {
                      lotti: lotti.filter(
                        (t) => t.prezzo > 0 && t.quantita > 0
                      ),
                      lottoAppoggio: lottoAppoggio,
                      total: totale,
                      fees: { wk: wkfee, ship: shipfee },
                      qty: qty,
                      riferimento: params.id,
                      accountId: localStorage.getItem(CONSTANTS.ID),
                      prodottoId: params.idProd,
                      payout: parseFloat(
                        totale -
                          (wkfee + shipfee) +
                          calcoloIvaNew(
                            totale - (wkfee + shipfee),
                            venditore.paese
                          )
                      )
                        .toFixed(2)
                        .toString(),
                      payin: parseFloat(
                        totale +
                          (wkfee + shipfee) +
                          calcoloIvaNew(totale + (wkfee + shipfee), nazione)
                      )
                        .toFixed(2)
                        .toString(),
                      ivaMargine: lottoGet.ivaMargine,
                    },
                  });
                } else {
                  alert(t("order_at_least_4_pairs"));
                }
              }}
              style={{
                width: "90%",
                height: 40,
                backgroundColor: "#0a09a2",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 7,
                marginBottom: "5%",
                position: "fixed",
                bottom: "15%",
              }}
            >
              <Text style={style.titleBtn}>{t("next")}</Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
              backgroundColor: "#f5f5f5",
              position: "fixed",
              borderWidth: 0.2,
              borderColor: "#a0a0a0",
              shadowRadius: 4,
              shadowOpacity: 0.2,
              bottom: 0,
              height: "15%",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <View
              style={{
                height: "85%",
                paddingTop: "5%",
                paddingLeft: "5%",
                justifyContent: "space-between",
              }}
            >
              <View>
                <Text
                  style={{
                    color: "#313131",
                    fontWeight: "bold",
                    fontSize: "0.7rem",
                  }}
                >
                  {t("transaction_fees")} (2,5%)
                </Text>
                <Text style={{ color: "#313131" }}>+{wkfee.toFixed(2)} €</Text>
              </View>
              {!lottoGet.ivaMargine ? (
                <View>
                  <Text
                    style={{
                      color: "#313131",
                      fontWeight: "bold",
                      fontSize: "0.7rem",
                    }}
                  >
                    V.A.T.{nazione === "Italy" ? "22% " : "0% "}
                  </Text>
                  <Text style={{ color: "#313131" }}>+{iva.toFixed(2)} €</Text>
                </View>
              ) : (
                <> {t("marginal_vat_checked")}: ✔</>
              )}
              <View>
                <Text
                  style={{
                    color: "#313131",
                    fontWeight: "bold",
                    fontSize: "0.7rem",
                  }}
                >
                  {t("estimated_shipping_fee")}
                </Text>
                <Text style={{ color: "#313131", fontSize: "0.7rem" }}>
                  +{shipfee.toFixed(2)} €
                </Text>
              </View>
            </View>

            <View
              style={{
                height: "85%",
                paddingTop: "5%",
                paddingRight: "5%",
                justifyContent: "space-between",
              }}
            >
              <View>
                <Text
                  style={{
                    color: "#313131",
                    fontWeight: "bold",
                    fontSize: "0.7rem",
                  }}
                >
                  {t("total")}
                </Text>
                <Text style={{ color: "#313131", fontSize: "0.7rem" }}>
                  {totale.toFixed(2)} €
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    color: "#313131",
                    fontWeight: "bold",
                    fontSize: "0.7rem",
                  }}
                >
                  {t("tot_pay")}:
                </Text>
                <Text style={{ color: "#313131", fontSize: "0.7rem" }}>
                  {(
                    totale +
                    (shipfee + wkfee) +
                    calcoloIvaNew(totale + (shipfee + wkfee), nazione)
                  ).toFixed(2)}{" "}
                  €
                </Text>
              </View>
            </View>
          </View>
        </View>
      )}
    </>
  );
};

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  board: {
    height: 160,
    width: "100%",
    backgroundColor: colors.secondary,
    padding: 30,
  },
  containerRule: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "8%",
    width: "90%",
    marginHorizontal: "5%",
  },
  imageRule: {
    width: 60,
    height: 60,
    backgroundColor: "#e4e4e4",
    marginRight: 10,
  },
  textRule: {
    fontSize: "1.1rem",
  },
  btnContinua: {
    backgroundColor: "#0A09A2",
    marginTop: "1%",
    width: "100%",
    padding: 10,
    textAlign: "center",
    borderRadius: 4,
  },

  btnText: {
    color: "#fff",
    fontSize: "1.1rem",
    fontWeight: "bold",
  },

  inputCerca: {
    borderWidth: 1,
    borderColor: "#f5f5f5",
    width: "100%",
    height: 30,
    marginTop: 10,
    paddingHorizontal: 15,
    paddingVertical: 20,
  },

  btnCrea: {
    width: "90%",
    height: 40,
    backgroundColor: "#0a09a2",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 7,
    marginBottom: "5%",
    position: "absolute",
  },
  titleBtn: {
    color: "white",
    fontSize: 20,
  },
  btnAltroLotto: {
    backgroundColor: "transparent",
    marginTop: "1%",
    width: "100%",
    padding: 10,
    textAlign: "center",
    borderRadius: 4,
  },

  btnTextAltro: {
    color: "#0a09a2",
    fontSize: 15,
    fontWeight: 600,
  },
  containerLottoListato: {
    width: "100%",
    backgroundColor: "#f5f5f5",
    padding: 5,
    borderRadius: 7,
    marginTop: 20,
  },
});

export default LottoCustomOne;
