import React, { useEffect, useState } from "react";
import logo from "../assets/wk-logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CONSTANTS from "../CONSTANTS";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";

function PasswordDimenticata() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [stato, setStato] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPending(true);
    window.alert(t("check_your_email"));

    console.log("stato", stato);
    setIsPending(false);
    Promise.all(await sendMail(email));

    localStorage.setItem(CONSTANTS.EMAILRESETPASSWORD, email);
    navigate("/");
  };
  const sendMail = async (emailUtente) => {
    console.log(emailUtente);
    await axios
      .get(CONSTANTS.API_URL + "/account/" + emailUtente)
      .then(async (result) => {
        console.log("success");
        console.log(result);
        const body = {
          id: result.data.id,
          email: emailUtente,
          host: CONSTANTS.URL_RESET_PASSWORD + "/reimposta-password/",
        };
        await axios
          .post(CONSTANTS.API_URL + "/mail/restpassword/", body)
          .then((res) => {
            console.log("success");
            console.log(res);
          })
          .catch((e) => {
            console.log(e.message);
          });
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  return (
    <div className="container-form">
      <div className="div-logo-form">
        <Link to="/">
          <img className="logo-login" src={logo} alt="logo" />
        </Link>
      </div>
      <div className="form-div">
        <div>
          <p>{t("forgot_password")}</p>
          <p className="testo-pass-dim">{t("email_below")}</p>
          <div className="login-form-div">
            <form onSubmit={handleSubmit} className="login-form-div">
              <div>
                <input
                  type="email"
                  required
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setEmail(e.target.value);
                  }}
                />
              </div>
              {isPending === true ? (
                <button type="submit" disabled="true" className="login-btn-s">
                  <Spinner animation="border" size="sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </button>
              ) : (
                <button type="submit" className="login-btn-s">
                  {t("send")}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordDimenticata;
