import axios from "axios";
import CONSTANTS from "../../CONSTANTS";

export const prodottoOffset = async (limit, offset) => {
  return await await axios
    .get(CONSTANTS.API_URL + "/prodotto/offset/" + limit + "/" + offset)
    .then((prodottoRicevuto) => {
      return prodottoRicevuto;
    })
    .catch((e) => {
      return e.message;
    });
};

export const prodottoHomePage = async () => {
  return await await axios
    .get(CONSTANTS.API_URL + "/prodotto/prodotto-home-page")
    .then((prodottoRicevuto) => {
      return prodottoRicevuto;
    })
    .catch((e) => {
      return e.message;
    });
};

export const marcaMostClicked = async () => {
  return await await axios
    .get(CONSTANTS.API_URL + "/marca/most-clicked")
    .then((marca) => {
      return marca.data;
    })
    .catch((e) => {
      return e.message;
    });
};

export const getLottoLimit = async (id) => {
  return await await axios
    .get(CONSTANTS.API_URL + "/lotto/lotto-limit/" + id)
    .then((prodottoRicevuto) => {
      return prodottoRicevuto;
    })
    .catch((e) => {
      return e.message;
    });
};
export const getLotto = async (id) => {
  return await await axios
    .get(CONSTANTS.API_URL + "/lotto/" + id)
    .then((prodottoRicevuto) => {
      return prodottoRicevuto;
    })
    .catch((e) => {
      return e.message;
    });
};
export const getTag = async (id) => {
  return await await axios
    .get(CONSTANTS.API_URL + "/tag/" + id)
    .then((prodottoRicevuto) => {
      return prodottoRicevuto;
    })
    .catch((e) => {
      return e.message;
    });
};

export const getProductById = async (id) => {
  return await await axios
    .get(CONSTANTS.API_URL + "/prodotto/productById/" + id)
    .then((prodottoRicevuto) => {
      return prodottoRicevuto;
    })
    .catch((e) => {
      return e.message;
    });
};

export const getTaglia = async (id) => {
  return await await axios
    .get(CONSTANTS.API_URL + "/taglia/" + id)
    .then((prodottoRicevuto) => {
      return prodottoRicevuto;
    })
    .catch((e) => {
      return e.message;
    });
};

export const getProductPrezzoMigliore = async (id) => {
  return await await axios
    .get(CONSTANTS.API_URL + "/prodotto/prezzoMigliore/" + id)
    .then((prodottoRicevuto) => {
      return prodottoRicevuto;
    })
    .catch((e) => {
      return e.message;
    });
};

export const pagamentoFindLotto = async (id, token) => {
  return await axios
    .get(CONSTANTS.API_URL + "/pagamento/find-lotto/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (res) => {
      console.log("DATI EMAIL 2", res.data);
      return res.data;
    });
};
export const postOffertaProdotto = async (data, token) => {
  return await axios
    .post(CONSTANTS.API_URL + "/offertaFatta/", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log("res", res);
      return res;
    })
    .catch((e) => {
      return "Password errata";
    });
};

export const piuCercate = async (data) => {
  return await axios
    .post(CONSTANTS.API_URL + "/piucercate/click", data)
    .then((res) => {
      console.log("res", res);
      return res;
    })
    .catch((e) => {
      console.log(e.message);
    });
};
export const isBuye = async (id, token) => {
  console.log("ISBUYE");
  return await axios
    .get(CONSTANTS.API_URL + "/pagamento/isBuy/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (res) => {
      console.log("DATI EMAIL 2", res.data);
      return res.data;
    });
};

export const sendMailNotification = async (data) => {
  return await axios
    .post(CONSTANTS.API_URL + "/mail/lotto", data)
    .then((res) => {
      console.log("res", res);
      return res;
    })
    .catch((e) => {
      console.log(e.message);
    });
};
