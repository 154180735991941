import APIKit from "./patchAxios";
import CONSTANTS from "../../CONSTANTS";
import paths from "./paths";
import { sendMail } from "../../ServicesApi";

export async function login(data) {
  try {
    let res = await APIKit.post(CONSTANTS.API_URL + paths.LOGIN, data);
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function getMarca() {
  try {
    let res = await APIKit.get(CONSTANTS.API_URL + paths.GET_BRAND);
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function productFilter(body) {
  try {
    let res = await APIKit.post(CONSTANTS.API_URL + paths.FILTERED_PROD, body);
    console.log("filtered service", res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function getProfile(data) {
  try {
    let res = await APIKit.get(
      CONSTANTS.API_URL + paths.GET_ACCOUNT + data.email,
    );
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}
export async function getProfileById(data) {
  try {
    let res = await APIKit.get(
      CONSTANTS.API_URL + paths.GET_USER_BY_ID + data.id,
    );
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function registration(data) {
  try {
    let res = await APIKit.post(CONSTANTS.API_URL + paths.GET_ACCOUNT, data);
    console.log(res.data.successo);
    console.log(res.data);
    const body = {
      email: data.email,
      subject: "Registration",
      text:
        "<div></div> <br> Registration was successful. <br> We have taken over your application for a  <br>" +
        "partnership with WholeKicks and will evaluate the data you have entered <br> and you will receive a response shortly. <br>" +
        "<br>Thank you,<br> Stephan<br>, Customer Service Wholekicks LTD<br>  ",
    };
    sendMail(body);

    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function lottoByLottoId(id) {
  try {
    let res = await APIKit.get(CONSTANTS.API_URL + paths.LOTTO_LOTTOID + id);
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function searchProd(word) {
  try {
    let res = await APIKit.get(CONSTANTS.API_URL + paths.SEARCH_GET + word);
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function productByProductId(id) {
  try {
    let res = await APIKit.get(CONSTANTS.API_URL + paths.PRODUCT_BY_ID + id);
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function newIn() {
  try {
    let res = await APIKit.get(CONSTANTS.API_URL + paths.NEW_IN, {});
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function shopAll(limit, offset) {
  try {
    let res = await APIKit.get(
      CONSTANTS.API_URL + paths.SHOP_ALL_PAGINATOR + "/" + limit + "/" + offset,
      {},
    );
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}
export async function countAllshopAll() {
  try {
    let res = await APIKit.get(
      CONSTANTS.API_URL + paths.COUNT_ALL_SHOP_ALL,
      {},
    );
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function piuCercate() {
  try {
    let res = await APIKit.get(CONSTANTS.API_URL + paths.MOST_SEARCH, {});
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function popBrand() {
  try {
    let res = await APIKit.get(CONSTANTS.API_URL + paths.POPULAR_BRAND, {});
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function newInPage() {
  try {
    let res = await APIKit.get(CONSTANTS.API_URL + paths.NEW_IN_PAGE, {});
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function singleProd(id) {
  try {
    let res = await APIKit.get(
      CONSTANTS.API_URL + paths.SINGLE_PRODUCT + id,
      {},
    );
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function relatedProd(id) {
  try {
    let res = await APIKit.get(
      CONSTANTS.API_URL + paths.RELATED_PRODUCT + id,
      {},
    );
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function lottoProd(id) {
  try {
    let res = await APIKit.get(CONSTANTS.API_URL + paths.GET_LOTTO + id, {});
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function tagliaProd(id) {
  try {
    let res = await APIKit.get(CONSTANTS.API_URL + paths.TAGLIA + id, {});
    console.log("res taglia", res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export function submitPropostaModello(data) {
  console.log(data);

  return APIKit.post(CONSTANTS.API_URL + paths.PROPOSTA_MODELLO, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(
        CONSTANTS.ACCESS_TOKEN_KEY,
      )}`,
    },
  });
  // .then((res) => {
  //   console.log("brv: ", res.data);
  // })
  // .catch((e) => {
  //   console.log(e.message);
  // });
}
export function changePassword(data) {
  console.log(data);

  return APIKit.post(CONSTANTS.API_URL + paths.NEW_PASSWORD, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(
        CONSTANTS.ACCESS_TOKEN_KEY,
      )}`,
    },
  });
  // .then((res) => {
  //   console.log("brv: ", res.data);
  // })
  // .catch((e) => {
  //   console.log(e.message);
  // });
}
export function changeMail(data) {
  console.log(data);

  return APIKit.post(CONSTANTS.API_URL + paths.NEW_EMAIL, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(
        CONSTANTS.ACCESS_TOKEN_KEY,
      )}`,
    },
  });
  // .then((res) => {
  //   console.log("brv: ", res.data);
  // })
  // .catch((e) => {
  //   console.log(e.message);
  // });
}
export function changeIban(data) {
  console.log(data);

  return APIKit.post(CONSTANTS.API_URL + paths.NEW_IBAN, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(
        CONSTANTS.ACCESS_TOKEN_KEY,
      )}`,
    },
  });
  // .then((res) => {
  //   console.log("brv: ", res.data);
  // })
  // .catch((e) => {
  //   console.log(e.message);
  // });
}
export function changeIndirizzo(data) {
  console.log(data);

  return APIKit.post(CONSTANTS.API_URL + paths.NEW_INDIRIZZO, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(
        CONSTANTS.ACCESS_TOKEN_KEY,
      )}`,
    },
  });
  // .then((res) => {
  //   console.log("brv: ", res.data);
  // })
  // .catch((e) => {
  //   console.log(e.message);
  // });
}
export async function acquistiGet(limit, offset) {
  try {
    let res = await APIKit.get(
      CONSTANTS.API_URL +
        paths.ACQUISTI_GET +
        localStorage.getItem(CONSTANTS.ID) +
        "/" +
        offset +
        "/" +
        limit,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY,
          )}`,
        },
      },
    );
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}
export async function acquistiCountAllGet() {
  try {
    let res = await APIKit.get(
      CONSTANTS.API_URL +
        paths.CONTEGGIO_PAGAMENTO +
        localStorage.getItem(CONSTANTS.ID),
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY,
          )}`,
        },
      },
    );
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}
export async function offerteFatteGet(limit, offset) {
  try {
    let res = await APIKit.get(
      CONSTANTS.API_URL +
        paths.OFFERTA_FATTA +
        localStorage.getItem(CONSTANTS.ID) +
        "/" +
        offset +
        "/" +
        limit,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY,
          )}`,
        },
      },
    );
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}
export async function offerteFatteCountGet() {
  try {
    let res = await APIKit.get(
      CONSTANTS.API_URL +
        paths.OFFERTA_FATTA_ACQUISTI_COUNT +
        localStorage.getItem(CONSTANTS.ID),
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY,
          )}`,
        },
      },
    );
    console.log(res.data);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}
export function handler(err) {
  let error = err;

  console.log(err);
  if (err.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  }

  if (err.response && err.response.data.hasOwnProperty("error_description"))
    error = err.response.data.error_description;
  else if (err.response && err.response.data.hasOwnProperty("error"))
    error = err.response.data.error;
  else if (!err.hasOwnProperty("error")) error = err.data.error;

  return new Error(error);
}
