import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import "../../styles/ModificaPage.css";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import AccountRoutes from "../../components/AccountRoutes";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";
import { modificaMail, getUser } from "../chiamateApi/ApiModifyUser";

function ModificaEmail() {
  const [showToast, setShowToast] = useState(false);
  const id = localStorage.getItem(CONSTANTS.ID);
  const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);

  const { t } = useTranslation();
  const desktop = useDesktopChecker();
  const navigate = useNavigate();

  return (
    <div className="container-account-general">
      {desktop && <Header />}
      {!desktop && <HeaderMobile context="Others" title={"Settings"} />}
      <div className="container-account-route">
        {desktop && <AccountRoutes />}
        <div className="modifica-password-container">
          <p className="modifica-pass-title">{t("edit_attivita")}</p>
          <p className="modifica-pass-subtitle delete-account-text">
            {t("disclaimer_delete_account")}
          </p>
          <button className="delete-account">{t("delete_account")}</button>

          {/* <Toast show={showToast} className="toast-confirm">
            <span>{t("success_mail")}</span>
          </Toast> */}
        </div>
      </div>
    </div>
  );
}

export default ModificaEmail;
