import React, { useState } from "react";
import {
  View,
  Text,
  Modal,
  TouchableWithoutFeedback,
  TouchableOpacity,
  KeyboardAvoidingView,
  Keyboard,
  TextInput,
} from "react-native";
import { colors, dim, isLittleDev } from "../components/utils/constants";
import { useTranslation } from "react-i18next";
import { Toast } from "react-bootstrap";
import * as api from "../api/service";
import CONSTANTS from "../../CONSTANTS";

const ModalAdd = ({
  modalAdd,
  onModalDismiss,
  onModalConfirm,
  handleChange,
}) => {
  const { t } = useTranslation();
  const [toast, setToast] = useState(false);
  const [proposta, setProposta] = useState({
    nome: "",
    colorazione: "",
    style: "",
    marca: "",
  });

  const onSubmitProposta = async (e) => {
    e.preventDefault();
    console.log(proposta);
    console.log(e);

    const sendProposta = await api
      .submitPropostaModello(proposta)
      .then((res) => {
        setToast(true);
        setTimeout(() => {
          setToast(false);
          onModalDismiss();
        }, 1000);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  return (
    <Modal visible={modalAdd} transparent animationType="slide">
      <View
        style={{ height: "100%", width: "100%", justifyContent: "flex-end" }}
      >
        {!isLittleDev() && (
          <TouchableWithoutFeedback onPress={onModalDismiss}>
            <View style={{ height: "8%", width: "100%" }} />
          </TouchableWithoutFeedback>
        )}
        <View
          style={{
            height: isLittleDev() ? "100%" : "92%",
            width: "100%",
            alignItems: "center",
            backgroundColor: "#fff",
            borderTopRightRadius: isLittleDev() ? 15 : 30,
            borderTopLeftRadius: isLittleDev() ? 15 : 30,
            shadowRadius: 10,
            shadowOpacity: 0.5,
            paddingTop: 20,
          }}
        >
          <View
            style={{ width: "100%", alignItems: "flex-end", marginRight: 20 }}
          >
            <TouchableOpacity onPress={onModalDismiss}>
              <Text style={{ fontWeight: "bold", color: colors.blue }}>
                {t("close")}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Text
              style={{
                width: "70%",
                textAlign: "center",
                alignSelf: "center",
                fontWeight: "600",
                fontSize: "1rem",
              }}
            >
              {t("add_new_model")}
            </Text>
            <Text
              style={{
                width: "70%",
                textAlign: "center",
                alignSelf: "center",
                fontWeight: "400",
                fontSize: "0.8rem",
              }}
            >
              {t("add_model_sub_text")}
            </Text>
            <View style={{ height: 20 }} />
            <form
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              onSubmit={onSubmitProposta}
            >
              <input
                required
                className="request-input"
                placeholder="Name"
                placeholderTextColor={"#535353"}
                onChange={(e) => {
                  setProposta({
                    ...proposta,
                    nome: e.target.value,
                  });
                }}
              />
              <View style={{ height: 10 }} />
              <input
                required
                className="request-input"
                placeholder="Color"
                placeholderTextColor={"#535353"}
                onChange={(e) => {
                  setProposta({
                    ...proposta,
                    colorazione: e.target.value,
                  });
                }}
              />
              <View style={{ height: 10 }} />
              <input
                required
                className="request-input"
                placeholder="SKU"
                placeholderTextColor={"#535353"}
                onChange={(e) => {
                  setProposta({
                    ...proposta,
                    style: e.target.value,
                  });
                }}
              />
              <View style={{ height: 10 }} />
              <input
                required
                className="request-input"
                placeholder="Brand"
                placeholderTextColor={"#535353"}
                onChange={(e) => {
                  setProposta({
                    ...proposta,
                    marca: e.target.value,
                  });
                }}
              />
              <View style={{ height: isLittleDev() ? 20 : 30 }} />
              <button type="submit" className="btn-request">
                <Text style={{ fontSize: "1.1rem", color: "#fff" }}>
                  {t("send_prop")}
                </Text>
              </button>
            </form>
          </View>
          <View
            style={{
              width: "90%",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              bottom: "10%",
              left: "5%",
            }}
          >
            <Toast show={toast} className="toast-clipboard">
              <span>{t("req_sent")}</span>
            </Toast>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ModalAdd;
