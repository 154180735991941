import React, { useState } from "react";
import { TAGLIE } from "./utils/defaultObject";

function SizeGuide({ state }) {
  const [rightValue, setrightValue] = useState(["UK"]);
  const onChangeValue = (e) => {
    setrightValue(e.target.value);
  };
  if (!state) return <div className="body-sizeGuide"></div>;
  else
    return (
      <div className="body-sizeGuide">
        <div className="inner-sizeGuide">
          <div className="box-couple">
            <div className="box-couple-child-l">US</div>
            <div className="box-couple-child">
              <select value={rightValue} onChange={onChangeValue}>
                <option value={"UK"}>UK</option>
                <option value={"EU"}>EU</option>
                <option value={"WMNS"}>WMNS</option>
              </select>
            </div>
          </div>
          {TAGLIE.US.map((item, index) => {
            return (
              <div style={{ backgroundColor: "#fff" }} className="box-couple">
                <div className="box-couple-child-l-size">{item}</div>
                <div className="box-couple-child-size">
                  {TAGLIE[rightValue][index]}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
}

export default SizeGuide;
