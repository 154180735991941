import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { Text, View, StyleSheet, Image, Modal } from "react-native";
import HeaderMobile from "../components/HeaderMobile";
import Menu from "../home/Menu";
import "./styles.css";

function About() {
  const { t } = useTranslation();
  return (
    <div className="Container-Common">
      <View style={style.container}>
        <HeaderMobile context="Home" />
        <Menu />
        <View
          style={{ height: "100%", width: "100%", backgroundColor: "#fff" }}
        >
          <Image
            source={require("../../assets/logone-uolkicks.png")}
            style={{
              width: "50%",
              height: 200,
              resizeMode: "contain",
              marginTop: 20,
              marginLeft: 20,
            }}
          />
          <View
            style={{
              width: "100%",
              paddingRight: "5%",
              paddingLeft: "20%",
              marginBottom: "10%",
            }}
          >
            <Text
              style={{
                fontSize: "2.5rem",
                textAlign: "right",
                color: "#363636",
                fontWeight: "bold",
              }}
            >
              Wholekicks
            </Text>
            <Text
              style={{
                fontSize: "1.5rem",

                color: "#363636",
                fontWeight: "bold",
                transform: [{ rotateX: "0deg" }, { rotateZ: "270deg" }],
                position: "absolute",
                left: 0,
                top: 70,
              }}
            >
              {t("who_is")}
            </Text>
            <Text style={{ color: "#363636", textAlign: "justify" }}>
              {t("about_1")} {t("about_2")}
              {t("about_3")}
              {t("about_4")}
              {t("about_5")}
              {t("about_6")}
            </Text>
          </View>
          <View
            style={{
              backgroundColor: "#f5f5f5",
              width: "100%",
              height: "90%",
            }}
          >
            <Text
              style={{
                fontSize: "2.5rem",
                textAlign: "right",
                color: "#363636",
                fontWeight: "bold",
                paddingRight: "5%",
              }}
            >
              {t("how_to_buy")}
            </Text>
            <Image
              source={require("../../assets/scarpa-about.png")}
              style={{
                width: "70%",
                height: 200,
                resizeMode: "contain",
                marginTop: 20,
              }}
            />
            <View
              style={{
                width: "100%",
                paddingHorizontal: "5%",
                flexDirection: "row",
                marginVertical: 10,
              }}
            >
              <Image
                source={require("../../assets/numero-uno.png")}
                style={{
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                  marginRight: 10,
                }}
              />
              <Text style={{ color: "#363636" }}>{t("how_to_buy_1")}</Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingHorizontal: "5%",
                flexDirection: "row",
                marginVertical: 10,
              }}
            >
              <Image
                source={require("../../assets/numero-2.png")}
                style={{
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                  marginRight: 10,
                }}
              />
              <Text style={{ color: "#363636" }}>{t("how_to_buy_2")}</Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingHorizontal: "5%",
                flexDirection: "row",
                marginVertical: 10,
              }}
            >
              <Image
                source={require("../../assets/numero-tre.png")}
                style={{
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                  marginRight: 10,
                }}
              />
              <Text style={{ color: "#363636" }}>{t("how_to_buy_3")}</Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingHorizontal: "5%",
                flexDirection: "row",
                marginVertical: 10,
              }}
            >
              <Image
                source={require("../../assets/numero-quattro.png")}
                style={{
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                  marginRight: 10,
                }}
              />
              <Text style={{ color: "#363636" }}>{t("how_to_buy_4")}</Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingHorizontal: "5%",
                flexDirection: "row",
                marginVertical: 10,
              }}
            >
              <Image
                source={require("../../assets/numero-cinque.png")}
                style={{
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                  marginRight: 10,
                }}
              />
              <Text style={{ color: "#363636" }}>{t("how_to_buy_5")}</Text>
            </View>
          </View>
          <View
            style={{
              backgroundColor: "white",
              width: "100%",
              height: "100%",
            }}
          >
            <Text
              style={{
                fontSize: "2.5rem",
                textAlign: "right",
                color: "#363636",
                fontWeight: "bold",
                paddingRight: "5%",
              }}
            >
              {t("how_to_sell")}
            </Text>
            <Image
              source={require("../../assets/scatola-wk.png")}
              style={{
                width: "100%",
                height: 300,
                resizeMode: "contain",
                marginTop: 20,
              }}
            />
            <View
              style={{
                width: "100%",
                paddingHorizontal: "5%",
                flexDirection: "row",
                marginVertical: 10,
              }}
            >
              <Image
                source={require("../../assets/numero-uno.png")}
                style={{
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                  marginRight: 10,
                }}
              />
              <Text style={{ color: "#363636" }}>{t("how_to_sell_1")}</Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingHorizontal: "5%",
                flexDirection: "row",
                marginVertical: 10,
              }}
            >
              <Image
                source={require("../../assets/numero-2.png")}
                style={{
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                  marginRight: 10,
                }}
              />
              <Text style={{ color: "#363636" }}>{t("how_to_sell_2")}</Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingHorizontal: "5%",
                flexDirection: "row",
                marginVertical: 10,
              }}
            >
              <Image
                source={require("../../assets/numero-tre.png")}
                style={{
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                  marginRight: 10,
                }}
              />
              <Text style={{ color: "#363636" }}>{t("how_to_sell_3")}</Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingHorizontal: "5%",
                flexDirection: "row",
                marginVertical: 10,
              }}
            >
              <Image
                source={require("../../assets/numero-quattro.png")}
                style={{
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                  marginRight: 10,
                }}
              />
              <Text style={{ color: "#363636" }}>{t("how_to_sell_4")}</Text>
            </View>
          </View>
        </View>
      </View>
    </div>
  );
}
const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

export default About;
