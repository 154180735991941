import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Modal,
  TouchableWithoutFeedback,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
  Keyboard,
  TextInput,
  StyleSheet,
} from "react-native";
import { Link, useNavigate, useParams } from "react-router-dom";
import { colors, dim, isLittleDev } from "../components/utils/constants";
import * as api from "../api/service";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { useTranslation } from "react-i18next";
import logo from "../../assets/wk-logo.png";
import { Accordion } from "react-bootstrap";
import "./style.css";
import {
  isAutenticated,
  getCallerName,
} from "../../components/utils/validatorActions";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import SliderFiltroTaglie from "../../components/utils/SliderFiltroTaglie";
const ModalVisualizzaProdotto = ({
  modalAdd,
  onModalDismiss,
  onModalConfirm,
  clickMakeProposal,
  handleChange,
  token,
  setFiltro,
  filtro,
  sizeGuideFunction,
}) => {
  const { id } = useParams();
  const [lotto, setLotto] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState("");
  const [taglia, setTaglia] = useState({});
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [lottoPayment, setLottoPayment] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    const getAllProd = async () => {
      console.log("lottoProd...");
      const lottoProd = await api.lottoProd(id).catch((e) => console.log(e));
      await axios
        .get(CONSTANTS.API_URL + "/pagamento/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (res) => {
          console.log("Pagamento", res.data);
          await setLottoPayment(res.data);
        })
        .catch((e) => {
          console.error(e);
        });

      console.log("taglia...");
      const taglia = await api
        .tagliaProd(lottoProd[0].id)
        .catch((e) => console.log(e));

      setLotto(lottoProd);
      setTaglia(taglia);

      console.log("lotto lista: ", lottoProd);
      console.log("stato lotto: ", lotto);
      console.log("taglie: ", taglia);
      setLoading(false);
    };

    getAllProd();
  }, []);
  const getTaglieById = async (id) => {
    const taglia = await api.tagliaProd(id).catch((e) => console.log(e));

    setTaglia(taglia);
  };

  const onPressAccordion = (key, item) => {
    setActiveAccordion(key);
    getTaglieById(item.id);
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("already_verified")}
    </Tooltip>
  );
  const verifiyPayment = (lottoId) => {
    if (lottoPayment.find((element) => element.LottoId === lottoId)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      {!loading && (
        <>
          <Modal visible={modalAdd} transparent animationType="slide">
            <View
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              {!isLittleDev() && (
                <TouchableWithoutFeedback onPress={onModalDismiss}>
                  <View style={{ height: "6%", width: "100%" }} />
                </TouchableWithoutFeedback>
              )}
              <ScrollView
                style={{
                  height: isLittleDev() ? "100%" : "94%",
                  width: "100%",
                  backgroundColor: "#fff",
                  borderTopRightRadius: isLittleDev() ? 15 : 30,
                  borderTopLeftRadius: isLittleDev() ? 15 : 30,
                  shadowRadius: 10,
                  shadowOpacity: 0.5,
                  paddingHorizontal: 10,
                  paddingTop: 20,
                }}
                contentContainerStyle={{
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <View style={{ width: "100%", alignItems: "flex-end" }}>
                  <TouchableOpacity onPress={onModalDismiss}>
                    <Text style={{ fontWeight: "bold", color: colors.blue }}>
                      {t("close")}
                    </Text>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      width: "70%",
                      textAlign: "center",
                      alignSelf: "center",
                      fontWeight: "400",
                      fontSize: "1.2rem",
                    }}
                  >
                    {t("avb_stock")}
                  </Text>
                  {/* <TouchableOpacity
                    style={{
                      width: "90%",
                      textAlign: "left",
                      alignSelf: "center",
                      fontWeight: "600",
                      fontSize: "0.8rem",
                      color: colors.blue,
                    }}
                  >
                    <Text style={{ color: "white" }}>{t("order_by")}</Text>
                  </TouchableOpacity> */}
                  <View
                    style={{
                      height: "100%",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div className="scroll-div-mobile">
                      {lotto.map((lottoUnico, index) => (
                        <Accordion
                          flush
                          activeKey={activeAccordion}
                          onSelect={(e) => {
                            onPressAccordion(e, lottoUnico);
                          }}
                          style={{ width: "100%" }}
                        >
                          <Accordion.Item eventKey={index.toString()}>
                            <Accordion.Header>
                              <View
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderBottomColor: colors.blue,
                                  borderBottomWidth: 1.5,
                                  paddingTop: 5,
                                  paddingBottom: 10,
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <View>
                                    <Text style={style.textBold}>
                                      {t("stock")} n° {lottoUnico.id}
                                    </Text>
                                    <View style={style.containerView}>
                                      <Text
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "700",
                                          width: "50%",
                                          fontSize: "0.6rem",
                                        }}
                                      >
                                        Average Price
                                      </Text>
                                      <Text style={style.textNotBold}>
                                        {parseInt(
                                          lottoUnico.prezzo /
                                            lottoUnico.quantita,
                                        )}{" "}
                                        €
                                      </Text>
                                    </View>
                                  </View>
                                  <View>
                                    <View style={{ flexDirection: "row" }}>
                                      <Text style={style.textBold}>Qty:</Text>
                                      <Text style={style.textNotBold}>
                                        {lottoUnico.quantita}
                                      </Text>
                                    </View>
                                    <View style={style.containerView}>
                                      <Text style={style.textBold}>
                                        {t("total_price")}:
                                      </Text>
                                      <Text style={style.textNotBold}>
                                        {lottoUnico.prezzo} €
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                                <View style={{ alignItems: "flex-end" }}>
                                  {lottoUnico.AccountId === 2 ||
                                  lottoUnico.AccountId === 22 ? (
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip}
                                    >
                                      <img
                                        src={logo}
                                        alt="wholekicks-logo"
                                        style={{ height: 30, width: 30 }}
                                      />
                                    </OverlayTrigger>
                                  ) : (
                                    <></>
                                  )}
                                  {verifiyPayment(lottoUnico.id) ? (
                                    <b style={{ color: "red" }}>{t("Sold")}</b>
                                  ) : (
                                    <></>
                                  )}
                                </View>
                              </View>
                            </Accordion.Header>
                            <Accordion.Body style={{ padding: 0 }}>
                              <SliderFiltroTaglie
                                setFiltro={setFiltro}
                                filtro={filtro}
                              />
                              <View
                                style={{
                                  backgroundColor: "#f5f5f5",
                                  marginTop: 2,
                                  borderRadius: 12,
                                  width: "100%",
                                  padding: 6,
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                                {taglia?.map((t) => (
                                  <View
                                    style={{
                                      borderWidth: 0.5,
                                      borderColor: "black",
                                      width: "25%",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      paddingVertical: 2,
                                      borderRadius: 7,
                                      backgroundColor: "white",
                                      margin: 5,
                                    }}
                                  >
                                    <Text>
                                      {filtro +
                                        " " +
                                        sizeGuideFunction(filtro, t.taglia)}
                                    </Text>
                                    <Text>Qty {t.numero}</Text>
                                  </View>
                                ))}
                              </View>

                              <View
                                style={{
                                  marginVertical: 10,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Link
                                  to={
                                    "/checkout-uno/" + lottoUnico.id + "/" + id
                                  }
                                  className="link-checkout"
                                >
                                  {verifiyPayment(lottoUnico.id) == false ? (
                                    <TouchableOpacity
                                      style={{
                                        backgroundColor: colors.blue,
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "1.1rem",
                                        width: "90%",
                                        paddingVertical: 7,
                                        textAlign: "center",
                                        borderRadius: 3,
                                      }}
                                      onPress={(e) => {
                                        e.preventDefault();
                                        if (verifiyPayment(lottoUnico.id)) {
                                          alert(t("Sold"));
                                        } else {
                                          if (isAutenticated()) {
                                            if (
                                              getCallerName() ===
                                              lottoUnico.AccountId.toString()
                                            ) {
                                              console.log("gg");
                                              alert(t("your_stock"));
                                            } else {
                                              console.log("gag");
                                              navigate(
                                                "/checkout-first/" +
                                                  lottoUnico.id +
                                                  "/" +
                                                  id,
                                                {},
                                              );
                                            }
                                          } else {
                                            alert(t("login_check"));
                                          }
                                        }
                                      }}
                                    >
                                      <Text style={{ color: "white" }}>
                                        {t("buy_prod")}
                                      </Text>
                                    </TouchableOpacity>
                                  ) : (
                                    <></>
                                  )}
                                </Link>
                                {verifiyPayment(lottoUnico.id) == false ? (
                                  <TouchableOpacity
                                    style={{
                                      backgroundColor: "white",
                                      color: colors.blue,
                                      fontWeight: "bold",
                                      fontSize: "1.2rem",
                                      width: "90%",
                                      borderWidth: 1,
                                      borderColor: "#0A09A2",
                                      paddingVertical: 7,
                                      borderRadius: 3,
                                      marginTop: 4,
                                      textAlign: "center",
                                    }}
                                    onPress={(e) => {
                                      e.preventDefault();
                                      if (verifiyPayment(lottoUnico.id)) {
                                        alert(t("Sold"));
                                      } else {
                                        if (isAutenticated()) {
                                          if (
                                            getCallerName() ===
                                            lottoUnico.AccountId.toString()
                                          ) {
                                            console.log("gg");
                                            alert(t("your_stock"));
                                          } else {
                                            console.log("gag");
                                            clickMakeProposal(lottoUnico);
                                          }
                                        } else {
                                          alert(t("login_check"));
                                        }
                                      }
                                    }}
                                  >
                                    <Text style={{ color: "#0A09A2" }}>
                                      {t("make_proposal")}
                                    </Text>
                                  </TouchableOpacity>
                                ) : (
                                  <></>
                                )}
                                {/* <TouchableOpacity
                                  style={{
                                    backgroundColor: "white",
                                    color: colors.blue,
                                    fontWeight: "bold",
                                    fontSize: "1.2rem",
                                    width: "90%",
                                    borderWidth:1,
                                    borderColor:'#0A09A2',
                                    paddingVertical: 7,
                                    borderRadius:3,
                                    marginTop:4,
                                    textAlign: "center",
                                  }}
                                  onPress={(e)=>{
                                    e.preventDefault();
                                    if(isAutenticated()){
                                      if (
                                        getCallerName() ===
                                        lottoUnico.AccountId.toString()
                                      ){
                                        console.log('gg')
                                        alert(t("your_stock"));
                                      } else {
                                        console.log('gag')
                                        clickMakeProposal(lottoUnico)
                                      }
                                    } else {
                                      alert(t("login_check"));
                                    }
                                  }}
                                >
                                  <Text style={{ color: "#0A09A2" }}>
                                    {t("make_c_proposal")}
                                  </Text>
                                </TouchableOpacity> */}
                              </View>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ))}
                    </div>
                  </View>
                  <View style={{ height: isLittleDev() ? 20 : 30 }} />
                </View>
              </ScrollView>
            </View>
          </Modal>
        </>
      )}
    </>
  );
};

const style = StyleSheet.create({
  containerView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  textBold: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  textNotBold: {
    fontSize: "1rem",
    marginLeft: 5,
  },
});

export default ModalVisualizzaProdotto;
