import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import "../styles/ModificaPage.css";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import useDesktopChecker from "../components/utils/useDesktopChecker";
import HeaderMobile from "../pagesMobile/components/HeaderMobile";
import { isNotMobile } from "../pagesMobile/components/utils/constants";
import Footer from "../components/Footer";

function Cookie() {
  const { t } = useTranslation();
  const desktop = useDesktopChecker();
  const navigate = useNavigate();

  return (
    <div className="container-account-general">
      {desktop && <Header />}
      {!desktop && <HeaderMobile context="Others" title={"Cookie Policies"} />}
      <div className="container-account-route" style={{ width: "100%" }}>
        <p
          className="modifica-pass-title"
          style={{
            paddingInline: isNotMobile() ? 80 : 10,
            fontSize: isNotMobile() ? 16 : 12,
            textAlign: "justify",
            width: "100%",
          }}
        >
          <br />
          <b>Cookie Policy</b>
          <br />
          Cookies are strings of text that websites visited by users (so-called
          Publishers, or "first parties") or different sites or web servers
          (so-called "third parties") place and store within the user's own
          terminal device, so that they are then transmitted back to the same
          sites on the next visit. Cookies are used for different purposes:
          performing computer authentication, monitoring sessions, storing
          information about specific configurations regarding users accessing
          the server, storing preferences, or to facilitate the enjoyment of
          online content, such as keeping track of items in a shopping cart or
          information for filling out a computer form, etc.; but they can also
          be used to profile the user, i.e. to "observe" their behavior, for
          example in order to send targeted advertising, measure the
          effectiveness of the advertising message and adopt consequent
          commercial strategies. In this case we speak of profiling cookies. The
          same result can also be achieved by means of other tracking tools or
          techniques, including fingerprinting. Technical cookies are those
          cookies that serve to carry out navigation or provide a service
          requested by the user. They are not used for any other purpose and are
          normally installed directly by the website owner. Without the use of
          such cookies, some operations could not be carried out or would be
          more complex and/or less secure, such as home banking activities
          (viewing account statements, bank transfers, bill payments, etc.), for
          which cookies, which allow the user to make and maintain
          identification within the session, are indispensable.
          <br />
          <b>Holder of the treatment</b>
          <br />
          of the data covered by this policy is: Wholekicks srl with registered
          office in Naples, (na), Italy, via riviera di chiaia 263, 80121
          <br />
          <b>Types of cookies used on our site</b>
          <br />
          The <a href="https://app.wholekicks.eu">
            https://app.wholekicks.eu
          </a>{" "}
          website uses "technical" cookies, which are those used for the sole
          purpose of "carrying out the transmission of a communication over an
          electronic communications network, or to the extent strictly necessary
          for the provider of an information society service explicitly
          requested by the subscriber or user to provide such a service" (Art.
          122, para. 1 of the Privacy Code). The technical cookies used by the
          site are divided into: - navigation or session cookies: necessary for
          normal navigation and use of the website - analytics cookies: allow
          counting the number of visitors and tracking how visitors move around
          the site when using it. This site uses Google Analytics, a web
          analytics service provided by Google Inc. (hereinafter also just
          "Google"). The information generated by the cookie about your use of
          the site (including your IP address) will be stored on Google's
          servers in the United States. Google will use this information for the
          purpose of tracking and examining your use of the site, compiling
          reports on site activity and providing other services relating to site
          activity and internet usage. Google may also transfer this information
          to third parties where required to do so by law or where such third
          parties process the information on Google's behalf. Google will not
          associate your IP address with any other data held about you. By using
          this site, you consent to the processing of your data by Google in the
          manner and for the purposes set out above. To see Google's privacy
          policy for the Google Analytics service, please visit{" "}
          <a href="https://support.google.com/analytics/answer/6004245">
            https://support.google.com/analytics/answer/6004245
          </a>
          . You can disable Google Analytics functionality by following the
          instructions given here:{" "}
          <a href="https://support.google.com/analytics/answer/181881?hl=it">
            https://support.google.com/analytics/answer/181881?hl=it
          </a>
          - Functionality cookies: they allow the user to navigate according to
          a set of selected criteria (e.g. language, products selected for
          purchase) in order to improve the experience of browsing the site.
          <br />
          Google:{" "}
          <a href="https://www.google.it/intl/it/policies/technologies/cookies/"></a>
          https://www.google.it/intl/it/ policies / technologies / cookies /
          <br />
          Facebook :{" "}
          <a href="https://www.facebook.com/help/cookies/">
            https://www.facebook.com/help/cookies/
          </a>
          <br />
          <b>Cookie Analytics</b>
          <br />
          Analytics Cookies are not Technical Cookies. However, the Guarantor
          (see Order of May 8, 2014 and Guidelines cookies and other tracking
          tools of June 10, 2021) has specified that they can be assimilated to
          technical cookies if they are used for site optimization purposes
          directly by the owner of the site itself, who may collect statistical
          information in aggregate form on the number of users and how they
          visit the site. If, on the other hand, the processing of such
          statistical analysis is entrusted to third parties, the users' data
          must be minimized in advance and may not be combined with other
          processing or transmitted to other third parties. Under these
          conditions, the same rules, regarding information and consent, apply
          to analytics cookies as to technical cookies. By way of exception, it
          is, however, permitted both for the first party to do this on its own
          and for the third party acting on behalf of the first party, to
          produce statistics with data from multiple domains, websites or apps
          that can be traced back to the same owner or business group.
          <br />
          <b>Third part cookie</b>
          <br />
          The cookies listed below are third-party cookies generated and read by
          the relevant third party in connection with third-party options on our
          website. For more information about these cookies and how the
          information collected by the third party is used, please use the links
          below or contact the relevant third party:
          <br /> <br />
          <p
            style={{
              textAlign: "left",
              textOverflow: "ellipsis",
              fontSize: isNotMobile() ? 14 : 10,
            }}
          >
            Google:{" "}
            <a href="https://www.google.it/intl/it/policies/technologies/cookies/">
              https://www.google.it/intl/it/policies/technologies/cookies/
            </a>
          </p>
          <p
            style={{
              textAlign: "left",
              textOverflow: "ellipsis",
              fontSize: isNotMobile() ? 14 : 10,
            }}
          >
            Facebook:{" "}
            <a href="https://www.facebook.com/help/cookies/">
              https://www.facebook.com/help/cookies/
            </a>
          </p>
          <p
            style={{
              textAlign: "left",
              textOverflow: "ellipsis",
              fontSize: isNotMobile() ? 14 : 10,
            }}
          >
            Linkedin:{" "}
            <a href="https://it.linkedin.com/legal/cookie-policy">
              https://it.linkedin.com/legal/cookie-policy
            </a>
          </p>
          <br />
          <b>
            Is user consent required for the installation of cookies on the
            user's terminal?
          </b>
          <br />
          It depends on the purposes for which the cookies are used and,
          therefore, whether they are "technical" or "profiling" cookies. For
          the installation of technical and analytics cookies, users' consent is
          not required, while it is still always necessary to give information
          (Art. 13 of the EU Regulation 2016/679). Profiling cookies or other
          tracking tools, on the other hand, can only be used if the user has
          given consent after being informed in a simplified manner. As
          established by the Guarantor in the provision of May 8, 2014 and
          reiterated in the Guidelines cookies and other tracking tools of June
          10, 2021, the information should be set up on several levels and it is
          possible that it is also rendered on several channels, adopting all
          the most appropriate arrangements to make it usable without
          discrimination even to persons with disabilities. While respecting the
          accountability of the owner and therefore its freedom to choose the
          measures and solutions that best ensure compliance with legal
          obligations, the Guarantor nevertheless suggests the adoption of a
          mechanism whereby, the moment the user accesses a website (on the home
          page or any other page), a banner immediately appears containing an
          initial "brief" information, a request for consent to the use of
          cookies and a link to access a more "extended" information. On this
          page, the user can find more and more detailed information about
          cookies choose which specific cookies to allow.
          <br />
          <b>Consent</b>
          <br />
          In order to keep track of the consent acquired, the owner of the site
          can make use of a special technical cookie, a system that is not
          particularly invasive and which does not in turn require further
          consent, as well as other ways of keeping up-to-date records of the
          data subject's choices. This is without prejudice to the user's
          ability to easily change his or her options at any time. In this
          regard, it constitutes good practice to adopt a technical expedient
          (e.g., an icon or graphic sign) that indicates at all times the status
          of consents previously rendered by the user. Simply scrolling the page
          cursor is not an act in itself suitable for the manifestation of
          informed consent. Scrolling could, if anything, constitute one of the
          components of a more structured process to generate a computer event
          suitable for expressing a recordable, documentable and unequivocal
          choice. It is unlawful to deny access to the site to those who do not
          express their consent to the use of cookies and other tracking tools,
          except in the hypothesis, to be verified on a case-by-case basis, in
          which the owner of the site, acting in accordance with the principle
          of fairness, offers the interested party the possibility of accessing
          equivalent content or service without giving consent.
          <br />
          <b>How to disable cookies (opt-out mechanism)</b>
          <br />
          It is possible to deny consent to the use of cookies on your device by
          selecting the appropriate setting on your browser: browsing will still
          be available in full functionality. Links are provided below that
          inform you how to disable cookies for the most commonly used browsers:
          <p
            style={{
              textAlign: "left",
              textOverflow: "ellipsis",
              fontSize: isNotMobile() ? 14 : 10,
            }}
          >
            Internet Explorer:{" "}
            <a href="http://windows.microsoft.com/it-it/windows7/block-enable-or-allow-cookies">
              http://windows.microsoft.com/it-it/windows7/block-enable-or-allow-cookies
            </a>
          </p>
          <p
            style={{
              textAlign: "left",
              textOverflow: "ellipsis",
              fontSize: isNotMobile() ? 14 : 10,
            }}
          >
            Google Chrome:{" "}
            <a href="https://support.google.com/chrome/answer/95647?hl=it-IT&p=cpn_cookies">
              https://support.google.com/chrome/answer/95647?hl=it-IT&p=cpn_cookies
            </a>
          </p>
          <p
            style={{
              textAlign: "left",
              textOverflow: "ellipsis",
              fontSize: isNotMobile() ? 14 : 10,
            }}
          >
            Mozilla Firefox:{" "}
            <a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">
              https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie
            </a>
          </p>
          <p
            style={{
              textAlign: "left",
              textOverflow: "ellipsis",
              fontSize: isNotMobile() ? 14 : 10,
            }}
          >
            Apple Safari:{" "}
            <a href="https://www.apple.com/legal/privacy/it/cookies">
              https://www.apple.com/legal/privacy/it/cookies
            </a>
          </p>
          <br />
          <b>Method of treatment</b>
          <br />
          Personal data are processed by automated means for the time strictly
          necessary to achieve the purposes for which they were collected.
          Specific security measures are observed to prevent data loss, illicit
          or incorrect use and unauthorized access.
          <br />
          <b>Rights of data subjects</b>
          <br />
          You may exercise your rights by emailing info@wholekicks.eu or by
          registered letter You have the right to request from the Company: -
          Access to your personal data (Art. 15 EU Regulation 2016/679), -
          Rectification (art. 16 EU Regulation 2016/679), - Deletion (art. 17 EU
          Regulation 2016/679), - Limitation (Art. 18 EU Regulation 2016/679), -
          Portability, understood as the right to obtain from the data
          controller the data in a commonly used, machine-readable structured
          format for transmission to another data controller without hindrance
          (Art. 2 0 EU Regulation 2016/679), - Objection to the processing (Art.
          21 EU Regulation 2016/679).
          <br />
          <b>Right of complaint</b>
          <br />
          If the data subjects consider that the processing of their personal
          data, carried out through this site, has taken place in violation of
          the Regulations, they have the right to lodge a complaint with the
          Guarantor, as provided for in Article 77 of the Regulations, or to
          take appropriate legal action (Article 79 of the Regulations).
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Cookie;
