import React from "react";
import pnf from "../assets/page_not_found.png";
import Header from "../components/Header";
import useDesktopChecker from "../components/utils/useDesktopChecker";
import HeaderMobile from "../pagesMobile/components/HeaderMobile";

function PageNotFound() {
  const desktop = useDesktopChecker();
  return (
    <>
      {desktop && <Header />}
      {!desktop && <HeaderMobile context={"Home"} />}
      <div className="container-not-foundona">
        <img
          src={pnf}
          style={{ width: desktop ? "80%" : "100%", height: "auto" }}
        />
      </div>
    </>
  );
}

export default PageNotFound;
