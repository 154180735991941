import React, { Component } from "react";
import "../../styles/Form.css";
import logo from "../../assets/wk-logo.png";
import { Link } from "react-router-dom";
import Personalinfo from "./Personalinfo";
import AziendaInfo from "./AziendaInfo";
import SignUpInfo from "./SignUpInfo";
import { ProgressBar } from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import ConfermaRegistrazione from "./ConfermaRegistrazione";
import CONSTANTS from "../../CONSTANTS";
import { withTranslation } from "react-i18next";
import { sendMail } from "../../ServicesApi";
class RegistrazioneClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      visura: {},
      error: "",
      values: {
        nome: "",
        cognome: "",
        indirizzo: "",
        numeroTelefono: "",
        paese: "",
        cap: "",
        visura: "",
        ragioneSociale: "",
        sedeLegale: "",
        partitaIva: "",
        iban: "",
        email: "",
        password: "",
        confermaPassword: "",
        visura: {},
      },
    };
  }
  routeChange = (string) => {
    window.location.href = "/" + string;
  };

  precedentePagina = () => {
    this.setState({ page: this.state.page - 1 });
  };
  onSubmitField = (e) => {
    console.log("e.target.file[0].name", e);
    this.setState({
      visura: e,
    });
  };
  onSubmitRegistrazione = (data) => {
    console.log("data1", data);
    const {
      nome,
      cognome,
      indirizzo,
      numeroTelefono,
      paese,
      cap,
      civico,
      citta,
      ragioneSociale,
      sedeLegale,
      partitaIva,
      iban,
      email,
      password,
      confermaPassword,
    } = data;
    this.setState(
      {
        values: {
          nome: nome,
          cognome: cognome,
          indirizzo: indirizzo,
          numeroTelefono: numeroTelefono,
          paese: paese,
          cap: cap,
          civico: civico,
          citta: citta,
          ragioneSociale: ragioneSociale,
          sedeLegale: sedeLegale,
          partitaIva: partitaIva,
          iban: iban,
          email: email,
          password: password,
          confermaPassword: confermaPassword,
          ruolo: "USER",
        },
      },
      () => {
        console.log(this.state.values);
        this.setState({ page: this.state.page + 1 });
      }
    );
  };
  // checkValue(){
  //   const bool=false
  //   if(this.state.values.nome.length===0||this.state.values.cognome||this.state.values.cognome||this.state.values.indirizzo||this.state.values.numeroTelefono||)
  // }

  onSubmitPost = async (data) => {
    console.log("data2", data);
    console.log(this.state.visura.File);

    let formData: FormData = new FormData();
    formData.append("nome", data.nome);
    formData.append("cognome", data.cognome);
    formData.append("indirizzo", data.indirizzo);
    formData.append("numeroTelefono", data.numeroTelefono);
    formData.append("paese", data.paese);
    formData.append("citta", data.citta);
    formData.append("cap", data.cap);
    formData.append("civico", data.civico);
    formData.append("ragioneSociale", data.ragioneSociale);
    formData.append("sedeLegale", data.sedeLegale);
    formData.append("partitaIva", data.partitaIva);
    formData.append("iban", data.iban);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("confermaPassword", data.confermaPassword);
    formData.append("visura", this.state.visura);
    formData.append("ruolo", "USER");

    if (data.confermaPassword === data.password) {
      await axios
        .post(CONSTANTS.API_URL + "/account", formData)
        .then((res) => {
          console.log(res.data.successo);
          const body = {
            email: data.email,
            subject: "Registration",
            text:
              "<div></div> <br> Registration was successful. <br> We have taken over your application for a  <br>" +
              "partnership with WholeKicks and will evaluate the data you have entered  <br>and you will receive a response shortly. <br>" +
              "<br>Thank you,<br> Stephan,<br> Customer Service Wholekicks LTD</br>  ",
          };
          sendMail(body);
          //  this.routeChange("registrazione/conferma-registrazione");
          this.setState({ page: this.state.page + 1 });
        })
        .catch((e) => {
          console.log("erroe: ", e.message);
          this.setState({
            error: "E-mail has already been used. Please login.",
          });
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 1000);
        });
    } else {
      alert("Password and Confirm Password do not correspond");
    }
  };
  pageDisplay = () => {
    if (this.state.page === 0) {
      return (
        <Personalinfo
          onSubmitRegistrazione={this.onSubmitRegistrazione}
          initialValues={this.state.values}
          // validationSchema={validationSchemaRegistrazione}
        />
      );
    } else if (this.state.page === 1) {
      return (
        <AziendaInfo
          onSubmitRegistrazione={this.onSubmitRegistrazione}
          onSubmitField={this.onSubmitField}
          initialValues={this.state.values}
          // validationSchema={validationSchemaRegistrazione}
        />
      );
    } else if (this.state.page === 2) {
      return (
        <SignUpInfo
          onSubmitRegistrazione={this.onSubmitPost}
          initialValues={this.state.values}
          // validationSchema={validationSchemaRegistrazione}
        />
      );
    } else if (this.state.page === 3) {
      return <ConfermaRegistrazione />;
    }
  };
  render() {
    const {
      nome,
      cognome,
      indirizzo,
      numeroTelefono,
      paese,
      cap,
      civico,
      ragioneSociale,
      sedeLegale,
      partitaIva,
      iban,
      email,
      password,
      confermaPassword,
      page,
    } = this.state;

    const { t } = this.props;
    return (
      <div className="container-form-reg">
        <div className="div-logo-form">
          <Link to="/">
            <img
              className={
                page === 0
                  ? "logo-login-0"
                  : page === 1
                  ? "logo-login-1"
                  : "logo-login"
              }
              src={logo}
              alt="logo"
            />
          </Link>
        </div>
        <div className="progress-bar">
          <ProgressBar now={page === 0 ? 33 : page === 1 ? 66 : 100} />
        </div>
        <div className="form-div">
          <div>
            {page === 3 ? (
              <p className="title-conferma-reg">
                {t("confirm")} {t("registration")}
              </p>
            ) : (
              <p>{t("registration")}</p>
            )}
            {this.pageDisplay()}
            {/* {page !== 2 ? (
            <button
              disabled={page === 2}
              className="login-btn"
              type="submit"
              
            >
              Avanti
            </button>
          ) : (
            <button className="conferma-btn">Conferma</button>
          )} */}

            {page !== 0 && (
              <button
                disabled={page === 0}
                className={
                  page === 3 ? "login-btn-indietro-3" : "login-btn-indietro"
                }
                onClick={this.precedentePagina}
              >
                Indietro
              </button>
            )}
          </div>
          <div className="div-error">
            {this.state.error.length !== 0 && (
              <p className="error-reg-form">{this.state.error}</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(RegistrazioneClass);
