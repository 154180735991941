import React, { Component } from "react";
import "../../styles/CheckoutStyles.css";
import logo from "../../assets/wk-logo.png";
import { Link, useLocation, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import { Formik, Form, Field } from "formik";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import LOGOUTFUNCTION from "../../LOGOUTFUNCTION";
import { useTranslation } from "react-i18next";
import scarpaVuota from "../../assets/wk_products_not_found.png";

class CheckoutTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utente: localStorage.getItem(CONSTANTS.ID),
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
      valore: false,
      vendite: {},
      pagamento: {},
      prodotto: {},
      loading: true,
      lotto: {},
      generalita: {
        indirizzo: "",
        cap: "",
        sedeLegale: "",
        paese: "",
        numeroTelefono: "",
      },
    };
  }

  componentDidMount = async () => {
    let data = {};
    console.log("Props: ", this.props.location.state);
    const autoSped = this.props.location.state.autoSped;
    await axios
      .get(CONSTANTS.API_URL + "/account/utente/" + this.state.utente, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        console.log("success");
        console.log(res.data);
        console.log("request");
        if (res.data.error) {
          LOGOUTFUNCTION();
        }
        data = res.data;
      })
      .catch((e) => {
        console.log(e.message);
      });
    await axios
      .get(CONSTANTS.API_URL + "/lotto/lottoId/" + this.props.params.id)
      .then(async (res) => {
        this.setState({
          lotto: res.data,
        });
      });
    console.log(this.props.params);
    await axios
      .get(
        CONSTANTS.API_URL + "/prodotto/productById/" + this.props.params.idProd
      )
      .then(async (res) => {
        console.log("res", res.data);
        this.setState({
          prodotto: res.data,
        });
        console.log(this.prodotto);
      });

    const body = {
      prezzo: this.state.lotto.prezzo,
      qta: this.state.lotto.quantita,
      carta: "",
      AccountId: localStorage.getItem(CONSTANTS.ID),
      ProdottoId: this.state.lotto.ProdottoId,
      StatoPagamentoId: 1,
      LottoId: this.state.lotto.id,
      autoSped: autoSped,
    };
    const body2 = {
      offerta: this.state.lotto.prezzo,
      qta: this.state.lotto.quantita,
      carta: "",
      AccountId: this.state.lotto.AccountId,
      ProdottoId: this.state.lotto.ProdottoId,
      StatoId: 1,
      LottoId: this.state.lotto.id,
      autoSped: autoSped,
    };
    this.setState(
      {
        generalita: {
          indirizzo: data.indirizzo,
          cap: data.cap,
          sedeLegale: data.sedeLegale,
          paese: data.paese,
          numeroTelefono: data.numeroTelefono,
          civico: data.civico,
        },
        valore: true,
        vendite: body2,
        pagamento: body,
      },
      () => {
        this.setState({ loading: false });
      }
    );
  };
  updateGeneralita = async (data) => {
    const indirizzo = document.getElementById("field1").value;
    const cap = document.getElementById("field3").value;
    const sedeLegale = document.getElementById("field4").value;
    const paese = document.getElementById("field5").value;
    const numeroTelefono = document.getElementById("field6").value;
    const civico = document.getElementById("field7").value;
    const body = {
      id: this.state.utente,
      indirizzo: indirizzo,
      cap: cap,
      sedeLegale: sedeLegale,
      paese: paese,
      numeroTelefono: numeroTelefono,
      civico: civico,
    };
    console.log(body);
    await axios
      .post(CONSTANTS.API_URL + "/account/update/generalita", body, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        console.log("success");
        console.log(res.data);
        console.log("request");
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  postLotto = async () => {
    // await axios
    //   .post(CONSTANTS.API_URL + "/vendite/", this.state.vendite, {
    //     headers: {
    //       Authorization: `Bearer ${this.state.token}`,
    //     },
    //   })
    //   .then((res) => {
    //     console.log("success");
    //     console.log(res);
    //   })
    //   .catch((e) => {
    //     console.log(e.message);
    //   });
    // await axios
    //   .post(CONSTANTS.API_URL + "/pagamento/", this.state.pagamento, {
    //     headers: {
    //       Authorization: `Bearer ${this.state.token}`,
    //     },
    //   })
    //   .then((res) => {
    //     console.log("success");
    //     console.log(res);
    //   })
    //   .catch((e) => {
    //     console.log(e.message);
    //   });
  };
  render() {
    const { t } = this.props.translation;
    return (
      <>
        <div className="container-form-check">
          <div className="div-logo-form">
            <Link to="/">
              <img className="logo-login" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="checkout-form-div checkout-two">
            <div className="image-prod-in-lotto">
              <img
                src={this.state.prodotto.imageProdotto}
                alt="immagine-prod"
                onError={(e) => {
                  e.target.src = scarpaVuota;
                }}
              />
              <p>
                {this.state.prodotto.nome + " " + this.state.prodotto.modello}
              </p>
            </div>{" "}
            <p className="checkout-title">Checkout</p>
            <span className="checkout-sub-title">{t("enter_ship")}</span>
            <div className="checkout-taglie-div-custom">
              {this.state.valore === true ? (
                <Formik
                  initialValues={this.state.generalita}
                  onSubmit={this.updateGeneralita}
                >
                  <Form>
                    <div className="form-checkout-custom">
                      <div className="form-field-checkout-custom">
                        <Field
                          id="field1"
                          type="text"
                          placeholder="Address"
                          name="indirizzo"
                        />
                        <Field
                          id="field3"
                          type="text"
                          placeholder="Postal Code"
                          name="cap"
                        />
                        <Field
                          id="field4"
                          type="text"
                          placeholder="Legal Address"
                          name="sedeLegale"
                        />
                        <Field
                          id="field5"
                          type="text"
                          placeholder="Country"
                          name="paese"
                        />
                        <Field
                          id="field7"
                          type="text"
                          placeholder="civic"
                          name="civico"
                        />
                      </div>
                      <div className="form-field-checkout-custom left-side">
                        <Field
                          id="field6"
                          type="text"
                          placeholder="Telephon Number"
                          name="numeroTelefono"
                        />
                        <div className="btn-modifica-div">
                          <button
                            className="btn-modifica-spedizione"
                            type="submit"
                          >
                            {t("modify")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Formik>
              ) : (
                <p>Nulla</p>
              )}
            </div>
          </div>
          <div className="btn-footer-checkout">
            <Link
              replace
              to={"/checkout-third/" + this.props.params.id}
              state={{
                v: this.state.vendite,
                p: this.state.pagamento,
                prodotto: this.state.prodotto,
                calcoloIva: this.props.location.state.calcoloIva,
                lotto: this.state.lotto,
              }}
            >
              {t("next")}
            </Link>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default function (props) {
  const params = useParams();
  const location = useLocation();
  const t = useTranslation();
  return (
    <CheckoutTwo
      translation={t}
      props={props}
      params={params}
      location={location}
    />
  );
}
