import React, { Component } from "react";
import wklogo from "../assets/logone-uolkicks.png";
import { isAutenticated } from "./utils/validatorActions";
import cookie from "../assets/file/cookie.pdf";
import termini from "../assets/file/termini.pdf";
import infoprivacy from "../assets/file/infoprivacy.pdf";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useParams } from "react-router-dom";
import useDesktopChecker from "../components/utils/useDesktopChecker";
import FooterMobile from "./FooterMobile";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCont: false,
    };
  }

  onClickLink = (e) => {
    if (!isAutenticated()) {
      this.onClickLink = (e, isProps) => {
        if (!isAutenticated()) {
          const { t } = this.props.translation;
          const mustBe = t("must_be_logged_to_do_this");
          alert(mustBe);
        }
      };
    }
  };
  render() {
    const { t } = this.props.translation;
    return (
      <>
        {this.props.desktop ? (
          <>
            {" "}
            <div className="footer">
              <div className="wk-logo-white-div">
                <img
                  src={wklogo}
                  alt="wk white"
                  className="logo-fuutter"
                  style={{ float: "left" }}
                />
              </div>
              <div className="innerDiv">
                <p className="title-footer-bello">{t("company")}</p>
                <a
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  href="https://app.wholekicks.eu/about"
                >
                  {t("about")}
                </a>
                <a
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  href={"https://app.wholekicks.eu/termini-condizioni"}
                >
                  {t("terms_and_conditions")}
                </a>
                <a
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  href={"http://app.wholekicks.eu/termini-pausepay"}
                >
                  {t("terms_pp")}
                </a>
                <a
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  href={"https://app.wholekicks.eu/cookie"}
                >
                  {t("cookies_policy")}
                </a>
                <a
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  href={"https://app.wholekicks.eu/privacy"}
                >
                  {t("privacy_policy")}
                </a>
              </div>
              <div className="innerDiv">
                <p className="title-footer-bello">{t("social")}</p>
                <a
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  href="https://www.linkedin.com/company/wholekicks-ltd/about/?viewAsMember=true"
                >
                  Linkedin
                </a>
                <a
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  href="https://www.facebook.com/Wholekicks-112069821108562"
                >
                  Facebook
                </a>
                <a
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  href="https://twitter.com/WholeKicks"
                >
                  Twitter
                </a>
                <a
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  href="https://www.instagram.com/whole.kicks/"
                >
                  Instagram
                </a>
              </div>
              {/*<div className="innerDiv">
                <p className="title-footer-bello">{t("products")}</p>
                <a
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  onClick={this.onClickLink}
                  href={isAutenticated() ? "/new-in/1" : "#"}
                >
                  New In
                </a>
                <Link
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  to={isAutenticated() ? "/new-in/1" : "#"}
                  state={{ marca: "Nike" }}
                  onClick={this.onClickLink}
                >
                  Nike
                </Link>
                <Link
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  to={isAutenticated() ? "/new-in/1" : "#"}
                  state={{ marca: "adidas" }}
                  onClick={this.onClickLink}
                >
                  Adidas
                </Link>
                <Link
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  to={isAutenticated() ? "/new-in/1" : "#"}
                  state={{ marca: "New Balance" }}
                  onClick={this.onClickLink}
                >
                  New Balance
                </Link>
                <Link
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                  to={isAutenticated() ? "/new-in/1" : "#"}
                  state={{ marca: "Jordan" }}
                  onClick={this.onClickLink}
                >
                  Jordan
                </Link>
                </div>*/}
              <div className="innerDiv">
                <p className="title-footer-bello">Info</p>
                <p
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                >
                  info@wholekicks.eu
                </p>
                <p
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                >
                  customerservice@wholekicks.eu
                </p>
                <p
                  style={{
                    textDecorationLine: "none",
                    color: "#fff",
                    marginBottom: "0.7rem",
                  }}
                >
                  Napoli, Italia <br />
                  Corso Novara 36, CAP 80143 <br />
                  {t("Iva")} 09816981212
                </p>
              </div>
              <div className="innerDiv">
                <Dropdown style={{ marginLeft: 20 }}>
                  <Dropdown.Toggle
                    className="dropdown-item-custom"
                    variant="success"
                    id="dropdown-basic"
                  >
                    {t("lang")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        window.location.reload();
                        i18n.changeLanguage("en");
                      }}
                    >
                      ENG
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        window.location.reload();
                        i18n.changeLanguage("it");
                      }}
                    >
                      IT
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div class="pausepay-website_enabled" />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#333333",
                display: "flex",
                fontSize: "0.8rem",
                color: "#f5f5f5",
                justifyContent: "center",
                paddingTop: 2,
                paddingBottom: 5,
                borderTopWidth: 2,
                borderColor: "#111",
                height: 40,
              }}
            >
              2022 by Wholekicks srl ©
            </div>{" "}
          </>
        ) : (
          <FooterMobile />
        )}
      </>
    );
  }
}
export default function (props) {
  const params = useParams();
  const location = useLocation();
  const t = useTranslation();
  const desktop = useDesktopChecker();
  return (
    <Footer
      translation={t}
      props={props}
      params={params}
      desktop={desktop}
      location={location}
    />
  );
}
