import React from "react";
import loadingLogo from "../assets/loading-wk.gif";
import "./style.css";

export default function LoadingAnimation() {
  return (
    <div className="container-loading">
      <img src={loadingLogo} className="logo-loading" alt="Logo wk" />
    </div>
  );
}
