import React, { useState, useEffect } from "react";
import "./Login.css";
import {
  Text,
  View,
  StyleSheet,
  Image,
  Modal,
  TextInput,
  TouchableOpacity,
} from "react-native";
import HeaderMobile from "../components/HeaderMobile";
import * as api from "../api/service";
import PasswordInput from "./PasswordInput";
import { Link, useNavigate } from "react-router-dom";
import { ActivityIndicator } from "react-native";
import CONSTANTS from "../../CONSTANTS";
import { useTranslation } from "react-i18next";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setloading] = useState(false);

  const { t } = useTranslation();

  const changePass = (e) => {
    setPassword(e.target.value);
    console.log(password);
  };

  const onSubmitPost = async (email, password) => {
    let data = {
      email,
      password,
    };
    console.log("EMAIL", email);
    console.log("PASSWORD", password);
    setloading(true);
    try {
      const response = await api.login(data);
      console.log(response);

      if (!response.error) {
        setloading(false);
        localStorage.setItem(CONSTANTS.ACCESS_TOKEN_KEY, response);
        const utente = await api.getProfile(data);
        if (utente) {
          console.log(utente);
          localStorage.setItem(CONSTANTS.NOME, utente.nome);
          localStorage.setItem(CONSTANTS.COGNOME, utente.cognome);
          localStorage.setItem(CONSTANTS.EMAIL, utente.email);
          localStorage.setItem(CONSTANTS.ID, utente.id);
        }

        window.location.href = "/";
      } else {
        alert(response.error);
        setloading(false);
      }
    } catch (e) {
      setloading(false);
      console.log(e.message);
    }
  };

  return (
    <div className="Container-Common">
      <View style={style.container}>
        <HeaderMobile context="Home" />
        <View style={style.containerInput}>
          <Text style={style.title}>{t("login")}</Text>

          <TextInput
            value={email}
            style={style.inputText}
            onChange={(e) => {
              setEmail(e.target.value);
              console.log(email);
            }}
            placeholder="Email"
          />
          <PasswordInput value={password} handleChange={changePass} />
          <TouchableOpacity
            onPress={() => onSubmitPost(email, password)}
            disabled={loading}
            style={style.btnLogin}
          >
            {loading ? (
              <ActivityIndicator />
            ) : (
              <Text style={style.titleBtn}>Login</Text>
            )}
          </TouchableOpacity>
        </View>
        <View style={style.containerInputDue}>
          <Text style={style.title}>{t("no_acc")}</Text>
          <Link
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
            }}
            to="/registrazione"
          >
            <TouchableOpacity style={style.btnLogin}>
              <Text style={style.titleBtn}>{t("sign_up")}</Text>
            </TouchableOpacity>
          </Link>
        </View>
      </View>
    </div>
  );
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  containerInput: {
    paddingTop: "5%",

    alignItems: "center",
    width: "100%",
  },

  containerInputDue: {
    paddingTop: "10%",
    marginTop: "2%",
    alignItems: "center",
    width: "100%",
    borderTopColor: "#7A79CD",
    borderTopWidth: 2,
  },

  inputText: {
    height: 50,
    width: "90%",
    borderWidth: 1,
    borderColor: "#7A79CD",
    padding: 10,
    marginVertical: 20,
    borderRadius: 7,
  },
  title: {
    fontSize: "1.3rem",
    width: "90%",
    marginBottom: "5%",
    fontWeight: "bold",
  },

  btnLogin: {
    width: "90%",
    marginTop: "15%",
    height: 40,
    backgroundColor: "#0a09a2",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 7,
    marginBottom: "5%",
  },
  titleBtn: {
    color: "white",
    fontSize: 20,
  },

  btnTouch: {
    width: "90%",
  },
});

export default Login;
