import React, { useEffect, useState, useReducer, useCallback } from "react";
import {
  View,
  Text,
  Modal,
  TouchableWithoutFeedback,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
} from "react-native";
import { colors, isLittleDev } from "../components/utils/constants";

import { useTranslation } from "react-i18next";

const ModalFilters = ({
  modalAdd,
  onModalDismiss,
  update,
  filterPrezzo,
  marca,
  filtriAttuali,
}) => {
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [value, setValue] = useState([]);
  const [type, setType] = useState("");
  const [user, setUser] = useState(0);
  const [statoProd, setStatoProd] = useState(1);
  const { t } = useTranslation();

  const valueSetter = async (valore, tipo) => {
    console.log("MOBILE");
    let filtriAtt = { ...filtriAttuali };
    if (tipo === "marca") {
      if (filtriAtt.marca.find((element) => element === valore)) {
        const V = filtriAtt.marca.filter((v) => v !== valore);
        filtriAtt.marca = V.sort().reverse();
      } else {
        if (filtriAtt.marca.length > 0) {
          const array = [...filtriAtt.marca.sort().reverse(), valore];
          filtriAtt.marca = array;
        } else {
          const array = [...filtriAtt.marca.sort().reverse(), valore];
          filtriAtt.marca = array;
        }
      }
    } else if (tipo === "prezzo") {
      disabledCheckedFunction(valore);

      if (valore !== filtriAtt.prezzo) {
        filtriAtt.prezzo = valore;
      } else {
        filtriAtt.prezzo = "";
      }
    } else if (tipo === "utente") {
      if (document.getElementById("user").checked !== true) {
        filtriAtt.utente = false;
      } else {
        filtriAtt.utente = valore;
      }
    }
    update(filtriAtt);
    setStatoProd(2);
  };

  const disabledCheckedFunction = (valore) => {
    console.log("valore", valore);
    if (document.getElementById(valore).checked) {
      for (let i = 1; i <= 6; i++) {
        if (valore != i) {
          console.log("CI SONO", i);
          document.getElementById(i.toString()).checked = false;
        }
      }
    }
  };
  useEffect(() => {
    filtriFunzione();
  }, [filtriAttuali]);

  const filtriFunzione = async () => {
    if (statoProd == 2) {
      await filterPrezzo(
        filtriAttuali.prezzo,
        filtriAttuali.marca,
        filtriAttuali.utente,
        statoProd,
      );
    }
  };

  return (
    <>
      <Modal visible={modalAdd} transparent animationType="slide">
        <View
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          {!isLittleDev() && (
            <TouchableWithoutFeedback onPress={onModalDismiss}>
              <View style={{ height: "8%", width: "100%" }} />
            </TouchableWithoutFeedback>
          )}
          <View
            style={{
              height: isLittleDev() ? "100%" : "92%",
              width: "100%",
              alignItems: "center",
              backgroundColor: "#fff",
              borderTopRightRadius: isLittleDev() ? 15 : 30,
              borderTopLeftRadius: isLittleDev() ? 15 : 30,
              shadowRadius: 10,
              shadowOpacity: 0.5,
              paddingHorizontal: 10,
              paddingTop: 20,
              justifyContent: "space-between",
              paddingBottom: "5%",
            }}
          >
            <View
              style={{
                width: "100%",
                alignItems: "flex-end",
                marginRight: "5%",
              }}
            >
              <TouchableOpacity onPress={onModalDismiss}>
                <Text style={{ fontWeight: "bold", color: colors.blue }}>
                  {t("close")}
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                height: "85%",
              }}
            >
              <Text
                style={{
                  width: "70%",
                  textAlign: "center",
                  alignSelf: "center",
                  fontWeight: "400",
                  fontSize: "1.7rem",
                }}
              >
                Filters
              </Text>

              <View style={{ width: "90%" }}>
                {/* <Text
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    marginVertical: 10,
                  }}
                >
                  Brand
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  {marca.map((m) => (
                    <View
                    key={m.nomeMarca}
                      style={{ flexDirection: "row", alignItems: "baseline" }}
                    >
                      <label>
                        <input
                          type="checkbox"
                          style={{ marginRight: 10 }}
                          defaultChecked={filtriAttuali.marca.includes(m.nomeMarca)}
                          onChange={() => {
                            valueSetter(m.nomeMarca, "marca");
                          }}
                        />
                        <Text style={{ fontSize: "0.9rem" }}>
                          {m.nomeMarca}
                        </Text>
                      </label>
                    </View>
                  ))}
                </View> */}
                <Text
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    marginVertical: 10,
                  }}
                >
                  {t("average_price")}
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <View style={style.checkbox}>
                    <label>
                      <input
                        id={"1"}
                        type="checkbox"
                        defaultChecked={filtriAttuali.prezzo === "1"}
                        onChange={() => valueSetter("1", "prezzo")}
                        style={{ marginRight: 10 }}
                      />
                      <Text style={{ fontSize: "0.9rem" }}>
                        {t("under")} 100 €
                      </Text>
                    </label>
                  </View>
                  <View style={style.checkbox}>
                    <label>
                      <input
                        id={"2"}
                        type="checkbox"
                        defaultChecked={filtriAttuali.prezzo === "2"}
                        onChange={() => valueSetter("2", "prezzo")}
                        style={{ marginRight: 10 }}
                      />
                      <Text style={{ fontSize: "0.9rem" }}>100 - 200 €</Text>
                    </label>
                  </View>
                  <View style={style.checkbox}>
                    <label>
                      <input
                        id={"3"}
                        type="checkbox"
                        defaultChecked={filtriAttuali.prezzo === "3"}
                        onChange={() => valueSetter("3", "prezzo")}
                        style={{ marginRight: 10 }}
                      />
                      <Text style={{ fontSize: "0.9rem" }}>200 - 300 €</Text>
                    </label>
                  </View>
                  <View style={style.checkbox}>
                    <label>
                      <input
                        id={"4"}
                        type="checkbox"
                        defaultChecked={filtriAttuali.prezzo === "4"}
                        onChange={() => valueSetter("4", "prezzo")}
                        style={{ marginRight: 10 }}
                      />
                      <Text style={{ fontSize: "0.9rem" }}>300 - 400 €</Text>
                    </label>
                  </View>
                  <View style={style.checkbox}>
                    <label>
                      <input
                        id={"5"}
                        type="checkbox"
                        defaultChecked={filtriAttuali.prezzo === "5"}
                        onChange={() => valueSetter("5", "prezzo")}
                        style={{ marginRight: 10 }}
                      />
                      <Text style={{ fontSize: "0.9rem" }}>400 - 500 €</Text>
                    </label>
                  </View>
                  <View style={style.checkbox}>
                    <label>
                      <input
                        id={"6"}
                        type="checkbox"
                        defaultChecked={filtriAttuali.prezzo === "6"}
                        onChange={() => valueSetter("6", "prezzo")}
                        style={{ marginRight: 10 }}
                      />
                      <Text style={{ fontSize: "0.9rem" }}>
                        {t("over")} 500 €
                      </Text>
                    </label>
                  </View>
                </View>
                <Text
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    marginVertical: 10,
                  }}
                >
                  {t("fast_ship")}
                </Text>
                <View style={style.checkbox}>
                  <label
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <input
                      id={"user"}
                      onChange={() => valueSetter(true, "utente")}
                      defaultChecked={filtriAttuali.utente > 0}
                      type="checkbox"
                      style={{ marginRight: 10 }}
                    />
                    <Image
                      source={require("../../assets/wk-logo.png")}
                      style={{
                        height: 50,
                        width: 50,
                        resizeMode: "contain",
                      }}
                    />
                  </label>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <TouchableOpacity
                style={{
                  width: "80%",
                  color: "white",
                  backgroundColor: colors.blue,
                  textAlign: "center",
                  paddingVertical: 5,
                  borderRadius: 4,
                }}
                onPress={() => filtriFunzione(type, value, user, 2)}
              >
                <Text style={{ color: "white" }}>Apply</Text>
              </TouchableOpacity> */}
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
};

const style = StyleSheet.create({
  containerView: {
    flexDirection: "row",
    alignItems: "baceline",
    marginTop: 10,
    width: "90%",
    justifyContent: "space-between",
  },
  textBold: {
    fontSize: "1.1rem",
    fontWeight: 600,
  },
  textNotBold: {
    fontSize: "1.1rem",
    marginLeft: 5,
  },
  containerLotto: {
    backgroundColor: "#DEE4FD",
    width: "90%",
    padding: 10,
    marginTop: 10,
    borderRadius: 12,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    paddingLeft: "7%",
  },
  containerL: {
    backgroundColor: "white",

    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    margin: 5,
    width: "25%",
    borderWidth: 1.5,
    borderColor: "black",
    borderRadius: 7,
    textAlign: "center",
  },
  checkbox: {
    marginVertical: 5,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default ModalFilters;
