import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Text,
  View,
  StyleSheet,
  TextInput,
  Image,
  TouchableOpacity,
} from "react-native";
import HeaderMobile from "../components/HeaderMobile";
import * as api from "../api/service";
import CONSTANTS from "../../CONSTANTS";
import { Link } from "react-router-dom";
import "./style.css";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prodotti: [],
      prodottiInit: [],
      prodToList: {},
      singleProd: {},
    };
  }

  async componentDidMount() {
    console.log("lotto appoggio: ", this.state.lottoAppoggio);
    const piuCercateCall = await api.piuCercate();

    this.setState({ prodottiInit: piuCercateCall });
    console.log("prodotti iniziali: ", this.state.prodottiInit);
  }

  searchFunctionKeyUp = async (word) => {
    const searchProd = await api.searchProd(word);

    console.log(searchProd);

    this.setState({ prodottiInit: searchProd });
  };
  render() {
    const { t } = this.props;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Others" title={t("search")} />
          <TextInput
            style={style.searchInput}
            placeholder={t("search_prod_t")}
            onKeyUp={(e) => this.searchFunctionKeyUp(e.target.value)}
          />
          <View
            style={{ width: "100%", paddingHorizontal: "5%", height: "70%" }}
          >
            <div
              style={{
                overflowY: "scroll",
                height: "100%",
                overflowX: "hidden",
                paddingRight: "2%",
                paddingLeft: "2%",
              }}
            >
              {this.state.prodottiInit.map((p) => (
                <Link
                  to={"/product/" + p.id}
                  className="link-prod-search"
                  style={{
                    display: "flex",
                    flexDirection: "row",

                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <View>
                    <Image
                      source={{
                        uri: p.imageProdotto,
                      }}
                      style={{
                        width: 120,
                        height: 70,
                        resizeMode: "contain",
                        borderRadius: 7,
                        backgroundColor: "white",
                      }}
                    />
                  </View>
                  <View style={{ paddingHorizontal: 10, width: "60%" }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                        width: "95%",
                      }}
                    >
                      {p.nome}
                    </Text>
                    <Text>{p.colore}</Text>
                  </View>
                </Link>
              ))}
            </div>
          </View>
        </View>
      </div>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  searchInput: {
    width: "80%",
    padding: 10,
    borderWidth: 1.5,
    borderColor: "#7A79CD",
    borderRadius: 4,
  },
});

export default withTranslation()(Search);
