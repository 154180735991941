import React, { Component, useEffect, useState } from "react";
import logo from "../../assets/wk-logo.png";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import "../../styles/NuovoLotto.css";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { sendMailNotification } from "../chiamateApi/ApiProdotto";

function LottoCustomTwo() {
  const params = useParams();
  const location = useLocation();
  const navigation = useNavigate();
  const [prodotto, setprodotto] = useState({});
  let IdUtente = localStorage.getItem(CONSTANTS.ID);
  let email = localStorage.getItem(CONSTANTS.EMAIL);
  console.log("five", location.state);
  const { t } = useTranslation();
  console.log("locationSTATE****************", location.state);

  useEffect(() => {
    axios
      .get(CONSTANTS.API_URL + "/prodotto/lotto/" + location.state.riferimento)
      .then(async (res) => {
        console.log("res", res.data);
        setprodotto(res.data);
      });
  }, []);
  const addLotto = async (body) => {
    console.log("body", body);
    let token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);

    await axios
      .post(CONSTANTS.API_URL + "/taglia/custom/", body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        await axios
          .get(
            CONSTANTS.API_URL +
              "/account/utente-by-lottoId/" +
              location.state.riferimento,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (resAccount) => {
            console.log("prodotto", prodotto);
            const objMail = {
              prodotto: prodotto,
              email: email,
              lotto: location.state.riferimento,
              idMailVendita: 9,
              idMailAcquisto: 10,
            };
            await sendMailNotification(objMail);
          })
          .catch((e) => alert(e.message));
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  return (
    <>
      <div className="container-form-check">
        <div className="div-logo-form">
          <Link to="/">
            <img className="logo-login" src={logo} alt="logo" />
          </Link>
        </div>
        <div className="checkout-final-div" style={{ width: "70%" }}>
          <p className="conferma-acquisto-lotto-p"> {t("confirm_stock")} </p>
          <span>{t("prop_to_seller")}</span>
        </div>
        <div
          className="btn-footer-checkout-lista-lotto"
          style={{ width: "68%" }}
        >
          <Link replace={true} to={"#"} onClick={() => navigation(-1)}>
            {t("back")}
          </Link>
          <Link
            replace={true}
            to={"/"}
            onClick={() => {
              addLotto(location.state);
            }}
          >
            {t("confirm")}
            {/* {t("next")} */}
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default LottoCustomTwo;
