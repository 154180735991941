import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SignUpInfo({
  initialValues,
  onSubmitRegistrazione,
  validationSchema,
}) {
  // console.log(values);
  // console.log(handleChange);

  const { t } = useTranslation();
  return (
    <>
      <div className="login-form-div">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitRegistrazione}
          validationSchema={validationSchema}
        >
          <Form>
            <div>
              <Field
                name="email"
                type="email"
                placeholder="Email"
                required
                className={"form-no-margin"}
              />
            </div>
            <ErrorMessage name="email" component="span" />
            <div>
              <Field
                name="password"
                type="password"
                placeholder="Password"
                required
                className={"form-no-margin"}
              />
            </div>
            <ErrorMessage name="password" component="span" />
            <div>
              <Field
                name="confermaPassword"
                type="password"
                placeholder="Conferma Password"
                required
                className={"form-no-margin"}
              />
            </div>
            <ErrorMessage name="confermaPassword" component="span" />

            <ErrorMessage name="confermaPassword" component="span" />
            <button className="login-btn" type="submit">
              {t("confirm")}
            </button>
          </Form>
        </Formik>
      </div>
    </>
  );
}

export default SignUpInfo;
