import React, { Component } from "react";
import HeaderMobile from "../../components/HeaderMobile";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
} from "react-native";
import { withTranslation } from "react-i18next";
import { changeSpedizione } from "./FunctionProfile";

import { getProfileById } from "../../api/service";
import CONSTANTS from "../../../CONSTANTS";
class ModificaSpedizione extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldIndirizzo: "",
      newIndirizzo: "",
      confIndirizzo: "",
      oldCivico: "",
      newCivico: "",
      confCivico: "",
      oldCap: "",
      newCap: "",
      confCap: "",
      utente: {},
    };
  }
  async componentDidMount() {
    const data = {
      id: localStorage.getItem(CONSTANTS.ID),
    };
    const utente = await getProfileById(data);
    this.setState({
      utente: utente,
    });
  }
  render() {
    const { t } = this.props;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Others" title="Modifica Indirizzo" />
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
            }}
          >
            <Text
              style={{
                textAlign: "center",
                fontSize: "1.2rem",
                marginTop: 10,
                marginBottom: "20%",
              }}
            >
              {t("fill_fields")}
            </Text>
            <View
              style={{
                width: "90%",
                borderWidth: 1,
                borderColor: "#7A79CD",
                borderRadius: 7,
                marginVertical: 10,
                padding: 10,
              }}
            >
              <TextInput
                placeholder="Vecchio Indirizzo"
                style={{
                  width: "100%",
                  fontSize: "1.2rem",
                }}
                value={this.state.utente.indirizzo}
              />
            </View>
            <View
              style={{
                width: "90%",
                borderWidth: 1,
                borderColor: "#7A79CD",
                borderRadius: 7,
                marginVertical: 10,
                padding: 10,
              }}
            >
              <TextInput
                style={{
                  width: "100%",
                  fontSize: "1.2rem",
                }}
                placeholder="Nuovo Indirizzo"
                onChange={(e) => {
                  this.setState({ newIndirizzo: e.target.value });
                }}
              />
            </View>
            <View
              style={{
                width: "90%",
                borderWidth: 1,
                borderColor: "#7A79CD",
                borderRadius: 7,
                marginVertical: 10,
                padding: 10,
              }}
            >
              <TextInput
                style={{
                  width: "100%",
                  fontSize: "1.2rem",
                }}
                placeholder="Conferma Indirizzo"
                onChange={(e) => {
                  this.setState({ confIndirizzo: e.target.value });
                }}
              />
            </View>
            <View
              style={{
                width: "90%",
                borderWidth: 1,
                borderColor: "#7A79CD",
                borderRadius: 7,
                marginVertical: 10,
                padding: 10,
              }}
            >
              <TextInput
                placeholder="Vecchio civico"
                style={{
                  width: "100%",
                  fontSize: "1.2rem",
                }}
                value={this.state.utente.civico}
              />
            </View>
            <View
              style={{
                width: "90%",
                borderWidth: 1,
                borderColor: "#7A79CD",
                borderRadius: 7,
                marginVertical: 10,
                padding: 10,
              }}
            >
              <TextInput
                style={{
                  width: "100%",
                  fontSize: "1.2rem",
                }}
                placeholder="Nuovo civico"
                onChange={(e) => {
                  this.setState({ newCivico: e.target.value });
                }}
              />
            </View>
            <View
              style={{
                width: "90%",
                borderWidth: 1,
                borderColor: "#7A79CD",
                borderRadius: 7,
                marginVertical: 10,
                padding: 10,
              }}
            >
              <TextInput
                style={{
                  width: "100%",
                  fontSize: "1.2rem",
                }}
                placeholder="Conferma civico"
                onChange={(e) => {
                  this.setState({ confCivico: e.target.value });
                }}
              />
            </View>
            <View
              style={{
                width: "90%",
                borderWidth: 1,
                borderColor: "#7A79CD",
                borderRadius: 7,
                marginVertical: 10,
                padding: 10,
              }}
            >
              <TextInput
                placeholder="Vecchio Postal Code"
                style={{
                  width: "100%",
                  fontSize: "1.2rem",
                }}
                value={this.state.utente.cap}
              />
            </View>
            <View
              style={{
                width: "90%",
                borderWidth: 1,
                borderColor: "#7A79CD",
                borderRadius: 7,
                marginVertical: 10,
                padding: 10,
              }}
            >
              <TextInput
                style={{
                  width: "100%",
                  fontSize: "1.2rem",
                }}
                placeholder="Nuovo Postal Code"
                onChange={(e) => {
                  this.setState({ newCap: e.target.value });
                }}
              />
            </View>
            <View
              style={{
                width: "90%",
                borderWidth: 1,
                borderColor: "#7A79CD",
                borderRadius: 7,
                marginVertical: 10,
                padding: 10,
              }}
            >
              <TextInput
                style={{
                  width: "100%",
                  fontSize: "1.2rem",
                }}
                placeholder="Conferma Postal Code"
                onChange={(e) => {
                  this.setState({ confCap: e.target.value });
                }}
              />
            </View>
          </View>

          <TouchableOpacity
            style={style.btnConf}
            onPress={() =>
              changeSpedizione(
                this.state.newIndirizzo,
                this.state.confIndirizzo,
                this.state.newCap,
                this.state.confCap,
                this.state.newCivico,
                this.state.confCivico,
              )
            }
          >
            <Text style={style.btnText}>Confirm</Text>
          </TouchableOpacity>
        </View>
      </div>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
  },
  btnConf: {
    width: "90%",
    borderRadius: 7,
    backgroundColor: "#0A09A2",
    paddingVertical: 15,
    marginTop: "2%",
    justifyContent: "center",
    alignItems: "center",
  },
  btnText: {
    color: "white",
    fontSize: "1.2rem",
  },
});

export default withTranslation()(ModificaSpedizione);
