import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import "../../styles/ModificaPage.css";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import AccountRoutes from "../../components/AccountRoutes";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";
import { modificaMail, getUser } from "../chiamateApi/ApiModifyUser";

function ModificaEmail() {
  const [oldMail, setOldMail] = useState("");
  const [newMail, setNewMail] = useState("");
  const [accountData, setAccountData] = useState({});
  const [confermaMail, setConfermaMail] = useState("");
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const id = localStorage.getItem(CONSTANTS.ID);
  const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);

  const { t } = useTranslation();
  const desktop = useDesktopChecker();
  const navigate = useNavigate();

  useEffect(() => {
    async function mail() {
      const data = await getUser(id, token);
      setOldMail(data.email);
    }
    mail();
  }, []);

  const SubmitNewMail = async (e) => {
    e.preventDefault();

    console.log("email", oldMail.includes("@"));
    if (oldMail.length > 6 && oldMail.includes("@") == true) {
      const data = {
        id: id,
        email: oldMail,
        oldMail: oldMail,
        oldPassword: password,
      };
      const res = await modificaMail(data, token);

      if (res.data.error) {
        setTimeout(() => {
          alert(t("incorrect_password"));

          navigate("/account/impostazioni");
        }, 2000);
      } else {
        alert(t("changed_email_success"));

        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
          navigate("/account/impostazioni");
        }, 2000);
      }
    } else {
      alert(t("email_wrong"));
    }
  };

  return (
    <div className="container-account-general">
      {desktop && <Header />}
      {!desktop && <HeaderMobile context="Others" title={"Settings"} />}
      <div className="container-account-route">
        {desktop && <AccountRoutes />}
        <div className="modifica-password-container">
          <p className="modifica-pass-title">{t("edit")} Email</p>
          <p className="modifica-pass-subtitle">{t("fill_fields")}</p>
          <form onSubmit={SubmitNewMail} className="form-modifica">
            <b className="oldValue">{t("Email")}:</b>
            <input
              onChange={(e) => {
                setOldMail(e.target.value);
              }}
              required
              type="text"
              placeholder={t("old_email")}
              value={oldMail}
            />

            <b className="oldValue">Password:</b>
            <input
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
              type="password"
              placeholder="Password"
            />
            <button type="submit">{t("confirm")} Email</button>
          </form>
          <Toast show={showToast} className="toast-confirm">
            <span>{t("success_mail")}</span>
          </Toast>
        </div>
      </div>
    </div>
  );
}

export default ModificaEmail;
