import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import "../../styles/ModificaPage.css";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import AccountRoutes from "../../components/AccountRoutes";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";
import { getUser, modificaIban } from "../chiamateApi/ApiModifyUser";

function ModificaIban() {
  const [oldIban, setOldIban] = useState("");
  const [newIban, setNewIban] = useState("");
  const [accountData, setAccountData] = useState({});
  const [confermaIban, setConfermaIban] = useState("");
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const id = localStorage.getItem(CONSTANTS.ID);
  const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const desktop = useDesktopChecker();

  useEffect(() => {
    async function getIban() {
      const data = await getUser(id, token);
      setOldIban(data.iban);
    }
    getIban();
  }, []);

  const SubmitNewIban = async (e) => {
    //console.log(oldIban);
    //console.log(newIban);
    e.preventDefault();

    if (oldIban.length > 5) {
      console.log("mail uguali");
      const data = {
        id: id,
        iban: oldIban,
        oldMail: oldIban,
        oldPassword: password,
      };

      const res = await modificaIban(data, token);

      if (res.data.error) {
        setTimeout(() => {
          alert(t("incorrect_password"));
          navigate("/account/impostazioni");
        }, 2000);
      } else {
        console.log(res);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
          navigate("/account/impostazioni");
        }, 2000);
      }
    } else {
      alert(t("iban_wrong"));
    }
  };

  return (
    <div className="container-account-general">
      {desktop && <Header />}
      {!desktop && <HeaderMobile context="Others" title={"Settings"} />}
      <div className="container-account-route">
        {desktop && <AccountRoutes />}
        <div className="modifica-password-container">
          <p className="modifica-pass-title">{t("edit")} Iban</p>
          <p className="modifica-pass-subtitle">{t("fill_fields")}</p>
          <form onSubmit={SubmitNewIban}>
            <b>{t("iban")}:</b>
            <input
              onChange={(e) => {
                setOldIban(e.target.value);
              }}
              required
              type="text"
              value={oldIban}
              placeholder={t("iban")}
            />

            <b>Password:</b>
            <input
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
              type="password"
              placeholder={"Password"}
            />
            <button>{t("confirm")} Iban</button>
          </form>
          <Toast show={showToast} className="toast-confirm">
            <span>{t("success_iban")}</span>
          </Toast>
        </div>
      </div>
    </div>
  );
}

export default ModificaIban;
