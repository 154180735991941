import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Modal,
  TouchableWithoutFeedback,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
} from "react-native";
import { colors, isLittleDev } from "../components/utils/constants";

import { useTranslation } from "react-i18next";

const ModalAdd = ({
  modalAdd,
  onModalDismiss,
  onModalConfirm,
  handleChange,
  filterPrezzo,
  marca,
  filtriAttuali,
}) => {
  const [value, setValue] = useState([]);
  const [type, setType] = useState("");
  const [user, setUser] = useState(0);
  const { t } = useTranslation();
  const valueSetter = (valore, tipo) => {
    console.log("valore value setter: ", valore);
    if (value.length > 0 && tipo === "marca") {
      if (value.find((element) => element === valore)) {
        const index = value.indexOf(valore, 1);
        const V = value.filter((v) => v !== valore);
        var array = [...type];
        setValue(V.sort().reverse());
        array.splice(index, 1);
        setType(array.sort());
      } else if (tipo === "marca") {
        setValue((value) => [...value.sort().reverse(), valore]);
      }
    } else if (type === valore && tipo === "prezzo") {
      setType("");
    } else if (type !== "" && tipo === "prezzo") {
      document.getElementById(type).checked = false;
      setType(valore);
    } else if (user === valore && tipo === "utente") {
      setUser(0);
    } else if (user === 0 && tipo === "utente") {
      setUser(valore);
    } else if (value.length <= 0 && tipo === "marca") {
      setValue((value) => [...value.sort().reverse(), valore]);
    } else if (type === "" && tipo === "prezzo") {
      setType(valore);
    } else if (user === 0 && tipo === "utente") {
      setUser(valore);
    }

    console.log(value);
    console.log(type);
    console.log(user);
  };

  return (
    <>
      <Modal visible={modalAdd} transparent animationType="slide">
        <View
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          {!isLittleDev() && (
            <TouchableWithoutFeedback onPress={onModalDismiss}>
              <View style={{ height: "8%", width: "100%" }} />
            </TouchableWithoutFeedback>
          )}
          <View
            style={{
              height: isLittleDev() ? "100%" : "92%",
              width: "100%",
              alignItems: "center",
              backgroundColor: "#fff",
              borderTopRightRadius: isLittleDev() ? 15 : 30,
              borderTopLeftRadius: isLittleDev() ? 15 : 30,
              shadowRadius: 10,
              shadowOpacity: 0.5,
              paddingHorizontal: 10,
              paddingTop: 20,
              justifyContent: "space-between",
              paddingBottom: "5%",
            }}
          >
            <View
              style={{
                width: "100%",
                alignItems: "flex-end",
                marginRight: "5%",
              }}
            >
              <TouchableOpacity onPress={onModalDismiss}>
                <Text style={{ fontWeight: "bold", color: colors.blue }}>
                  {t("close")}
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                height: "85%",
              }}
            >
              <Text
                style={{
                  width: "70%",
                  textAlign: "center",
                  alignSelf: "center",
                  fontWeight: "400",
                  fontSize: "1.7rem",
                }}
              >
                Filters
              </Text>

              <View style={{ width: "90%" }}>
                <Text
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    marginVertical: 10,
                  }}
                >
                  Brand
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  {marca.map((m) => (
                    <View
                      style={{ flexDirection: "row", alignItems: "baseline" }}
                    >
                      <label>
                        <input
                          type="checkbox"
                          style={{ marginRight: 10 }}
                          // checked={}
                          onClick={() => {
                            valueSetter(m.nomeMarca, "marca");
                          }}
                        />
                        <Text style={{ fontSize: "0.9rem" }}>
                          {m.nomeMarca}
                        </Text>
                      </label>
                    </View>
                  ))}
                </View>
                <Text
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    marginVertical: 10,
                  }}
                >
                  {t("average_price")}
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <View style={style.checkbox}>
                    <label>
                      <input
                        id={"1"}
                        type="checkbox"
                        checked={type === "1" ? true : false}
                        onClick={() => valueSetter("1", "prezzo")}
                        style={{ marginRight: 10 }}
                      />
                      <Text style={{ fontSize: "0.9rem" }}>
                        {t("under")} 100
                      </Text>
                    </label>
                  </View>
                  <View style={style.checkbox}>
                    <label>
                      <input
                        id={"2"}
                        type="checkbox"
                        checked={type === "2" ? true : false}
                        onClick={() => valueSetter("2", "prezzo")}
                        style={{ marginRight: 10 }}
                      />
                      <Text style={{ fontSize: "0.9rem" }}>100 - 200</Text>
                    </label>
                  </View>
                  <View style={style.checkbox}>
                    <label>
                      <input
                        id={"3"}
                        type="checkbox"
                        checked={type === "3" ? true : false}
                        onClick={() => valueSetter("3", "prezzo")}
                        style={{ marginRight: 10 }}
                      />
                      <Text style={{ fontSize: "0.9rem" }}>200 - 300</Text>
                    </label>
                  </View>
                  <View style={style.checkbox}>
                    <label>
                      <input
                        id={"4"}
                        type="checkbox"
                        checked={type === "4" ? true : false}
                        onClick={() => valueSetter("4", "prezzo")}
                        style={{ marginRight: 10 }}
                      />
                      <Text style={{ fontSize: "0.9rem" }}>300 - 400</Text>
                    </label>
                  </View>
                  <View style={style.checkbox}>
                    <label>
                      <input
                        id={"5"}
                        type="checkbox"
                        checked={type === "5" ? true : false}
                        onClick={() => valueSetter("5", "prezzo")}
                        style={{ marginRight: 10 }}
                      />
                      <Text style={{ fontSize: "0.9rem" }}>400 - 500</Text>
                    </label>
                  </View>
                  <View style={style.checkbox}>
                    <label>
                      <input
                        id={"6"}
                        type="checkbox"
                        checked={type === "6" ? true : false}
                        onClick={() => valueSetter("6", "prezzo")}
                        style={{ marginRight: 10 }}
                      />
                      <Text style={{ fontSize: "0.9rem" }}>
                        {t("over")} 500
                      </Text>
                    </label>
                  </View>
                </View>
                <Text
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    marginVertical: 10,
                  }}
                >
                  {t("fast_ship")}
                </Text>
                <View style={style.checkbox}>
                  <label
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <input
                      onClick={() => valueSetter(2, "utente")}
                      checked={user === 2 ? true : false}
                      type="checkbox"
                      style={{ marginRight: 10 }}
                    />
                    <Image
                      source={require("../../assets/wk-logo.png")}
                      style={{
                        height: 50,
                        width: 50,
                        resizeMode: "contain",
                      }}
                    />
                  </label>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                style={{
                  width: "80%",
                  color: "white",
                  backgroundColor: colors.blue,
                  textAlign: "center",
                  paddingVertical: 5,
                  borderRadius: 4,
                }}
                onPress={() => filterPrezzo(type, value, user, 2)}
              >
                <Text style={{ color: "white" }}>Apply</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
};

const style = StyleSheet.create({
  containerView: {
    flexDirection: "row",
    alignItems: "baceline",
    marginTop: 10,
    width: "90%",
    justifyContent: "space-between",
  },
  textBold: {
    fontSize: "1.1rem",
    fontWeight: 600,
  },
  textNotBold: {
    fontSize: "1.1rem",
    marginLeft: 5,
  },
  containerLotto: {
    backgroundColor: "#DEE4FD",
    width: "90%",
    padding: 10,
    marginTop: 10,
    borderRadius: 12,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    paddingLeft: "7%",
  },
  containerL: {
    backgroundColor: "white",

    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    margin: 5,
    width: "25%",
    borderWidth: 1.5,
    borderColor: "black",
    borderRadius: 7,
    textAlign: "center",
  },
  checkbox: {
    marginVertical: 5,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default ModalAdd;
