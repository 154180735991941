import axios from "axios";
import CONSTANTS from "../../CONSTANTS";

const APIKit = axios.create({
  baseURL: CONSTANTS.APIRES_URL,
  timeout: 100000,
});

APIKit.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);
    //const tenant = await getItemFromStorage(CONSTANTS.USER_ORGANIZATION, null);
    if (token) config.headers.Authorization = `Bearer ${token}`;
    //if (tenant) config.headers['X-TenantID'] = tenant;
    //    console.log('***************** '+config.headers.Authorization)
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

/*
// Set JSON Web Token in Client to be included in all calls
export const setClientToken = (token) => {
  console.log('***************** setClientToken: '+token)
  APIKit.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${token}`;
    console.log('***************** '+config.headers.Authorization)
    return config;
  });
};
*/

export default APIKit;
