import React, { Component, useState, useEffect } from "react";
import logo from "../../assets/wk-logo.png";
import { Link, useParams, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import "../../styles/NuovoLotto.css";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { colors } from "../../pagesMobile/components/utils/constants";
import { useTranslation } from "react-i18next";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import scarpaVuota from "../../assets/wk_products_not_found.png";
import { Text, View, StyleSheet } from "react-native";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";

const ListaLottoFourth = () => {
  const params = useParams();
  const location = useLocation();
  const [prodotto, setprodotto] = useState({});
  const lotti = location.state.lotti.filter(
    (item) => item.quantita > 0 && item.prezzo > 0
  );
  const wkfee = location.state.fees.wk;
  const shipfee = location.state.fees.ship;
  const iva = location.state.fees.iva;
  const ivaMargine = location.state.ivaMargine;
  const totale = location.state.total;
  const qty = location.state.qty;
  const desktop = useDesktopChecker();
  const nazione = localStorage.getItem(CONSTANTS.NAZIONE);

  const { t } = useTranslation();
  console.log("four", location.state);

  useEffect(() => {
    init();
  }, []);
  const init = async () => {
    console.log("IVA", iva);
    await axios
      .get(CONSTANTS.API_URL + "/prodotto/productById/" + params.id)
      .then(async (res) => {
        console.log("res", res.data);
        setprodotto(res.data);
      });
  };

  return (
    <>
      {desktop ? (
        <>
          <div className="container-form-check">
            <div className="div-logo-form">
              <Link to="/">
                <img className="logo-login" src={logo} alt="logo" />
              </Link>
            </div>
            <div className="checkout-form-div">
              <div className="image-prod-in-lotto">
                <img
                  src={prodotto.imageProdotto}
                  alt="immagine-prod"
                  onError={(e) => {
                    e.target.src = scarpaVuota;
                  }}
                />
                {/* <p>{prodotto.modello}</p> */}
              </div>{" "}
              <p className="checkout-title"> {t("new_stock")} </p>
              <p> {t("confirm_stock")} </p>
              <div className="nuovolotto-div-tuttoforte">
                {lotti.map((item, index) => {
                  return (
                    <div className="taglia-nuovolotto-centrata-2">
                      <div className="nuovo-lotto-container">
                        <span>US {item.taglia}</span>
                        <div className="nuovo-lotto-taglia-counter">
                          <input
                            type="number"
                            min="0"
                            value={item.quantita}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="nuovo-lotto-container-ai">
                        <input
                          type="number"
                          min="0"
                          value={item.prezzo}
                          placeholder="Prezzo"
                          disabled
                        />
                        <p
                          style={{
                            fontSize: "0.6rem",
                            textAlign: "center",
                            verticalAlign: "center",
                            margin: 0,
                            marginLeft: 3,
                            height: "fit-content",
                          }}
                        >
                          {" "}
                          €
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <p style={{ width: "100%", marginLeft: "10%" }}>
                {t("pack_ins")}
                <b>{" " + qty}</b>{" "}
                {/* {this.props.location.state[29].tagliaFinale} */}
              </p>
              <div className="prezzi-nuovo-lotto-2">
                {!(totale === 0 || totale === NaN) && (
                  <p
                    className="text-right-lotto"
                    style={{ width: "100%", marginRight: "6%" }}
                  >
                    {t("total")}
                    <b>
                      {" "}
                      {parseFloat(totale)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",") + " €"}
                    </b>
                    <br />
                    {t("shipping_fee")} ({t("pair_of_shoes")}):{" "}
                    <b>
                      {" "}
                      {"-" +
                        parseFloat(shipfee)
                          .toFixed(2)
                          .toString()
                          .replace(".", ",") +
                        " €"}
                    </b>
                    <br />
                    {t("transaction_fees")} (2,5%):
                    <b>
                      {" "}
                      {"-" +
                        parseFloat(wkfee)
                          .toFixed(2)
                          .toString()
                          .replace(".", ",") +
                        " €"}
                    </b>
                    <br />
                    {!ivaMargine ? (
                      <div>
                        V.A.T. 22%{" "}
                        <b>
                          {" "}
                          {"+" +
                            parseFloat(iva)
                              .toFixed(2)
                              .toString()
                              .replace(".", ",") +
                            " €"}
                        </b>
                      </div>
                    ) : (
                      <> {t("marginal_vat_checked")}: ✔</>
                    )}
                    <br />
                    {t("tot_pay")}:{" "}
                    <b>
                      {nazione === "Italy"
                        ? (totale - shipfee - wkfee + iva)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",") + " €"
                        : (totale - shipfee - wkfee)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",") + " €"}
                    </b>
                    <br />
                  </p>
                )}
              </div>
            </div>
            <div className="btn-footer-checkout">
              <Link
                replace
                to={"/lista-lotto-five/" + params.id}
                state={{
                  ...location.state,
                  lotti: lotti,
                  accountId: localStorage.getItem(CONSTANTS.ID),
                  prodottoId: params.id,
                }}
              >
                {t("next")}
              </Link>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <View style={style.container}>
          <HeaderMobile context="Home" />
          {/* <Menu /> */}
          <View
            style={{
              width: "90%",
              height: "90%",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 10,
            }}
          >
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <div onClick={this.onClickBack}>Indietro</div> */}
              <p style={{ fontWeight: "bold", width: "100%" }}>
                {t("add_size")}
              </p>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <img
                  src={prodotto.imageProdotto}
                  style={{
                    width: 120,
                    height: 70,
                    resizeMode: "contain",
                    borderRadius: 7,
                    backgroundColor: "white",
                  }}
                  onError={(e) => {
                    e.target.src = scarpaVuota;
                  }}
                />
                <View style={{ width: "60%" }}>
                  <Text style={{ fontWeight: "bold" }}>{prodotto.nome}</Text>
                  <Text>{prodotto.modello}</Text>

                  <Text>
                    {t("qty")}: {qty}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: 10,
                  paddingBottom: 5,
                  borderBottomColor: "#c4c4c4",
                  borderBottomWidth: 1,
                }}
              >
                <View style={{ width: "50%" }}></View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ fontWeight: "bold", fontSize: "0.7rem" }}>
                    {t("tot_pay")}:{" "}
                    {(totale - (shipfee + wkfee) + iva).toFixed(2)} €
                  </Text>
                  <Text></Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  backgroundColor: "#f5f5f5",
                  borderRadius: 7,
                  padding: 10,
                }}
              >
                <Text style={{ marginBottom: 10, fontWeight: "bold" }}>
                  {t("size")}
                </Text>
                <View
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    borderRadius: 7,
                    padding: 5,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {lotti.map((lotto) => (
                    <View
                      key={lotto.taglia}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        paddingVertical: 5,
                        borderRadius: 7,
                        borderWidth: 1,
                        paddingHorizontal: 10,
                        backgroundColor: "white",
                        width: "30%",
                        margin: 5,
                      }}
                    >
                      <Text>US {lotto.taglia}</Text>
                      <Text style={{ color: "#0A09A2", fontWeight: "bold" }}>
                        {t("qty")} {lotto.quantita}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
            <View
              style={{
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
                position: "absolute",
                bottom: "2%",
              }}
            >
              <Link
                replace
                style={{
                  backgroundColor: "#0A09A2",
                  marginTop: "%",
                  width: "100%",
                  padding: 10,
                  textAlign: "center",
                  borderRadius: 4,
                  color: "#fff",
                  textDecorationLine: "none",
                }}
                to={"/lista-lotto-five/" + params.id}
                state={{
                  ...location.state,
                  lotti: lotti,
                  accountId: localStorage.getItem(CONSTANTS.ID),
                  prodottoId: params.id,
                }}
              >
                {t("next")}
              </Link>
            </View>
          </View>
        </View>
      )}
    </>
  );
};
const style = StyleSheet.create({
  container: {
    width: "100%",
    height: "90vh",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  board: {
    height: 160,
    width: "100%",
    backgroundColor: colors.secondary,
    padding: 30,
  },
  containerRule: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "8%",
    width: "90%",
    marginHorizontal: "5%",
  },
  imageRule: {
    width: 60,
    height: 60,
    backgroundColor: "#e4e4e4",
    marginRight: 10,
  },
  textRule: {
    fontSize: "1.1rem",
  },
  btnContinua: {
    backgroundColor: "#0A09A2",
    marginTop: "1%",
    width: "100%",
    padding: 10,
    textAlign: "center",
    borderRadius: 4,
  },
  btnContinuaMob: {
    backgroundColor: "#0A09A2",
    width: "100%",
    padding: 10,
    textAlign: "center",
    borderRadius: 4,
  },
  btnText: {
    color: "#fff",
    fontSize: "1.1rem",
    fontWeight: "bold",
  },

  inputCerca: {
    borderWidth: 1,
    borderColor: "#f5f5f5",
    width: "100%",
    height: 30,
    marginTop: 10,
    paddingHorizontal: 15,
    paddingVertical: 20,
  },

  btnCrea: {
    width: "90%",
    height: 40,
    backgroundColor: "#0a09a2",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 7,
    marginBottom: "5%",
    position: "absolute",
  },
  titleBtn: {
    color: "white",
    fontSize: 20,
  },
  btnAltroLotto: {
    backgroundColor: "transparent",
    marginTop: "1%",
    width: "100%",
    padding: 10,
    textAlign: "center",
    borderRadius: 4,
  },

  btnTextAltro: {
    color: "#0a09a2",
    fontSize: 15,
    fontWeight: 600,
  },
  containerLottoListato: {
    width: "100%",
    backgroundColor: "#f5f5f5",
    padding: 5,
    borderRadius: 7,
    marginTop: 20,
  },
});
export default ListaLottoFourth;
