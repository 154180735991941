import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Modal,
} from "react-native";
import HeaderMobile from "../components/HeaderMobile";
import ModalProposal from "./ModalProposal";
import axios from "axios";
import ModalProposalCustom from "./ModalProposalCustom";
import CONSTANTS from "../../CONSTANTS";
import Paginator from "../components/Paginator";
import { isLittleDev } from "../components/utils/constants";

class Vendite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statoProfile: 0,
      modal: false,
      modalSelectedItem: false,
      modalItemTools: {},
      objectSelected: {},
      listaLottiFatti: [],
      totalPageMieiLotti: [],
      activeVendite: [],
      listaProposte: [],
      listaProdotti: [],
      listaLottiFatti: [],
      totalPageMieiLotti: [],
      taglieVisualizzaLotti: [],
    };
  }
  componentDidMount() {
    this.init();
  }
  init() {
    this.getCustomProposal(9, 0);
    this.getActiveSell(9, 0);
    this.getProposal(9, 0);
    this.getUserStocks(9, 0);
  }
  async getCustomProposal() {
    await axios
      .get(
        CONSTANTS.API_URL +
          "/lotto/account-proposal-custom/" +
          localStorage.getItem(CONSTANTS.ID),
        {
          HeaderMobiles: {
            Authorization: `Bearer ${localStorage.getItem(
              CONSTANTS.ACCESS_TOKEN_KEY,
            )}`,
          },
        },
      )
      .then((propLottiCustom) => {
        console.log("gg", propLottiCustom);
        this.setState({
          activeVendite: propLottiCustom.data,
        });
      });
  }
  async getProposal(limit, offset) {
    await axios
      .get(
        CONSTANTS.API_URL +
          "/offertaFatta/account/" +
          localStorage.getItem(CONSTANTS.ID) +
          "/" +
          offset +
          "/" +
          limit +
          "",
        {
          HeaderMobiles: {
            Authorization: `Bearer ${localStorage.getItem(
              CONSTANTS.ACCESS_TOKEN_KEY,
            )}`,
          },
        },
      )
      .then((prodottoRicevuto) => {
        console.log("offertaFatta", prodottoRicevuto);
        this.setState({
          listaProposte: prodottoRicevuto.data,
        });
      });
  }
  async getActiveSell(limit, offset) {
    await axios
      .get(
        CONSTANTS.API_URL +
          "/vendite/buyId/" +
          localStorage.getItem(CONSTANTS.ID) +
          "/" +
          offset +
          "/" +
          limit,
        {
          HeaderMobiles: {
            Authorization: `Bearer ${localStorage.getItem(
              CONSTANTS.ACCESS_TOKEN_KEY,
            )}`,
          },
        },
      )
      .then((prodottoRicevuto) => {
        console.log("lp", prodottoRicevuto);
        this.setState({
          listaProdotti: prodottoRicevuto.data,
        });
      });
  }
  async getUserStocks(limit, offset) {
    await axios
      .get(
        CONSTANTS.API_URL +
          "/lotto/account/" +
          localStorage.getItem(CONSTANTS.ID) +
          "/" +
          offset +
          "/" +
          limit,
        {
          HeaderMobiles: {
            Authorization: `Bearer ${localStorage.getItem(
              CONSTANTS.ACCESS_TOKEN_KEY,
            )}`,
          },
        },
      )
      .then((lottiMiei) => {
        console.log("lottiMiei", lottiMiei);
        this.setState({
          listaLottiFatti: lottiMiei.data,
        });
      });
    axios
      .get(
        CONSTANTS.API_URL +
          "/lotto/conteggio/" +
          localStorage.getItem(CONSTANTS.ID),
        {
          HeaderMobiles: {
            Authorization: `Bearer ${localStorage.getItem(
              CONSTANTS.ACCESS_TOKEN_KEY,
            )}`,
          },
        },
      )
      .then((proposteRicevute) => {
        const countPage =
          proposteRicevute.data[0].totalCount / this.state.elementPerPage;
        console.log(proposteRicevute.data);
        this.setState({
          totalPageMieiLotti: Array(parseInt(countPage) + 1),
        });
      });
  }
  onPressModalCancel = () => {
    this.setState({ modal: false, modalItemTools: {} });
  };
  onPressAggiungiModel = () => {
    console.log("openModal");
    this.setState({ modal: true });
  };
  onPressModelCustom = () => {
    console.log("openModal");
    this.setState({ modalCustom: true });
  };
  onPressModalTools = (item) => {
    this.setState({ modalItemTools: item }, () => {
      this.setState({ modalSelectedItem: true });
    });
  };
  onCloseModalTools = () => {
    this.setState({ modalSelectedItem: false }, () => {
      this.setState({ modalItemTools: {} });
    });
  };
  onPressVisualizzaTools = () => {
    this.getTagliebyLotto(this.state.modalItemTools);
  };
  onPressVisualizzaLotto = (prod) => {
    this.getTagliebyLotto(prod);
  };
  async getTagliebyLotto(prodotto) {
    await axios
      .get(CONSTANTS.API_URL + "/taglia/" + prodotto.id, {
        HeaderMobiles: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY,
          )}`,
        },
      })
      .then((res) => {
        console.log("r", res.data);
        this.setState({ taglieVisualizzaLotti: res.data }, () => {
          this.setState(
            {
              objectSelected: {
                ...prodotto,
                taglie: this.state.taglieVisualizzaLotti,
              },
            },
            () => {
              this.setState({ modalSelectedItem: false, modal: true });
            },
          );
        });
      });
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="Container-Common">
          <View style={style.container}>
            <HeaderMobile context="Others" title={t("sell")} />
            <ScrollView
              style={style.HeaderMobileProfile}
              showsHorizontalScrollIndicator={false}
              horizontal
            >
              <TouchableOpacity
                onPress={() => {
                  this.setState({ statoProfile: 0 });
                }}
              >
                <Text
                  style={{
                    fontWeight: this.state.statoProfile === 0 ? "bold" : 400,
                    color: this.state.statoProfile === 0 ? "#0A09A2" : "black",
                  }}
                >
                  {t("pending_prop")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginLeft: "10%" }}
                onPress={() => {
                  this.setState({ statoProfile: 1 });
                }}
              >
                <Text
                  style={{
                    fontWeight: this.state.statoProfile === 1 ? "bold" : 400,
                    color: this.state.statoProfile === 1 ? "#0A09A2" : "black",
                  }}
                >
                  {t("purch_prop")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginLeft: "10%" }}
                onPress={() => {
                  this.setState({ statoProfile: 2 });
                }}
              >
                <Text
                  style={{
                    fontWeight: this.state.statoProfile === 2 ? "bold" : 400,
                    color: this.state.statoProfile === 2 ? "#0A09A2" : "black",
                  }}
                >
                  {t("in_prog")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginLeft: "10%" }}
                onPress={() => {
                  this.setState({ statoProfile: 3 });
                }}
              >
                <Text
                  style={{
                    fontWeight: this.state.statoProfile === 3 ? "bold" : 400,
                    color: this.state.statoProfile === 3 ? "#0A09A2" : "black",
                  }}
                >
                  {t("stock_listed")}
                </Text>
              </TouchableOpacity>
            </ScrollView>
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {this.state.statoProfile === 0 && (
                <>
                  <ScrollView
                    style={{ width: "100%", height: "72vh" }}
                    contentContainerStyle={{ alignItems: "center" }}
                  >
                    {this.state.activeVendite.length == 0 && (
                      <Text>No items in this section</Text>
                    )}
                    {this.state.activeVendite.length > 0 &&
                      this.state.activeVendite.map((item) => {
                        return (
                          <View style={style.containerProposte}>
                            <Image
                              style={{
                                width: "40%",
                                resizeMode: "center",
                                height: "100%",
                              }}
                              source={{ uri: item.prodotto.imageProdotto }}
                            />
                            <View style={style.containerLabelProdotto}>
                              <Text style={style.titleContProd}>
                                {item.prodotto.nome}
                              </Text>
                              <Text style={style.SubTitleContProd}>
                                {item.prodotto.modello}
                              </Text>
                              <View style={style.containerShipping}>
                                <View>
                                  <Text>
                                    N. Lotto <br />
                                    riferimento
                                  </Text>
                                  <Text>{item.id}</Text>
                                </View>
                                <View>
                                  <TouchableOpacity
                                    onPress={() =>
                                      this.onPressVisualizzaLotto(item)
                                    }
                                  >
                                    <Image
                                      source={require("../../assets/check_prop.png")}
                                      style={{
                                        height: 30,
                                        width: 30,
                                        resizeMode: "contain",
                                      }}
                                    />
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                  </ScrollView>
                  <Paginator
                    elemPerPage={9}
                    data={this.state.activeVendite}
                    countAll={18}
                  />
                </>
              )}
              {this.state.statoProfile === 1 && (
                <>
                  <ScrollView
                    style={{ width: "100%", height: "72vh" }}
                    contentContainerStyle={{ alignItems: "center" }}
                  >
                    {this.state.listaProposte.length == 0 && (
                      <Text>No items in this section</Text>
                    )}
                    {this.state.listaProposte.length > 0 &&
                      this.state.listaProposte.map((item) => {
                        return (
                          <View style={style.containerProposte}>
                            <Image
                              style={{
                                width: "40%",
                                resizeMode: "center",
                                height: "100%",
                              }}
                              source={{ uri: item.prodotto.imageProdotto }}
                            />
                            <View style={style.containerLabelProdotto}>
                              <Text style={style.titleContProd}>
                                {item.prodotto.nome}
                              </Text>
                              <Text style={style.SubTitleContProd}>
                                {item.prodotto.modello}
                              </Text>
                              <View style={style.containerShipping}>
                                <View>
                                  <Text>N. Lotto:</Text>
                                  <Text>{item.id}</Text>
                                </View>
                                <View
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingRight: 20,
                                    flexDirection: "row",
                                  }}
                                >
                                  <Text style={{ marginRight: 10 }}>
                                    Proposta
                                  </Text>
                                  <TouchableOpacity
                                    onPress={this.onPressAggiungiModel}
                                  >
                                    <Image
                                      source={require("../../assets/check_prop.png")}
                                      style={{
                                        height: 30,
                                        width: 30,
                                        resizeMode: "contain",
                                      }}
                                    />
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                  </ScrollView>
                  {this.state.listaProposte.length > 0 && (
                    <Paginator
                      elemPerPage={9}
                      data={this.state.listaProposte}
                      countAll={18}
                    />
                  )}
                </>
              )}
              {this.state.statoProfile === 2 && (
                <>
                  <ScrollView
                    style={{ width: "100%", height: "72vh" }}
                    contentContainerStyle={{ alignItems: "center" }}
                  >
                    {this.state.listaProdotti.length == 0 && (
                      <Text>No items in this section</Text>
                    )}
                    {this.state.listaProdotti.length > 0 &&
                      this.state.listaProdotti.map((item) => {
                        return (
                          <View style={style.containerProposte}>
                            <Image
                              style={{
                                width: "40%",
                                resizeMode: "center",
                                height: "100%",
                              }}
                              source={{ uri: item.imageProdotto }}
                            />
                            <View style={style.containerLabelProdotto}>
                              <Text style={style.titleContProd}>
                                {item.nome}
                              </Text>
                              <Text style={style.SubTitleContProd}>
                                {item.modello}
                              </Text>
                              <View style={style.containerShipping}>
                                <View>
                                  <Text>N. Lotto:</Text>
                                  <Text>{item.id}</Text>
                                </View>
                                <View
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingRight: 20,
                                    width: "100%",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Text style={{ marginRight: 10 }}>Qty</Text>
                                  <Text style={{ marginRight: 10 }}>
                                    {item.qta}
                                  </Text>
                                </View>
                              </View>
                              <View style={style.containerShipping}>
                                <View>
                                  <Text>
                                    <b>Total Payout</b>
                                  </Text>
                                  <Text>
                                    <b>{item.lotto.payout}€</b>
                                  </Text>
                                </View>
                                <View style={{ width: "30%" }}>
                                  <Text>Status</Text>
                                  <Text>
                                    <b>{item.StatoNome}</b>
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                  </ScrollView>
                  {this.state.listaProdotti.length > 0 && (
                    <Paginator
                      elemPerPage={9}
                      data={this.state.listaProdotti}
                      countAll={18}
                    />
                  )}
                </>
              )}
              {this.state.statoProfile === 3 && (
                <>
                  <ScrollView
                    style={{ width: "100%", height: "72vh" }}
                    contentContainerStyle={{ alignItems: "center" }}
                  >
                    {this.state.listaLottiFatti.length == 0 && (
                      <Text>No items in this section</Text>
                    )}
                    {this.state.listaLottiFatti.length > 0 &&
                      this.state.listaLottiFatti.map((item) => {
                        return (
                          <View style={style.containerProposte}>
                            <Image
                              style={{
                                width: "40%",
                                resizeMode: "center",
                                height: "100%",
                              }}
                              source={{ uri: item.prodotto.imageProdotto }}
                            />
                            <View style={style.containerLabelProdotto}>
                              <Text style={style.titleContProd}>
                                {item.prodotto.nome}
                              </Text>
                              <Text style={style.SubTitleContProd}>
                                {item.prodotto.modello}
                              </Text>
                              <View style={style.containerShipping}>
                                <View>
                                  <Text>N. Lotto:</Text>
                                  <Text>{item.id}</Text>
                                </View>
                                <View
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingRight: 20,
                                    flexDirection: "row",
                                  }}
                                >
                                  <TouchableOpacity
                                    onPress={() => this.onPressModalTools(item)}
                                  >
                                    <Text
                                      style={{
                                        marginRight: 10,
                                        alignSelf: "flex-end",
                                        color: "rgb(10, 9, 162)",
                                        fontSize: "1.2rem",
                                        fontWeight: "900",
                                      }}
                                    >
                                      . . .
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                  </ScrollView>
                  {this.state.listaLottiFatti.length > 0 && (
                    <Paginator
                      elemPerPage={9}
                      data={this.state.listaLottiFatti}
                      countAll={18}
                    />
                  )}
                </>
              )}
            </View>
          </View>
        </div>
        <ModalProposal
          modalAdd={this.state.modal}
          data={this.state.objectSelected}
          onModalDismiss={this.onPressModalCancel}
        />
        <Modal
          visible={this.state.modalSelectedItem}
          animationType={"fade"}
          transparent
          style={{}}
        >
          <View
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "#11111160",
            }}
          >
            <View
              style={{ width: "100%", flexGrow: 1 }}
              onTouchStart={() => this.onCloseModalTools()}
            ></View>
            <View
              style={{
                width: "100%",
                backgroundColor: "#fff",
                borderTopLeftRadius: 30,
                borderTopRightRadius: 30,
                shadowRadius: 8,
                shadowOpacity: 0.3,
                justifyContent: "flex-start",
                alignItems: "center",
                paddingHorizontal: 30,
                paddingVertical: 20,
              }}
            >
              <Text style={style.labelModalTools}>
                Lotto n° {this.state.modalItemTools?.id}
              </Text>
              <TouchableOpacity
                style={style.buttonModalTools}
                onPress={() => this.onPressVisualizzaTools()}
              >
                <Text style={style.labelBtnTools}>Visualizza</Text>
              </TouchableOpacity>
              <TouchableOpacity style={style.buttonModalTools}>
                <Text style={style.labelBtnTools}>Modifica</Text>
              </TouchableOpacity>
              <TouchableOpacity style={style.buttonModalTools}>
                <Text style={{ ...style.labelBtnTools, color: "#f00" }}>
                  Elimina
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  HeaderMobileProfile: {
    flexDirection: "row",
    width: "90%",
    height: "fit-content",
    marginHorizontal: "5%",
    paddingHorizontal: "5%",
    paddingBottom: 15,
    borderBottomColor: "#7A79CD",
    flexGrow: "none",
    borderBottomWidth: 1.5,
  },
  containerProposte: {
    width: "90%",
    marginTop: 10,
    paddingBottom: 8,
    marginVertical: 20,
    flexDirection: "row",
    borderBottomColor: "#7A79CD",
    borderBottomWidth: 1.5,
  },

  containerLabelProdotto: {
    width: "60%",
  },

  titleContProd: {
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
  containerShipping: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 10,
    alignItems: "baseline",
  },
  labelModalTools: {
    fontSize: "1.1rem",
    fontWeight: "800",
    marginBottom: 6,
  },
  buttonModalTools: {
    paddingVertical: 18,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderTopWidth: 1,
    borderColor: "#DEE4FD",
  },
  labelBtnTools: {
    fontWeight: "700",
    color: "#0A09A2",
  },
});

export default withTranslation()(Vendite);
