import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import "../styles/ModificaPage.css";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import { isNotMobile } from "../pagesMobile/components/utils/constants";
import useDesktopChecker from "../components/utils/useDesktopChecker";
import HeaderMobile from "../pagesMobile/components/HeaderMobile";
import Footer from "../components/Footer";

function Privacy() {
  const { t } = useTranslation();
  const desktop = useDesktopChecker();
  const navigate = useNavigate();

  return (
    <div className="container-account-general">
      {desktop && <Header />}
      {!desktop && <HeaderMobile context="Others" title={"Privacy Policy"} />}
      <div className="container-account-route">
        <p
          className="modifica-pass-title"
          style={{
            paddingInline: isNotMobile() ? 80 : 10,
            fontSize: isNotMobile() ? 16 : 12,
            textAlign: "justify",
          }}
        >
          <br />
          Information on the protection of personal data
          <br />
          (art. 13 and 14 of Regulation 2016/679/EU)
          <br />
          Dear User, pursuant to art. 13 and 14 of Regulation 2016/679/EU
          (GDPR), we inform you that the personal data you provide or otherwise
          acquired at the time will be processed in compliance with current
          legislation and the principles of lawfulness, correctness,
          transparency, minimization of data, accuracy, retention limitation,
          integrity, confidentiality and accountability.
          <br />
          WHO WE ARE
          <br />
          The Data Controller is wholekicks, with registered office in Sofia
          Bulgary , which, within the scope of its prerogatives, may avail
          itself of the collaboration of persons in charge or appointed persons
          specifically identified. In carrying out its activities, the Company
          pays the utmost attention to the security and confidentiality of the
          personal data of its customers. The Company is the Data Controller of
          the personal data collected on this website.
          <br />
          HOW WE COLLECT YOUR PERSONAL DATA
          <br />
          The Company collects and processes your personal data in the following
          circumstances: - Data collection by electronic means directly via
          website. - Data collection through email contacts; If you provide
          personal data on behalf of someone else, you must ensure, in advance,
          that the data subjects have read this Privacy Policy. Please help us
          to keep your personal data up to date and inform us of any changes.
          <br />
          FOR WHAT PURPOSES YOUR PERSONAL DATA MAY BE USED
          <br />
          Your personal data, as described above and provided by you, are
          collected and used for the following purposes connected and
          instrumental to the activity of Wholekicks: 1 Contractual purposes
          [Art. 6, par. 1, lett. b) GDPR] Allow the performance of the
          obligations subject to the contract for the use of the services
          contained in it. 2 Legal obligations [Art. 6, par. 1, lett. c) GDPR]
          Fulfil the following legal obligations: a. obligations laid down by
          law, regulations, Community legislation or an order of the Authority
          (such as anti-money laundering); b. administrative, accounting and tax
          obligations. The data acquired for these purposes are stored by us for
          the time provided by the respective regulations; 3 Purposes related to
          the services offered [Art. 6, par. 1, lett. a) GDPR] Subject to your
          free consent, which you can express by selecting the respective boxes
          of the form "Consent to the processing of personal data" that you can
          fill out at the time of registration on the site, the data you provide
          to us may be processed for the purposes of the commercial contract. 4
          Promotional purposes [Art. 6, par. 1, lett. a) GDPR] a. Promotional
          purposes related to the advertising of services (cd. Soft Spam) [art.
          6, par. 1, lit. f) GDPR] b. Promotional purposes related to other
          services, events, promotions and commercial initiatives [art. 6, par.
          1, lett. a) GDPR] Subject to your free consent, which you can express
          by selecting the respective boxes of the form "Consent to the
          processing of personal data" that you can fill out at the time of
          registration on the site, the data you provide to us may be processed
          for the sending of commercial communications or the sending of
          advertising material; you can at any time withdraw the consent
          previously granted, even partially, for example by consenting to the
          traditional contact methods only.
          <br />
          HOW WE KEEP YOUR PERSONAL DATA SECURE
          <br />
          The processing of "common" personal data for each of the above
          purposes will take place in an automated and telematic and/or
          traditional form (paper media, ordinary mail or non-automated
          telephone communication)with logics strictly related to the purposes
          indicated and, in any case, in order to ensure the security and
          confidentiality of the data, as well as the possibility of the data
          subject to consent to even one of the contact methods (electronic or
          traditional).
          <br />
          HOW LONG WE KEEP YOUR INFORMATION
          <br />
          We store your personal data only for the time necessary to achieve the
          purposes for which they were collected or for any other legitimate
          related purpose. Therefore, if the personal data are processed for two
          different purposes, we will retain such data until the purpose ceases
          with the longest term, however we will no longer process the personal
          data for that purpose the retention period of which has expired. We
          restrict access to your personal data only to those who need to use it
          for relevant purposes. Your personal data that are no longer
          necessary, or for which there is no longer a legal requirement for its
          storage, are irreversibly anonymized (and thus can be stored) or
          safely destroyed. Here are the storage times in relation to the
          different purposes listed above: Fulfillment of contractual
          obligations: the data processed to fulfill any contractual obligation
          may be stored for the duration of the contract and in any case no
          later than the following 10 years, in order to verify any dependencies
          including accounting documents (such as invoices). Operational
          management and purposes strictly related to this for access to the
          website: the data processed for this purpose may be stored for the
          duration of the contract and in any case no later than 10 years.
          Customer satisfaction purposes: the data processed for this purpose
          may be stored from the date on which we obtained your last consent for
          this purpose (with the exception of opposition to receiving further
          communications) in accordance with the applicable regulations. In the
          event of a dispute: If we defend or take action or even make claims
          against you or any third party, we may retain personal data that we
          reasonably deem necessary to process for such purposes, for as long as
          this claim can be pursued. of investigations of
          <br />
          WHO WE CAN SHARE YOUR PERSONAL DATA WITH
          <br />
          Your personal data may be accessed by: 1) duly authorised employees;
          2) duly appointed controllers YOUR DATA PROTECTION RIGHTS AND YOUR
          RIGHT TO COMPLAIN TO THE SUPERVISORY AUTHORITY The data subject can
          exercise his or her rights by email, writing to the following email
          address info@wholekicks.eu or by registered letter addressed to the
          registered office of the company. You have the right to ask the
          Company: - access to your personal data (Art. 15 EU Regulation
          2016/679); - Corrigendum (Art. 16 EU Regulation 2016/679), -
          Cancellation (Art. 17 EU Regulation 2016/679), - Limitation (Art. 18
          EU Regulation 2016/679), - Portability, understood as the right to
          obtain from the data controller data in a structured format of common
          use and readable by automatic device to transmit them to another data
          controller without hindrance (Art. 20 EU Regulation 2016/679), -
          Opposition to processing (Art. 21 EU Regulation 2016/679);
          <br />
          COMPLAINT
          <br />
          Please note that pursuant to art. 77 of EU Regulation 2016/679 may
          lodge a complaint with the Italian Data Protection Authority in the
          event of a breach of current legislation on the protection of personal
          data.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
