import React, { Component, useState } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import HeaderMobile from "../components/HeaderMobile";
import { colors } from "../components/utils/constants";
import Menu from "../home/Menu";
import ProductGrid from "./ProductGrid";
import * as api from "../api/service";
import { withTranslation } from "react-i18next";
import ModalFilters from "./FiltersModal";

class NewIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newInTotal: [],
      // modal: false,
      // marca: [],
      // filtri: {
      //   prezzo: "",
      //   marca: [],
      // },
    };
    // this.prezzoDispatcher = {
    //   "0": { prezzo1: 0, prezzo2: 1000000 },
    //   "1": { prezzo1: 0, prezzo2: 100 },
    //   "2": { prezzo1: 100, prezzo2: 200 },
    //   "3": { prezzo1: 200, prezzo2: 300 },
    //   "4": { prezzo1: 300, prezzo2: 400 },
    //   "5": { prezzo1: 400, prezzo2: 500 },
    //   "6": { prezzo1: 500, prezzo2: 100000 },
    // };
  }
  async componentDidMount() {
    const newInTotal = await api.newInPage();
    this.setState({ newInTotal: newInTotal.produ });

    const marcaList = await api.getMarca();
    console.log("lista marche shop all: ", marcaList);
    this.setState({ marca: marcaList });
  }

  onPressModalCancel = () => {
    this.setState({ modal: false });
  };
  onPressAggiungiModel = () => {
    console.log("openModal");
    this.setState({ modal: true });
  };

  // filtriFunction = async (prezzo, marche, utente, stato) => {
  //   const body = {
  //     marche: marche,
  //     prezzo: this.prezzoDispatcher[prezzo],
  //     utente: utente,
  //     offset: 9,
  //     limit: 0,
  //   };
  //   console.log(body);
  //   const filterProductList = await api.productFilter(body);
  //   console.log("prodotti filtrati: ", filterProductList);
  //   this.setState({ newInTotal: filterProductList });
  //   this.setState({ modal: false });
  // };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="Container-Common">
          <View style={style.container}>
            <HeaderMobile context="Home" />
            <Menu />
            <View style={style.board}>
              <Text
                style={{
                  fontSize: "1.3rem",
                  color: "#363636",
                  fontWeight: "500",
                  width: "90%",
                  textAlign: "left",
                }}
              >
                {t("what_new")}
              </Text>
            </View>
            {/* <View
              style={{
                width: "90%",
                justifyContent: "center",
                paddingTop: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.setState({ modal: true });
                }}
              >
                <Text
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    color: "#0A09A2",
                  }}
                >
                  Filters
                </Text>
              </TouchableOpacity>
            </View> */}
            <ProductGrid newInTotal={this.state.newInTotal} />
          </View>
        </div>
        {/* <ModalFilters
          filterPrezzo={this.filtriFunction}
          marca={this.state.marca}
          filtriAttuali={this.state.filtri}
          modalAdd={this.state.modal}
          onModalDismiss={this.onPressModalCancel}
        /> */}
      </>
    );
  }
}
const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  board: {
    height: 160,
    width: "100%",
    backgroundColor: "#f5f5f5",
    padding: 30,
  },
});
export default withTranslation()(NewIn);
