import axios from "axios";
import CONSTANTS from "../../CONSTANTS";
const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);

export const createOrder = async (body) => {
  try {
    const res = await axios.post(CONSTANTS.API_URL + "/pausepay/create", body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
    alert(error.message);
  }
};
