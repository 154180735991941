import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const BannerComponent = () => {
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://sdk-web.pausepay.it/embeddable/v.0.0.8/pausepay_sdk.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [location]);

  return null;
};

export default BannerComponent;
