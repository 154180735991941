import React from "react";
import { Formik, Form, Field, ErrorMessage, Select } from "formik";
import Nazioni from "../../Nazioni";
import { useTranslation } from "react-i18next";

function Personalinfo({
  initialValues,
  onSubmitRegistrazione,
  validationSchema,
}) {
  const { t } = useTranslation();
  console.log("Nazioni", Nazioni);
  // console.log(handleChange);
  return (
    <>
      <div className="login-form-div">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitRegistrazione}
          validationSchema={validationSchema}
        >
          <Form>
            <div>
              <Field
                name="nome"
                type="text"
                placeholder={t("name")}
                required
                className={"form-no-margin"}
              />
            </div>
            <ErrorMessage name="nome" component="span" required />
            <div>
              <Field
                name="cognome"
                type="text"
                placeholder={t("surname")}
                required
                className={"form-no-margin"}
              />
            </div>
            <ErrorMessage name="cognome" component="span" />
            <div>
              <Field
                as="select"
                name="paese"
                type="text"
                placeholder={t("country")}
                required
                className={"form-no-margin"}
              >
                {Nazioni.map((naz, index) => {
                  return (
                    <option key={naz.name} id={naz.name} value={naz.name}>
                      {naz.name}
                    </option>
                  );
                })}
              </Field>
            </div>
            <ErrorMessage name="paese" component="span" />
            <div>
              <Field
                name="citta"
                type="text"
                placeholder={t("City")}
                required
                className={"form-no-margin"}
              />
            </div>
            <ErrorMessage name="citta" component="span" />

            <div>
              <Field
                name="indirizzo"
                type="text"
                placeholder={t("address")}
                required
                className={"form-no-margin"}
              />
            </div>
            <div>
              <Field
                name="civico"
                type="text"
                placeholder={t("Street number")}
                required
                className={"form-no-margin"}
              />
            </div>
            <ErrorMessage name="civico" component="span" />
            <ErrorMessage name="indirizzo" component="span" />
            <div>
              <Field
                name="numeroTelefono"
                type="text"
                placeholder={t("tel")}
                required
                className={"form-no-margin"}
              />
            </div>
            <ErrorMessage name="numeroTelefono" component="span" />

            <div>
              <Field
                name="cap"
                type="text"
                placeholder={t("Postal_code")}
                required
                className={"form-no-margin"}
              />
            </div>
            <ErrorMessage name="cap" component="span" />
            <button className="login-btn" type="submit">
              {t("next")}
            </button>
          </Form>
        </Formik>
      </div>
    </>
  );
}

export default Personalinfo;
