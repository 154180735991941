import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Modal,
  TouchableWithoutFeedback,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
  Keyboard,
  TextInput,
  StyleSheet,
} from "react-native";
import { Link, useNavigate, useParams } from "react-router-dom";
import { colors, dim, isLittleDev } from "../components/utils/constants";
import * as api from "../api/service";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Accordion } from "react-bootstrap";
import "./style.css";
import {
  isAutenticated,
  getCallerName,
} from "../../components/utils/validatorActions";

const ModalVisualizzatore = ({
  modalAdd,
  onModalDismiss,
  lotto,
  taglie,
  typePage,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const nazione = localStorage.getItem(CONSTANTS.NAZIONE);
  useEffect(() => {
    console.log("LOTTO", lotto);
  }, []);

  const calcFeeSped = (lotto) => {
    const module = lotto.quantita % 6;
    let division = lotto.quantita / 6;
    division = Math.round(division - 0.5);
    console.log(division);
    if (module > 0) {
      return (division + 1) * CONSTANTS.PRICESPEDIZIONE;
    } else {
      return division * CONSTANTS.PRICESPEDIZIONE;
    }
  };
  const calcWKfee = (lotto) => {
    return lotto?.prezzo * 0.025;
    //return 0
  };
  const calcIva = (lotto) => {
    if (nazione === "Italy" && !lotto.ivaMargine) {
      if (typePage === "Vendita") {
        return (lotto.prezzo - (calcFeeSped(lotto) + calcWKfee(lotto))) * 0.22;
      } else {
        return (lotto.prezzo + (calcFeeSped(lotto) + calcWKfee(lotto))) * 0.22;
      }
    } else {
      return 0;
    }
  };
  const totale = (lotto) => {
    if (typePage === "Vendita") {
      return (
        lotto.prezzo - (calcFeeSped(lotto) + calcWKfee(lotto)) + calcIva(lotto)
      );
    } else {
      return (
        lotto.prezzo + (calcFeeSped(lotto) + calcWKfee(lotto)) + calcIva(lotto)
      );
    }
  };
  return (
    <>
      {!loading && (
        <>
          <Modal visible={modalAdd} transparent animationType="slide">
            <View
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              {!isLittleDev() && (
                <TouchableWithoutFeedback onPress={onModalDismiss}>
                  <View style={{ height: "6%", width: "100%" }} />
                </TouchableWithoutFeedback>
              )}
              <ScrollView
                style={{
                  height: isLittleDev() ? "100%" : "94%",
                  width: "100%",
                  backgroundColor: "#fff",
                  borderTopRightRadius: isLittleDev() ? 15 : 30,
                  borderTopLeftRadius: isLittleDev() ? 15 : 30,
                  shadowRadius: 10,
                  shadowOpacity: 0.5,
                  paddingHorizontal: 10,
                  paddingTop: 20,
                }}
                contentContainerStyle={{
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <View style={{ width: "100%", alignItems: "flex-end" }}>
                  <TouchableOpacity onPress={onModalDismiss}>
                    <Text style={{ fontWeight: "bold", color: colors.blue }}>
                      {t("close")}
                    </Text>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      width: "70%",
                      textAlign: "center",
                      alignSelf: "center",
                      fontWeight: "400",
                      fontSize: "1.2rem",
                    }}
                  >
                    {t("avb_stock")}
                  </Text>
                  {/* <TouchableOpacity
                    style={{
                      width: "90%",
                      textAlign: "left",
                      alignSelf: "center",
                      fontWeight: "600",
                      fontSize: "0.8rem",
                      color: colors.blue,
                    }}
                  >
                    <Text style={{ color: "white" }}>{t("order_by")}</Text>
                  </TouchableOpacity> */}
                  <View
                    style={{
                      height: "100%",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div className="scroll-div-mobile">
                      <View
                        style={{
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                          borderBottomColor: colors.blue,
                          borderBottomWidth: 1.5,
                          paddingTop: 5,
                          paddingBottom: 10,
                        }}
                      >
                        <View>
                          <Text style={style.textBold}>
                            {t("stock")} n° {lotto.id}
                          </Text>
                          <View style={style.containerView}>
                            <Text
                              style={{
                                textAlign: "center",
                                fontWeight: "700",
                                width: "50%",
                                fontSize: "0.6rem",
                              }}
                            >
                              {t("average_price")}
                            </Text>
                            <Text style={style.textNotBold}>
                              {parseInt(lotto.prezzo / lotto.quantita)} €
                            </Text>
                          </View>
                        </View>
                        <View>
                          <View style={{ flexDirection: "row" }}>
                            <Text style={style.textBold}>Qty:</Text>
                            <Text style={style.textNotBold}>
                              {lotto.quantita}
                            </Text>
                          </View>
                          <View style={style.containerView}>
                            <Text style={style.textBold}>{t("price")}</Text>
                            <Text style={style.textNotBold}>
                              {lotto.prezzo}€
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          backgroundColor: "#f5f5f5",
                          marginTop: 2,
                          borderRadius: 12,
                          width: "100%",
                          padding: 6,
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          flexWrap: "wrap",
                        }}
                      >
                        {taglie?.map((t) => (
                          <View
                            style={{
                              borderWidth: 0.5,
                              borderColor: "black",
                              width: "30%",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              paddingVertical: 2,
                              paddingHorizontal: "2%",
                              borderRadius: 7,
                              backgroundColor: "white",
                              margin: 5,
                            }}
                          >
                            <View
                              style={{
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Text>US</Text>
                              <Text>{t.taglia}</Text>
                            </View>
                            <View
                              style={{
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Text>Qty</Text>
                              <Text>{t.numero}</Text>
                            </View>
                            <View
                              style={{
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Text>€</Text>
                              <Text>{t.prezzo}</Text>
                            </View>
                          </View>
                        ))}
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          width: "100%",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <View style={{ flexDirection: "row", width: "100%" }}>
                          <Text style={style.textBold}>
                            {t("shipping_fee")}
                          </Text>
                          <Text style={style.textNotBold}>
                            {calcFeeSped(lotto)}€
                          </Text>
                        </View>
                        <View style={style.containerView}>
                          <Text style={style.textBold}>
                            {t("transaction_fees")}
                          </Text>

                          <Text style={style.textNotBold}>
                            {calcWKfee(lotto)}€
                          </Text>
                        </View>
                        <View style={style.containerView}>
                          <Text style={style.textBold}>
                            {" "}
                            {!lotto.ivaMargine ? (
                              t("Iva") + "22%: "
                            ) : (
                              <>{t("marginal_vat_checked") + ":"}</>
                            )}
                          </Text>
                          <Text style={style.textNotBold}>
                            {calcIva(lotto)}€
                          </Text>
                        </View>
                        <View style={style.containerView}>
                          <Text style={style.textBold}>{t("total_price")}</Text>
                          <Text style={style.textNotBold}>
                            {totale(lotto)}€
                          </Text>
                        </View>
                      </View>
                    </div>
                    <View style={{ display: "grid" }}></View>
                  </View>
                  <View style={{ height: isLittleDev() ? 20 : 30 }} />
                </View>
              </ScrollView>
            </View>
          </Modal>
        </>
      )}
    </>
  );
};

const style = StyleSheet.create({
  containerView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  textBold: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  textNotBold: {
    fontSize: "1rem",
    marginLeft: 5,
  },
});

export default ModalVisualizzatore;
