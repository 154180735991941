import React from "react";

export default function SliderFiltroTaglie({ setFiltro, filtro }) {
  return (
    <div className="div-button-taglie">
      <button
        className={filtro === "US" ? "button-taglie-selected" : "button-taglie"}
        onClick={() => setFiltro("US")}
      >
        US
      </button>
      <button
        className={filtro === "EU" ? "button-taglie-selected" : "button-taglie"}
        onClick={() => setFiltro("EU")}
      >
        EU
      </button>
      <button
        className={filtro === "UK" ? "button-taglie-selected" : "button-taglie"}
        onClick={() => setFiltro("UK")}
      >
        UK
      </button>
      <button
        className={
          filtro === "WMNS" ? "button-taglie-selected" : "button-taglie"
        }
        onClick={() => setFiltro("WMNS")}
      >
        WMNS
      </button>
    </div>
  );
}
