import React, { useRef, useEffect } from "react";

const useHorizontalScroll = () => {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        // console.log(window.innerWidth+el.scrollLeft+"  "+el.scrollWidth+" "+e.deltaY+" "+window.innerWidth)
        if (e.deltaY == 0) return;
        if (
          window.innerWidth + el.scrollLeft > el.scrollWidth ||
          (el.scrollLeft == 0 && e.deltaY < 0)
        ) {
          if (e.deltaY < 0) {
            if (el.scrollLeft == 0) {
              return;
            } else {
              e.preventDefault();
              el.scrollTo({
                left: el.scrollLeft + e.deltaY,
              });
            }
          } else {
            return;
          }
        } else {
          e.preventDefault();
          el.scrollTo({
            left: el.scrollLeft + e.deltaY,
          });
        }
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
};

export default useHorizontalScroll;
