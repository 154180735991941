export const lottoDefault = {
  "3,5": {
    taglia: "3,5",
    quantita: 0,
    prezzo: 0,
  },
  4: {
    taglia: "4",
    quantita: 0,
    prezzo: 0,
  },
  "4,5": {
    taglia: "4,5",
    quantita: 0,
    prezzo: 0,
  },
  5: {
    taglia: "5",
    quantita: 0,
    prezzo: 0,
  },
  "5,5": {
    taglia: "5,5",
    quantita: 0,
    prezzo: 0,
  },
  6: {
    taglia: "6",
    quantita: 0,
    prezzo: 0,
  },
  "6,5": {
    taglia: "6,5",
    quantita: 0,
    prezzo: 0,
  },
  7: {
    taglia: "7",
    quantita: 0,
    prezzo: 0,
  },
  "7,5": {
    taglia: "7,5",
    quantita: 0,
    prezzo: 0,
  },
  8: {
    taglia: "8",
    quantita: 0,
    prezzo: 0,
  },
  "8,5": {
    taglia: "8,5",
    quantita: 0,
    prezzo: 0,
  },
  9: {
    taglia: "9",
    quantita: 0,
    prezzo: 0,
  },
  "9,5": {
    taglia: "9,5",
    quantita: 0,
    prezzo: 0,
  },
  10: {
    taglia: "10",
    quantita: 0,
    prezzo: 0,
  },
  "10,5": {
    taglia: "10,5",
    quantita: 0,
    prezzo: 0,
  },
  11: {
    taglia: "11",
    quantita: 0,
    prezzo: 0,
  },
  "11,5": {
    taglia: "11,5",
    quantita: 0,
    prezzo: 0,
  },
  12: {
    taglia: "12",
    quantita: 0,
    prezzo: 0,
  },
  "12,5": {
    taglia: "12,5",
    quantita: 0,
    prezzo: 0,
  },
  13: {
    taglia: "13",
    quantita: 0,
    prezzo: 0,
  },
  "13,5": {
    taglia: "13,5",
    quantita: 0,
    prezzo: 0,
  },
  14: {
    taglia: "14",
    quantita: 0,
    prezzo: 0,
  },
  "14,5": {
    taglia: "14,5",
    quantita: 0,
    prezzo: 0,
  },
  15: {
    taglia: "15",
    quantita: 0,
    prezzo: 0,
  },
  
};
export const lottoVettore = [
  {
    taglia: "3,5",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "4",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "4,5",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "5",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "5,5",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "6",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "6,5",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "7",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "7,5",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "8",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "8,5",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "9",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "9,5",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "10",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "10,5",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "11",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "11,5",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "12",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "12,5",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "13",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "13,5",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "14",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "14,5",
    quantita: 0,
    prezzo: 0,
  },
  {
    taglia: "15",
    quantita: 0,
    prezzo: 0,
  },
 
  
];
export const TAGLIE = {
  US: [
    "3,5",
    "4",
    "4,5",
    "5",
    "5,5",
    "6",
    "6,5",
    "7",
    "7,5",
    "8",
    "8,5",
    "9",
    "9,5",
    "10",
    "10,5",
    "11",
    "11.5",
    "12",
    "12,5",
    "13",
    "13,5",
    "14",
    "14,5",
    "15",
  ],

  EU: [
    "35.5",
    "36",
    "36.5",
    "37.5",
    "38",
    "38.5",
    "39",
    "40",
    "40.5",
    "41",
    "42",
    "42.5",
    "43",
    "44",
    "44.5",
    "45",
    "45.5",
    "46",
    "47",
    "47.5",
    "48",
    "48,5",
    "49",
    "49,5",
  ],
  UK: [
    "3",
    "3.5",
    "4",
    "4.5",
    "5",
    "5.5",
    "6",
    "6.5",
    "7",
    "7.5",
    "8",
    "8.5",
    "9",
    "9.5",
    "10",
    "10.5",
    "11",
    "11.5",
    "12",
    "12.5",
    "13",
    "13.5",
    "14",
    "14,5",
  ],
  WMNS: [
    "5",
    "5,5",
    "6",
    "6,5",
    "7",
    "7,5",
    "8",
    "8,5",
    "9",
    "9,5",
    "10",
    "10,5",
    "11",
    "11,5",
    "12",
    "12,5",
    "13",
    "13,5",
    "14",
    "14,5",
    "15",
    "15,5",
    "16",
    "16,5",
  ],
};
