import React, { Component, useState, useEffect, useReducer } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Image,
  ScrollView,
} from "react-native";
import HeaderMobile from "../components/HeaderMobile";
import { colors } from "../components/utils/constants";
import Menu from "../home/Menu";
import * as api from "../api/service";
import "./Sell.css";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import {
  lottoDefault,
  lottoVettore,
} from "../../components/utils/defaultObject";
import axios from "axios";
import CONSTANTS from "../../CONSTANTS";
import { useLocation, useNavigate } from "react-router-dom";

class Sell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSelector: 0,
      prodotti: [],
      prodottiInit: [],
      prodToList: {},
      singleProd: {},
      state: {},
      nazione: localStorage.getItem(CONSTANTS.NAZIONE),
    };
  }
  onClickBack = () => {
    this.setState({ pageSelector: this.state.pageSelector - 1 });
  };
  onClickContinua = () => {
    this.setState({ pageSelector: this.state.pageSelector + 1 });
  };
  onClickContinuaThree = (item) => {
    console.log(item);
    const itemTotal = item.total - (item.fees.wk + item.fees.ship);
    console.log(itemTotal);
    if (itemTotal > 0) {
      this.setState({ state: item, pageSelector: this.state.pageSelector + 1 });
    } else {
      alert("Stock inserted is not valid");
    }
  };
  onClickContinuaFive = (item) => {
    console.log(item);
    this.addLotto(item);
    this.setState({ pageSelector: this.state.pageSelector + 1 });
  };
  onClickContinuaSix = (item) => {
    this.props.navigate("/", { replace: true });
    this.setState({ pageSelector: this.state.pageSelector + 1 });
  };
  onClickAltroLotto = () => {
    this.setState({ pageSelector: 0 });
  };
  searchFunctionKeyUp = async (word) => {
    const searchProd = await api.searchProd(word);

    console.log(searchProd);

    this.setState({ prodottiInit: searchProd });
  };

  async componentDidMount() {
    const location = this.props.location;
    console.log("location", location);
    if (location.state.pos === 1) {
      this.setState({ pageSelector: 2, prodToList: location.state.prodotto });
    } else {
    }
    console.log("lotto appoggio: ", this.state.lottoAppoggio);
    const piuCercateCall = await api.piuCercate();

    this.setState({ prodottiInit: piuCercateCall });
    console.log("prodotti iniziali: ", this.state.prodottiInit);
  }
  addLotto = async (body) => {
    console.log("body", body);
    let token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);
    await axios
      .post(CONSTANTS.API_URL + "/taglia/", body, {
        HeaderMobiles: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("success");
        console.log(res);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  SellFirst = () => {
    const { t } = useTranslation();
    return (
      <View style={style.container}>
        <HeaderMobile context="Home" />
        {/* <Menu /> */}
        <View
          style={{
            width: "90%",
            height: "90%",
            paddingTop: 10,
          }}
        >
          <Text
            style={{
              fontWeight: "700",
              fontSize: "1.8rem",
              textAlign: "left",
            }}
          >
            {t("confirm_stock")}
          </Text>
          <Text
            style={{
              fontSize: "1.2rem",
              textAlign: "left",

              marginVertical: 10,
            }}
          >
            {t("conf_stock_1")}
          </Text>
          <View style={style.containerRule}>
            <Image
              source={require("../../assets/logo-check.png")}
              style={{
                width: "20%",
                height: 70,
                resizeMode: "contain",
                marginRight: 10,
              }}
            />
            <Text style={style.textRule}>{t("conf_stock_2")}</Text>
          </View>
          <View style={style.containerRule}>
            <Image
              source={require("../../assets/logo-check.png")}
              style={{
                width: "20%",
                height: 70,
                resizeMode: "contain",
                marginRight: 10,
              }}
            />
            <Text style={style.textRule}>{t("conf_stock_3")}</Text>
          </View>
          <View style={style.containerRule}>
            <Image
              source={require("../../assets/logo-check.png")}
              style={{
                width: "20%",
                height: 70,
                resizeMode: "contain",
                marginRight: 10,
              }}
            />
            <Text style={style.textRule}>{t("conf_stock_4")}</Text>
          </View>
          <View
            style={{
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              position: "absolute",
              bottom: "2%",
            }}
          >
            <TouchableOpacity
              onPress={this.onClickContinua}
              style={style.btnContinua}
            >
              <Text style={style.titleBtn}>{t("next")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };
  SellSecond = () => {
    const { t } = useTranslation();
    return (
      <View style={style.container}>
        <HeaderMobile context="Home" />
        {/* <Menu /> */}
        <View
          style={{
            width: "90%",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <div onClick={this.onClickBack}>Indietro</div> */}
            <p style={{ width: "100%", fontWeight: "bold" }}>
              {t("new_stock")}
            </p>

            <TextInput
              placeholder={t("search_sell")}
              onKeyUp={(e) => this.searchFunctionKeyUp(e.target.value)}
              style={style.inputCerca}
            />
          </View>
        </View>
        <View style={{ width: "100%", paddingHorizontal: "5%", height: "70%" }}>
          <div
            style={{
              overflowY: "scroll",
              height: "90%",
              overflowX: "hidden",
              paddingRight: "2%",
              paddingLeft: "2%",
            }}
          >
            {this.state.prodottiInit.map((p) => (
              <TouchableOpacity
                onPress={async () => {
                  console.log(p);
                  this.setState({
                    prodToList: p,
                  });
                  const getProd = await api.singleProd(p.id);
                  this.setState({ singleProd: getProd });

                  this.setState({ pageSelector: this.state.pageSelector + 1 });
                }}
                style={{
                  flexDirection: "row",
                  padding: 10,
                  backgroundColor: "#f5f5f5",
                  marginVertical: 10,
                  borderRadius: 10,
                }}
              >
                <View>
                  <Image
                    source={{
                      uri: p.imageProdotto,
                    }}
                    style={{
                      width: 120,
                      height: 70,
                      resizeMode: "contain",
                      borderRadius: 7,
                      backgroundColor: "white",
                    }}
                  />
                </View>
                <View style={{ paddingHorizontal: 10, width: "60%" }}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      width: "80%",
                    }}
                  >
                    {p.nome}
                  </Text>
                  <Text>{p.colore}</Text>
                </View>
              </TouchableOpacity>
            ))}
          </div>
        </View>
        {/* <View
          style={{
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            position: "absolute",
            bottom: "2%",
          }}
        >
          <TouchableOpacity
            onPress={this.onClickContinua}
            style={style.btnCrea}
          >
            <Text style={style.titleBtn}>{t("create_stock")}</Text>
          </TouchableOpacity>
        </View> */}
      </View>
    );
  };

  SellThird = () => {
    const { t } = useTranslation();
    console.log(this.state.prodToList, "single Prod:", this.state.singleProd);
    const [lotti, setlotti] = useState(lottoVettore);
    const [lottoAppoggio, setlottoAppoggio] = useState(lottoDefault);
    const [totale, settotale] = useState(0);
    const [shipfee, setshipfee] = useState(0);
    const [qty, setqty] = useState(0);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [wkfee, setwkfee] = useState(0);

    const clearForm = () => {
      setlotti(lottoVettore);
      setlottoAppoggio(lottoDefault);
      console.log("LOTTO DEFAULT VETTORE", lottoVettore);
      console.log("LOTTO DEFAULT OGGETTO", lottoDefault);
    };

    const updateValueArray = (taglia, value, field) => {
      let array = [...lotti];
      let index = array.findIndex((a) => a.taglia === taglia);
      let item = { ...array[index] };
      item[field] = Number.parseFloat(isNaN(value) ? 0 : value ? value : 0);
      array[index] = item;
      setlotti(array);
    };

    function calcPrezSpedizione() {
      let sum = 0;
      for (let i = 0; i < lotti.length; i++) {
        sum += lotti[i].quantita;
      }
      const module = sum % 8;
      let division = sum / 8;
      division = Math.round(division - 0.5);
      console.log(division);
      if (module > 0) {
        return (division + 1) * CONSTANTS.PRICESPEDIZIONE;
      } else {
        return division * CONSTANTS.PRICESPEDIZIONE;
      }
    }

    function sumAllPricesLotto() {
      let sum = 0;
      for (let i = 0; i < lotti.length; i++) {
        sum += lotti[i].prezzo * lotti[i].quantita;
      }
      return sum;
    }

    function sumAllQtyLotto() {
      let sum = 0;
      for (let i = 0; i < lotti.length; i++) {
        sum += lotti[i].quantita;
      }
      return sum;
    }
    function updateAllPrices(price) {
      let array = [...lotti];
      let lottoApp = lottoAppoggio;
      for (let i = 0; i < lotti.length; i++) {
        let item = { ...array[i] };
        item.prezzo = price;
        array[i] = item;
        lottoApp[array[i].taglia].prezzo = price;
      }
      setlotti(array);
      setlottoAppoggio(lottoApp);
      console.log(lotti[0].prezzo);
      forceUpdate();
    }

    function calcoloIvaNew(tot) {
      return this.state.nazione === "Italy" ? tot * 0.22 : 0;
    }

    useEffect(() => {
      console.log(lotti);
      const somma = sumAllPricesLotto();
      const estShip = calcPrezSpedizione();
      const fee = somma * 0.025;
      //const fee = 0;
      const qty = sumAllQtyLotto();
      settotale(somma);
      setshipfee(estShip);
      setwkfee(fee);
      setqty(qty);
    }, [lottoAppoggio]);

    return (
      <View style={style.container}>
        <HeaderMobile context="Home" />
        {/* <Menu /> */}
        <View
          style={{
            width: "90%",
            height: "75%",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <div onClick={this.onClickBack}>Indietro</div> */}
            <p style={{ fontWeight: "bold", width: "100%" }}>{t("add_size")}</p>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                marginBottom: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                source={{
                  uri: this.state.prodToList.imageProdotto,
                }}
                style={{
                  width: 120,
                  height: 70,
                  resizeMode: "contain",
                  borderRadius: 7,
                  backgroundColor: "white",
                }}
              />
              <View style={{ width: "60%" }}>
                <Text style={{ fontWeight: "bold" }}>
                  {this.state.prodToList.nome}
                </Text>
                <Text>{this.state.prodToList.modello}</Text>

                <Text>Qty: {qty}</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text>{t("choose_price")}</Text>
              <TextInput
                onChange={(e) => updateAllPrices(e.target.value)}
                style={{ borderWidth: 1, borderColor: "black", padding: 5 }}
                placeholder={t("average_price")}
              />
            </View>
            <ScrollView style={{ height: "100%", paddingBottom: "60%" }}>
              {lotti.map((lotto) => (
                <View
                  key={lotto.taglia}
                  style={{
                    width: "100%",
                    backgroundColor: "#f5f5f5",
                    borderRadius: 7,
                    padding: 10,
                    marginVertical: 5,
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: 7,
                      padding: 5,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        backgroundColor: "white",

                        borderRadius: 7,
                      }}
                    >
                      <View style={{ width: "50%", float: "left" }}>
                        <Text style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                          US {lotto.taglia}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "50%",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {/* <TouchableOpacity
                          style={{ marginRight: 10, fontSize: "1.3rem" }}
                        >
                          <Text style={{ fontSize: "1.3rem" }}>+</Text>
                        </TouchableOpacity> */}
                        <TextInput
                          style={{
                            width: "30%",
                            fontSize: "1.3rem",
                            borderWidth: 1,
                            borderColor: "c4c4c4",
                            textAlign: "center",
                          }}
                          onChange={(e) => {
                            setlottoAppoggio({
                              ...lottoAppoggio,
                              [lotto.taglia]: {
                                ...[lotto.taglia],
                                quantita: e.target.value,
                              },
                            });

                            updateValueArray(
                              lotto.taglia,
                              e.target.value,
                              "quantita"
                            );
                          }}
                        />
                        {/* <TouchableOpacity style={{ marginLeft: 10 }}>
                          <Text style={{ fontSize: "1.3rem" }}>-</Text>
                        </TouchableOpacity> */}
                      </View>
                    </View>
                    <View style={{ marginTop: 10 }}>
                      <input
                        placeholder={t("price")}
                        style={{
                          width: "100%",
                          fontSize: "1.1rem",
                          paddingVertical: 5,
                          borderWidth: 1,
                          borderColor: "c4c4c4",
                          textAlign: "left",
                          paddingLeft: 10,
                        }}
                        min={"0"}
                        value={lotto.prezzo === "NaN" ? 0 : lotto.prezzo}
                        onChange={(e) => {
                          setlottoAppoggio({
                            ...lottoAppoggio,
                            [lotto.taglia]: {
                              ...[lotto.taglia],
                              prezzo:
                                e.target.value.length === 0
                                  ? 0
                                  : e.target.value,
                            },
                          });
                          updateValueArray(
                            lotto.taglia,
                            e.target.value,
                            "prezzo"
                          );
                        }}
                      />
                      {console.log(lotto.prezzo)}
                    </View>
                  </View>
                </View>
              ))}
            </ScrollView>
          </View>
          <TouchableOpacity
            onPress={() =>
              this.onClickContinuaThree({
                lotti: lotti.filter((t) => t.prezzo > 0 && t.quantita > 0),
                lottoAppoggio: lottoAppoggio,
                total: totale,
                fees: { wk: wkfee, ship: shipfee },
                qty: qty,
                payout: parseFloat(
                  totale -
                    (wkfee + shipfee) +
                    calcoloIvaNew(totale - (wkfee + shipfee))
                )
                  .toFixed(2)
                  .toString(),
                accountId: localStorage.getItem(CONSTANTS.ID),
                prodottoId: this.state.prodToList.id,
              })
            }
            style={{
              width: "90%",
              height: 40,
              backgroundColor: "#0a09a2",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 7,
              marginBottom: "5%",
              position: "fixed",
              bottom: "15%",
            }}
          >
            <Text style={style.titleBtn}>{t("next")}</Text>
          </TouchableOpacity>
        </View>
        <View
          style={{
            width: "100%",
            justifyContent: "space-between",
            flexDirection: "row",
            backgroundColor: "#f5f5f5",
            position: "fixed",
            borderWidth: 0.2,
            borderColor: "#a0a0a0",
            shadowRadius: 4,
            shadowOpacity: 0.2,
            bottom: 0,
            height: "15%",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <View
            style={{
              height: "85%",
              paddingTop: "5%",
              paddingLeft: "5%",
              justifyContent: "space-between",
            }}
          >
            <View>
              <Text
                style={{
                  color: "#313131",
                  fontWeight: "bold",
                  fontSize: "0.7rem",
                }}
              >
                Transaction fee (2,5%)
              </Text>
              <Text style={{ color: "#313131" }}>-{wkfee.toFixed(2)} €</Text>
            </View>
            <View>
              <Text
                style={{
                  color: "#313131",
                  fontWeight: "bold",
                  fontSize: "0.7rem",
                }}
              >
                Estimated Shipping Fee
              </Text>
              <Text style={{ color: "#313131", fontSize: "0.7rem" }}>
                -{shipfee.toFixed(2)} €
              </Text>
            </View>
          </View>

          <View
            style={{
              height: "85%",
              paddingTop: "5%",
              paddingRight: "5%",
              justifyContent: "space-between",
            }}
          >
            <View>
              <Text
                style={{
                  color: "#313131",
                  fontWeight: "bold",
                  fontSize: "0.7rem",
                }}
              >
                Total
              </Text>
              <Text style={{ color: "#313131", fontSize: "0.7rem" }}>
                {totale.toFixed(2)} €
              </Text>
            </View>
            <View>
              <Text
                style={{
                  color: "#313131",
                  fontWeight: "bold",
                  fontSize: "0.7rem",
                }}
              >
                Total Payout
              </Text>
              <Text style={{ color: "#313131", fontSize: "0.7rem" }}>
                {(totale - (shipfee + wkfee)).toFixed(2)} €
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  SellFour = () => {
    const { t } = useTranslation();
    return (
      <View style={style.container}>
        <HeaderMobile context="Home" />
        {/* <Menu /> */}
        <View
          style={{
            width: "90%",
            height: "90%",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <div onClick={this.onClickBack}>Indietro</div> */}
            <p style={{ fontWeight: "bold", width: "100%" }}>{t("add_size")}</p>
            <View
              style={{ width: "100%", flexDirection: "row", marginBottom: 10 }}
            >
              <Image
                source={{
                  uri: this.state.prodToList.imageProdotto,
                }}
                style={{
                  width: 120,
                  height: 70,
                  resizeMode: "contain",
                  borderRadius: 7,
                  backgroundColor: "white",
                }}
              />
              <View style={{ width: "60%" }}>
                <Text style={{ fontWeight: "bold" }}>
                  {this.state.prodToList.nome}
                </Text>
                <Text>{this.state.prodToList.modello}</Text>

                <Text>Qty: {this.state.state.qty}</Text>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                marginBottom: 10,
                paddingBottom: 5,
                borderBottomColor: "#c4c4c4",
                borderBottomWidth: 1,
              }}
            >
              <View style={{ width: "50%" }}></View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontWeight: "bold", fontSize: "0.7rem" }}>
                  {t("tot_pay")}:{" "}
                  {(
                    this.state.state.total -
                    (this.state.state.fees.wk + this.state.state.fees.ship)
                  ).toFixed(2)}{" "}
                  €
                </Text>
                <Text></Text>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                backgroundColor: "#f5f5f5",
                borderRadius: 7,
                padding: 10,
              }}
            >
              <Text style={{ marginBottom: 10, fontWeight: "bold" }}>
                {t("size")}
              </Text>
              <View
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  borderRadius: 7,
                  padding: 5,
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {this.state.state.lotti.map((lotto) => (
                  <View
                    key={lotto.taglia}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      paddingVertical: 5,
                      borderRadius: 7,
                      borderWidth: 1,
                      paddingHorizontal: 10,
                      backgroundColor: "white",
                      width: "30%",
                      margin: 5,
                    }}
                  >
                    <Text>US {lotto.taglia}</Text>
                    <Text style={{ color: "#0A09A2", fontWeight: "bold" }}>
                      Qty {lotto.quantita}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
          <View
            style={{
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              position: "absolute",
              bottom: "2%",
            }}
          >
            <TouchableOpacity
              onPress={this.onClickContinua}
              style={style.btnCrea}
            >
              <Text style={style.titleBtn}>{t("create_stock")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  SellFive = () => {
    const { t } = useTranslation();
    return (
      <View style={style.container}>
        <HeaderMobile context="Home" />
        {/* <Menu /> */}
        <View
          style={{
            width: "90%",
            height: "90%",
            paddingTop: 10,
          }}
        >
          <Text
            style={{
              fontWeight: "700",
              fontSize: "1.4rem",
              textAlign: "left",
            }}
          >
            {t("stock_listed")}
          </Text>
          <View style={style.containerRule}>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: 20,
              }}
            >
              <Image
                source={{
                  uri: this.state.prodToList.imageProdotto,
                }}
                style={{
                  width: 200,
                  height: 140,
                  resizeMode: "contain",
                  borderRadius: 7,
                  backgroundColor: "white",
                }}
              />
              <Text
                style={{ fontSize: "1.4rem", fontWeight: "bold", width: "80%" }}
              >
                {this.state.prodToList.nome}
              </Text>
              <Text style={{ fontSize: "1.3rem", width: "80%" }}>
                {this.state.prodToList.modello}
              </Text>
              <View style={style.containerLottoListato}>
                <Text style={{ fontWeight: "bold", paddingHorizontal: 5 }}>
                  {this.state.prodToList.name}
                </Text>
                <Text style={{ marginLeft: 5 }}>
                  Qty {this.state.state.qty}
                </Text>

                <View
                  style={{
                    width: "100%",
                    paddingHorizontal: 5,
                    marginTop: 5,
                  }}
                >
                  <Text>{this.state.prodToList.modello}</Text>
                </View>

                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingHorizontal: 5,
                    paddingVertical: 10,
                    marginTop: 20,
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        width: "45%",
                      }}
                    >
                      {t("average_price")}
                    </Text>
                    <Text style={{ fontSize: "0.7rem" }}>
                      €{" "}
                      {(this.state.state.total / this.state.state.qty).toFixed(
                        2
                      )}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        width: "45%",
                        fontSize: "0.7rem",
                      }}
                    >
                      Total Payout
                    </Text>
                    <Text style={{ fontSize: "0.7rem" }}>
                      €{" "}
                      {(
                        this.state.state.total -
                        (this.state.state.fees.wk + this.state.state.fees.ship)
                      ).toFixed(2)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              position: "absolute",
              bottom: "2%",
            }}
          >
            <TouchableOpacity
              onPress={() => this.onClickContinuaFive(this.state.state)}
              style={style.btnContinua}
            >
              <Text style={style.titleBtn}>{t("confirm")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  SellSix = () => {
    const { t } = useTranslation();
    return (
      <View style={style.container}>
        <HeaderMobile context="Home" />
        {/* <Menu /> */}
        <View
          style={{
            width: "90%",
            height: "88%",
            paddingTop: 10,
          }}
        >
          <Text
            style={{
              fontWeight: "700",
              fontSize: "1.4rem",
              textAlign: "left",
            }}
          >
            {t("listed_stock")}
          </Text>
          <span>
            {t("conf_purch_1")} <br /> {t("conf_purch_2")} <br />{" "}
            {t("conf_purch_3")}
            . <br />
            {t("conf_purch_4")}
            {t("conf_purch_5")}.
          </span>

          <View
            style={{
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              position: "absolute",
              bottom: "2%",
            }}
          >
            <TouchableOpacity
              onPress={this.onClickContinuaSix}
              style={style.btnContinua}
            >
              <Text style={style.btnText}>{t("confirm")}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={this.onClickContinua}
              style={style.btnAltroLotto}
            >
              <Text style={style.btnTextAltro}>{t("list_another")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };
  render() {
    return (
      <div className="Container-Common">
        {this.state.pageSelector === 0 && <this.SellFirst />}
        {this.state.pageSelector === 1 && <this.SellSecond />}
        {this.state.pageSelector === 2 && <this.SellThird />}
        {this.state.pageSelector === 3 && <this.SellFour />}
        {this.state.pageSelector === 4 && <this.SellFive />}
        {this.state.pageSelector === 5 && <this.SellSix />}
      </div>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  board: {
    height: 160,
    width: "100%",
    backgroundColor: colors.secondary,
    padding: 30,
  },
  containerRule: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "8%",
    width: "90%",
    marginHorizontal: "5%",
  },
  imageRule: {
    width: 60,
    height: 60,
    backgroundColor: "#e4e4e4",
    marginRight: 10,
  },
  textRule: {
    fontSize: "1.1rem",
  },
  btnContinua: {
    backgroundColor: "#0A09A2",
    marginTop: "1%",
    width: "100%",
    padding: 10,
    textAlign: "center",
    borderRadius: 4,
  },

  btnText: {
    color: "#fff",
    fontSize: "1.1rem",
    fontWeight: "bold",
  },

  inputCerca: {
    borderWidth: 1,
    borderColor: "#f5f5f5",
    width: "100%",
    height: 30,
    marginTop: 10,
    paddingHorizontal: 15,
    paddingVertical: 20,
  },

  btnCrea: {
    width: "90%",
    height: 40,
    backgroundColor: "#0a09a2",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 7,
    marginBottom: "5%",
    position: "absolute",
  },
  titleBtn: {
    color: "white",
    fontSize: 20,
  },
  btnAltroLotto: {
    backgroundColor: "transparent",
    marginTop: "1%",
    width: "100%",
    padding: 10,
    textAlign: "center",
    borderRadius: 4,
  },

  btnTextAltro: {
    color: "#0a09a2",
    fontSize: 15,
    fontWeight: 600,
  },
  containerLottoListato: {
    width: "100%",
    backgroundColor: "#f5f5f5",
    padding: 5,
    borderRadius: 7,
    marginTop: 20,
  },
});

export default function (props) {
  const t = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Sell {...props} translation={t} location={location} navigate={navigate} />
  );
}
