import React, { Component } from "react";
import Header from "../components/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CONSTANTS from "../CONSTANTS";
import Filters from "../components/Filters";
import { DataArraySharp } from "@mui/icons-material";
import { Modal, Accordion, Tooltip, OverlayTrigger } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import logo from "../assets/wk-logo.png";
import { useTranslation, withTranslation } from "react-i18next";
import PageItem from "react-bootstrap/PageItem";
import { isEmpty, map, set } from "lodash";
import Footer from "../components/Footer";
import Loading from "../components/Loading";

class ShopAllNoReplace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      listaProdotti: [],
      ListaProdottiDaFiltrare: [],
      listaPiuCercati: [],
      PagFilter: [],
      prezzo1: 0,
      prezzo2: 0,
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
      listaMarca: [],
      filtri: { prezzo: "0", marca: [] },
      array: [],
      modalShow: false,
      statoProd: 1,
      count: 2,
      countList: 2,
      numberElem: 2,
      Prezzo: 0,
      Marche: 0,
      limit: 0,
      offset: 0,
      elementPerPage: 30,
      page: 1,
      utente: "",
      totalPage: [],
      countFilter: [],
      timing: false,
      loading: false,
    };
    this.prezzoDispatcher = {
      0: { prezzo1: 0, prezzo2: 1000000 },
      1: { prezzo1: 0, prezzo2: 100 },
      2: { prezzo1: 100, prezzo2: 200 },
      3: { prezzo1: 200, prezzo2: 300 },
      4: { prezzo1: 300, prezzo2: 400 },
      5: { prezzo1: 400, prezzo2: 500 },
      6: { prezzo1: 500, prezzo2: 100000 },
    };
  }
  componentDidMount = async () => {
    this.setState({ loading: true }, () => {
      console.log("loading is true ");
    });
    const pagesCustom = Number.parseInt(this.props.params.page);
    await axios
      .get(CONSTANTS.API_URL + "/prodotto/rand/" + 0 + "/" + pagesCustom * 30)
      .then((prodottoRicevuto) => {
        this.setState({
          listaProdotti: prodottoRicevuto.data,
        });
      });

    await axios.get(CONSTANTS.API_URL + "/marca/").then((marca) => {
      this.setState({
        listaMarca: marca.data,
      });
    });
    await axios
      .get(CONSTANTS.API_URL + "/prodotto/random")
      .then((prodottoRicevuto) => {
        console.log(prodottoRicevuto);
        this.setState({
          PagFilter: prodottoRicevuto.data,
          ListaProdottiDaFiltrare: prodottoRicevuto.data,
        });
      });
    await axios.get(CONSTANTS.API_URL + "/prodotto/count-all").then((res) => {
      console.log("sono qui");

      const countPage = res.data.totalElement / this.state.elementPerPage;
      console.log("countPage", countPage);
      this.setState(
        {
          totalPage: Math.round(countPage + 0.49),
          page: pagesCustom,
        },
        () => {
          if (pagesCustom > this.state.totalPage) {
            this.setState(
              {
                page: this.state.totalPage,
              },
              () => {
                this.props.navigate("/shop-all/" + this.state.totalPage);
              },
            );
          }
        },
      );

      this.setState({ loading: false }, () => {
        console.log("loading is false ");
      });
    });
    console.log(this.state.totalPage.length);
  };

  upgradeClick = async (data) => {
    console.log(data);
    const body = {
      ProdottoId: data,
    };
    await axios
      .post(CONSTANTS.API_URL + "/piucercate/click", body)
      .then((res) => {
        console.log(res.data.successo);
        this.routeChange("registrazione/conferma-registrazione");
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  filtriFunction = async (prezzo, marche, utente, stato) => {
    console.log(stato);
    const indexOfLastTodo = 1 * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    console.log("indici", this.state.elementPerPage, indexOfFirstTodo);

    this.setState(
      {
        statoProd: stato,
        page: 1,
      },
      () => {
        this.props.navigate("/shop-all/" + 1);
      },
    );

    const body = {
      marche: marche,
      prezzo: this.prezzoDispatcher[prezzo],
      utente: utente,
      offset: 30,
      limit: 0,
    };
    console.log(body);
    await axios
      .post(CONSTANTS.API_URL + "/prodotto/filter", body)
      .then((res) => {
        console.log(res.data);
        const countPage = res.data.countFinalFilter / this.state.elementPerPage;
        this.setState({
          ListaProdottiDaFiltrarePage: res.data.prodottos,
          statoProd: 2,
          Prezzo: prezzo,
          Marche: marche,
          Utente: utente,
          countFilter: Array(parseInt(countPage) + 1),
        });
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  handlePageClickFilter = async (page) => {
    const indexOfLastTodo = page * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    console.log("indici", this.state.elementPerPage, indexOfFirstTodo);
    this.setState({
      offset: indexOfLastTodo,
      limit: indexOfFirstTodo,
    });
    const body = {
      marche: this.state.Marche,
      prezzo: this.prezzoDispatcher[this.state.Prezzo],
      utente: this.state.utente,
      offset: page * this.state.elementPerPage,
      limit: 0,
    };
    const prodotti = await axios.post(
      CONSTANTS.API_URL + "/prodotto/filter",
      body,
    );
    console.log("prodotti", prodotti);

    this.setState(
      {
        page: page,
        ListaProdottiDaFiltrarePage: prodotti.data.prodottos,
      },
      () => {
        this.props.navigate("/shop-all/" + page);
      },
    );
  };
  handlePageClick = async (page) => {
    const indexOfLastTodo = page * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    console.log("indici", this.state.elementPerPage, indexOfFirstTodo);

    const prodotti = await axios.get(
      CONSTANTS.API_URL +
        "/prodotto/rand/" +
        0 +
        "/" +
        page * this.state.elementPerPage,
    );
    console.log("prodotti", prodotti);

    this.setState(
      {
        page: page,
        listaProdotti: prodotti.data,
      },
      () => {
        this.props.navigate("/shop-all/" + page);
      },
    );
  };
  render() {
    const { t } = this.props.translation;
    return (
      <>
        {this.state.loading === true ? (
          <Loading />
        ) : (
          <div>
            <Header />

            <div className="container">
              <div className="filtri-container">
                <Filters
                  filterPrezzo={this.filtriFunction}
                  marca={this.state.listaMarca}
                  filtriAttuali={this.state.filtri}
                />
              </div>
              <div className="new-in-prodotti-wrapped-gridded">
                {this.state.statoProd === 2 &&
                this.state.ListaProdottiDaFiltrarePage
                  ? this.state.ListaProdottiDaFiltrarePage.map(
                      (prodotto, key) => {
                        const prezzo = prodotto.prezzo;
                        const prezzoFinale = parseFloat(prezzo).toFixed(2);
                        const finalPrice = prezzoFinale
                          .toString()
                          .replace(".", ",");
                        return (
                          <Link
                            className="product-gridded"
                            to={`/product/${prodotto.id}`}
                            onClick={() => this.upgradeClick(prodotto.id)}
                          >
                            <div key={key}>
                              <div
                              //  className="sneaker-cell"
                              >
                                {" "}
                                <img
                                  src={prodotto.imageProdotto}
                                  alt="scarpa immagine"
                                  className="product-gridded-image"
                                />
                                {finalPrice !== "NaN" ? (
                                  <div className="order-align-text">
                                    <span className="title-sn-homepage">
                                      {prodotto.nome}
                                    </span>
                                    <div
                                      className="sneaker-prezzo-lotto-div"
                                      style={{ flexDirection: "column" }}
                                    >
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "0.8rem",
                                        }}
                                      >
                                        Average price
                                      </p>
                                      <span className="prezzo-prod">
                                        € {finalPrice}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="order-align-text">
                                    <span className="title-sn-homepage">
                                      {prodotto.nome}{" "}
                                    </span>
                                    <p
                                      style={{
                                        margin: 0,
                                        fontSize: "0.8rem",
                                        alignSelf: "end",
                                      }}
                                    >
                                      {t("no_stock")}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Link>
                        );
                      },
                    )
                  : this.state.listaProdotti.map((prodotto, key) => {
                      const prezzo = prodotto.prezzo;
                      const prezzoFinale = parseFloat(prezzo).toFixed(2);
                      const finalPrice = prezzoFinale
                        .toString()
                        .replace(".", ",");
                      return (
                        <Link
                          className="product-gridded"
                          to={`/product/${prodotto.id}`}
                          onClick={() => this.upgradeClick(prodotto.id)}
                        >
                          <div key={key}>
                            <div
                            // className="sneaker-cell"
                            >
                              {" "}
                              <img
                                src={prodotto.imageProdotto}
                                alt="scarpa immagine"
                                className="product-gridded-image"
                              />
                              {finalPrice !== "NaN" ? (
                                <div className="order-align-text">
                                  <span className="title-sn-homepage">
                                    {prodotto.nome}
                                  </span>
                                  <div
                                    className="sneaker-prezzo-lotto-div"
                                    style={{ flexDirection: "column" }}
                                  >
                                    <p
                                      style={{ margin: 0, fontSize: "0.8rem" }}
                                    >
                                      Average price
                                    </p>
                                    <span className="prezzo-prod">
                                      € {finalPrice}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="order-align-text">
                                  <span className="title-sn-homepage">
                                    {prodotto.nome}{" "}
                                  </span>
                                  <p
                                    style={{
                                      margin: 0,
                                      fontSize: "0.8rem",
                                      alignSelf: "end",
                                    }}
                                  >
                                    {t("no_stock")}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </Link>
                      );
                    })}

                <Modal
                  centered
                  show={this.state.modalShow}
                  onHide={() => this.setState({ modalShow: false })}
                  dialogClassName="aggiungi-modello-modal"
                >
                  <div className="modal-header-text">
                    <p className="modello-title-modal">{t("no_stock")}</p>
                  </div>
                  <div className="modal-footer-button">
                    <button
                      className="chiudi-proposta"
                      onClick={() => this.setState({ modalShow: false })}
                    >
                      Close
                    </button>
                  </div>
                </Modal>
              </div>
              {this.state.statoProd === 2 ? (
                <Pagination>
                  {this.state.page > 1 && (
                    <Pagination.Prev
                      onClick={() =>
                        this.handlePageClickFilter(this.state.page - 1)
                      }
                    />
                  )}
                  {this.state.page > 1 && (
                    <Pagination.Item
                      onClick={() => this.handlePageClickFilter(1)}
                    >
                      1
                    </Pagination.Item>
                  )}
                  {this.state.page > 1 && <Pagination.Ellipsis />}
                  {this.state.page - 1 > 1 && (
                    <Pagination.Item
                      active={this.state.page === this.state.page - 1}
                      key={"_pagination_" + this.state.page - 1}
                      onClick={() =>
                        this.handlePageClickFilter(this.state.page - 1)
                      }
                    >
                      {this.state.page !== 1 ? this.state.page - 1 : null}
                    </Pagination.Item>
                  )}

                  <Pagination.Item
                    active={this.state.page === this.state.page}
                    key={"_pagination_" + this.state.page}
                    onClick={() => this.handlePageClickFilter(this.state.page)}
                  >
                    {this.state.page}
                  </Pagination.Item>
                  {this.state.page != this.state.countFilter.length && (
                    <Pagination.Item
                      active={this.state.page + 1 === this.state.page}
                      key={"_pagination_" + this.state.page + 1}
                      onClick={() =>
                        this.handlePageClickFilter(this.state.page + 1)
                      }
                    >
                      {this.state.page + 1}
                    </Pagination.Item>
                  )}
                  {this.state.page + 1 < this.state.countFilter.length && (
                    <Pagination.Ellipsis />
                  )}
                  {this.state.page + 1 < this.state.countFilter.length && (
                    <Pagination.Item
                      onClick={() =>
                        this.handlePageClickFilter(
                          this.state.countFilter.length,
                        )
                      }
                    >
                      {this.state.countFilter.length}
                    </Pagination.Item>
                  )}
                  {this.state.page < this.state.countFilter.length && (
                    <Pagination.Next
                      onClick={() =>
                        this.handlePageClickFilter(this.state.page + 1)
                      }
                      disabled={
                        this.state.page + 1 === this.state.countFilter.length ||
                        this.state.page === this.state.countFilter.length ||
                        this.state.page > this.state.countFilter.length
                      }
                    />
                  )}
                </Pagination>
              ) : (
                <Pagination>
                  {this.state.page > 1 && (
                    <Pagination.Prev
                      onClick={() => this.handlePageClick(this.state.page - 1)}
                    ></Pagination.Prev>
                  )}
                  {this.state.page > 1 && (
                    <Pagination.Item onClick={() => this.handlePageClick(1)}>
                      1
                    </Pagination.Item>
                  )}
                  {this.state.page > 1 && <Pagination.Ellipsis />}
                  {this.state.page - 1 > 1 && (
                    <Pagination.Item
                      active={this.state.page === this.state.page - 1}
                      key={"_pagination_" + this.state.page - 1}
                      onClick={() => this.handlePageClick(this.state.page - 1)}
                    >
                      {this.state.page !== 1 ? this.state.page - 1 : null}
                    </Pagination.Item>
                  )}
                  <Pagination.Item
                    active={this.state.page === this.state.page}
                    key={"_pagination_" + this.state.page}
                    onClick={() => this.handlePageClick(this.state.page)}
                  >
                    {this.state.page}
                  </Pagination.Item>
                  {this.state.page + 1 < this.state.totalPage && (
                    <Pagination.Item
                      key={"_pagination_" + this.state.page + 1}
                      onClick={() => this.handlePageClick(this.state.page + 1)}
                    >
                      {Number.parseInt(this.state.page) + 1}
                    </Pagination.Item>
                  )}
                  {this.state.page != this.state.totalPage && (
                    <>
                      {!(this.state.page + 1 == this.state.totalPage) && (
                        <Pagination.Ellipsis />
                      )}
                      <Pagination.Item
                        active={this.state.page + 1 === this.state.page}
                        key={"_pagination_" + this.state.page + 1}
                        onClick={() =>
                          this.handlePageClick(this.state.totalPage)
                        }
                      >
                        {this.state.totalPage}
                      </Pagination.Item>
                    </>
                  )}
                  {this.state.page < this.state.totalPage && (
                    <Pagination.Next
                      onClick={() => this.handlePageClick(this.state.page + 1)}
                    ></Pagination.Next>
                  )}
                </Pagination>
              )}
            </div>
            <Footer />
          </div>
        )}
      </>
    );
  }
}

export default function (props) {
  const params = useParams();
  const translation = useTranslation();
  const navigate = useNavigate();
  return (
    <ShopAllNoReplace
      {...props}
      params={params}
      translation={translation}
      navigate={navigate}
    />
  );
}
