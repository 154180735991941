import React, { Component } from "react";
import logo from "../../assets/wk-logo.png";
import { Link, useParams, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import "../../styles/NuovoLotto.css";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { sendMailNotification } from "../chiamateApi/ApiProdotto";

function ListaLottoFive() {
  const params = useParams();
  const location = useLocation();
  console.log("five", location.state);
  const { t } = useTranslation();
  console.log("locationSTATE****************", location.state);

  const addLotto = async (body) => {
    console.log("body", body);
    let token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);
    let id = localStorage.getItem(CONSTANTS.ID);
    await axios
      .post(CONSTANTS.API_URL + "/taglia/", body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (rest) => {
        console.log("rest", rest.data);
        await axios
          .get(
            CONSTANTS.API_URL +
              "/account/utente/" +
              localStorage.getItem(CONSTANTS.ID),
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  CONSTANTS.ACCESS_TOKEN_KEY,
                )}`,
              },
            },
          )
          .then(async (result) => {
            await axios
              .get(CONSTANTS.API_URL + "/prodotto/productById/" + params.id)
              .then(async (res) => {
                console.log("SONO QUI");

                const objMail = {
                  prodotto: res.data,
                  email: result.data.email,
                  idMailVendita: 15,
                };
                await sendMailNotification(objMail);
              });
          });
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  return (
    <>
      <div className="container-form-check">
        <div className="div-logo-form">
          <Link to="/">
            <img className="logo-login" src={logo} alt="logo" />
          </Link>
        </div>
        <div className="checkout-final-div">
          <p className="conferma-acquisto-lotto-p"> {t("confirm_stock")} </p>
          <span>{t("confirm_stock_span")}</span>
        </div>
        <div className="btn-footer-checkout-lista-lotto bottone-a-destra">
          <Link
            replace={true}
            to={"/lista-lotto-six/" + params.id}
            onClick={() => {
              addLotto(location.state);
            }}
          >
            {t("next")}
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default ListaLottoFive;
