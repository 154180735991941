import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import "../../styles/CheckoutStyles.css";
import logo from "../../assets/wk-logo.png";
import axios from "axios";
import CONSTANTS from "../../CONSTANTS";
import Footer from "../../components/Footer";
import LOGOUTFUNCTION from "../../LOGOUTFUNCTION";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";
import { View, Text, Image, StyleSheet, TouchableOpacity } from "react-native";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import scarpaVuota from "../../assets/wk_products_not_found.png";
import Switch from "@mui/material/Switch";

class CheckoutOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utente: localStorage.getItem(CONSTANTS.ID),
      partitaIva: localStorage.getItem(CONSTANTS.P_IVA),
      nazione: localStorage.getItem(CONSTANTS.NAZIONE),
      showModal: false,
      italy: false,
      showPropostaModal: false,
      lotto: {},
      taglie: [],
      prodotto: {},
      autoProvidedShipping: false,
      generalita: {
        indirizzo: "",
        cap: "",
        sedeLegale: "",
        paese: "",
        numeroTelefono: "",
      },
    };
  }
  componentDidMount = async () => {
    if (this.state.nazione) {
      console.log("Utente:", this.state.nazione.slice(0, 2).toString());
      this.state.nazione === "Italy"
        ? this.setState({ italy: true })
        : this.setState({ italy: false });
    } else {
      console.log(localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY, ""));
      window.sessionStorage.removeItem("accessToken");
      window.sessionStorage.removeItem("nama");
      localStorage.clear();
      window.location.href = "/";
      this.setState({ showModal: false });
    }

    console.log(this.props);
    await axios
      .get(CONSTANTS.API_URL + "/account/utente/" + this.state.utente, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        console.log("success");
        console.log(res.data);
        console.log("request");
        if (res.data.error) {
          LOGOUTFUNCTION();
        }
        this.setState({
          generalita: {
            indirizzo: res.data.indirizzo,
            cap: res.data.cap,
            sedeLegale: res.data.sedeLegale,
            paese: res.data.paese,
            numeroTelefono: res.data.numeroTelefono,
            civico: res.data.civico,
          },
        });
      });
    await axios
      .get(CONSTANTS.API_URL + "/lotto/lottoId/" + this.props.params.id)
      .then(async (res) => {
        this.setState({
          lotto: res.data,
        });
      });
    await axios
      .get(CONSTANTS.API_URL + "/taglia/" + this.props.params.id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY
          )}`,
        },
      })
      .then(async (res) => {
        if (res.data.error) {
          LOGOUTFUNCTION();
        }
        console.log(res.data);
        this.setState({
          taglie: res.data,
        });
      });
    console.log(this.props.params);
    await axios
      .get(
        CONSTANTS.API_URL + "/prodotto/productById/" + this.props.params.idProd
      )
      .then(async (res) => {
        console.log("res", res.data);
        this.setState({
          prodotto: res.data,
        });
        console.log(this.prodotto);
      });
  };
  calcPrezSpedizione(sum) {
    const module = sum % 6;
    let division = sum / 6;
    division = Math.round(division - 0.5);
    console.log(division);
    if (module > 0) {
      return (division + 1) * CONSTANTS.PRICESPEDIZIONE;
    } else {
      return division * CONSTANTS.PRICESPEDIZIONE;
    }
  }

  async updatePayInLotto() {
    const body = {
      id: this.state.lotto.id,
      payin: this.state.autoProvidedShipping
        ? parseFloat(
            this.state.lotto.prezzo +
              this.state.lotto.prezzo * 0.025 +
              this.calcoloIvaNew(
                this.state.lotto.prezzo + this.state.lotto.prezzo * 0.025
              )
          ).toFixed(2)
        : parseFloat(
            this.state.lotto.prezzo +
              this.state.lotto.prezzo * 0.025 +
              this.calcPrezSpedizione(this.state.lotto.quantita) +
              this.calcoloIvaNew(
                this.state.lotto.prezzo +
                  this.state.lotto.prezzo * 0.025 +
                  this.calcPrezSpedizione(this.state.lotto.quantita)
              )
          ).toFixed(2),
    };
    console.log("BODY", body);
    await axios
      .post(CONSTANTS.API_URL + "/lotto/updatePayIn", body)
      .then(async (res) => {
        console.log(res);
      });
  }
  calcoloIvaNew(tot) {
    return !this.state.lotto.ivaMargine
      ? this.state.nazione === "Italy"
        ? tot * 0.22
        : 0
      : 0;
  }
  calcoloIva() {
    console.log("IVA MARG: ", this.state.lotto.ivaMargine);
    //aggiungere poi per le fees this.state.lotto.prezzo * 0.05
    return (
      (this.state.lotto.prezzo * 0.025 +
        this.state.lotto.prezzo +
        this.calcPrezSpedizione(this.state.lotto.quantita) +
        0) *
      0.22
    );
  }
  MobileRender = () => {
    const { t } = this.props.translation;
    return (
      <>
        <HeaderMobile context="Home" />
        <View
          style={{
            width: "100%",
            paddingHorizontal: 10,
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            marginVertical: 4,
          }}
        >
          <Text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
            {t("prod_name")}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              aspectRatio: 1,
              width: "22%",
              resizeMode: "contain",
            }}
            src={this.state.prodotto.imageProdotto}
            onError={(e) => {
              e.target.src = scarpaVuota;
            }}
          />
          <View style={{ paddingTop: "10%", width: "50%", paddingLeft: "5%" }}>
            <Text style={{ fontSize: "1rem", fontWeight: "bold" }}>
              {this.state.prodotto.nome}
            </Text>
            <Text style={{ fontSize: "0.8rem", marginTop: 5 }}>
              {this.state.prodotto.modello}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            width: "90%",
            marginHorizontal: "5%",
            padding: 20,
            justifyContent: "flex-start",
            flexWrap: "wrap",
            backgroundColor: "#f5f5f5",
            borderRadius: 8,
            marginBottom: 2,
          }}
        >
          {this.state.taglie.map((taglia, key) => {
            if (taglia.numero > 0)
              return (
                <>
                  <div key={taglia.id} className="taglia-checkout-2">
                    <span>US {taglia.taglia}</span>
                    <span>
                      <b>
                        {t("qty")} {taglia.numero}
                      </b>
                    </span>
                  </div>
                </>
              );
          })}
        </View>
        <View
          style={{
            flexDirection: "row",
            width: "90%",
            marginHorizontal: "5%",
            padding: 20,
            paddingBottom: 2,
            justifyContent: "space-between",
            borderRadius: 8,
            marginBottom: 2,
          }}
        >
          <View>
            <Text style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
              {t("stock_n")} {this.state.lotto.id}
            </Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text
                style={{ fontSize: "0.9rem", fontWeight: "bold", width: "50%" }}
              >
                {t("total_price")}
              </Text>
              <Text style={{ fontSize: "1.2rem", fontWeight: "700" }}>
                €{this.state.lotto.prezzo}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <Text
              style={{ fontSize: "1.2rem", fontWeight: "bold", marginRight: 5 }}
            >
              {t("qty")}
            </Text>
            <Text
              style={{ fontSize: "1.1rem", paddingTop: 2, fontWeight: "700" }}
            >
              {this.state.lotto.quantita}
            </Text>
          </View>
        </View>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBottom: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              marginBottom: 20,
            }}
          >
            <span style={{ width: "fit-content", fontSize: "0.9rem" }}></span>
            <span style={{ width: "fit-content", fontSize: "0.9rem" }}>
              {t("transaction_fees")} (2,5%){" "}
              <b>
                {"+" +
                  (this.state.lotto.prezzo * 0.025)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",") +
                  " €"}
              </b>
            </span>
            {!this.state.autoProvidedShipping && (
              <span style={{ width: "fit-content", fontSize: "0.9rem" }}>
                {t("shipping_fee")} ({t("pair_of_shoes")})
                <b>
                  {" "}
                  {"+" +
                    this.calcPrezSpedizione(this.state.lotto.quantita)
                      .toFixed(2)
                      .toString()
                      .replace(".", ",") +
                    " €"}
                </b>
              </span>
            )}
            {this.state.italy && !this.state.lotto.ivaMargine ? (
              <span style={{ width: "fit-content", fontSize: "0.9rem" }}>
                V.A.T. 22%
                <b style={{ marginLeft: 4 }}>
                  {" "}
                  {"+" +
                    this.calcoloIva().toFixed(2).toString().replace(".", ",") +
                    " €"}
                </b>
              </span>
            ) : (
              <span style={{ width: "fit-content", fontSize: "0.9rem" }}>
                <>
                  {!this.state.lotto.ivaMargine
                    ? "V.A.T. 0%: 0€"
                    : t("marginal_vat_checked") + ": ✔"}
                </>{" "}
              </span>
            )}
            <span style={{ width: "fit-content", fontSize: "0.9rem" }}>
              {t("final_price")}{" "}
              {this.state.italy && !this.state.lotto.ivaMargine ? (
                <b>
                  {" "}
                  {this.state.autoProvidedShipping
                    ? (
                        this.state.lotto.prezzo +
                        this.state.lotto.prezzo * 0.025 /*0*/ +
                        this.calcoloIva()
                      )
                        .toFixed(2)
                        .toString()
                        .replace(".", ",") + " €"
                    : (
                        this.state.lotto.prezzo +
                        this.state.lotto.prezzo * 0.025 /*0*/ +
                        this.calcoloIva() +
                        this.calcPrezSpedizione(this.state.lotto.quantita)
                      )
                        .toFixed(2)
                        .toString()
                        .replace(".", ",") + " €"}
                </b>
              ) : (
                <b>
                  {" "}
                  {this.state.autoProvidedShipping
                    ? (
                        this.state.lotto.prezzo +
                        this.state.lotto.prezzo * 0.025
                      ) /*0*/
                        .toFixed(2)
                        .toString()
                        .replace(".", ",") + " €"
                    : (
                        this.state.lotto.prezzo +
                        this.state.lotto.prezzo * 0.025 /*0*/ +
                        this.calcPrezSpedizione(this.state.lotto.quantita)
                      )
                        .toFixed(2)
                        .toString()
                        .replace(".", ",") + " €"}
                </b>
              )}
            </span>
          </div>
          <View style={{ flexDirection: "row", marginBottom: 20 }}>
            <Switch
              checked={this.state.autoProvidedShipping}
              onChange={(e) =>
                this.setState({
                  autoProvidedShipping: !this.state.autoProvidedShipping,
                })
              }
              sx={{ float: "left" }}
              color="primary"
            />

            <span style={{ marginLeft: 5, marginTop: "2%" }}>
              {t("handle_shippment")}
            </span>
          </View>
          {this.state.italy ? (
            <Link
              onClick={() => this.updatePayInLotto()}
              to={
                "/checkout-second/" +
                this.props.params.id +
                "/" +
                this.props.params.idProd
              }
              className="btn-footer-checkout-mob"
              state={{
                payout: this.state.autoProvidedShipping
                  ? parseFloat(
                      this.state.lotto.prezzo +
                        this.calcoloIva() +
                        this.state.lotto.prezzo * 0.025 /*0*/
                    )
                      .toFixed(2)
                      .toString()
                  : parseFloat(
                      this.state.lotto.prezzo +
                        this.state.lotto.prezzo * 0.025 /*0*/ +
                        this.calcoloIva() +
                        this.calcPrezSpedizione(this.state.lotto.quantita)
                    )
                      .toFixed(2)
                      .toString(),
                autoSped: this.state.autoProvidedShipping,
                calcoloIva: this.calcoloIva(),
              }}
            >
              {t("next")}
            </Link>
          ) : (
            <Link
              onClick={() => this.updatePayInLotto()}
              to={
                "/checkout-second/" +
                this.props.params.id +
                "/" +
                this.props.params.idProd
              }
              className="btn-footer-checkout-mob"
              state={{
                payout: this.state.autoProvidedShipping
                  ? parseFloat(
                      this.state.lotto.prezzo +
                        this.state.lotto.prezzo * 0.025 /*0*/ +
                        this.calcoloIva()
                    )
                      .toFixed(2)
                      .toString()
                  : parseFloat(
                      this.state.lotto.prezzo +
                        this.state.lotto.prezzo * 0.025 /*0*/ +
                        this.calcPrezSpedizione(this.state.lotto.quantita) +
                        this.calcoloIva()
                    )
                      .toFixed(2)
                      .toString(),
                autoSped: this.state.autoProvidedShipping,
                calcoloIva: 0,
              }}
            >
              {t("next")}
            </Link>
          )}
        </View>
      </>
    );
  };
  DesktopRender = () => {
    const { t } = this.props.translation;
    return (
      <>
        <div className="container-form-check">
          <div className="div-logo-form">
            <Link to="/">
              <img className="logo-login" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="checkout-form-div">
            <div className="image-prod-in-lotto">
              <img
                src={this.state.prodotto.imageProdotto}
                alt="immagine-prod"
                onError={(e) => {
                  e.target.src = scarpaVuota;
                }}
              />
              <p>
                {this.state.prodotto.nome + " " + this.state.prodotto.modello}
              </p>
            </div>{" "}
            <p className="checkout-title">Checkout</p>
            <span className="checkout-sub-title">{t("av_size")}:</span>
            <div className="checkout-taglie-div checkout-container-con-taglie">
              {this.state.taglie.map((taglia, key) => {
                if (taglia.numero > 0)
                  return (
                    <button key={taglia.id} className="taglia-checkout">
                      <span>US {taglia.taglia}</span>
                      <span>
                        <b>
                          {t("qty")} {taglia.numero}
                        </b>
                      </span>
                    </button>
                  );
              })}{" "}
            </div>
            <div className="footer-checkout-first">
              <div className="child-footer-checkout">
                <span> {t("pack_ins")} :</span>
                <span>
                  {" "}
                  <b>{this.state.lotto.quantita}</b>
                </span>
              </div>

              <div
                className="child-footer-checkout"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "fit-content",
                  }}
                >
                  <span style={{ width: "fit-content" }}>
                    {t("total")}{" "}
                    <b>
                      {parseFloat(this.state.lotto?.prezzo)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",") + " €"}
                    </b>
                  </span>
                  <span style={{ width: "fit-content" }}>
                    {t("transaction_fees")} (2,5%){" "}
                    <b>
                      {"+" +
                        (this.state.lotto.prezzo * 0.025) /*0*/
                          .toFixed(2)
                          .toString()
                          .replace(".", ",") +
                        " €"}
                    </b>
                  </span>
                  {!this.state.autoProvidedShipping && (
                    <span style={{ width: "fit-content" }}>
                      {t("shipping_fee")} ({t("pair_of_shoes")})
                      <b>
                        {" "}
                        {"+" +
                          this.calcPrezSpedizione(this.state.lotto.quantita)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",") +
                          " €"}
                      </b>
                    </span>
                  )}
                  {this.state.italy && !this.state.lotto.ivaMargine ? (
                    <span style={{ width: "fit-content" }}>
                      V.A.T. 22%
                      <b style={{ marginLeft: 4 }}>
                        {" "}
                        {"+" +
                          this.calcoloIva()
                            .toFixed(2)
                            .toString()
                            .replace(".", ",") +
                          " €"}
                      </b>
                    </span>
                  ) : (
                    <span style={{ width: "fit-content" }}>
                      <>
                        {!this.state.lotto.ivaMargine
                          ? "V.A.T. 0%: 0€"
                          : t("marginal_vat_checked") + ": ✔"}
                      </>
                    </span>
                  )}
                  <span style={{ width: "fit-content" }}>
                    {t("final_price")}{" "}
                    {this.state.italy && !this.state.lotto.ivaMargine ? (
                      <b>
                        {" "}
                        {this.state.autoProvidedShipping
                          ? (
                              this.state.lotto.prezzo +
                              this.state.lotto.prezzo * 0.025 /*0*/ +
                              this.calcoloIva()
                            )
                              .toFixed(2)
                              .toString()
                              .replace(".", ",") + " €"
                          : (
                              this.state.lotto.prezzo +
                              this.state.lotto.prezzo * 0.025 /*0*/ +
                              this.calcoloIva() +
                              this.calcPrezSpedizione(this.state.lotto.quantita)
                            )
                              .toFixed(2)
                              .toString()
                              .replace(".", ",") + " €"}
                      </b>
                    ) : (
                      <b>
                        {" "}
                        {this.state.autoProvidedShipping
                          ? (
                              this.state.lotto.prezzo +
                              this.state.lotto.prezzo * 0.025
                            ) /*0*/
                              .toFixed(2)
                              .toString()
                              .replace(".", ",") + " €"
                          : (
                              this.state.lotto.prezzo +
                              this.state.lotto.prezzo * 0.025 +
                              this.calcPrezSpedizione(this.state.lotto.quantita)
                            )
                              .toFixed(2)
                              .toString()
                              .replace(".", ",") + " €"}
                      </b>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "65%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Switch
                checked={this.state.autoProvidedShipping}
                onChange={(e) =>
                  this.setState({
                    autoProvidedShipping: !this.state.autoProvidedShipping,
                  })
                }
                sx={{ float: "left" }}
                color="primary"
              />
              <span style={{ marginLeft: 5 }}>{t("handle_shippment")}</span>
            </div>
          </div>
          <div className="btn-footer-checkout">
            {this.state.italy ? (
              <Link
                onClick={() => this.updatePayInLotto()}
                to={
                  "/checkout-second/" +
                  this.props.params.id +
                  "/" +
                  this.props.params.idProd
                }
                className="btn-footer-checkout-mob"
                state={{
                  payout: this.state.autoProvidedShipping
                    ? parseFloat(
                        this.state.lotto.prezzo +
                          this.calcoloIva() +
                          this.state.lotto.prezzo * 0.025 /*0*/
                      )
                        .toFixed(2)
                        .toString()
                    : parseFloat(
                        this.state.lotto.prezzo +
                          this.state.lotto.prezzo * 0.025 /*0*/ +
                          this.calcoloIva() +
                          this.calcPrezSpedizione(this.state.lotto.quantita)
                      )
                        .toFixed(2)
                        .toString(),
                  autoSped: this.state.autoProvidedShipping,
                  calcoloIva: this.calcoloIva(),
                }}
              >
                {t("next")}
              </Link>
            ) : (
              <Link
                onClick={() => this.updatePayInLotto()}
                to={
                  "/checkout-second/" +
                  this.props.params.id +
                  "/" +
                  this.props.params.idProd
                }
                className="btn-footer-checkout-mob"
                state={{
                  payout: this.state.autoProvidedShipping
                    ? parseFloat(
                        this.state.lotto.prezzo +
                          this.state.lotto.prezzo * 0.025 /*0*/ +
                          this.calcoloIva()
                      )
                        .toFixed(2)
                        .toString()
                    : parseFloat(
                        this.state.lotto.prezzo +
                          this.state.lotto.prezzo * 0.025 /*0*/ +
                          this.calcoloIva() +
                          this.calcPrezSpedizione(this.state.lotto.quantita)
                      )
                        .toFixed(2)
                        .toString(),
                  autoSped: this.state.autoProvidedShipping,
                  calcoloIva: 0,
                }}
              >
                {t("next")}
              </Link>
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  };
  render() {
    return (
      <>{this.props.desktop ? <this.DesktopRender /> : <this.MobileRender />}</>
    );
  }
}
export default function (props) {
  const params = useParams();
  const t = useTranslation();
  const desktop = useDesktopChecker();
  return (
    <CheckoutOne
      translation={t}
      props={props}
      params={params}
      desktop={desktop}
    />
  );
}
