import React, { Component } from "react";
import logo from "../../assets/wk-logo.png";
import { Link, useParams, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import "../../styles/NuovoLotto.css";
import scarpaImg from "../../assets/image-scarpa.png";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { useTranslation } from "react-i18next";
import scarpaVuota from "../../assets/wk_products_not_found.png"

class ListaLottoSix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scarpa: {},
    };
  }
  componentDidMount = async () => {
    await axios
      .get(CONSTANTS.API_URL + "/prodotto/productById/" + this.props.params.id)
      .then(async (res) => {
        console.log(res.data);
        this.setState({ scarpa: res.data });
      });
  };
  render() {
    const { t } = this.props.translation;
    return (
      <>
        <div className="container-form-check">
          <div className="div-logo-form">
            <Link to="/">
              <img className="logo-login" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="checkout-final-div">
            <p className="conferma-acquisto-lotto-p"> {t("stock_listed")} </p>
            <div className="riepilogo-lotto container-riep-lotto-six">
              <img
                src={this.state.scarpa.imageProdotto}
                alt="scarpa-immagine"
                style={{ objectFit: "contain" }}
                onError={(e) => {
                  e.target.src = scarpaVuota;
                }}
              />
              <div>
                <p className="scarpa-title-riep-lotto-six">
                  {this.state.scarpa.nome}
                </p>
                <p>{this.state.scarpa.modello}</p>
              </div>
            </div>
          </div>
          <div className="btn-footer-checkout">
            <Link to="/vendi" className="btn-footer-lotto-left">
              {t("list_another")}
            </Link>
            <Link to="/" className="btn-footer-lotto-right">
              {t("back_home")}
            </Link>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default function (props) {
  const params = useParams();
  const t = useTranslation();
  const location = useLocation();
  return (
    <ListaLottoSix
      translation={t}
      props={props}
      params={params}
      location={location}
    />
  );
}
