import React, { Component } from "react";
import "../../styles/CheckoutStyles.css";
import Loading from "../../components/Loading";
import axios from "axios";
import CONSTANTS from "../../CONSTANTS";
import { useLocation, useNavigate, useParams } from "react-router-dom";

class NexiProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
    };
  }

  componentDidMount() {
    this.checkNexiEsito();
  }

  checkNexiEsito = () => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);

    // Ottieni i valori dei singoli parametri
    //   const token = params.get("xpay__lb__token");
    //   const mail = params.get("mail");
    //   const data = params.get("data");
    //   const messaggio = params.get("messaggio");
    //   const cognome = params.get("cognome");
    //   const nazionalita = params.get("nazionalita");
    //   const regione = params.get("regione");
    //   const mac = params.get("mac");
    //   const codAut = params.get("codAut");
    //   const tipoProdotto = params.get("tipoProdotto");
    //   const selectedcard = params.get("selectedcard");
    //   const alias = params.get("alias");
    //   const pan = params.get("pan");
    //   const brand = params.get("brand");
    //   const orario = params.get("orario");
    //   const divisa = params.get("divisa");
    //   const scadenza_pan = params.get("scadenza_pan");
    //   const importo = params.get("importo");
    //   const codiceEsito = params.get("codiceEsito");
    //   const languageId = params.get("languageId");
    //   const nome = params.get("nome");
    //   const check = params.get("check");
    //   const tipoTransazione = params.get("tipoTransazione");
    //   const codiceConvenzione = params.get("codiceConvenzione");
    //   const aliasEffettivo = params.get("aliasEffettivo");
    //   const OPTION_CF = params.get("OPTION_CF");
    //   const num_contratto = params.get("num_contratto");

    const codTrans = params.get("codTrans");
    const esito = params.get("esito");
    console.log("esito", esito);
    if (params.has("esito") && params.has("codTrans")) {
      this.updatePagamentoNexi(esito, codTrans);
    } else {
      console.log("NEXI not OK");
      window.location.href = "/checkoutnexiko";
    }
  };

  updatePagamentoNexi = async (esito, codTrans) => {
    const body = {
      esito,
      codTrans,
    };
    try {
      const response = await axios.post(
        CONSTANTS.API_URL + "/nexi/simple_pay",
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      );

      console.log("credit card payment success", response);
      window.location.href = "/checkout-fourth";
    } catch (error) {
      console.log("error", error);
      window.location.href = "/checkoutnexiko";
    }
  };

  render() {
    return (
      <>
        <Loading />
      </>
    );
  }
}

export default function (props) {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <NexiProcessing
      props={props}
      params={params}
      location={location}
      navigate={navigate}
    />
  );
}
