import React, { useState } from "react";
import { View, Text, Image, StyleSheet, TouchableOpacity } from "react-native";
import { colors, dim } from "./utils/constants";
import "./style.css";
import CONSTANTS from "../../CONSTANTS";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { width } from "@mui/system";
import axios from "axios";
import ModalAdd from "../home/ModalAdd";
import { isAutenticated } from "../../components/utils/validatorActions";
import { ImportantDevices } from "@mui/icons-material";

const HeaderMobile = ({ context, title }) => {
  const [search, setsearch] = useState(false);
  const navigate = useNavigate();
  const [wordSearch, setWordsearch] = useState("");
  const [listaprodotto, setListaProdotto] = useState([]);
  const [modal, setmodal] = useState(false);
  const style = StyleSheet.create({
    container: {
      height: dim.height * 0.09,
      width: "100%",
      backgroundColor: "#fff",
      borderBottomWidth: 1,
      borderBottomColor: "#7A79CD",
      flexDirection: "row",
      position: search ? "fixed" : "relative",
      top: 0,
      zIndex: 100,
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: 10,
    },
    containerOthers: {
      height: dim.height * 0.09,
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: 10,
    },
  });
  const onPressSearch = () => {
    if (search) {
    } else {
      setsearch(true);
    }
  };
  const onPressModalCancel = () => {
    setmodal(false);
  };
  const onPressModal = () => {
    setmodal(true);
  };
  const searchFunctionKeyUp = async (word) => {
    console.log("word", word);
    setWordsearch(word);
    if (word) {
      await axios
        .get(CONSTANTS.API_URL + "/prodotto/search/" + word + " /9/0")
        .then((res) => {
          setListaProdotto(res.data.prodottoConteggio);
          console.log("res.data", res.data);
        })
        .catch((e) => {
          console.log(e.message);
        });
    } else {
      setListaProdotto([]);
    }
  };
  const onClickLinkSubmit = (e) => {
    e.preventDefault();
    if (!isAutenticated()) {
      alert("Must be logged to do this");
    } else {
      if (window.location.pathname.split("/")[1] === "search-page") {
        window.location.href = `/search-page/${wordSearch}`;
      } else {
        navigate(isAutenticated() ? `/search-page/${wordSearch}` : "#");
      }
    }
  };
  const onClickLinkProd = (x) => {
    if (!isAutenticated()) {
      alert("Must be logged to do this");
    } else {
      window.location.href = "/product/" + x;
    }
  };

  if (context === "Home")
    return (
      <View style={style.container}>
        {!search && (
          <Link to="/">
            <Image
              source={require("../../assets/wk-logo.png")}
              style={{ height: dim.height * 0.07, width: dim.height * 0.07 }}
            />
          </Link>
        )}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: search ? "98%" : "80%",
            height: "100%",
            justifyContent: "flex-end",
          }}
        >
          <View onClick={() => onPressSearch()}>
            {!search ? (
              <Image
                source={require("../../assets/mobile/search.png")}
                style={{ height: dim.height * 0.03, width: dim.height * 0.03 }}
              />
            ) : (
              <TouchableOpacity
                onPress={onClickLinkSubmit}
                style={{
                  backgroundColor: colors.blue,
                  padding: 10,
                  borderRadius: 8,
                }}
              >
                <Image
                  source={require("../../assets/mobile/search-wh.png")}
                  style={{
                    height: dim.height * 0.02,
                    width: dim.height * 0.02,
                  }}
                />
              </TouchableOpacity>
            )}
          </View>
          {!search && <View style={{ width: 25 }} />}
          {!search && (
            <div
              className="header-link"
              onClick={() => {
                if (localStorage.getItem(CONSTANTS.NOME)) {
                  window.location.href = "/account";
                } else {
                  window.location.href = "/login";
                }
              }}
            >
              <Image
                source={require("../../assets/mobile/wk-profile.png")}
                style={{ height: dim.height * 0.03, width: dim.height * 0.03 }}
              />
            </div>
          )}

          <form
            className={!search ? "search-inline" : "search-inline-active"}
            onSubmit={onClickLinkSubmit}
          >
            <input
              value={wordSearch}
              onChange={(e) => searchFunctionKeyUp(e.target.value)}
              placeholder="Search..."
              style={{
                width: "100%",
                border: "none",
                padding: 10,
                borderRadius: 9,
              }}
              disabled={!search}
            />
          </form>
          {search && (
            <div style={{ zIndex: 10 }}>
              <span
                onClick={() => {
                  if (wordSearch.length > 0) {
                    setWordsearch("");
                    setListaProdotto([]);
                  } else {
                    setsearch(false);
                  }
                }}
              >
                <CloseIcon />
              </span>
              <div>
                {wordSearch && (
                  <ul className="ul-cerca-mobile">
                    {listaprodotto.map((prodotto, index) => {
                      if (index < 5 && prodotto?.id) {
                        return (
                          <div
                            style={{ display: "block" }}
                            onClick={() => {
                              window.location.href = "/product/" + prodotto.id;
                            }}
                          >
                            <div>
                              <img
                                src={prodotto.imageProdotto}
                                alt="scarpa-immagine"
                              />
                              <div
                                id="myLi"
                                className="testo-cerca"
                                value={prodotto.nome}
                              >
                                <a
                                  className="nome-elemento-cerca"
                                  id="myA"
                                  value={prodotto.id}
                                >
                                  {prodotto.nome}
                                </a>
                                <p>{prodotto.style}</p>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                    {/* {listaprodotto?.length>0&&
            <div onClick={onPressModal} className="bottone-finale">
                <p>Can't find a product? <b style={{color:'#0a09a2'}}>Submit a proposal</b></p>
              </div>} */}
                  </ul>
                )}
              </div>
            </div>
          )}

          {!search && <View style={{ width: 25 }} />}
          {/* <Link className="header-link" to="/menu">
            <Image
              source={require("../../assets/mobile/drawer.png")}
              style={{
                height: dim.height * 0.03,
                width: dim.height * 0.03,
                marginTop: dim.height * 0.009,
              }}
            />
          </Link> */}
        </View>
        <ModalAdd modalAdd={modal} onModalDismiss={onPressModalCancel} />
      </View>
    );
  if (context === "Drawer")
    return (
      <View style={style.containerOthers}>
        <TouchableOpacity onPress={() => navigate(-1)} style={{ flex: 1 }}>
          <Image
            source={require("../../assets/mobile/chevron-left.png")}
            style={{ height: dim.height * 0.026, width: dim.height * 0.026 }}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <View style={{ flex: 3, alignItems: "center" }}>
          <Image
            source={require("../../assets/wk-logo.png")}
            style={{ height: dim.height * 0.07, width: dim.height * 0.07 }}
          />
        </View>
        <View style={{ flex: 1 }} />
      </View>
    );
  if (context === "Others")
    return (
      <View style={style.containerOthers}>
        <TouchableOpacity onPress={() => navigate(-1)} style={{ flex: 1 }}>
          <Image
            source={require("../../assets/mobile/chevron-left.png")}
            style={{ height: dim.height * 0.026, width: dim.height * 0.026 }}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <View style={{ flex: 3, alignItems: "center" }}>
          <Text style={{ fontSize: "1.2rem", fontWeight: "500" }}>{title}</Text>
        </View>
        <View style={{ flex: 1 }} />
      </View>
    );
  else return <View></View>;
};

export default HeaderMobile;
