import { useState, useEffect } from "react";
import { isNotMobile } from "../../pagesMobile/components/utils/constants";

export default function useDesktopChecker() {
  const [width, setwidth] = useState(window.innerWidth);
  const [isNotMobileState, setIsNotMobileState] = useState(false);
  const onResize = () => {
    setwidth(window.innerWidth);
  };
  const setResponse = () => {
    setIsNotMobileState(isNotMobile());
  };
  useEffect(() => {
    window.addEventListener("resize", () => onResize());
    return () => {
      window.removeEventListener("resize", () => onResize());
    };
  }, []);
  useEffect(() => {
    setResponse();
  }, [width]);

  return isNotMobileState;
}
