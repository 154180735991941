import React from "react";
import { useTranslation } from "react-i18next";
import { View, Text, ScrollView, Image, TouchableOpacity } from "react-native";
import { Link, useNavigate } from "react-router-dom";
import CONSTANTS from "../../CONSTANTS";
import scarpaVuota from "../../assets/wk_products_not_found.png";
import { dim, isLittleDev } from "../components/utils/constants";
import "./style.css";

const ProductGrid = ({ newInTotal }) => {
  const data = newInTotal;
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <View style={{ width: "100%", position: "relative" }}>
      <ScrollView
        contentContainerStyle={{
          width: "100%",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          marginHorizontal: 10,
        }}
      >
        {data.map((item) => {
          if (item?.id) {
            return (
              <TouchableOpacity
                onPress={() => {
                  navigate(`/product/${item.id}`);
                }}
                key={item.id}
                style={{
                  height: 250,
                  width: isLittleDev() ? "80%" : "45%",
                  marginTop: 28,
                  marginBottom: 10,
                  backgroundColor: "#fff",
                  shadowRadius: 12,
                  shadowOpacity: 0.3,
                  borderRadius: 10,
                  padding: 5,
                  marginHorizontal: "1%",
                }}
              >
                <img
                  src={item.imageProdotto}
                  onError={(e) => {
                    e.target.src = scarpaVuota;
                  }}
                  style={{
                    width: "100%",
                    height: "50%",
                    borderRadius: 10,
                    resizeMode: "contain",
                  }}
                />

                <Text
                  style={{
                    width: "90%",
                    textAlign: "left",
                    fontSize: 12,
                    marginLeft: 5,
                    marginTop: 2,
                    fontWeight: "bold",
                    textDecorationLine: "none",
                  }}
                  numberOfLines={2}
                >
                  {item.nome}
                </Text>
                <Text
                  style={{
                    width: "90%",
                    textAlign: "left",
                    marginLeft: 5,
                    fontSize: 10,
                    marginTop: 2,
                    textDecorationLine: "none",
                    textDecorationColor: "transparent",
                  }}
                  numberOfLines={2}
                >
                  {item.modello}
                </Text>
                {item.prezzo && (
                  <>
                    <Text
                      style={{
                        alignSelf: "flex-end",
                        marginLeft: "5%",
                        marginTop: 20,
                        fontSize: 10,
                        textDecorationLine: "none",
                      }}
                    >
                      {t("average_price")}
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        paddingHorizontal: "5%",
                        paddingTop: 4,
                      }}
                    >
                      <Text style={{ fontWeight: "bold", fontSize: 10 }}>
                        {Number.parseFloat(item.prezzo).toFixed(2) + "€"}
                      </Text>
                    </View>
                  </>
                )}
                {!item.prezzo && (
                  <>
                    <Text
                      style={{
                        alignSelf: "flex-end",
                        marginLeft: "5%",
                        fontSize: 10,
                        marginTop: 20,
                        textDecorationLine: "none",
                      }}
                    >
                      {t("No_batches_listed")}
                    </Text>
                  </>
                )}
              </TouchableOpacity>
            );
          } else {
            return null;
          }
        })}
      </ScrollView>
      <View style={{ height: 40 }} />
    </View>
  );
};

export default ProductGrid;
