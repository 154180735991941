import React, { Component } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
} from "react-native";
import axios from "axios";
import HeaderMobile from "../components/HeaderMobile";
import { withTranslation } from "react-i18next";
import CONSTANTS from "../../CONSTANTS";
import Paginator from "../components/Paginator";
import {
  acquistiCountAllGet,
  acquistiGet,
  offerteFatteCountGet,
  offerteFatteGet,
} from "../api/service";
class Acquisti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statoProfile: 0,
      totalPageOfferte: [],
      totalPage: [],
      listaProdotti: [],
      listaProposte: [],
      elementPerPage: 9,
    };
  }
  componentDidMount() {
    this.init();
  }
  async pageAcquistiGet(limit, offset, withCount) {
    const prodottoRicevuto = await acquistiGet(limit, offset);
    this.setState({
      listaProdotti: prodottoRicevuto,
    });

    if (withCount) {
      const proposteRicevute = await acquistiCountAllGet();
      const countPage =
        proposteRicevute.totalElement / this.state.elementPerPage;
      this.setState({
        totalPage: Array(parseInt(countPage) + 1),
      });
    }
  }
  async pageOfferteGet(limit, offset, withCount) {
    const proposteRicevute = await offerteFatteGet(limit, offset);
    console.log("proposte", proposteRicevute);
    this.setState({
      listaProposte: proposteRicevute,
    });

    if (withCount) {
      const proposteRicevute = await offerteFatteCountGet();
      const countPage =
        proposteRicevute[0].totalCount / this.state.elementPerPage;
      console.log(proposteRicevute);
      this.setState({
        totalPageOfferte: Array(parseInt(countPage) + 1),
      });
    }
  }

  init() {
    this.pageAcquistiGet(9, 0, true);
    this.pageOfferteGet(9, 0, true);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Others" title={t("purchase")} />
          <View style={style.HeaderMobileProfile}>
            <TouchableOpacity
              onPress={() => {
                this.setState({ statoProfile: 0 });
              }}
            >
              <Text
                style={{
                  fontWeight: this.state.statoProfile === 0 ? "bold" : 400,
                  color: this.state.statoProfile === 0 ? "#0A09A2" : "black",
                }}
              >
                {t("in_prog")}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                this.setState({ statoProfile: 1 });
              }}
              style={{ marginLeft: "10%" }}
            >
              <Text
                style={{
                  fontWeight: this.state.statoProfile === 1 ? "bold" : 400,
                  color: this.state.statoProfile === 1 ? "#0A09A2" : "black",
                }}
              >
                {t("prop_purch")}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.statoProfile === 0 && (
              <>
                <ScrollView
                  style={{ width: "100%", height: "72vh" }}
                  contentContainerStyle={{ alignItems: "center" }}
                >
                  {this.state.listaProdotti.length == 0 && (
                    <Text>No items in this section</Text>
                  )}
                  {this.state.listaProdotti.length > 0 &&
                    this.state.listaProdotti.map((item) => {
                      return (
                        <View style={style.containerProposte}>
                          <Image
                            style={{
                              width: "40%",
                              resizeMode: "center",
                              height: "100%",
                            }}
                            source={{ uri: item.imageProdotto }}
                          />
                          <View style={style.containerLabelProdotto}>
                            <Text style={style.titleContProd}>{item.nome}</Text>
                            <Text style={style.SubTitleContProd}>
                              {item.modello}
                            </Text>
                            <View>
                              <Text>
                                N. Lotto <br />
                              </Text>
                              <Text>{item.id}</Text>
                              <Text>
                                Prezzo <br />
                              </Text>
                              <Text>{item.prezzo}</Text>
                            </View>
                          </View>
                        </View>
                      );
                    })}
                </ScrollView>
                <Paginator elemPerPage={9} />
              </>
            )}
            {this.state.statoProfile === 1 && (
              <>
                <ScrollView
                  style={{ width: "100%", height: "72vh" }}
                  contentContainerStyle={{ alignItems: "center" }}
                >
                  {this.state.listaProposte.length == 0 && (
                    <Text>No items in this section</Text>
                  )}
                  {this.state.listaProposte.length > 0 &&
                    this.state.listaProposte.map((item) => {
                      return (
                        <View style={style.containerProposte}>
                          <Image
                            style={{
                              width: "40%",
                              resizeMode: "center",
                              height: "100%",
                            }}
                            source={{ uri: item.imageProdotto }}
                          />
                          <View style={style.containerLabelProdotto}>
                            <Text style={style.titleContProd}>{item.nome}</Text>
                            <Text style={style.SubTitleContProd}>
                              {item.modello}
                            </Text>
                            <View>
                              <Text>
                                N. Lotto <br />
                              </Text>
                              <Text>{item.id}</Text>
                              <Text>
                                Prezzo <br />
                              </Text>
                              <Text>{item.prezzo}</Text>
                            </View>
                          </View>
                        </View>
                      );
                    })}
                </ScrollView>
                {this.state.listaProposte.length > 0 && (
                  <Paginator elemPerPage={9} />
                )}
              </>
            )}
          </View>
        </View>
      </div>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  HeaderMobileProfile: {
    flexDirection: "row",
    width: "90%",
    marginHorizontal: "5%",
    paddingHorizontal: "5%",
    paddingBottom: 15,
    borderBottomColor: "#7A79CD",
    borderBottomWidth: 1.5,
  },
  containerProposte: {
    width: "90%",
    marginTop: 10,
    height: 120,
    marginVertical: 20,
    flexDirection: "row",
    borderBottomColor: "#7A79CD",
    borderBottomWidth: 1.5,
  },

  containerLabelProdotto: {
    width: "60%",
  },

  titleContProd: {
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
  containerShipping: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginVertical: 10,
  },
});

export default withTranslation()(Acquisti);
