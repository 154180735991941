import React, { Component } from "react";
import "../../styles/AccountDetails.css";
import SearchIcon from "@mui/icons-material/Search";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import Header from "../../components/Header";
import { Modal } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import AccountRoutes from "../../components/AccountRoutes";
import { useTranslation, withTranslation } from "react-i18next";
import {
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
  StyleSheet,
  Modal as MobileModal,
} from "react-native";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import ModalVisualizzatore from "../../pagesMobile/singleProduct/ModalVisualizzatore";
import { totale, calcFeeSped, calcIva, calcWKfee } from "./UtilsTax";
import { sendMailNotification } from "../chiamateApi/ApiProdotto";
import scarpaVuota from "../../assets/wk_products_not_found.png";

class Acquisti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      listaProdotti: [],
      listaCustom: [],
      listaProposte: [],
      elementPerPage: 9,
      page: 1,
      totalPage: [],
      totalPageOfferte: [],
      iva: 0,
      nazione: localStorage.getItem(CONSTANTS.NAZIONE),
      pageProposte: 1,
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
      UtenteId: localStorage.getItem(CONSTANTS.ID),
      switch: 2,
      modalCheckOut: false,
      prodottoCheck: {},
      imgeRicevuta: {},
      fileRicevuta: "",
      visualizzatore: false,
      taglieVisualizzatore: [],
      prodottoVisualizzatore: {},
      modalSelectedItem: false,
      modalItemTools: {},
    };
  }
  componentDidMount() {
    if (this.state.nazione) {
      console.log("Utente:", this.state.nazione.slice(0, 2).toString());
    } else {
      console.log(localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY, ""));
      window.sessionStorage.removeItem("accessToken");
      window.sessionStorage.removeItem("nama");
      localStorage.clear();
      window.location.href = "/";
    }
    this.refreshPage();
    this.getCustomProposal(localStorage.getItem(CONSTANTS.ID));
  }
  calcWKfee = (lotto) => {
    return lotto?.prezzo * 0.025;
    //return 0
  };
  calcIva = (lotto) => {
    if (this.state.nazione === "Italy" && !lotto?.ivaMargine)
      return (
        (this.calcFeeSped(lotto) + this.calcWKfee(lotto) + lotto?.prezzo) * 0.22
      );
    else {
      return 0;
    }
  };
  totale = (lotto) => {
    return parseFloat(
      lotto.prezzo +
        (this.calcFeeSped(lotto) + this.calcWKfee(lotto) + this.calcIva(lotto))
    ).toFixed(2);
  };
  calcFeeSped = (lotto) => {
    const module = lotto?.quantita % 6;
    let division = lotto?.quantita / 6;
    division = Math.round(division - 0.5);
    console.log(division);
    if (module > 0) {
      return (division + 1) * CONSTANTS.PRICESPEDIZIONE;
    } else {
      return division * CONSTANTS.PRICESPEDIZIONE;
    }
  };
  async getCustomProposal(id) {
    await axios
      .get(CONSTANTS.API_URL + "/lotto/custom-by-idMaker/" + id, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        if (res.data) {
          console.log("res", res.data);
          this.setState({ listaCustom: res.data });
        }
      })
      .catch((e) => {
        alert(e.message);
      });
  }

  async refreshPage() {
    axios
      .get(
        CONSTANTS.API_URL + "/pagamento/buyId/" + this.state.UtenteId + "/0/9",
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((prodottoRicevuto) => {
        console.log("y", prodottoRicevuto.data);
        this.setState({
          listaProdotti: prodottoRicevuto.data,
        });
      });
    axios
      .get(
        CONSTANTS.API_URL + "/offertaFatta/" + this.state.UtenteId + "/0/9",
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((proposteRicevute) => {
        console.log("off", proposteRicevute.data);
        this.setState({
          listaProposte: proposteRicevute.data,
        });
      });
    axios
      .get(
        CONSTANTS.API_URL +
          "/offertaFatta/acquisti-count/" +
          this.state.UtenteId,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((proposteRicevute) => {
        if (proposteRicevute.data[0].totalCount > 1) {
          const countPage =
            proposteRicevute.data[0].totalCount / this.state.elementPerPage;
          console.log("proposteRicevute.data1", countPage);
          this.setState({
            totalPageOfferte: Array(Math.round(countPage + 0.4)),
          });
        } else {
          this.setState({
            totalPageOfferte: Array(1),
          });
        }
      });
    axios
      .get(CONSTANTS.API_URL + "/pagamento/count-all/" + this.state.UtenteId, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((proposteRicevute) => {
        if (proposteRicevute.data.totalElement > 1) {
          const countPage =
            proposteRicevute.data.totalElement / this.state.elementPerPage;
          console.log("proposteRicevute.data2", countPage);
          this.setState({
            totalPage: Array(Math.round(countPage + 0.4)),
          });
        } else {
          console.log(
            "proposteRicevute.data22",
            proposteRicevute.data.totalElement
          );
          this.setState({
            totalPage: Array(1),
          });
        }
      });
  }
  handlePageClick = async (page) => {
    const indexOfLastTodo = page * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    console.log("indici", this.state.elementPerPage, indexOfFirstTodo);

    const prodotti = await axios.get(
      CONSTANTS.API_URL +
        "/pagamento/buyId/" +
        this.state.UtenteId +
        "/" +
        indexOfFirstTodo +
        "/" +
        this.state.elementPerPage,
      {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      }
    );
    console.log("prodotti", prodotti);

    this.setState({
      page: page,
      listaProdotti: prodotti.data,
    });
  };
  handlePageClickProposte = async (page) => {
    const indexOfLastTodo = page * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    console.log("indici", this.state.elementPerPage, indexOfFirstTodo);

    const prodotti = await axios.get(
      CONSTANTS.API_URL +
        "/offertaFatta/" +
        this.state.UtenteId +
        "/" +
        indexOfFirstTodo +
        "/" +
        this.state.elementPerPage,
      {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      }
    );
    console.log("prodotti", prodotti);

    this.setState({
      pageProposte: page,
      listaProposte: prodotti.data,
    });
  };

  onOpenModal(prodotto) {
    console.log("prodotto", prodotto);
    this.setState({ prodottoCheck: prodotto }, () => {
      this.setState({ modalCheckOut: true });
    });
  }
  onHideModal() {
    this.setState({ modalCheckOut: false }, () => {
      this.setState({ prodottoCheck: {}, fileRicevuta: "" });
    });

    this.refreshPage();
  }
  async submitPayment(LottoId, prod) {
    let formData = new FormData();

    console.log("prodottoCheck", prod);
    console.log(LottoId);
    formData.append("LottoId", LottoId);
    formData.append("imagePagamentoRicevuto", this.state.imgeRicevuta);
    await axios
      .post(CONSTANTS.API_URL + "/offertaFatta/Ricevuta", formData, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then(async (res) => {
        console.log("r", res.data);
        await axios
          .get(
            CONSTANTS.API_URL +
              "/account/utente/" +
              localStorage.getItem(CONSTANTS.ID),
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  CONSTANTS.ACCESS_TOKEN_KEY
                )}`,
              },
            }
          )
          .then(async (res) => {
            const objMail = {
              prodotto: prod,
              lotto: LottoId,
              email: res.data.email,
              idMailVendita: 8,
              idMailAcquisto: 14,
            };
            await sendMailNotification(objMail);
            console.log(res.data);
          });

        this.onHideModal();
      });
  }

  calcWKfee = (lotto) => {
    return lotto?.prezzo * 0.025;
    //return 0
  };
  /*calcWKfee=(lotto)=>{
    return 0
  }*/

  calcShipFee(lotto) {
    if (lotto && lotto.prezzo !== undefined && lotto.quantita !== undefined) {
      let input = lotto.quantita;
      // Calcola il numero del gruppo
      let groupNumber = Math.ceil(input / 6);

      // Calcola il valore base
      let baseValue = (groupNumber - 1) * 10;

      // Restituisci il valore base più il valore aggiuntivo
      return baseValue + 10;
    } else {
      return 0;
    }
  }

  async requestTaglieVisualizzatore(prodotto) {
    await axios
      .get(CONSTANTS.API_URL + "/taglia/" + prodotto.id, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        const resConstant = { ...res };
        this.setState({ taglieVisualizzatore: resConstant.data });
      });
  }
  onPressVisualizzatore(prodotto) {
    this.requestTaglieVisualizzatore(prodotto);
    this.setState({
      visualizzatore: true,
      prodottoVisualizzatore: prodotto,
      modalSelectedItem: false,
    });
  }
  onHideVisualizzatore = () => {
    console.log("kkkk");
    this.setState({
      visualizzatore: false,
      prodottoVisualizzatore: {},
      taglieVisualizzatore: [],
    });
  };
  onPressModalTools = (item) => {
    this.setState({ modalItemTools: item }, () => {
      this.setState({ modalSelectedItem: true });
    });
  };
  onCloseModalTools = () => {
    this.setState({ modalSelectedItem: false }, () => {
      this.setState({ modalItemTools: {} });
    });
  };

  MobileRender = () => {
    const { t } = this.props.t;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Others" title={t("purchase")} />
          <View style={style.HeaderMobileProfile}>
            <TouchableOpacity
              onPress={() => {
                this.setState({ switch: 2 });
              }}
            >
              <Text
                style={{
                  fontWeight: this.state.switch === 2 ? "bold" : 400,
                  color: this.state.switch === 2 ? "#0A09A2" : "black",
                }}
              >
                {t("custom_proposal")}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                this.setState({ switch: 0 });
              }}
            >
              <Text
                style={{
                  fontWeight: this.state.switch === 0 ? "bold" : 400,
                  color: this.state.switch === 0 ? "#0A09A2" : "black",
                }}
              >
                {t("in_prog")}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                this.setState({ switch: 1 });
              }}
            >
              <Text
                style={{
                  fontWeight: this.state.switch === 1 ? "bold" : 400,
                  color: this.state.switch === 1 ? "#0A09A2" : "black",
                }}
              >
                {t("prop_purch")}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.switch === 0 && (
              <>
                <ScrollView
                  style={{ width: "100%", height: "72vh" }}
                  contentContainerStyle={{ alignItems: "center" }}
                >
                  {this.state.listaProdotti.length == 0 && (
                    <Text> {t("no_item")}</Text>
                  )}
                  {this.state.listaProdotti.length > 0 &&
                    this.state.listaProdotti.map((item) => {
                      return (
                        <View style={style.containerProposte}>
                          <img
                            style={{
                              width: "30%",
                              resizeMode: "center",
                              height: "100%",
                              marginRight: "3%",
                            }}
                            src={item.imageProdotto}
                            onError={(e) => {
                              e.target.src = scarpaVuota;
                            }}
                          />
                          <View style={style.containerLabelProdotto}>
                            <Text style={style.titleContProd}>{item.nome}</Text>
                            <Text style={style.SubTitleContProd}>
                              {item.modello}
                            </Text>
                            <View style={{ flexDirection: "row" }}>
                              <View>
                                <Text style={style.boldLabel}>
                                  {t("stock_n")} <br />
                                </Text>
                                <Text
                                  style={{ fontSize: "0.9rem", color: "red" }}
                                >
                                  {item.lotto.id}
                                </Text>
                                <Text style={style.boldLabel}>
                                  {t("price")} <br />
                                </Text>
                                <Text style={style.NoboldLabel}>
                                  {item.prezzo}€
                                </Text>
                              </View>
                              <View style={{ marginLeft: "10%" }}>
                                <Text style={style.boldLabel}>
                                  {t("quantity")}
                                  <br />
                                </Text>
                                <Text style={style.NoboldLabel}>
                                  {item.qta}
                                </Text>
                                <Text style={style.boldLabel}>
                                  {t("status")}
                                  <br />
                                </Text>
                                <Text style={style.NoboldLabel}>
                                  {t(item.StatoNome)}
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexGrow: 1,
                                  justifyContent: "flex-start",
                                  paddingRight: 10,
                                }}
                              >
                                <TouchableOpacity
                                  onPress={() =>
                                    this.onPressModalTools(item.lotto)
                                  }
                                >
                                  <Text
                                    style={{
                                      marginRight: 10,
                                      alignSelf: "flex-end",
                                      color: "rgb(10, 9, 162)",
                                      fontSize: "1.2rem",
                                      fontWeight: "900",
                                      lineHeight: 8,
                                    }}
                                  >
                                    .<br />.<br />.
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                            {item.isOfferta > 0 && (
                              <div>
                                <button
                                  style={{
                                    borderWidth: 0,
                                    backgroundColor: "#0A09A2",
                                    fontSize: "0.8rem",
                                    alignSelf: "flex-start",
                                    width: "90%",
                                    paddingTop: "2%",
                                    paddingBottom: "2%",
                                    color: "#fff",
                                    marginBottom: 12,
                                    marginTop: "3%",
                                    borderRadius: 6,
                                  }}
                                  onClick={() => this.onOpenModal(item)}
                                >
                                  {t("comp_checkout")}
                                </button>
                              </div>
                            )}
                          </View>
                        </View>
                      );
                    })}
                </ScrollView>
                <Pagination>
                  {this.state.page > 1 && (
                    <Pagination.Prev
                      onClick={() => this.handlePageClick(this.state.page - 1)}
                    />
                  )}
                  {this.state.page > 1 && (
                    <Pagination.Item onClick={() => this.handlePageClick(1)}>
                      1
                    </Pagination.Item>
                  )}
                  {this.state.page > 1 && <Pagination.Ellipsis />}
                  {this.state.page - 1 > 1 && (
                    <Pagination.Item
                      active={this.state.page === this.state.page - 1}
                      key={"_pagination_" + this.state.page - 1}
                      onClick={() => this.handlePageClick(this.state.page - 1)}
                    >
                      {this.state.page !== 1 ? this.state.page - 1 : null}
                    </Pagination.Item>
                  )}
                  <Pagination.Item
                    active={this.state.page === this.state.page}
                    key={"_pagination_" + this.state.page}
                    onClick={() => this.handlePageClick(this.state.page)}
                  >
                    {this.state.page}
                  </Pagination.Item>
                  {this.state.page != this.state.totalPage.length && (
                    <Pagination.Item
                      active={this.state.page + 1 === this.state.page}
                      key={"_pagination_" + this.state.page + 1}
                      onClick={() => this.handlePageClick(this.state.page + 1)}
                    >
                      {this.state.page + 1}
                    </Pagination.Item>
                  )}
                  {this.state.page + 1 < this.state.totalPage.length && (
                    <Pagination.Ellipsis />
                  )}
                  {this.state.page + 1 < this.state.totalPage.length && (
                    <Pagination.Item
                      onClick={() =>
                        this.handlePageClick(this.state.totalPage.length)
                      }
                    >
                      {this.state.totalPage.length}
                    </Pagination.Item>
                  )}
                  {this.state.page < this.state.totalPage.length && (
                    <Pagination.Next
                      onClick={() => this.handlePageClick(this.state.page + 1)}
                      disabled={
                        this.state.page + 1 === this.state.totalPage.length ||
                        this.state.page === this.state.totalPage.length ||
                        this.state.page > this.state.totalPage.length
                      }
                    />
                  )}
                </Pagination>
              </>
            )}
            {this.state.switch === 1 && (
              <>
                <ScrollView
                  style={{ width: "100%", height: "72vh" }}
                  contentContainerStyle={{ alignItems: "center" }}
                >
                  {this.state.listaProposte.length == 0 && <Text></Text>}
                  {this.state.listaProposte.length > 0 &&
                    this.state.listaProposte.map((item) => {
                      return (
                        <View style={style.containerProposte}>
                          <img
                            style={{
                              width: "30%",
                              resizeMode: "center",
                              height: "100%",
                              marginRight: "3%",
                            }}
                            src={item.imageProdotto}
                            onError={(e) => {
                              e.target.src = scarpaVuota;
                            }}
                          />
                          <View style={style.containerLabelProdotto}>
                            <Text style={style.titleContProd}>
                              {item.modello}
                            </Text>
                            {/* <Text style={style.SubTitleContProd}>
                      {item.modello}
                      </Text> */}
                            <View style={{ flexDirection: "row" }}>
                              <View>
                                <Text style={style.boldLabel}>
                                  {t("stock_n")} <br />
                                </Text>
                                <Text style={{ color: "red" }}>
                                  {item.lotto.id}
                                </Text>
                                <Text style={style.boldLabel}>
                                  {t("price")} <br />
                                </Text>
                                <Text>{item.offerta}€</Text>
                              </View>
                              <View style={{ marginLeft: 30 }}>
                                <Text style={style.boldLabel}>
                                  {t("quantity")}
                                  <br />
                                </Text>
                                <Text>{item.qta}</Text>
                                <Text style={style.boldLabel}>
                                  {t("status")}
                                  <br />
                                </Text>
                                <Text>{item.nome}</Text>
                              </View>
                              <View
                                style={{
                                  flexGrow: 1,
                                  alignItems: "flex-end",
                                  paddingRight: 10,
                                }}
                              >
                                {item.isOfferta === 0 && (
                                  <div className="proposta-account-detail">
                                    <p
                                      style={{ marginTop: 20 }}
                                      onClick={() => {
                                        this.onPressVisualizzatore(item.lotto);
                                      }}
                                    >
                                      {t("view_stock")}
                                    </p>
                                  </div>
                                )}
                                {item.isOfferta > 0 && (
                                  <div className="proposta-account-detail">
                                    <button
                                      className="completa-checkout"
                                      onClick={() => this.onOpenModal(item)}
                                    >
                                      {t("comp_checkout")}
                                    </button>
                                  </div>
                                )}
                                <TouchableOpacity
                                  onPress={() =>
                                    this.onPressModalTools(item.lotto)
                                  }
                                >
                                  <Text
                                    style={{
                                      marginRight: 10,
                                      alignSelf: "flex-end",
                                      color: "rgb(10, 9, 162)",
                                      fontSize: "1.2rem",
                                      fontWeight: "900",
                                      lineHeight: 8,
                                    }}
                                  >
                                    .<br />.<br />.
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      );
                    })}
                </ScrollView>
                <Pagination>
                  {this.state.pageProposte !== 1 && (
                    <Pagination.Prev
                      onClick={() =>
                        this.handlePageClickProposte(
                          this.state.pageProposte - 1
                        )
                      }
                      disabled={this.state.pageProposte === 1}
                    />
                  )}
                  {this.state.pageProposte > 1 && (
                    <Pagination.Item
                      onClick={() => this.handlePageClickProposte(1)}
                    >
                      1
                    </Pagination.Item>
                  )}
                  {this.state.pageProposte > 1 && <Pagination.Ellipsis />}
                  {this.state.pageProposte - 1 > 1 && (
                    <Pagination.Item
                      active={
                        this.state.pageProposte === this.state.pageProposte - 1
                      }
                      key={"_pagination_" + this.state.pageProposte - 1}
                      onClick={() =>
                        this.handlePageClickProposte(
                          this.state.pageProposte - 1
                        )
                      }
                    >
                      {this.state.pageProposte !== 1
                        ? this.state.pageProposte - 1
                        : null}
                    </Pagination.Item>
                  )}

                  <Pagination.Item
                    active={this.state.pageProposte === this.state.pageProposte}
                    key={"_pagination_" + this.state.pageProposte}
                    onClick={() =>
                      this.handlePageClickProposte(this.state.pageProposte)
                    }
                  >
                    {this.state.pageProposte}
                  </Pagination.Item>
                  {this.state.pageProposte !==
                    this.state.totalPageOfferte.length && (
                    <Pagination.Item
                      active={
                        this.state.pageProposte + 1 === this.state.pageProposte
                      }
                      key={"_pagination_" + this.state.pageProposte + 1}
                      onClick={() =>
                        this.handlePageClickProposte(
                          this.state.pageProposte + 1
                        )
                      }
                    >
                      {this.state.pageProposte !==
                      this.state.totalPageOfferte.length
                        ? this.state.pageProposte + 1
                        : null}
                    </Pagination.Item>
                  )}
                  {this.state.pageProposte + 1 <
                    this.state.totalPageOfferte.length && (
                    <Pagination.Ellipsis />
                  )}
                  {this.state.pageProposte + 1 <
                    this.state.totalPageOfferte.length && (
                    <Pagination.Item
                      onClick={() =>
                        this.handlePageClickProposte(
                          this.state.totalPageOfferte.length
                        )
                      }
                    >
                      {this.state.totalPageOfferte.length}
                    </Pagination.Item>
                  )}
                  {this.state.pageProposte !==
                    this.state.totalPageOfferte.length && (
                    <Pagination.Next
                      onClick={() =>
                        this.handlePageClickProposte(
                          this.state.pageProposte + 1
                        )
                      }
                      disabled={
                        this.state.pageProposte + 1 ===
                          this.state.totalPageOfferte.length ||
                        this.state.pageProposte ===
                          this.state.totalPageOfferte.length ||
                        this.state.pageProposte >
                          this.state.totalPageOfferte.length
                      }
                    />
                  )}
                </Pagination>
              </>
            )}
            {this.state.switch === 2 && (
              <>
                <ScrollView
                  style={{ width: "100%", height: "72vh" }}
                  contentContainerStyle={{ alignItems: "center" }}
                >
                  {this.state.listaCustom.length == 0 && (
                    <Text>{t("no_item")}</Text>
                  )}
                  {this.state.listaCustom.length > 0 &&
                    this.state.listaCustom.map((item) => {
                      return (
                        <View style={style.containerProposte}>
                          <img
                            style={{
                              width: "30%",
                              resizeMode: "center",
                              height: "100%",
                              marginRight: "3%",
                            }}
                            src={item.prodotto.imageProdotto}
                            onError={(e) => {
                              e.target.src = scarpaVuota;
                            }}
                          />
                          <View style={style.containerLabelProdotto}>
                            <Text style={style.titleContProd}>
                              {item.prodotto.nome}
                            </Text>
                            <Text style={style.SubTitleContProd}>
                              {item.prodotto.modello}
                            </Text>
                            <View style={{ flexDirection: "row" }}>
                              <View>
                                <Text style={style.boldLabel}>
                                  {t("stock_n")} <br />
                                </Text>
                                <Text style={{ color: "red" }}>
                                  {item.riferimento}
                                </Text>
                                <Text style={style.boldLabel}>
                                  {t("price")} <br />
                                </Text>
                                <Text>{item.payin}€</Text>
                              </View>
                              <View style={{ marginLeft: 30 }}>
                                <Text style={style.boldLabel}>
                                  {t("quantity")}
                                  <br />
                                </Text>
                                <Text>{item.quantita}</Text>
                              </View>
                              <View
                                style={{
                                  flexGrow: 1,
                                  alignItems: "flex-end",
                                  paddingRight: 10,
                                }}
                              >
                                {item.isOfferta === 0 && (
                                  <div className="proposta-account-detail"></div>
                                )}
                                {item.isOfferta > 0 && (
                                  <div className="proposta-account-detail">
                                    <button
                                      className="completa-checkout"
                                      onClick={() => this.onOpenModal(item)}
                                    >
                                      {t("comp_checkout")}
                                    </button>
                                  </div>
                                )}
                                <TouchableOpacity
                                  onPress={() => this.onPressModalTools(item)}
                                >
                                  <Text
                                    style={{
                                      marginRight: 10,
                                      alignSelf: "flex-end",
                                      color: "rgb(10, 9, 162)",
                                      fontSize: "1.2rem",
                                      fontWeight: "900",
                                      lineHeight: 8,
                                    }}
                                  >
                                    .<br />.<br />.
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      );
                    })}
                </ScrollView>
              </>
            )}
          </View>
          <ModalVisualizzatore
            modalAdd={this.state.visualizzatore}
            onModalDismiss={this.onHideVisualizzatore}
            lotto={this.state.prodottoVisualizzatore}
            taglie={this.state.taglieVisualizzatore}
          />
          <MobileModal
            visible={this.state.modalSelectedItem}
            animationType={"fade"}
            transparent
            style={{}}
          >
            <View
              style={{
                height: "100%",
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-end",
                backgroundColor: "#11111160",
              }}
            >
              <TouchableOpacity
                style={{ width: "100%", flexGrow: 1 }}
                onPress={() => this.onCloseModalTools()}
              ></TouchableOpacity>
              <View
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderTopLeftRadius: 30,
                  borderTopRightRadius: 30,
                  shadowRadius: 8,
                  shadowOpacity: 0.3,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingHorizontal: 30,
                  paddingVertical: 20,
                }}
              >
                <Text style={style.labelModalTools}>
                  {t("stock_n")}{" "}
                  {this.state.switch === 2
                    ? this.state.modalItemTools?.riferimento
                    : this.state.modalItemTools?.id}
                </Text>
                <TouchableOpacity
                  style={style.buttonModalTools}
                  onPress={() =>
                    this.onPressVisualizzatore(this.state.modalItemTools)
                  }
                >
                  <Text style={style.labelBtnTools}>{t("view")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </MobileModal>
        </View>
      </div>
    );
  };
  DesktopRender = () => {
    const { t } = this.props.t;
    return (
      <div className="container-account-general">
        <Header />
        <div className="container-account-route">
          <AccountRoutes />
          <div className="right-column-account">
            <div className="header-account-pages">
              <div
                className="buttons-header-account-pages-div"
                style={{ width: "48%" }}
              >
                <button
                  onClick={() => {
                    this.setState({ switch: 2 });
                  }}
                  className={
                    this.state.switch === 2 ? "btn-switch-0" : "btn-switch-1"
                  }
                >
                  {t("custom_proposal")}
                  {/* {t("purch_prop")} */}
                </button>
                <button
                  onClick={() => {
                    this.setState({ switch: 0 });
                  }}
                  className={
                    this.state.switch === 0 ? "btn-switch-0" : "btn-switch-1"
                  }
                >
                  {t("in_prog")}
                  {/* {t("pending_prop")} */}
                </button>
                <button
                  onClick={() => {
                    this.setState({ switch: 1 });
                  }}
                  className={
                    this.state.switch === 1 ? "btn-switch-0" : "btn-switch-1"
                  }
                >
                  {t("purch_prop")}
                </button>
              </div>
              {/*<div className="search-bar-div-account">
            <label className="search-bar-account">
              <SearchIcon className="search-icon" />
              <input
                type="search"
                placeholder="Search"
                className="search-bar-input"
              />
            </label>
            </div>*/}
            </div>

            {/* LISTA PRODOTTI */}
            {this.state.switch === 2 && this.state.listaCustom.length === 0 && (
              <p style={{ marginTop: 10, marginLeft: 10 }}>{t("no_product")}</p>
            )}
            {this.state.switch === 2 && this.state.listaCustom.length > 0 && (
              <>
                {this.state.listaCustom.map((prodotto, key) => {
                  return (
                    <div className="account-list">
                      <div className="account-list-detail">
                        <div className="scarpa-img-list">
                          <img
                            src={prodotto.prodotto.imageProdotto}
                            onError={(e) => {
                              e.target.src = scarpaVuota;
                            }}
                            alt="scarpa"
                          />
                        </div>
                        <div className="modello-account-detail">
                          <p className="title-modello">{t("model")}</p>
                          <p className="modello-mod">
                            {prodotto.prodotto.nome}
                          </p>
                          <p className="tag-modello">{prodotto.prodotto.tag}</p>
                        </div>
                        <div className="n-lotto-detail">
                          <p className="title-div">{t("stock")} n°</p>
                          <p className="qty-div stock-n-account">
                            {prodotto.riferimento}
                          </p>
                        </div>
                        <div className="n-lotto-detail">
                          <p className="title-div">{t("qty")}</p>
                          <p className="qty-div">{prodotto.quantita}</p>
                        </div>
                        <div className="qty-account-detail">
                          <p className="title-div">{t("price")} </p>
                          <p className="qty-div">
                            <b>
                              {parseFloat(prodotto.payin)
                                .toFixed(2)
                                .toString()
                                .replace(".", ",") + " €"}
                            </b>
                          </p>
                        </div>
                        {prodotto.isOfferta === 0 && (
                          <div className="proposta-account-detail">
                            <p className="title-div">{t("status")}</p>
                            <p className="qty-div">{t(prodotto.StatoNome)}</p>
                            <p
                              style={{ marginTop: 20 }}
                              onClick={() => {
                                this.onPressVisualizzatore(prodotto.lotto);
                              }}
                            >
                              {t("view_stock")}
                            </p>
                          </div>
                        )}
                        {prodotto.isOfferta > 0 && (
                          <div className="proposta-account-detail">
                            <button
                              className="completa-checkout"
                              onClick={() => this.onOpenModal(prodotto)}
                            >
                              {t("comp_checkout")}
                            </button>
                            <p
                              style={{ marginTop: 20 }}
                              onClick={() => {
                                this.onPressVisualizzatore(prodotto.lotto);
                              }}
                            >
                              {t("view_stock")}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {this.state.switch === 0 &&
              this.state.listaProdotti.length === 0 && (
                <p style={{ marginTop: 10, marginLeft: 10 }}>
                  {t("no_product")}
                </p>
              )}
            {this.state.switch === 0 && this.state.listaProdotti.length > 0 && (
              <>
                {this.state.listaProdotti.map((prodotto, key) => {
                  return (
                    <div className="account-list">
                      <div className="account-list-detail">
                        <div className="scarpa-img-list">
                          <img
                            src={prodotto.imageProdotto}
                            onError={(e) => {
                              e.target.src = scarpaVuota;
                            }}
                            alt="scarpa"
                          />
                        </div>
                        <div className="modello-account-detail">
                          <p className="title-modello">{t("model")}</p>
                          <p className="modello-mod">{prodotto.nome}</p>
                          <p className="tag-modello">{prodotto.tag}</p>
                        </div>
                        <div className="n-lotto-detail">
                          <p className="title-div">{t("stock")} n°</p>
                          <p className="qty-div stock-n-account">
                            {prodotto.lotto.id}
                          </p>
                        </div>
                        <div className="n-lotto-detail">
                          <p className="title-div">{t("qty")}</p>
                          <p className="qty-div">{prodotto.qta}</p>
                        </div>
                        <div className="qty-account-detail">
                          <p className="title-div">{t("price")}</p>
                          <p className="qty-div">
                            <b>
                              {parseFloat(prodotto.lotto.payin)
                                .toFixed(2)
                                .toString()
                                .replace(".", ",") + " €"}
                            </b>
                          </p>
                        </div>
                        {prodotto.isOfferta === 0 && (
                          <div className="proposta-account-detail">
                            <p className="title-div">{t("status")}</p>
                            <p className="qty-div">{t(prodotto.StatoNome)}</p>
                            <p
                              style={{ marginTop: 20 }}
                              onClick={() => {
                                this.onPressVisualizzatore(prodotto.lotto);
                              }}
                            >
                              {t("view_stock")}
                            </p>
                          </div>
                        )}
                        {prodotto.isOfferta > 0 && (
                          <div className="proposta-account-detail">
                            <button
                              className="completa-checkout"
                              onClick={() => this.onOpenModal(prodotto)}
                            >
                              {t("comp_checkout")}
                            </button>
                            <p
                              style={{ marginTop: 20, cursor: "pointer" }}
                              onClick={() => {
                                this.onPressVisualizzatore(prodotto.lotto);
                              }}
                            >
                              {t("view_stock")}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}

                <Pagination>
                  {this.state.page > 1 && (
                    <Pagination.Prev
                      onClick={() => this.handlePageClick(this.state.page - 1)}
                    />
                  )}
                  {this.state.page > 1 && (
                    <Pagination.Item onClick={() => this.handlePageClick(1)}>
                      1
                    </Pagination.Item>
                  )}
                  {this.state.page > 1 && <Pagination.Ellipsis />}
                  {this.state.page - 1 > 1 && (
                    <Pagination.Item
                      active={this.state.page === this.state.page - 1}
                      key={"_pagination_" + this.state.page - 1}
                      onClick={() => this.handlePageClick(this.state.page - 1)}
                    >
                      {this.state.page !== 1 ? this.state.page - 1 : null}
                    </Pagination.Item>
                  )}
                  <Pagination.Item
                    active={this.state.page === this.state.page}
                    key={"_pagination_" + this.state.page}
                    onClick={() => this.handlePageClick(this.state.page)}
                  >
                    {this.state.page}
                  </Pagination.Item>
                  {this.state.page != this.state.totalPage.length && (
                    <Pagination.Item
                      active={this.state.page + 1 === this.state.page}
                      key={"_pagination_" + this.state.page + 1}
                      onClick={() => this.handlePageClick(this.state.page + 1)}
                    >
                      {this.state.page + 1}
                    </Pagination.Item>
                  )}
                  {this.state.page + 1 < this.state.totalPage.length && (
                    <Pagination.Ellipsis />
                  )}
                  {this.state.page + 1 < this.state.totalPage.length && (
                    <Pagination.Item
                      onClick={() =>
                        this.handlePageClick(this.state.totalPage.length)
                      }
                    >
                      {this.state.totalPage.length}
                    </Pagination.Item>
                  )}
                  {this.state.page < this.state.totalPage.length && (
                    <Pagination.Next
                      onClick={() => this.handlePageClick(this.state.page + 1)}
                      disabled={
                        this.state.page + 1 === this.state.totalPage.length ||
                        this.state.page === this.state.totalPage.length ||
                        this.state.page > this.state.totalPage.length
                      }
                    />
                  )}
                </Pagination>
              </>
            )}
            {this.state.switch === 1 &&
              this.state.listaProposte.length === 0 && <p>{t("no_product")}</p>}
            {this.state.switch === 1 && this.state.listaProposte.length > 0 && (
              <>
                {this.state.listaProposte.map((prodotto, key) => {
                  return (
                    <div className="account-list">
                      <div className="account-list-detail">
                        <div className="scarpa-img-list">
                          <img
                            src={prodotto.imageProdotto}
                            alt="scarpa"
                            onError={(e) => {
                              e.target.src = scarpaVuota;
                            }}
                          />
                        </div>
                        <div className="modello-account-detail">
                          <p className="title-modello">{t("model")}</p>
                          <p className="modello-mod">{prodotto.modello}</p>
                          <p className="tag-modello">{prodotto.tag}</p>
                        </div>
                        <div className="qty-account-detail">
                          <p className="title-div">{t("stock_n")}</p>
                          <p className="qty-div stock-n-account">
                            {prodotto.lotto.id}
                          </p>
                        </div>
                        <div className="qty-account-detail">
                          <p className="title-div">{t("qty")}</p>
                          <p className="qty-div">{prodotto.lotto.quantita}</p>
                        </div>
                        <div className="qty-account-detail">
                          <p className="title-div">{t("bid")}</p>
                          <p className="qty-div">
                            <b>
                              {parseFloat(prodotto.offerta)
                                .toFixed(2)
                                .toString()
                                .replace(".", ",") + " €"}
                            </b>
                          </p>
                        </div>

                        <div className="proposta-account-detail">
                          <p className="title-div">{t("status")}</p>
                          <p className="qty-div">{prodotto.nome}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <Pagination>
                  {this.state.pageProposte !== 1 && (
                    <Pagination.Prev
                      onClick={() =>
                        this.handlePageClickProposte(
                          this.state.pageProposte - 1
                        )
                      }
                      disabled={this.state.pageProposte === 1}
                    />
                  )}
                  {this.state.pageProposte > 1 && (
                    <Pagination.Item
                      onClick={() => this.handlePageClickProposte(1)}
                    >
                      1
                    </Pagination.Item>
                  )}
                  {this.state.pageProposte > 1 && <Pagination.Ellipsis />}
                  {this.state.pageProposte - 1 > 1 && (
                    <Pagination.Item
                      active={
                        this.state.pageProposte === this.state.pageProposte - 1
                      }
                      key={"_pagination_" + this.state.pageProposte - 1}
                      onClick={() =>
                        this.handlePageClickProposte(
                          this.state.pageProposte - 1
                        )
                      }
                    >
                      {this.state.pageProposte !== 1
                        ? this.state.pageProposte - 1
                        : null}
                    </Pagination.Item>
                  )}

                  <Pagination.Item
                    active={this.state.pageProposte === this.state.pageProposte}
                    key={"_pagination_" + this.state.pageProposte}
                    onClick={() =>
                      this.handlePageClickProposte(this.state.pageProposte)
                    }
                  >
                    {this.state.pageProposte}
                  </Pagination.Item>
                  {this.state.pageProposte !==
                    this.state.totalPageOfferte.length && (
                    <Pagination.Item
                      active={
                        this.state.pageProposte + 1 === this.state.pageProposte
                      }
                      key={"_pagination_" + this.state.pageProposte + 1}
                      onClick={() =>
                        this.handlePageClickProposte(
                          this.state.pageProposte + 1
                        )
                      }
                    >
                      {this.state.pageProposte !==
                      this.state.totalPageOfferte.length
                        ? this.state.pageProposte + 1
                        : null}
                    </Pagination.Item>
                  )}
                  {this.state.pageProposte + 1 <
                    this.state.totalPageOfferte.length && (
                    <Pagination.Ellipsis />
                  )}
                  {this.state.pageProposte + 1 <
                    this.state.totalPageOfferte.length && (
                    <Pagination.Item
                      onClick={() =>
                        this.handlePageClickProposte(
                          this.state.totalPageOfferte.length
                        )
                      }
                    >
                      {this.state.totalPageOfferte.length}
                    </Pagination.Item>
                  )}
                  {this.state.pageProposte !==
                    this.state.totalPageOfferte.length && (
                    <Pagination.Next
                      onClick={() =>
                        this.handlePageClickProposte(
                          this.state.pageProposte + 1
                        )
                      }
                      disabled={
                        this.state.pageProposte + 1 ===
                          this.state.totalPageOfferte.length ||
                        this.state.pageProposte ===
                          this.state.totalPageOfferte.length ||
                        this.state.pageProposte >
                          this.state.totalPageOfferte.length
                      }
                    />
                  )}
                </Pagination>
              </>
            )}
            <Modal
              size={"xl"}
              show={this.state.visualizzatore}
              onHide={() => this.onHideVisualizzatore()}
            >
              <div className="body-modal-view-lotto">
                <div className="mvl-header">
                  <p className="mvl-title">
                    {t("stock_n")} {this.state.prodottoVisualizzatore.id}
                  </p>
                </div>
                <div
                  className="nuovolotto-div-tuttofortissimo"
                  style={{ backgroundColor: "#f5f5f5" }}
                >
                  <div className="container-per-griglia">
                    <div className="content-griglia-tuttofortissimo-flex-start">
                      {this.state.taglieVisualizzatore.map((item, index) => (
                        <div className="taglia-nuovo-lotto-new">
                          <div className="taglie-per-custom-modal">
                            <span
                              style={{
                                fontSize: "0.8rem",
                                alignSelf: "center",
                              }}
                            >
                              US {item.taglia}
                            </span>

                            <div className="taglie-per-custom-modal-child">
                              <span
                                className="right-box-noblue"
                                style={{ fontSize: "0.7rem" }}
                              >
                                {t("qty")}
                              </span>
                              <div className="right-box-blue">
                                {item.numero}
                              </div>
                            </div>
                          </div>

                          <div className="taglie-per-custom-modal-child">
                            <span
                              className="right-box-noblue"
                              style={{ fontSize: "0.7rem" }}
                            >
                              {t("price")}
                            </span>
                            <div className="right-box-blue">{item.prezzo}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mvl-fortissimo">
                  <p className="mvl-price">
                    {
                      //Traduzione
                    }{" "}
                    <div className="prezzi-nuovo-lotto-2">
                      <p
                        class="text-right-lotto"
                        style={{
                          width: "100%",
                          marginRight: "6%",
                          fontSize: "initial",
                        }}
                      >
                        {t("tot_pay")}:
                        <b>{this.state.prodottoVisualizzatore.prezzo + " €"}</b>
                        <br />
                        {!this.state.prodottoVisualizzatore.ivaMargine ? (
                          t("Iva") + "22%: "
                        ) : (
                          <>{t("marginal_vat_checked") + ":"}</>
                        )}
                        <b>
                          {this.calcIva(this.state.prodottoVisualizzatore) +
                            " €"}
                        </b>
                        <br />
                        {t("shipping_fee")}:
                        <b>
                          {this.calcFeeSped(this.state.prodottoVisualizzatore) +
                            " €"}
                        </b>
                        <br />
                        {t("transaction_fees")}:
                        <b>
                          {this.calcWKfee(this.state.prodottoVisualizzatore) +
                            " €"}
                        </b>
                        <br />
                        {t("tot_pay_to_do")}:
                        <b>
                          {this.totale(this.state.prodottoVisualizzatore) +
                            " €"}
                        </b>
                      </p>
                    </div>
                  </p>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { t } = this.props.t;
    return (
      <>
        {this.props.desktop ? <this.DesktopRender /> : <this.MobileRender />}
        <Modal
          size={"xl"}
          show={this.state.modalCheckOut}
          onHide={() => this.onHideModal()}
        >
          <div className="body-modal-view-lotto">
            <div className="checkout-form-div-modal">
              <p className="checkout-title-modal">{t("checkout")}</p>
              <span className="checkout-sub-title-modal">{t("pay_info")}</span>
              <span
                className="checkout-sub-sub-total-modal"
                style={{ textAlign: "center" }}
              >
                {t("total")}
                <b>
                  {" "}
                  {parseFloat(this.state.prodottoCheck?.lotto?.prezzo)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",") + " €"}
                </b>
                <br />
                {t("shipping_fee")} ( {t("pair_of_shoes")}):{" "}
                <b>
                  {" "}
                  {"+" +
                    parseFloat(
                      this.calcShipFee(this.state.prodottoCheck?.lotto)
                    )
                      .toFixed(2)
                      .toString()
                      .replace(".", ",") +
                    " €"}
                </b>
                <br />
                <br />
                V.A.T. 22%{" "}
                <b>
                  {" "}
                  {parseFloat(this.calcIva(this.state.prodottoCheck?.lotto))
                    .toFixed(2)
                    .toString()
                    .replace(".", ",") + " €"}
                </b>
                <br />
                {t("transaction_fees")} (5%):
                <b>
                  {" "}
                  {"+" +
                    parseFloat(this.calcWKfee(this.state.prodottoCheck?.lotto))
                      .toFixed(2)
                      .toString()
                      .replace(".", ",") +
                    " €"}
                </b>
                <br />
              </span>
              <span className="checkout-sub-sub-total-modal">
                {t("tot_stock")}:
              </span>
              <span className="checkout-total-4">
                {this.state.prodottoCheck?.lotto?.payin}€
              </span>

              <img
                src={this.state.prodottoCheck?.imageProdotto}
                onError={(e) => {
                  e.target.src = scarpaVuota;
                }}
                alt="scarpa-immagine"
                className="checkout-image-prod"
              />
              <p>{this.state.prodottoCheck?.nome}</p>
              <p>{this.state.prodottoCheck?.modello}</p>

              <div
                className="checkout-iban-div-modal"
                style={{ padding: "3%" }}
              >
                <span>{t("complete_buy")}:</span>
                <p>Bank: Bper Banca</p>
                <p>IBAN: IT84K0538703410000003423434</p>
                <p>Name: WHOLE KICKS S.R.L.</p>
              </div>
              <div className="carica-ricevuta-bonifico-modal">
                <label for="ricevuta">
                  {this.state.fileRicevuta === ""
                    ? ""
                    : this.state.fileRicevuta}
                </label>
                <span style={{ width: "100%" }}>
                  <label for="ricevuta" className="choose-file">
                    {t("upload_file")}
                  </label>
                </span>
                <input
                  name="ricevuta"
                  id="ricevuta"
                  className="input-file-visibility"
                  type="file"
                  placeholder="Upload Receipt"
                  accept=".pdf"
                  onChange={(e) => {
                    console.log(e.target.value);
                    let trimValue = e.target.value.split("\\");
                    console.log(trimValue);
                    console.log(e.target.files[0]);
                    this.setState({
                      fileRicevuta: trimValue[trimValue.length - 1],
                      imgeRicevuta: e.target.files[0],
                    });
                  }}
                />
              </div>
            </div>
            <div
              className="mvl-footer-fortissimo"
              style={{ width: "85%", marginTop: 8 }}
            >
              <p className="mvl-price"></p>
              <div className="footer-button-custom custom-btn-conferma">
                <button
                  disabled={this.state.fileRicevuta === ""}
                  className={
                    this.state.fileRicevuta === ""
                      ? "pointer-link-none"
                      : "pointer-link-auto"
                  }
                  onClick={() =>
                    this.submitPayment(
                      this.state.prodottoCheck?.nLotto,
                      this.state.prodottoCheck
                    )
                  }
                >
                  {t("confirm")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  HeaderMobileProfile: {
    flexDirection: "row",
    width: "100%",
    paddingBottom: 15,
    justifyContent: "space-around",
    borderBottomColor: "#7A79CD",
    borderBottomWidth: 0.5,
  },
  containerProposte: {
    width: "100%",
    marginTop: 10,
    marginVertical: 20,
    flexDirection: "row",
    borderBottomColor: "#7A79CD",
    borderBottomWidth: 0.5,
  },

  containerLabelProdotto: {
    width: "60%",
    marginLeft: 8,
  },

  titleContProd: {
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  containerShipping: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginVertical: 10,
  },
  boldLabel: {
    fontWeight: 700,
    fontSize: "0.9rem",
    marginTop: "5%",
  },
  NoboldLabel: {
    fontSize: "0.9rem",
  },
  labelModalTools: {
    fontSize: "1.1rem",
    fontWeight: "800",
    marginBottom: 6,
  },
  buttonModalTools: {
    paddingVertical: 18,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderTopWidth: 1,
    borderColor: "#DEE4FD",
  },
  labelBtnTools: {
    fontWeight: "700",
    color: "#0A09A2",
  },
  mobileCompletaCheckoutButton: {
    borderWidth: 0,
    backgroundColor: "#0A09A2",
    color: "#fff",
    borderRadius: 8,
  },
  SubTitleContProd: {
    fontSize: "0.8rem",
  },
});

export default function (props) {
  const desktop = useDesktopChecker();
  const t = useTranslation();
  return <Acquisti desktop={desktop} t={t} />;
}
