import React from "react";
import { View, Text } from "react-native";
import { Link, useLocation } from "react-router-dom";
import "./style.css";

const Menu = () => {
  const location = useLocation();
  return (
    <View
      style={{
        flexDirection: "row",
        alignSelf: "flex-start",
        marginLeft: 10,
        marginTop: 10,
      }}
    >
      <View style={{ width: 15 }} />
      <Link className="header-link" to="/">
        <p
          className={
            location.pathname === "/" ? "menu-label-ac" : "menu-label-un"
          }
        >
          Home
        </p>
      </Link>
      <View style={{ width: 15 }} />
      <Link className="header-link" to="/new-in/1">
        <p
          className={
            location.pathname === "/new-in/1"
              ? "menu-label-ac"
              : "menu-label-un"
          }
        >
          New In
        </p>
      </Link>
      <View style={{ width: 15 }} />
      {/* <Link className="header-link" to="/shop-all">
        <p
          className={
            location.pathname === "/shop-all" ? "menu-label-ac" : "menu-label-un"
          }
        >
          Shop All
        </p>
      </Link> */}
      {/* <View style={{ width: 15 }} /> */}
      {/* <Link className="header-link" to="/sell" state={{pos:0}}>
        <p
          className={
            location.pathname === "/sell" ? "menu-label-ac" : "menu-label-un"
          }
        >
          Sell
        </p>
      </Link>
      <View style={{ width: 15 }} /> */}
      <Link className="header-link" to="/about">
        <p
          className={
            location.pathname === "/about" ? "menu-label-ac" : "menu-label-un"
          }
        >
          About
        </p>
      </Link>
    </View>
  );
};

export default Menu;
