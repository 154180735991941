import React, { Component } from "react";
import "../../styles/AccountDetails.css";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CONSTANTS from "../../CONSTANTS";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Pagination from "react-bootstrap/Pagination";
import jsPDF from "jspdf";
import Header from "../../components/Header";
import AccountRoutes from "../../components/AccountRoutes";
import { lottoVettore } from "../../components/utils/defaultObject";
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
  Modal as MobileModal,
} from "react-native";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import { useTranslation } from "react-i18next";
import ModalVisualizzatore from "../../pagesMobile/singleProduct/ModalVisualizzatore";
import ModalSearch from "../../pagesMobile/singleProduct/ModalSearch";
import ModalSearchMobile from "../../pagesMobile/singleProduct/ModalSearchMobile";
import ModalCustomViewer from "../../pagesMobile/singleProduct/ModalCustomViewer";
import ModalNewLotto from "../../pagesMobile/singleProduct/ModalNewLotto";
import ModalVisualizzaListati from "../../pagesMobile/singleProduct/ModalVisualizzaListati";
import { sendMailNotification } from "../chiamateApi/ApiProdotto";
import scarpaVuota from "../../assets/wk_products_not_found.png";
import { getUser } from "../chiamateApi/ApiModifyUser";
import { Switch } from "@mui/material";

class Vendite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      listaProdotti: [],
      listaProposte: [],
      listaLottiFatti: [],
      modalSearch: false,
      elementPerPage: 9,
      page: 1,
      pageMieiLotti: 1,
      totalPage: [],
      totalPageOfferte: [],
      totalPageMieiLotti: [],
      pageProposte: 1,
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
      UtenteId: localStorage.getItem(CONSTANTS.ID),
      email: localStorage.getItem(CONSTANTS.EMAIL),
      nazione: localStorage.getItem(CONSTANTS.NAZIONE),
      switch: 0,
      prodottoModalPropostaVendite: {},
      prodottoModalLotto: {},
      showModalPropostaVendita: false,
      showModalLotto: false,
      activeVendite: [],
      taglieVisualizzaLotti: [],
      taglieVisualizzaLottiCustom: [],
      taglieDeleted: [],
      backupTaglie: [],
      modalConfermaEdit: false,
      modalConfirmData: {},
      modalConfermaAccetta: false,
      recapEdit: {},
      editStock: false,
      propostaScelta: {},
      visualizzatore: false,
      taglieVisualizzatore: [],
      prodottoVisualizzatore: {},
      loading: false,
      modalSelectedItem: false,
      modalItemTools: {},
      showSaveBtn: false,
      selectListed: false,
      lottoPerLaProposta: {},
      showNewLotto: false,
      nuovoPrezzoLotto: 0,
    };
    this.lottiDefault = lottoVettore;
  }
  componentDidMount = async () => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
    await this.refreshPage();
    await this.getProposteLottiCustom();
    console.log("this.state.listaLottiFatti", this.state.listaLottiFatti);
  };

  calcWKfee = (lotto) => {
    return lotto?.prezzo * 0.025;
    //return 0
  };

  calcIva = (lotto) => {
    //OLD
    // if (this.state.nazione === "Italy" && !lotto.ivaMargine)
    //   return parseFloat(
    //     (
    //       (this.calcFeeSped(lotto) + this.calcWKfee(lotto) + lotto.prezzo) *
    //       0.22
    //     ).toFixed(2)
    //   );
    // else {
    //   return 0;
    // }

    return !lotto.ivaMargine
      ? this.state.nazione === "Italy"
        ? parseFloat(
            (
              (lotto.prezzo -
                (this.calcFeeSped(lotto) + this.calcWKfee(lotto))) *
              0.22
            ).toFixed(2)
          )
        : 0
      : 0;
  };
  totale = (lotto) => {
    return (
      lotto.prezzo -
      (this.calcFeeSped(lotto) + this.calcWKfee(lotto)) +
      this.calcIva(lotto)
    );
  };
  refreshPage = async () => {
    await this.getProposteLottiCustom();
    axios
      .get(
        CONSTANTS.API_URL +
          "/offertaFatta/account/" +
          this.state.UtenteId +
          "/0/9",
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((prodottoRicevuto) => {
        console.log("offertaFatta", prodottoRicevuto);
        this.setState({
          listaProposte: prodottoRicevuto.data,
        });
      });
    axios
      .get(
        CONSTANTS.API_URL + "/vendite/buyId/" + this.state.UtenteId + "/0/9",
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((prodottoRicevuto) => {
        console.log("prodottoRicevuto", prodottoRicevuto);
        this.setState({
          listaProdotti: prodottoRicevuto.data,
        });
      });

    await axios
      .get(
        CONSTANTS.API_URL + "/lotto/account/" + this.state.UtenteId + "/0/9",
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((lottiMiei) => {
        console.log("lottiMiei", lottiMiei);
        this.setState({
          listaLottiFatti: lottiMiei.data,
        });
      });
    axios
      .get(CONSTANTS.API_URL + "/lotto/conteggio/" + this.state.UtenteId, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((proposteRicevute) => {
        if (proposteRicevute.data[0].totalCount > 1) {
          console.log(
            "proposteRicevute.data[0].totalCount",
            proposteRicevute.data[0].totalCount
          );
          const countPage =
            proposteRicevute.data[0].totalCount / this.state.elementPerPage;
          console.log(
            "proposteRicevute.data",
            proposteRicevute.data[0].totalCount
          );
          this.setState({
            totalPageMieiLotti: Array(Math.round(countPage + 0.4)),
          });
        } else {
          this.setState({
            totalPageMieiLotti: Array(1),
          });
        }
      });
    axios
      .get(
        CONSTANTS.API_URL + "/offertaFatta/conteggio/" + this.state.UtenteId,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((proposteRicevute) => {
        if (proposteRicevute.data[0].totalCount > 1) {
          const countPage =
            proposteRicevute.data[0].totalCount / this.state.elementPerPage;
          console.log("proposteRicevute.data", proposteRicevute.data);
          this.setState({
            totalPageOfferte: Array(Math.round(countPage + 0.4)),
          });
        } else {
          this.setState({
            totalPageOfferte: Array(1),
          });
        }
      });
    axios
      .get(CONSTANTS.API_URL + "/vendite/count-all/" + this.state.UtenteId, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((proposteRicevute) => {
        if (proposteRicevute.data.totalElement > 1) {
          const countPage =
            proposteRicevute.data.totalElement / this.state.elementPerPage;
          console.log("proposteRicevute.data", proposteRicevute.data);
          this.setState({
            totalPage: Array(Math.round(countPage + 0.4)),
          });
        } else {
          this.setState({
            totalPage: Array(1),
          });
        }
      });
  };
  getProposteLottiCustom = async () => {
    await axios
      .get(
        CONSTANTS.API_URL +
          "/lotto/account-proposal-custom/" +
          this.state.UtenteId,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((propLottiCustom) => {
        console.log("propLottiCustom", propLottiCustom);
        this.setState({
          activeVendite: propLottiCustom.data,
        });
      });
  };
  deleteLotto = async (id) => {
    const isAvailable = await axios.get(
      CONSTANTS.API_URL + "/lotto/lotto-is-available/" + id,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY
          )}`,
        },
      }
    );
    console.log("isAv", isAvailable);
    if (isAvailable.data) {
      axios
        .delete(CONSTANTS.API_URL + "/lotto/delete-lotto/" + id, {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        })
        .then((prodottoRicevuto) => {
          this.refreshPage();
        });
    } else {
      const { t } = this.props.translation;
      alert(t("you_not_delete"));
    }
  };
  //QUI MAIL RIFIUTA
  deleteLottoCustom = async (id) => {
    console.log(
      "prodottoModalPropostaVendite",
      this.state.prodottoModalPropostaVendite
    );
    const prod = await axios
      .get(CONSTANTS.API_URL + "/prodotto/lotto/" + id)
      .then(async (res) => {
        return res.data;
      });
    await axios
      .delete(CONSTANTS.API_URL + "/lotto/delete-lotto/" + id, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then(async (prodottoRicevuto) => {
        await axios
          .get(
            CONSTANTS.API_URL +
              "/account/utente/" +
              this.state.prodottoModalPropostaVendite.AccountId,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  CONSTANTS.ACCESS_TOKEN_KEY
                )}`,
              },
            }
          )
          .then(async (res) => {
            const objMail = {
              prodotto: prod,
              lotto: id,
              email: res.data.email,
              idMailVendita: 1,
              idMailAcquisto: 2,
            };
            await sendMailNotification(objMail);
            await this.getProposteLottiCustom();
            await this.refreshPage();
            console.log("data", res.data);
          });
      });
  };

  calcoloIvaNew(tot, nazione) {
    return !this.state.propostaScelta.lotto.ivaMargine
      ? nazione === "Italy"
        ? parseFloat(tot * 0.22)
        : 0
      : 0;
  }
  calcPayoutPayIn = async (lotto, nuovoPrezzo) => {
    console.log("lotto", lotto);
    const acquirente = await getUser(
      this.state.propostaScelta.account,
      this.state.token
    );
    const spedizionefee = this.calcFeeSped(lotto);
    const wkfee = nuovoPrezzo * 0.025;
    let payout = parseFloat(
      (
        parseFloat(nuovoPrezzo) -
        parseFloat(wkfee) -
        parseFloat(spedizionefee) +
        parseFloat(
          this.calcoloIvaNew(
            parseFloat(nuovoPrezzo) -
              parseFloat(wkfee) -
              parseFloat(spedizionefee),
            this.state.nazione
          )
        )
      ).toFixed(2)
    );

    let payin = parseFloat(
      (
        parseFloat(nuovoPrezzo) +
        parseFloat(wkfee) +
        parseFloat(spedizionefee) +
        parseFloat(
          this.calcoloIvaNew(
            parseFloat(nuovoPrezzo) +
              parseFloat(wkfee) +
              parseFloat(spedizionefee),
            acquirente.paese
          )
        )
      ).toFixed(2)
    );

    console.log("nuovo payout", payout);

    return {
      nuovo: nuovoPrezzo,
      payout: payout,
      payin: payin,
      id: this.state.prodottoModalLotto,
    };
  };
  calcFeeSped = (lotto) => {
    const module = lotto.quantita % 6;
    let division = lotto.quantita / 6;
    division = Math.round(division - 0.5);
    if (module > 0) {
      return (division + 1) * CONSTANTS.PRICESPEDIZIONE;
    } else {
      return division * CONSTANTS.PRICESPEDIZIONE;
    }
  };

  updateAllPrices = async (price) => {
    console.log("prezzo: ", price);
    console.log(
      "taglieVisualizzaLotti.length",
      this.state.taglieVisualizzaLotti.length
    );
    // await this.setState({ showSaveBtn: true });
    const array = [...this.state.taglieVisualizzaLotti];
    for (let i = 0; i < this.state.taglieVisualizzaLotti.length; i++) {
      const item = { ...array[i] };
      item.prezzo = price;
      array[i] = item;
    }
    this.setState({ taglieVisualizzaLotti: array });
  };
  calcLotto() {
    let qty = 0;
    let prezzo = 0;
    let taglieLotto = [...this.state.taglieVisualizzaLotti];
    for (let i = 0; i < taglieLotto.length; i++) {
      if (!taglieLotto[i].delete) {
        qty = qty + Number.parseInt(taglieLotto[i].numero);
        prezzo =
          prezzo +
          Number.parseInt(taglieLotto[i].numero) *
            Number.parseFloat(taglieLotto[i].prezzo);
      }
    }
    const finalData = this.calcPayoutPayIn({ quantita: qty }, prezzo);
    console.log("DATANL", finalData);
    return finalData;
  }
  accetta = (proposta) => {
    this.setState({ propostaScelta: proposta }, async () => {
      const calcolo = await this.calcPayoutPayIn(
        proposta.lotto,
        proposta.offerta
      );
      this.setState({ modalConfirmData: calcolo, modalConfermaAccetta: true });
    });
  };
  onHideModalConfermaAccetta = () => {
    this.setState({ modalConfermaAccetta: false }, () => {
      this.setState({ modalConfirmData: {} });
    });
  };
  accettaFinal = async (proposta) => {
    this.setState({
      modalConfermaAccetta: false,
      lottoPerLaProposta: proposta.lotto,
    });
    const calcolo = await this.calcPayoutPayIn(
      proposta.lotto,
      proposta.offerta
    );

    const body = {
      AccountId: proposta.account,
      prezzo: proposta.offerta,
      ProdottoId: proposta.ProdottoId,
      LottoId: proposta.LottoId,
      StatoPagamentoId: 1,
      isOfferta: 1,
      AccountIdVenditore: parseInt(this.state.UtenteId),
      offertaVenditore: proposta.offerta,
      ProdottoIdVenditore: proposta.ProdottoId,
      LottoIdVenditore: proposta.LottoId,
      StatoIdVenditore: 1,
      isOffertaVenditore: 1,
      prezzoVenditore: calcolo.nuovo,
      payout: calcolo.payout,
      payin: calcolo.payin,
    };

    const prod = await axios
      .get(CONSTANTS.API_URL + "/prodotto/lotto/" + proposta.LottoId)
      .then(async (res) => {
        return res.data;
      });

    await axios
      .post(CONSTANTS.API_URL + "/pagamento/isOfferta", body, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        console.log("success", res);
        this.refreshPage();
      })
      .catch((e) => {
        console.log("error", e.message);
      });

    await axios
      .get(CONSTANTS.API_URL + "/account/utente/" + proposta.account, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY
          )}`,
        },
      })
      .then(async (res) => {
        console.log("email", res.data.email);

        const objMail = {
          prodotto: prod,
          lotto: proposta.LottoId,
          email: res.data.email,
          idMailVendita: 3,
          idMailAcquisto: 4,
        };
        await sendMailNotification(objMail);
      });
  };
  rifiuta = async (prodotto) => {
    console.log("prodotto", prodotto);
    const body = { id: prodotto.id };
    const prod = await axios
      .get(CONSTANTS.API_URL + "/prodotto/lotto/" + prodotto.LottoId)
      .then(async (res) => {
        return res.data;
      });
    axios
      .post(CONSTANTS.API_URL + "/vendite/rifiuta", body, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then(async (prodottoRicevuto) => {
        await this.refreshPage();
        await axios
          .get(
            CONSTANTS.API_URL +
              "/offertaFatta/account/" +
              this.state.UtenteId +
              "/0/9",
            {
              headers: {
                Authorization: `Bearer ${this.state.token}`,
              },
            }
          )
          .then((prodottoRicevuto) => {
            console.log("offertaFatta2", prodottoRicevuto);
            this.setState({
              listaProposte: prodottoRicevuto.data,
            });
          });
      });
    await axios
      .get(CONSTANTS.API_URL + "/account/utente/" + prodotto.account, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY
          )}`,
        },
      })
      .then(async (res) => {
        const objMail = {
          prodotto: prod,
          lotto: prodotto.LottoId,
          email: res.data.email,
          idMailVendita: 2,
          idMailAcquisto: 1,
        };
        await sendMailNotification(objMail);

        console.log("data", res.data);
      });
  };

  handlePageClick = async (page) => {
    const indexOfLastTodo = page * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;

    const prodotti = await axios.get(
      CONSTANTS.API_URL +
        "/vendite/buyId/" +
        this.state.UtenteId +
        "/" +
        indexOfFirstTodo +
        "/" +
        this.state.elementPerPage,
      {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      }
    );
    const data = [...prodotti.data];
    this.setState({
      page: page,
      listaProdotti: data,
    });
  };
  handlePageClickMieiLotti = async (page) => {
    const indexOfLastTodo = page * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;

    const prodotti = await axios.get(
      CONSTANTS.API_URL +
        "/lotto/account/" +
        this.state.UtenteId +
        "/" +
        indexOfFirstTodo +
        "/" +
        this.state.elementPerPage,
      {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      }
    );
    console.log("prodotti", prodotti);

    this.setState({
      pageMieiLotti: page,
      listaLottiFatti: prodotti.data,
    });
  };
  handlePageClickProposte = async (page) => {
    const indexOfLastTodo = page * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;

    const prodotti = await axios.get(
      CONSTANTS.API_URL +
        "/offertaFatta/account/" +
        this.state.UtenteId +
        "/" +
        indexOfFirstTodo +
        "/" +
        this.state.elementPerPage,
      {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      }
    );

    this.setState({
      pageProposte: page,
      listaProposte: prodotti.data,
    });
  };
  generatePDF = () => {
    var doc = new jsPDF("p", "pt");

    doc.text(20, 20, "This is the first title.");
    doc.addFont("helvetica", "normal");
    doc.text(20, 60, "This is the second title.");
    doc.text(20, 100, "This is the thrid title.");

    doc.save("demo.pdf");
  };
  requestTaglieProdotto = async (prodotto) => {
    await axios
      .get(CONSTANTS.API_URL + "/taglia/" + prodotto.id, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        const resConstant = { ...res };
        this.setState({ taglieVisualizzaLotti: resConstant.data }, () => {
          const tempArray = [...res.data];
          this.setState({ backupTaglie: tempArray });
        });
      });
  };
  async requestTaglieVisualizzatore(prodotto) {
    await axios
      .get(CONSTANTS.API_URL + "/taglia/" + prodotto.id, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        const resConstant = { ...res };
        this.setState({ taglieVisualizzatore: resConstant.data });
      });
  }
  async requestTaglieProdottoCustom(prodotto) {
    await axios
      .get(CONSTANTS.API_URL + "/taglia/" + prodotto.id, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        const resC = res;
        console.log("r", resC.data);
        this.setState({ taglieVisualizzaLottiCustom: resC.data }, () => {
          const tempArray = [...res.data];
          this.setState({ backupTaglie: tempArray });
        });
      });
  }
  onPressVisualizzaLottiListati = (prodotto) => {
    console.log("PRODTTO: ", prodotto);
    this.requestTaglieProdotto(prodotto);
    this.setState({ showModalLotto: true, prodottoModalLotto: prodotto });
  };
  onPressVisualizzatore(prodotto) {
    if (!this.props.desktop && this.state.switch === 0) {
      this.setState({ modalSelectedItem: false });
      this.onPressVisualizzaLottiListatiCustom(prodotto);
    } else if (!this.props.desktop && this.state.switch === 2) {
      this.setState({ modalSelectedItem: false });
      this.onPressVisualizzaLottiListati(prodotto);
    } else {
      this.requestTaglieVisualizzatore(prodotto);
      this.setState({
        visualizzatore: true,
        prodottoVisualizzatore: prodotto,
        modalSelectedItem: false,
      });
    }
  }
  onHideModal = () => {
    this.setState({
      showModalLotto: false,
      prodottoModalLotto: {},
      taglieVisualizzaLotti: [],
      backupTaglie: [],
      taglieDeleted: [],
      editStock: false,
    });
  };

  onPressVisualizzaLottiListatiCustom(prodotto) {
    this.requestTaglieProdottoCustom(prodotto);
    this.openReference(prodotto.riferimento);
    this.setState({
      showModalPropostaVendita: true,
      prodottoModalPropostaVendite: prodotto,
    });
  }
  onHideModalCustom = () => {
    this.setState({
      showModalPropostaVendita: false,
      prodottoModalPropostaVendite: {},
      taglieVisualizzaLottiCustom: [],
      backupTaglie: [],
    });
  };
  onHideVisualizzatore = () => {
    this.setState({
      visualizzatore: false,
      prodottoVisualizzatore: {},
      taglieVisualizzatore: [],
      modalSearch: false,
    });
  };
  async openReference(rif) {
    const lotto = await this.requestRiferimentoById(rif);
    this.setState({ prodottoModalLotto: lotto });
    this.requestTaglieProdotto(lotto);
  }

  requestRiferimentoById = async (id) => {
    try {
      const lotto = await axios.get(
        CONSTANTS.API_URL + "/lotto/lottoId/" + id,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      );
      return lotto.data;
    } catch (e) {
      console.log("error", e);
    }
  };
  onChangeTagliaEdit = (item, type, value, id, index) => {
    let array = this.state.taglieVisualizzaLotti;
    let newItem = { ...item };
    if (type === "quantita") {
      newItem.numero = value;
    }
    if (type === "prezzo") {
      newItem.prezzo = value;
    }
    if (type === "taglia") {
      newItem.taglia = value;
    }
    if (item.new) {
      newItem.id = id;
    }
    array[index] = newItem;
    this.setState({ taglieVisualizzaLotti: array });
  };
  onHideModalConfermaEdit = () => {
    this.setState({ modalConfermaEdit: false }, () =>
      this.setState({ recapEdit: {} })
    );
  };
  toFindDuplicates(arry) {
    let toMap = {};
    let resultToReturn = false;
    for (let i = 0; i < arry.length; i++) {
      if (toMap[arry[i].taglia]) {
        resultToReturn = true;
        // terminate the loop
        break;
      }

      toMap[arry[i].taglia] = true;
    }
    return resultToReturn;
  }
  onClickSaveEdit = () => {
    if (this.toFindDuplicates(this.state.taglieVisualizzaLotti)) {
      const { t } = this.props.translation;
      alert(t("duplicated_sizes"));
    } else {
      if (!this.props.desktop) {
        const newLottoCalculated = this.calcLotto();
        console.log("tagliaDeleted", this.state.taglieDeleted);
        this.setState(
          { recapEdit: newLottoCalculated, showModalLotto: false },
          () => {
            this.setState({ modalConfermaEdit: true });
          }
        );
      } else {
        const newLottoCalculated = this.calcLotto();
        console.log("tagliaDeleted", this.state.taglieDeleted);
        this.setState({ recapEdit: newLottoCalculated }, () => {
          this.setState({ modalConfermaEdit: true });
        });
      }
    }
  };
  async onPressModalEditConfirm() {
    this.setState({ loading: true });

    const taglieNew = this.state.taglieVisualizzaLotti.filter(
      (t) => t.new === true
    );
    const taglieUp = this.state.taglieVisualizzaLotti.filter((t) => !t.new);
    const taglieDel = this.state.taglieDeleted;
    console.log("tn", taglieNew);
    console.log("tn", taglieDel);
    const qty = this.calcNewQuantita(
      this.state.taglieVisualizzaLotti,
      taglieDel,
      taglieNew
    );
    console.log("CALCOLO", qty);
    const body = {
      taglieNew: taglieNew,
      taglieDel: taglieDel,
      taglieUp: taglieUp,
      lotto: { ...this.state.recapEdit, quantita: qty },
    };
    console.log("quantita", body.lotto.quantita);
    await axios
      .post(CONSTANTS.API_URL + "/taglia/remove-taglieDel", body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY
          )}`,
        },
      })
      .then(async (res) => {
        await axios
          .post(CONSTANTS.API_URL + "/taglia/update-taglie", body, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                CONSTANTS.ACCESS_TOKEN_KEY
              )}`,
            },
          })
          .then(async (response) => {
            await axios
              .post(CONSTANTS.API_URL + "/taglia/add-taglieNew", body, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    CONSTANTS.ACCESS_TOKEN_KEY
                  )}`,
                },
              })
              .then(async (risp) => {
                this.setState({ loading: false });
                window.location.href = "";
              })
              .catch((e) => {
                this.setState({ loading: false });
                alert(e.message);
              });
          })
          .catch((e) => {
            this.setState({ loading: false });
            alert(e.message);
          });
      })
      .catch((e) => {
        this.setState({ loading: false });
        alert(e.message);
      });
  }
  addEmptySize = () => {
    const array = [...this.state.taglieVisualizzaLotti];
    if (array.length <= 25) {
      const idTemp = "a" + (Math.floor(Math.random() * 10000) + 1);
      array.push({
        taglia: "4",
        id: idTemp,
        numero: 0,
        prezzo: 0,
        new: true,
        LottoId: this.state.prodottoModalLotto.id,
      });
      this.setState({ taglieVisualizzaLotti: array });
    } else {
      const { t } = this.props.translation;
      alert(t("sizes_reached"));
    }
  };
  addEmptySizeMob = (string) => {
    const array = [...this.state.taglieVisualizzaLotti];
    if (array.filter((item) => item.taglia === string).length > 0) {
      const { t } = this.props.translation;
      alert(t("size_alredy_added"));
    } else {
      if (array.length <= 25) {
        const idTemp = "a" + (Math.floor(Math.random() * 10000) + 1);
        array.push({
          taglia: string,
          id: idTemp,
          numero: 0,
          prezzo: 0,
          new: true,
          LottoId: this.state.prodottoModalLotto.id,
        });
        this.setState({ taglieVisualizzaLotti: array });
      } else {
        const { t } = this.props.translation;
        alert(t("sizes_reached"));
      }
    }
  };

  onClickDeleteSize = (item) => {
    let array = [...this.state.taglieVisualizzaLotti];
    let arrayDeleted = [...this.state.taglieDeleted];
    if (!item.new) {
      if (array.length > 1) {
        array = array.filter((t) => t.id !== item.id);
        arrayDeleted.push(item);
        this.setState({ taglieVisualizzaLotti: array }, () => {
          this.setState({ taglieDeleted: arrayDeleted });
        });
      } else {
        const { t } = this.props.translation;
        alert(t("not_delete_stock"));
      }
    } else {
      array = array.filter((t) => t.id !== item.id);
      this.setState({ taglieVisualizzaLotti: array });
    }
  };
  calcNewQuantita = (taglie, del, nuovi) => {
    let sumU = 0;
    for (let i = 0; i < taglie.length; i++) {
      sumU = sumU + Number.parseInt(taglie[i].numero);
      console.log("sumU" + [i], sumU);
    }

    return sumU;
  };
  checkEdit = () => {
    return (
      JSON.stringify(this.state.taglieVisualizzaLotti) ===
      JSON.stringify(this.state.backupTaglie)
    );
  };

  //EMAIL QUI PER ACCETTA LOTTO CUSTOM
  onPressAccettaCustom = async () => {
    this.setState({ loading: true });

    const prod = await axios
      .get(
        CONSTANTS.API_URL +
          "/prodotto/lotto/" +
          this.state.prodottoModalPropostaVendite.riferimento
      )
      .then(async (res) => {
        return res.data;
      });

    console.log("lotto", this.state.prodottoModalLotto);

    const bodyVendite = {
      lotto: this.state.prodottoModalPropostaVendite,
      AccountId: this.state.prodottoModalLotto.AccountId,
      isOfferta: 0,
    };

    console.log("bodyVendite", bodyVendite);

    const oldLotto = this.state.prodottoModalLotto;

    const nuovaQuantita = oldLotto.quantita - bodyVendite.lotto.quantita;

    //nuovo lotto dopo accettazione proposta custom
    const newLotto = {
      ...oldLotto,
      quantita: oldLotto.quantita - bodyVendite.lotto.quantita,
      prezzo: oldLotto.prezzo - bodyVendite.lotto.prezzo,
      id: bodyVendite.lotto.id,
    };

    console.log("newLotto", newLotto);

    const bodyPagamenti = {
      lotto: this.state.prodottoModalPropostaVendite,
      AccountVenditore: this.state.prodottoModalLotto.AccountId,
      isOfferta: 0,
      // newLotto: newLotto,
    };

    const relistLotto = nuovaQuantita <= 4 ? false : this.state.selectListed;

    console.log("relistLotto", relistLotto);
    console.log(
      "nuovaQuantita",
      nuovaQuantita,
      "selectListed",
      this.state.selectListed
    );

    const bodyAcquisto = {
      bodyPagamenti,
      bodyVendite,
      relistLotto,
      // prodotto: prod,
    };

    console.log("bodyAcquisto", bodyAcquisto);

    try {
      await axios.post(
        CONSTANTS.API_URL + "/pagamento/custom-isOfferta",
        bodyAcquisto,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              CONSTANTS.ACCESS_TOKEN_KEY
            )}`,
          },
        }
      );
      let nuovoPrezzo = 0;
      if (relistLotto) {
        this.state.taglieVisualizzaLotti.map((item, index) => {
          const tagliaCustom = this.state.taglieVisualizzaLottiCustom.find(
            (f) => f.taglia === item.taglia
          );
          let nuovoNumero = tagliaCustom?.numero
            ? item.numero - tagliaCustom?.numero
            : item.numero;

          nuovoPrezzo = nuovoPrezzo + nuovoNumero * item.prezzo;
        });
      }

      this.setState({
        showModalPropostaVendita: false,
        selectListed: false,
        loading: false,
        showNewLotto: relistLotto ? true : false,
        nuovoPrezzoLotto: nuovoPrezzo,
      });
      await this.refreshPage();
    } catch (e) {
      console.log("error", e);
      alert(e.message);
    }

    //OLD
    // await axios
    //   .post(CONSTANTS.API_URL + "/vendite/custom-isOfferta", bodyVendite, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem(
    //         CONSTANTS.ACCESS_TOKEN_KEY
    //       )}`,
    //     },
    //   })
    //   .then(async (res) => {
    //     await axios
    //       .post(
    //         CONSTANTS.API_URL + "/pagamento/custom-isOfferta",
    //         bodyPagamenti,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${localStorage.getItem(
    //               CONSTANTS.ACCESS_TOKEN_KEY
    //             )}`,
    //           },
    //         }
    //       )
    //       .then(async (r) => {
    // await axios
    //   .get(CONSTANTS.API_URL + "/account/utente/" + r.data.AccountId, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem(
    //         CONSTANTS.ACCESS_TOKEN_KEY
    //       )}`,
    //     },
    //   })
    //   .then(async (res) => {
    //     const body3 = {
    //       email: res.data.email,
    //       lotto: this.state.prodottoModalPropostaVendite.id,
    //       subject:
    //         "Order Approved " +
    //         this.state.prodottoModalPropostaVendite.riferimento,
    //       prodotto: prod,

    //       text:
    //         " Hi, The seller did accept your offer for order " +
    //         this.state.prodottoModalPropostaVendite.riferimento +
    //         ".<br><br> Thank you, <br> Stephan,<br>" +
    //         "Customer Service Wholekicks LTD<br>",
    //     };

    //     const objMail = {
    //       prodotto: prod,
    //       lotto: this.state.prodottoModalPropostaVendite.id,
    //       email: res.data.email,
    //       idMailVendita: 5,
    //       idMailAcquisto: 6,
    //     };
    //     await sendMailNotification(objMail).then(async () => {
    //       console.log("body3", body3);
    //       this.setState({
    //         showModalPropostaVendita: false,
    //         loading: false,
    //       });

    //       await this.refreshPage();
    //     });
    //   });
    // });
    //   });
  };
  onPressRifiutaCustom = async () => {
    this.setState({ loading: true });
    this.deleteLottoCustom(this.state.prodottoModalPropostaVendite.id).then(
      async () => {
        this.setState({ showModalPropostaVendita: false, loading: false });
      }
    );
  };
  onPressEdit = async (id) => {
    const isAvailable = await axios.get(
      CONSTANTS.API_URL + "/lotto/lotto-is-available/" + id,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY
          )}`,
        },
      }
    );
    console.log("isAv", isAvailable);
    if (isAvailable.data) {
      this.setState({ editStock: !this.state.editStock });
    } else {
      const { t } = this.props.translation;
      alert(t("you_not_delete"));
    }
  };

  onPressModalTools = (item) => {
    console.log("ITEM", item);
    this.setState({ modalItemTools: item }, () => {
      this.setState({ modalSelectedItem: true });
    });
  };
  onCloseModalTools = () => {
    this.setState({ modalSelectedItem: false }, () => {
      this.setState({ modalItemTools: {} });
    });
  };
  getDifferenceofSizes = () => {
    let s = "";
    const c = this.state.taglieVisualizzaLottiCustom;

    const taglieDifferenza = this.state.taglieVisualizzaLotti.filter(function (
      obj
    ) {
      return !c.some(function (obj2) {
        return obj.taglia === obj2.taglia;
      });
    });
    console.log("diff", taglieDifferenza);
    taglieDifferenza.map((item, index) => {
      if (index !== 0) {
        s += ", US " + item.taglia;
      } else {
        s += "US " + item.taglia;
      }
    });
    return s;
  };
  onPressEditMobile = async (prodotto) => {
    const isAvailable = await axios.get(
      CONSTANTS.API_URL + "/lotto/lotto-is-available/" + prodotto.id,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY
          )}`,
        },
      }
    );
    console.log("isAv", isAvailable);
    if (isAvailable.data) {
      this.onPressVisualizzaLottiListati(prodotto);
      this.setState({ editStock: true, modalSelectedItem: false });
    } else {
      const { t } = this.props.translation;
      alert(t("you_not_delete"));
    }
  };
  findReferenceIndex = (taglia) => {
    const index = this.state.taglieVisualizzaLotti.findIndex(
      (a) => a.taglia === taglia
    );
    return index;
  };
  MobileRender = () => {
    const { t } = this.props.translation;
    return (
      <>
        <div className="Container-Common">
          <View style={style.container}>
            <HeaderMobile context="Others" title={t("sell")} />
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={style.HeaderMobileProfile}
            >
              <TouchableOpacity
                onPress={() => {
                  this.setState({ switch: 0 });
                }}
                style={{ marginRight: 10 }}
              >
                <Text
                  style={{
                    fontWeight: this.state.switch === 0 ? "bold" : 400,
                    color: this.state.switch === 0 ? "#0A09A2" : "black",
                  }}
                >
                  {t("custom_proposal")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  this.setState({ switch: 1 });
                }}
                style={{ marginRight: 10 }}
              >
                <Text
                  style={{
                    fontWeight: this.state.switch === 1 ? "bold" : 400,
                    color: this.state.switch === 1 ? "#0A09A2" : "black",
                  }}
                >
                  {t("purch_prop")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  this.setState({ switch: 3 });
                }}
                style={{ marginRight: 10 }}
              >
                <Text
                  style={{
                    fontWeight: this.state.switch === 3 ? "bold" : 400,
                    color: this.state.switch === 3 ? "#0A09A2" : "black",
                  }}
                >
                  {t("in_prog")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  this.setState({ switch: 2 });
                }}
                style={{ marginRight: 30 }}
              >
                <Text
                  style={{
                    fontWeight: this.state.switch === 2 ? "bold" : 400,
                    color: this.state.switch === 2 ? "#0A09A2" : "black",
                  }}
                >
                  {t("stock_listed")}
                </Text>
              </TouchableOpacity>
              {this.state.switch === 2 ? (
                <button
                  onClick={() => {
                    this.setState({ modalSearch: true }, () => {});
                  }}
                  className={
                    this.state.switch === 3
                      ? "btn-switch-0"
                      : "btn-switch-1 ricerca-btn"
                  }
                >
                  {t("search")}
                </button>
              ) : (
                <></>
              )}
            </ScrollView>
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {this.state.switch === 0 && (
                <>
                  <ScrollView
                    style={{ width: "100%", height: "72vh" }}
                    contentContainerStyle={{ alignItems: "center" }}
                  >
                    {this.state.activeVendite.length === 0 && (
                      <Text>{t("no_item")}</Text>
                    )}
                    {this.state.activeVendite.length > 0 &&
                      this.state.activeVendite.map((item) => {
                        return (
                          <View style={style.containerProposte}>
                            <img
                              style={{
                                width: "30%",
                                resizeMode: "center",
                                height: "100%",
                                marginRight: "3%",
                              }}
                              src={item.prodotto.imageProdotto}
                              onError={(e) => {
                                e.target.src = scarpaVuota;
                              }}
                            />
                            <View style={style.containerLabelProdotto}>
                              <Text style={style.titleContProd}>
                                {item.prodotto.nome}
                              </Text>
                              <Text style={style.SubTitleContProd}>
                                {item.prodotto.modello}
                              </Text>
                              <View style={{ flexDirection: "row" }}>
                                <View>
                                  <Text style={style.boldLabel}>
                                    {t("stock_n")} <br />
                                  </Text>
                                  <Text style={{ color: "red" }}>
                                    {item.riferimento}
                                  </Text>
                                  <Text style={style.boldLabel}>
                                    {t("price")} <br />
                                  </Text>
                                  <Text>{item.payout}€</Text>
                                </View>
                                <View style={{ marginLeft: 30 }}>
                                  <Text style={style.boldLabel}>
                                    {t("qty")}
                                    <br />
                                  </Text>
                                  <Text>{item.quantita}</Text>
                                </View>
                                <View
                                  style={{
                                    flexGrow: 1,
                                    alignItems: "flex-end",
                                    paddingRight: 10,
                                  }}
                                >
                                  {item.isOfferta === 0 && (
                                    <div className="proposta-account-detail"></div>
                                  )}
                                  {item.isOfferta > 0 && (
                                    <div className="proposta-account-detail">
                                      <button
                                        className="completa-checkout"
                                        onClick={() => this.onOpenModal(item)}
                                      >
                                        {t("comp_checkout")}
                                      </button>
                                    </div>
                                  )}
                                  <TouchableOpacity
                                    onPress={() => this.onPressModalTools(item)}
                                  >
                                    <Text
                                      style={{
                                        marginRight: 10,
                                        alignSelf: "flex-end",
                                        color: "rgb(10, 9, 162)",
                                        fontSize: "1.2rem",
                                        fontWeight: "900",
                                        lineHeight: 8,
                                      }}
                                    >
                                      .<br />.<br />.
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                  </ScrollView>
                </>
              )}
              {this.state.switch === 1 && (
                <>
                  <ScrollView
                    style={{ width: "100%", height: "72vh" }}
                    contentContainerStyle={{ alignItems: "center" }}
                  >
                    {this.state.listaProposte.length === 0 && (
                      <Text>No items in this section</Text>
                    )}
                    {this.state.listaProposte.length > 0 &&
                      this.state.listaProposte.map((item) => {
                        return (
                          <View style={style.containerProposte}>
                            <img
                              style={{
                                width: "30%",
                                resizeMode: "center",
                                height: "100%",
                                marginRight: "3%",
                              }}
                              src={item.imageProdotto}
                              onError={(e) => {
                                e.target.src = scarpaVuota;
                              }}
                            />
                            <View style={style.containerLabelProdotto}>
                              <Text style={style.titleContProd}>
                                {item.nome}
                              </Text>
                              <Text style={style.SubTitleContProd}>
                                {item.modello}
                              </Text>
                              <View style={{ flexDirection: "row" }}>
                                <View>
                                  <Text style={style.boldLabel}>
                                    {t("stock_n")} <br />
                                  </Text>
                                  <Text style={{ color: "red" }}>
                                    {item.lotto.id}
                                  </Text>
                                  <Text style={style.boldLabel}>
                                    {t("price")} <br />
                                  </Text>
                                  <Text>{item.offerta}€</Text>
                                  <Text style={style.boldLabel}>
                                    {t("original_price")} <br />
                                  </Text>
                                  <Text>
                                    {parseFloat(item.lotto.prezzo)
                                      .toFixed(2)
                                      .toString()
                                      .replace(".", ",") + " €"}
                                  </Text>
                                </View>
                                <View style={{ marginLeft: 30 }}>
                                  <Text style={style.boldLabel}>
                                    {t("qty")}
                                    <br />
                                  </Text>
                                  <Text>{item.qta}</Text>
                                </View>
                                {/* <View style={{flexGrow:1,alignItems:"flex-end",paddingRight:10}}>
                                <TouchableOpacity onPress={()=>this.onPressModalTools(item.lotto)}>
                                <Text style={{marginRight: 10,alignSelf:'flex-end',color:'rgb(10, 9, 162)',fontSize:'1.2rem',fontWeight:'900',lineHeight:8}}>.<br/>.<br/>.</Text>
                                </TouchableOpacity>
                      </View>*/}
                              </View>
                              <div
                                style={{
                                  width: "80%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <button
                                  style={{
                                    borderWidth: 0,
                                    backgroundColor: "#0A09A2",
                                    fontSize: "0.8rem",
                                    alignSelf: "flex-start",
                                    width: "40%",
                                    paddingTop: "2%",
                                    paddingBottom: "2%",
                                    color: "#fff",
                                    marginBottom: 12,
                                    marginTop: "3%",
                                    borderRadius: 6,
                                  }}
                                  onClick={() => this.accetta(item)}
                                >
                                  {t("accept")}
                                </button>
                                <button
                                  style={{
                                    borderWidth: 0,
                                    backgroundColor: "#f00",
                                    fontSize: "0.8rem",
                                    alignSelf: "flex-start",
                                    width: "40%",
                                    paddingTop: "2%",
                                    paddingBottom: "2%",
                                    color: "#fff",
                                    marginBottom: 12,
                                    marginTop: "3%",
                                    borderRadius: 6,
                                  }}
                                  onClick={() => this.rifiuta(item)}
                                >
                                  {t("reject")}
                                </button>
                              </div>
                            </View>
                          </View>
                        );
                      })}
                  </ScrollView>
                  {this.state.listaProposte?.length !== 0 && (
                    <Pagination>
                      {this.state.pageProposte !== 1 && (
                        <Pagination.Prev
                          onClick={() =>
                            this.handlePageClickProposte(
                              this.state.pageProposte - 1
                            )
                          }
                          disabled={this.state.pageProposte === 1}
                        />
                      )}
                      {this.state.pageProposte > 1 && (
                        <Pagination.Item
                          onClick={() => this.handlePageClickProposte(1)}
                        >
                          1
                        </Pagination.Item>
                      )}
                      {this.state.pageProposte > 1 && <Pagination.Ellipsis />}
                      {this.state.pageProposte - 1 > 1 && (
                        <Pagination.Item
                          active={
                            this.state.pageProposte ===
                            this.state.pageProposte - 1
                          }
                          key={"_pagination_" + this.state.pageProposte - 1}
                          onClick={() =>
                            this.handlePageClickProposte(
                              this.state.pageProposte - 1
                            )
                          }
                        >
                          {this.state.pageProposte - 1}
                        </Pagination.Item>
                      )}

                      <Pagination.Item
                        active={true}
                        key={"_pagination_" + this.state.pageProposte}
                        onClick={() =>
                          this.handlePageClickProposte(this.state.pageProposte)
                        }
                      >
                        {this.state.pageProposte}
                      </Pagination.Item>
                      {this.state.pageProposte !==
                        this.state.totalPageOfferte.length && (
                        <Pagination.Item
                          active={
                            this.state.pageProposte + 1 ===
                            this.state.pageProposte
                          }
                          key={"_pagination_" + this.state.pageProposte + 1}
                          onClick={() =>
                            this.handlePageClickProposte(
                              this.state.pageProposte + 1
                            )
                          }
                        >
                          {this.state.pageProposte !==
                          this.state.totalPageOfferte.length
                            ? this.state.pageProposte + 1
                            : null}
                        </Pagination.Item>
                      )}
                      {this.state.pageProposte + 1 <
                        this.state.totalPageOfferte.length && (
                        <Pagination.Ellipsis />
                      )}
                      {this.state.pageProposte + 1 <
                        this.state.totalPageOfferte.length && (
                        <Pagination.Item
                          onClick={() =>
                            this.handlePageClickProposte(
                              this.state.totalPageOfferte.length
                            )
                          }
                        >
                          {this.state.totalPageOfferte.length}
                        </Pagination.Item>
                      )}
                      {this.state.pageProposte !==
                        this.state.totalPageOfferte.length && (
                        <Pagination.Next
                          onClick={() =>
                            this.handlePageClickProposte(
                              this.state.pageProposte + 1
                            )
                          }
                          disabled={
                            this.state.pageProposte + 1 ===
                              this.state.totalPageOfferte.length ||
                            this.state.pageProposte ===
                              this.state.totalPageOfferte.length ||
                            this.state.pageProposte >
                              this.state.totalPageOfferte.length
                          }
                        />
                      )}
                    </Pagination>
                  )}
                </>
              )}
              {this.state.switch === 3 && (
                <>
                  <ScrollView
                    style={{ width: "100%", height: "72vh" }}
                    contentContainerStyle={{ alignItems: "center" }}
                  >
                    {this.state.listaProdotti.length === 0 && (
                      <Text>No items in this section</Text>
                    )}
                    {this.state.listaProdotti.length > 0 &&
                      this.state.listaProdotti.map((item) => {
                        return (
                          <View style={style.containerProposte}>
                            <img
                              style={{
                                width: "30%",
                                resizeMode: "center",
                                height: "100%",
                                marginRight: "3%",
                              }}
                              src={item.imageProdotto}
                              onError={(e) => {
                                e.target.src = scarpaVuota;
                              }}
                            />
                            <View style={style.containerLabelProdotto}>
                              <Text style={style.titleContProd}>
                                {item.nome}
                              </Text>
                              <Text style={style.SubTitleContProd}>
                                {item.modello}
                              </Text>
                              <View style={{ flexDirection: "row" }}>
                                <View>
                                  <Text style={style.boldLabel}>
                                    {t("stock_n")} <br />
                                  </Text>
                                  <Text style={{ color: "red" }}>
                                    {item.lotto.id}
                                  </Text>
                                  <Text style={style.boldLabel}>
                                    {t("price")} <br />
                                  </Text>
                                  <Text>{item.lotto.payout}€</Text>
                                </View>
                                <View style={{ marginLeft: 30 }}>
                                  <Text style={style.boldLabel}>
                                    {t("qty")}
                                    <br />
                                  </Text>
                                  <Text>{item.qta}</Text>
                                  <View style={{ maxWidth: 90 }}>
                                    <Text>{t("status")}</Text>
                                    <Text
                                      style={{
                                        width: "100%",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      <b> {t(item.StatoNome)}</b>
                                    </Text>
                                  </View>
                                </View>
                                <View
                                  style={{
                                    flexGrow: 1,
                                    alignItems: "flex-end",
                                    paddingRight: 10,
                                  }}
                                >
                                  <TouchableOpacity
                                    onPress={() =>
                                      this.onPressModalTools(item.lotto)
                                    }
                                  >
                                    <Text
                                      style={{
                                        marginRight: 10,
                                        alignSelf: "flex-end",
                                        color: "rgb(10, 9, 162)",
                                        fontSize: "1.2rem",
                                        fontWeight: "900",
                                        lineHeight: 8,
                                      }}
                                    >
                                      .<br />.<br />.
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                  </ScrollView>
                  {this.state.listaProdotti?.length !== 0 && (
                    <Pagination>
                      {this.state.page > 1 && (
                        <Pagination.Prev
                          onClick={() =>
                            this.handlePageClick(this.state.page - 1)
                          }
                        />
                      )}
                      {this.state.page > 1 && (
                        <Pagination.Item
                          onClick={() => this.handlePageClick(1)}
                        >
                          1
                        </Pagination.Item>
                      )}
                      {this.state.page > 1 && <Pagination.Ellipsis />}
                      {this.state.page - 1 > 1 && (
                        <Pagination.Item
                          active={this.state.page === this.state.page - 1}
                          key={"_pagination_" + this.state.page - 1}
                          onClick={() =>
                            this.handlePageClick(this.state.page - 1)
                          }
                        >
                          {this.state.page !== 1 ? this.state.page - 1 : null}
                        </Pagination.Item>
                      )}
                      <Pagination.Item
                        active={true}
                        key={"_pagination_" + this.state.page}
                        onClick={() => this.handlePageClick(this.state.page)}
                      >
                        {this.state.page}
                      </Pagination.Item>
                      {this.state.page !== this.state.totalPage.length && (
                        <Pagination.Item
                          active={this.state.page + 1 === this.state.page}
                          key={"_pagination_" + this.state.page + 1}
                          onClick={() =>
                            this.handlePageClick(this.state.page + 1)
                          }
                        >
                          {this.state.page + 1}
                        </Pagination.Item>
                      )}
                      {this.state.page + 1 < this.state.totalPage.length && (
                        <Pagination.Ellipsis />
                      )}
                      {this.state.page + 1 < this.state.totalPage.length && (
                        <Pagination.Item
                          onClick={() =>
                            this.handlePageClick(this.state.totalPage.length)
                          }
                        >
                          {this.state.totalPage.length}
                        </Pagination.Item>
                      )}
                      {this.state.page < this.state.totalPage.length && (
                        <Pagination.Next
                          onClick={() =>
                            this.handlePageClick(this.state.page + 1)
                          }
                          disabled={
                            this.state.page + 1 ===
                              this.state.totalPage.length ||
                            this.state.page === this.state.totalPage.length ||
                            this.state.page > this.state.totalPage.length
                          }
                        />
                      )}
                    </Pagination>
                  )}
                </>
              )}
              {this.state.switch === 2 && (
                <>
                  <ScrollView
                    style={{ width: "100%", height: "72vh" }}
                    contentContainerStyle={{ alignItems: "center" }}
                  >
                    {this.state.listaLottiFatti.length === 0 && (
                      <Text>{t("no_item")}</Text>
                    )}
                    {this.state.listaLottiFatti.length > 0 &&
                      this.state.listaLottiFatti.map((item) => {
                        return (
                          <View style={style.containerProposte}>
                            <img
                              style={{
                                width: "30%",
                                resizeMode: "center",
                                height: "100%",
                                marginRight: "3%",
                              }}
                              src={item.prodotto.imageProdotto}
                              onError={(e) => {
                                e.target.src = scarpaVuota;
                              }}
                            />
                            <View style={style.containerLabelProdotto}>
                              <Text style={style.titleContProd}>
                                {item.prodotto.nome}
                              </Text>
                              <Text style={style.SubTitleContProd}>
                                {item.prodotto.modello}
                              </Text>
                              <View style={style.containerShipping}>
                                <View>
                                  <Text style={style.boldLabel}>
                                    {t("stock_n")} <br />
                                  </Text>
                                  <Text style={{ color: "red" }}>
                                    {item.id}
                                  </Text>
                                  <Text style={style.boldLabel}>
                                    {t("price")} <br />
                                  </Text>
                                  <Text>{item.payout}€</Text>
                                </View>
                                <View style={{ marginLeft: 30 }}>
                                  <Text style={style.boldLabel}>
                                    {t("qty")}
                                    <br />
                                  </Text>
                                  <Text>{item.quantita}</Text>
                                </View>
                                <View
                                  style={{
                                    flexGrow: 1,
                                    alignItems: "flex-end",
                                    paddingRight: 10,
                                  }}
                                >
                                  <TouchableOpacity
                                    onPress={() => this.onPressModalTools(item)}
                                  >
                                    <Text
                                      style={{
                                        marginRight: 10,
                                        alignSelf: "flex-end",
                                        color: "rgb(10, 9, 162)",
                                        fontSize: "1.2rem",
                                        fontWeight: "900",
                                        lineHeight: 8,
                                      }}
                                    >
                                      .<br />.<br />.
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                  </ScrollView>
                  {this.state.listaLottiFatti?.length !== 0 && (
                    <Pagination>
                      {this.state.pageMieiLotti !== 1 && (
                        <Pagination.Prev
                          onClick={() =>
                            this.handlePageClickMieiLotti(
                              this.state.pageMieiLotti - 1
                            )
                          }
                          disabled={this.state.pageMieiLotti === 1}
                        />
                      )}
                      {this.state.pageMieiLotti > 1 && (
                        <Pagination.Item
                          onClick={() => this.handlePageClickMieiLotti(1)}
                        >
                          1
                        </Pagination.Item>
                      )}
                      {this.state.pageMieiLotti > 1 && <Pagination.Ellipsis />}
                      {this.state.pageMieiLotti - 1 > 1 && (
                        <Pagination.Item
                          active={
                            this.state.pageMieiLotti ===
                            this.state.pageMieiLotti - 1
                          }
                          key={"_pagination_" + this.state.pageMieiLotti - 1}
                          onClick={() =>
                            this.handlePageClickMieiLotti(
                              this.state.pageMieiLotti - 1
                            )
                          }
                        >
                          {this.state.pageMieiLotti - 1}
                        </Pagination.Item>
                      )}

                      <Pagination.Item
                        active={true}
                        key={"_pagination_" + this.state.pageMieiLotti}
                        onClick={() =>
                          this.handlePageClickMieiLotti(
                            this.state.pageMieiLotti
                          )
                        }
                      >
                        {this.state.pageMieiLotti}
                      </Pagination.Item>
                      {this.state.pageMieiLotti !==
                        this.state.totalPageMieiLotti.length && (
                        <Pagination.Item
                          active={
                            this.state.pageMieiLotti + 1 ===
                            this.state.pageMieiLotti
                          }
                          key={"_pagination_" + this.state.pageMieiLotti + 1}
                          onClick={() =>
                            this.handlePageClickMieiLotti(
                              this.state.pageMieiLotti + 1
                            )
                          }
                        >
                          {this.state.pageMieiLotti !==
                          this.state.totalPageMieiLotti.length
                            ? this.state.pageMieiLotti + 1
                            : null}
                        </Pagination.Item>
                      )}
                      {this.state.pageMieiLotti + 1 <
                        this.state.totalPageMieiLotti.length && (
                        <Pagination.Ellipsis />
                      )}
                      {this.state.pageMieiLotti + 1 <
                        this.state.totalPageMieiLotti.length && (
                        <Pagination.Item
                          onClick={() =>
                            this.handlePageClickMieiLotti(
                              this.state.totalPageMieiLotti.length
                            )
                          }
                        >
                          {this.state.totalPageMieiLotti.length}
                        </Pagination.Item>
                      )}
                      {this.state.pageMieiLotti !==
                        this.state.totalPageMieiLotti.length && (
                        <Pagination.Next
                          onClick={() =>
                            this.handlePageClickMieiLotti(
                              this.state.pageMieiLotti + 1
                            )
                          }
                          disabled={
                            this.state.pageMieiLotti + 1 ===
                              this.state.totalPageMieiLotti.length ||
                            this.state.pageMieiLotti ===
                              this.state.totalPageMieiLotti.length ||
                            this.state.pageMieiLotti >
                              this.state.totalPageMieiLotti.length
                          }
                        />
                      )}
                    </Pagination>
                  )}
                  <ModalSearchMobile
                    modalAdd={this.state.modalSearch}
                    onModalDismiss={this.onHideVisualizzatore}
                    utente={this.state.UtenteId}
                    onPressModalTools={this.onPressModalTools}
                    onPressVisualizzatore={this.onPressVisualizzatore}
                  />
                </>
              )}
            </View>
          </View>
        </div>
        <ModalCustomViewer
          typePage={"Vendita"}
          modalAdd={this.state.showModalPropostaVendita}
          lotto={this.state.prodottoModalPropostaVendite}
          taglie={this.state.taglieVisualizzaLottiCustom}
          riferimento={this.state.prodottoModalLotto}
          t_riferimento={this.state.taglieVisualizzaLotti}
          diff_sizes={this.getDifferenceofSizes()}
          onModalDismiss={this.onHideModalCustom}
          accept={this.onPressAccettaCustom}
          reject={this.onPressRifiutaCustom}
          selectListed={this.state.selectListed}
          onChangeSelectListed={(newSelectListed) => {
            console.log("newSelectListed", newSelectListed);
            this.setState({ selectListed: newSelectListed });
          }}
        />
        {this.state.showNewLotto && (
          <ModalNewLotto
            typePage={"Vendita"}
            modalAdd={this.state.showNewLotto}
            taglie={this.state.taglieVisualizzaLottiCustom}
            t_riferimento={this.state.taglieVisualizzaLotti}
            nuovoPrezzo={this.state.nuovoPrezzoLotto}
            onModalDismiss={() => this.setState({ showNewLotto: false })}
          />
        )}

        {/* <ModalProposal
        modalAdd={this.state.modal}
        data={this.state.objectSelected}
        onModalDismiss={this.onPressModalCancel}
      /> */}
        <MobileModal
          visible={this.state.modalSelectedItem}
          animationType={"fade"}
          transparent
          style={{}}
        >
          <View
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "#11111160",
            }}
          >
            <TouchableOpacity
              style={{ width: "100%", flexGrow: 1 }}
              onPress={() => this.onCloseModalTools()}
            ></TouchableOpacity>
            <View
              style={{
                width: "100%",
                backgroundColor: "#fff",
                borderTopLeftRadius: 30,
                borderTopRightRadius: 30,
                shadowRadius: 8,
                shadowOpacity: 0.3,
                justifyContent: "flex-start",
                alignItems: "center",
                paddingHorizontal: 30,
                paddingVertical: 20,
              }}
            >
              <Text style={style.labelModalTools}>
                {t("stock_n")}{" "}
                {this.state.switch === 0
                  ? this.state.modalItemTools?.riferimento
                  : this.state.modalItemTools?.id}
              </Text>
              <TouchableOpacity
                style={style.buttonModalTools}
                onPress={() =>
                  this.onPressVisualizzatore(this.state.modalItemTools)
                }
              >
                <Text style={style.labelBtnTools}>{t("view")}</Text>
              </TouchableOpacity>
              {this.state.switch === 2 && (
                <TouchableOpacity
                  onPress={() =>
                    this.onPressEditMobile(this.state.modalItemTools)
                  }
                  style={style.buttonModalTools}
                >
                  <Text style={style.labelBtnTools}>{t("edit")}</Text>
                </TouchableOpacity>
              )}
              {this.state.switch === 2 && (
                <TouchableOpacity
                  onPress={() => this.deleteLotto(this.state.modalItemTools.id)}
                  style={style.buttonModalTools}
                >
                  <Text style={{ ...style.labelBtnTools, color: "#f00" }}>
                    {t("delete")}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </MobileModal>
        <ModalVisualizzatore
          typePage={"Vendita"}
          modalAdd={this.state.visualizzatore}
          onModalDismiss={this.onHideVisualizzatore}
          lotto={this.state.prodottoVisualizzatore}
          taglie={this.state.taglieVisualizzatore}
        />
        <ModalVisualizzaListati
          modalAdd={this.state.showModalLotto}
          onModalDismiss={this.onHideModal}
          deleteTaglie={this.onClickDeleteSize}
          updateTaglie={this.onChangeTagliaEdit}
          lotto={this.state.prodottoModalLotto}
          taglie={this.state.taglieVisualizzaLotti}
          edit={this.state.editStock}
          addEmpty={this.addEmptySizeMob}
          t_backup={this.lottiDefault}
          checkEdit={this.checkEdit}
          save={this.onClickSaveEdit}
          updateAll={this.updateAllPrices}
        />
      </>
    );
  };
  DesktopRender = () => {
    const { t } = this.props.translation;
    return (
      <div className="container-account-general">
        <Header />
        <div className="container-account-route">
          <AccountRoutes />
          <div className="right-column-account">
            <div className="header-account-pages">
              <div className="buttons-header-account-pages-div buttons-header-account-pages-div-sell">
                <button
                  onClick={() => {
                    this.setState({ switch: 0 });
                  }}
                  className={
                    this.state.switch === 0 ? "btn-switch-0" : "btn-switch-1"
                  }
                >
                  {t("custom_proposal")}
                  {/* {t("pending_prop")} */}
                </button>
                <button
                  onClick={() => {
                    this.setState({ switch: 1 });
                  }}
                  className={
                    this.state.switch === 1 ? "btn-switch-0" : "btn-switch-1"
                  }
                >
                  {t("purch_prop")}
                </button>
                <button
                  onClick={() => {
                    this.setState({ switch: 3 });
                  }}
                  className={
                    this.state.switch === 3 ? "btn-switch-0" : "btn-switch-1"
                  }
                >
                  {t("in_prog")}
                </button>
                <button
                  onClick={() => {
                    this.setState({ switch: 2 }, () => {});
                  }}
                  className={
                    this.state.switch === 2 ? "btn-switch-0" : "btn-switch-1"
                  }
                >
                  {t("stock_listed")}
                </button>

                <ModalSearch
                  modalAdd={this.state.modalSearch}
                  onModalDismiss={this.onHideVisualizzatore}
                  utente={this.state.UtenteId}
                  onPressVisualizzaLottiListati={
                    this.onPressVisualizzaLottiListati
                  }
                  onPressVisualizzatore={this.onPressVisualizzatore}
                />
              </div>
              {this.state.switch === 2 ? (
                <button
                  onClick={() => {
                    this.setState({ modalSearch: true }, () => {});
                  }}
                  className={
                    this.state.switch === 3
                      ? "btn-switch-0"
                      : "btn-switch-1 ricerca-btn"
                  }
                >
                  {t("search")}
                </button>
              ) : (
                <></>
              )}
              {/*<div className="search-bar-div-account">
            <label className="search-bar-account">
              <SearchIcon className="search-icon" />
              <input
                type="search"
                placeholder="Search"
                className="search-bar-input"
              />
            </label>
            </div>*/}
            </div>

            {/* lista prodotti */}
            {this.state.switch === 3 && (
              <>
                {this.state.listaProdotti?.map((prodotto, key) => {
                  return (
                    <div key={prodotto.id + "_" + key} className="account-list">
                      <div className="account-list-detail">
                        <div className="scarpa-img-list">
                          <img
                            src={prodotto.imageProdotto}
                            alt="scarpa"
                            onError={(e) => {
                              e.target.src = scarpaVuota;
                            }}
                          />
                        </div>
                        <div className="modello-account-detail">
                          <p className="title-modello">{t("model")}</p>
                          <p className="modello-mod">{prodotto.nome}</p>
                          <p className="tag-modello">{prodotto.tag}</p>
                        </div>
                        <div className="qty-account-detail">
                          <p className="title-div">{t("stock_n")}</p>
                          <p className="qty-div stock-n-account">
                            {prodotto.lotto.id}
                          </p>
                        </div>
                        <div className="n-lotto-detail">
                          <p className="title-div">{t("qty")}</p>
                          <p className="qty-div">{prodotto.lotto.quantita}</p>
                        </div>
                        <div className="qty-account-detail">
                          <p className="title-div">{t("status")}</p>
                          <p className="qty-div">{t(prodotto.StatoNome)}</p>
                        </div>
                        <div className="proposta-account-detail">
                          <p className="title-div">{t("bid")}</p>
                          <p className="qty-div">
                            {" "}
                            <b>
                              {parseFloat(prodotto.lotto?.payout)
                                .toFixed(2)
                                .toString()
                                .replace(".", ",") + " €"}
                            </b>
                          </p>
                          <p
                            className="p-vendite-cursor"
                            onClick={() => {
                              this.onPressVisualizzatore(prodotto.lotto);
                            }}
                          >
                            {t("view_stock")}
                          </p>
                        </div>
                        {/* <div>
                        <button onClick={this.generatePDF} type="primary">
                        Download PDF
                      </button>
                    </div> */}
                      </div>
                    </div>
                  );
                })}
                {this.state.listaProdotti?.length === 0 && (
                  <p style={{ marginTop: 10, marginLeft: 10 }}>
                    {t("no_product")}
                  </p>
                )}
                {this.state.listaProdotti?.length !== 0 && (
                  <Pagination>
                    {this.state.page > 1 && (
                      <Pagination.Prev
                        onClick={() =>
                          this.handlePageClick(this.state.page - 1)
                        }
                      />
                    )}
                    {this.state.page > 1 && (
                      <Pagination.Item onClick={() => this.handlePageClick(1)}>
                        1
                      </Pagination.Item>
                    )}
                    {this.state.page > 1 && <Pagination.Ellipsis />}
                    {this.state.page - 1 > 1 && (
                      <Pagination.Item
                        active={this.state.page === this.state.page - 1}
                        key={"_pagination_" + this.state.page - 1}
                        onClick={() =>
                          this.handlePageClick(this.state.page - 1)
                        }
                      >
                        {this.state.page !== 1 ? this.state.page - 1 : null}
                      </Pagination.Item>
                    )}
                    <Pagination.Item
                      active={true}
                      key={"_pagination_" + this.state.page}
                      onClick={() => this.handlePageClick(this.state.page)}
                    >
                      {this.state.page}
                    </Pagination.Item>
                    {this.state.page !== this.state.totalPage.length && (
                      <Pagination.Item
                        active={this.state.page + 1 === this.state.page}
                        key={"_pagination_" + this.state.page + 1}
                        onClick={() =>
                          this.handlePageClick(this.state.page + 1)
                        }
                      >
                        {this.state.page + 1}
                      </Pagination.Item>
                    )}
                    {this.state.page + 1 < this.state.totalPage.length && (
                      <Pagination.Ellipsis />
                    )}
                    {this.state.page + 1 < this.state.totalPage.length && (
                      <Pagination.Item
                        onClick={() =>
                          this.handlePageClick(this.state.totalPage.length)
                        }
                      >
                        {this.state.totalPage.length}
                      </Pagination.Item>
                    )}
                    {this.state.page < this.state.totalPage.length && (
                      <Pagination.Next
                        onClick={() =>
                          this.handlePageClick(this.state.page + 1)
                        }
                        disabled={
                          this.state.page + 1 === this.state.totalPage.length ||
                          this.state.page === this.state.totalPage.length ||
                          this.state.page > this.state.totalPage.length
                        }
                      />
                    )}
                  </Pagination>
                )}
              </>
            )}

            {this.state.switch === 1 && this.state.listaProposte && (
              <>
                {this.state.listaProposte?.length === 0 && (
                  <p style={{ marginTop: 10, marginLeft: 10 }}>
                    {t("no_product")}
                  </p>
                )}
                {this.state.listaProposte?.map((prodotto, key) => {
                  return (
                    <div key={prodotto.id + "_" + key} className="account-list">
                      <div className="account-list-detail">
                        <div className="scarpa-img-list">
                          <img
                            src={prodotto.imageProdotto}
                            alt="scarpa"
                            onError={(e) => {
                              e.target.src = scarpaVuota;
                            }}
                          />
                        </div>
                        <div className="modello-account-detail">
                          <p className="title-modello">{t("model")}</p>
                          <p className="modello-mod">{prodotto.modello}</p>
                          <p className="tag-modello">{prodotto.tag}</p>
                        </div>
                        <div className="qty-account-detail">
                          <p className="title-div">{t("stock_n")}</p>
                          <p className="qty-div  stock-n-account">
                            {prodotto.LottoId}
                          </p>
                        </div>
                        <div className="qty-account-detail">
                          <p className="title-div">{t("qty")}</p>
                          <p className="qty-div">{prodotto.qta}</p>
                        </div>
                        <div className="qty-account-detail">
                          <p className="title-div">{t("original_price")}</p>
                          <p className="qty-div">
                            {parseFloat(prodotto.lotto.prezzo)
                              .toFixed(2)
                              .toString()
                              .replace(".", ",") + " €"}
                          </p>
                        </div>

                        <div className="proposta-account-detail">
                          <p className="title-div">{t("prop")}</p>
                          <p className="qty-div">
                            {" "}
                            <b>
                              {parseFloat(prodotto.offerta)
                                .toFixed(2)
                                .toString()
                                .replace(".", ",") + " €"}
                            </b>
                          </p>
                          <div>
                            <button
                              className="accetta-proposta-btn"
                              onClick={() => this.accetta(prodotto)}
                            >
                              <CheckIcon />
                              {t("accept")}
                            </button>
                            <button
                              className="rifiuta-proposta-btn"
                              onClick={() => this.rifiuta(prodotto)}
                            >
                              <CloseIcon />
                              {t("reject")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}{" "}
                {this.state.listaProposte?.length !== 0 && (
                  <Pagination>
                    {this.state.pageProposte !== 1 && (
                      <Pagination.Prev
                        onClick={() =>
                          this.handlePageClickProposte(
                            this.state.pageProposte - 1
                          )
                        }
                        disabled={this.state.pageProposte === 1}
                      />
                    )}
                    {this.state.pageProposte > 1 && (
                      <Pagination.Item
                        onClick={() => this.handlePageClickProposte(1)}
                      >
                        1
                      </Pagination.Item>
                    )}
                    {this.state.pageProposte > 1 && <Pagination.Ellipsis />}
                    {this.state.pageProposte - 1 > 1 && (
                      <Pagination.Item
                        active={
                          this.state.pageProposte ===
                          this.state.pageProposte - 1
                        }
                        key={"_pagination_" + this.state.pageProposte - 1}
                        onClick={() =>
                          this.handlePageClickProposte(
                            this.state.pageProposte - 1
                          )
                        }
                      >
                        {this.state.pageProposte - 1}
                      </Pagination.Item>
                    )}

                    <Pagination.Item
                      active={true}
                      key={"_pagination_" + this.state.pageProposte}
                      onClick={() =>
                        this.handlePageClickProposte(this.state.pageProposte)
                      }
                    >
                      {this.state.pageProposte}
                    </Pagination.Item>
                    {this.state.pageProposte !==
                      this.state.totalPageOfferte.length && (
                      <Pagination.Item
                        active={
                          this.state.pageProposte + 1 ===
                          this.state.pageProposte
                        }
                        key={"_pagination_" + this.state.pageProposte + 1}
                        onClick={() =>
                          this.handlePageClickProposte(
                            this.state.pageProposte + 1
                          )
                        }
                      >
                        {this.state.pageProposte !==
                        this.state.totalPageOfferte.length
                          ? this.state.pageProposte + 1
                          : null}
                      </Pagination.Item>
                    )}
                    {this.state.pageProposte + 1 <
                      this.state.totalPageOfferte.length && (
                      <Pagination.Ellipsis />
                    )}
                    {this.state.pageProposte + 1 <
                      this.state.totalPageOfferte.length && (
                      <Pagination.Item
                        onClick={() =>
                          this.handlePageClickProposte(
                            this.state.totalPageOfferte.length
                          )
                        }
                      >
                        {this.state.totalPageOfferte.length}
                      </Pagination.Item>
                    )}
                    {this.state.pageProposte !==
                      this.state.totalPageOfferte.length && (
                      <Pagination.Next
                        onClick={() =>
                          this.handlePageClickProposte(
                            this.state.pageProposte + 1
                          )
                        }
                        disabled={
                          this.state.pageProposte + 1 ===
                            this.state.totalPageOfferte.length ||
                          this.state.pageProposte ===
                            this.state.totalPageOfferte.length ||
                          this.state.pageProposte >
                            this.state.totalPageOfferte.length
                        }
                      />
                    )}
                  </Pagination>
                )}
              </>
            )}

            {this.state.switch === 2 && this.state.listaLottiFatti && (
              <>
                {this.state.listaLottiFatti?.length === 0 && (
                  <p style={{ marginTop: 10, marginLeft: 10 }}>
                    {t("no_product")}
                  </p>
                )}
                {this.state.listaLottiFatti.map((prodotto, key) => {
                  console.log("PRODOTTO", prodotto);
                  return (
                    <div key={prodotto.id + "_" + key} className="account-list">
                      <div className="account-list-detail">
                        <div className="scarpa-img-list">
                          <img
                            src={prodotto.prodotto.imageProdotto}
                            alt="scarpa"
                            onError={(e) => {
                              e.target.src = scarpaVuota;
                            }}
                          />
                        </div>
                        <div className="modello-account-detail">
                          <p className="title-modello">{t("prod")}</p>
                          <p className="modello-mod">
                            {prodotto.prodotto.nome}
                          </p>
                          <p className="tag-modello">
                            {prodotto.prodotto.style}
                          </p>
                        </div>
                        <div className="n-lotto-detail">
                          <p className="title-div">{t("stock_n")}</p>
                          <p className="modello-mod stock-n-account">
                            {prodotto.id}
                          </p>
                        </div>
                        <div className="qty-account-detail">
                          <p className="title-div">
                            {t("qty")}
                            {}
                          </p>
                          <p className="qty-div">{prodotto.quantita}</p>
                        </div>
                        <div className="proposta-account-detail">
                          <p className="title-div">{t("price")}</p>
                          <p className="qty-div">
                            {" "}
                            <b>
                              {parseFloat(prodotto.payout)
                                .toFixed(2)
                                .toString()
                                .replace(".", ",") + " €"}
                            </b>
                          </p>
                          <div>
                            <button
                              className="accetta-proposta-btn"
                              onClick={() =>
                                this.onPressVisualizzaLottiListati(prodotto)
                              }
                            >
                              {t("see_more")}
                            </button>
                            <button
                              className="rifiuta-proposta-btn"
                              onClick={() => this.deleteLotto(prodotto.id)}
                            >
                              <CloseIcon />
                              {t("delete")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}{" "}
                {this.state.listaLottiFatti?.length !== 0 && (
                  <Pagination>
                    {this.state.pageMieiLotti !== 1 && (
                      <Pagination.Prev
                        onClick={() =>
                          this.handlePageClickMieiLotti(
                            this.state.pageMieiLotti - 1
                          )
                        }
                        disabled={this.state.pageMieiLotti === 1}
                      />
                    )}
                    {this.state.pageMieiLotti > 1 && (
                      <Pagination.Item
                        onClick={() => this.handlePageClickMieiLotti(1)}
                      >
                        1
                      </Pagination.Item>
                    )}
                    {this.state.pageMieiLotti > 1 && <Pagination.Ellipsis />}
                    {this.state.pageMieiLotti - 1 > 1 && (
                      <Pagination.Item
                        active={
                          this.state.pageMieiLotti ===
                          this.state.pageMieiLotti - 1
                        }
                        key={"_pagination_" + this.state.pageMieiLotti - 1}
                        onClick={() =>
                          this.handlePageClickMieiLotti(
                            this.state.pageMieiLotti - 1
                          )
                        }
                      >
                        {this.state.pageMieiLotti - 1}
                      </Pagination.Item>
                    )}

                    <Pagination.Item
                      active={true}
                      key={"_pagination_" + this.state.pageMieiLotti}
                      onClick={() =>
                        this.handlePageClickMieiLotti(this.state.pageMieiLotti)
                      }
                    >
                      {this.state.pageMieiLotti}
                    </Pagination.Item>
                    {this.state.pageMieiLotti !==
                      this.state.totalPageMieiLotti.length && (
                      <Pagination.Item
                        active={
                          this.state.pageMieiLotti + 1 ===
                          this.state.pageMieiLotti
                        }
                        key={"_pagination_" + this.state.pageMieiLotti + 1}
                        onClick={() =>
                          this.handlePageClickMieiLotti(
                            this.state.pageMieiLotti + 1
                          )
                        }
                      >
                        {this.state.pageMieiLotti !==
                        this.state.totalPageMieiLotti.length
                          ? this.state.pageMieiLotti + 1
                          : null}
                      </Pagination.Item>
                    )}
                    {this.state.pageMieiLotti + 1 <
                      this.state.totalPageMieiLotti.length && (
                      <Pagination.Ellipsis />
                    )}
                    {this.state.pageMieiLotti + 1 <
                      this.state.totalPageMieiLotti.length && (
                      <Pagination.Item
                        onClick={() =>
                          this.handlePageClickMieiLotti(
                            this.state.totalPageMieiLotti.length
                          )
                        }
                      >
                        {this.state.totalPageMieiLotti.length}
                      </Pagination.Item>
                    )}
                    {this.state.pageMieiLotti !==
                      this.state.totalPageMieiLotti.length && (
                      <Pagination.Next
                        onClick={() =>
                          this.handlePageClickMieiLotti(
                            this.state.pageMieiLotti + 1
                          )
                        }
                        disabled={
                          this.state.pageMieiLotti + 1 ===
                            this.state.totalPageMieiLotti.length ||
                          this.state.pageMieiLotti ===
                            this.state.totalPageMieiLotti.length ||
                          this.state.pageMieiLotti >
                            this.state.totalPageMieiLotti.length
                        }
                      />
                    )}
                  </Pagination>
                )}{" "}
              </>
            )}

            {this.state.switch === 0 && this.state.activeVendite && (
              <>
                {this.state.activeVendite?.length === 0 && (
                  <p style={{ marginTop: 10, marginLeft: 10 }}>
                    {t("no_product")}
                  </p>
                )}
                {this.state.activeVendite.map((prodotto, key) => {
                  return (
                    <div
                      key={prodotto?.id + "_" + key}
                      className="account-list"
                    >
                      <div className="account-list-detail">
                        <div className="scarpa-img-list">
                          <img
                            src={prodotto.prodotto.imageProdotto}
                            alt="scarpa"
                            onError={(e) => {
                              e.target.src = scarpaVuota;
                            }}
                          />
                        </div>
                        <div className="modello-account-detail">
                          <p className="title-modello">{t("model")}</p>
                          <p className="modello-mod">
                            {prodotto.prodotto.nome}
                          </p>
                          <p className="tag-modello">
                            {prodotto.prodotto.style}
                          </p>
                        </div>
                        <div className="n-lotto-detail">
                          <p className="title-div ">
                            {
                              //
                            }
                            {t("stock_n")}
                          </p>
                          <p className="qty-div stock-n-account">
                            {prodotto.riferimento}
                          </p>
                        </div>
                        <div className="qty-account-detail">
                          <p className="title-div">
                            {t("qty")}
                            {
                              //Traduzione
                            }
                          </p>
                          <p className="qty-div">{prodotto.quantita}</p>
                        </div>
                        <div className="proposta-account-detail">
                          <p className="title-div">{t("price")}</p>
                          <p className="qty-div">
                            <b>
                              {parseFloat(prodotto.payout)
                                .toFixed(2)
                                .toString()
                                .replace(".", ",") + " €"}
                            </b>
                          </p>
                          <div>
                            <button
                              className="accetta-proposta-btn"
                              onClick={() =>
                                this.onPressVisualizzaLottiListatiCustom(
                                  prodotto
                                )
                              }
                            >
                              {t("see_more")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}{" "}
                {/*this.state.activeVendite?.length!=0&&
            <Pagination>
          {this.state.activeVendite !== 1&&<Pagination.Prev
                onClick={() =>
                  this.handlePageClickMieiLotti(this.state.pageMieiLotti - 1)
                }
                disabled={this.state.pageMieiLotti == 1}
              />}
              
             {this.state.activeVendite  !== 1&&<Pagination.Item
                active={this.state.activeVendite === this.state.activeVendite - 1}
                key={"_pagination_" + this.state.pageMieiLotti - 1}
                onClick={() =>
                  this.handlePageClickMieiLotti(this.state.pageMieiLotti - 1)
                }
              >
                {this.state.pageMieiLotti - 1}
              </Pagination.Item>}

              <Pagination.Item
                active={this.state.pageMieiLotti === this.state.pageMieiLotti}
                key={"_pagination_" + this.state.pageMieiLotti}
                onClick={() =>
                  this.handlePageClickMieiLotti(this.state.pageMieiLotti)
                }
              >
                {this.state.pageMieiLotti}
              </Pagination.Item>
            {this.state.pageMieiLotti!=this.state.totalPageMieiLotti.length&&<Pagination.Item
                active={this.state.pageMieiLotti + 1 === this.state.pageMieiLotti}
                key={"_pagination_" + this.state.pageMieiLotti + 1}
                onClick={() =>
                  this.handlePageClickMieiLotti(this.state.pageMieiLotti + 1)
                }
              >
                {this.state.pageMieiLotti !== this.state.totalPageMieiLotti.length
                  ? this.state.pageMieiLotti + 1
                  : null}
              </Pagination.Item>}
              {this.state.pageMieiLotti!=this.state.totalPageMieiLotti.length&&
              <Pagination.Next
                onClick={() =>
                  this.handlePageClickMieiLotti(this.state.pageMieiLotti + 1)
                }
                // disabled={
                //   this.state.pageMieiLotti + 1 ===
                //     this.state.totalPageMieiLotti.length ||
                //   this.state.pageMieiLotti ===
                //     this.state.totalPageMieiLotti.length ||
                //   this.state.pageMieiLotti > this.state.totalPageMieiLotti.length
                // }
              />}
              </Pagination>*/}
              </>
            )}
            <Modal
              className="custom-modal"
              size={"xl"}
              show={this.state.showModalPropostaVendita}
              onHide={() => this.onHideModalCustom()}
            >
              <div className="body-modal-view-lotto">
                <div className="mvl-header">
                  <p className="mvl-title">
                    {t("ref_stock")}
                    <a
                      onClick={() =>
                        this.openReference(
                          this.state.prodottoModalPropostaVendite.riferimento
                        )
                      }
                    >
                      {" " +
                        this.state.prodottoModalPropostaVendite.riferimento}
                    </a>
                  </p>
                </div>
                <div
                  className="nuovolotto-div-tuttofortissimo"
                  style={{ backgroundColor: "#f5f5f5" }}
                >
                  <div className="container-per-griglia">
                    <div className="content-griglia-tuttofortissimo-flex-start">
                      {this.state.taglieVisualizzaLottiCustom.map(
                        (item, index) => {
                          console.log("taglieVisualizzaLottiCustom", item);
                          console.log(
                            "findIndex",
                            this.state.taglieVisualizzaLotti[
                              this.findReferenceIndex(item.taglia)
                            ]
                          );
                          return (
                            <div className="taglia-nuovo-lotto-new">
                              <div className="taglie-per-custom-modal">
                                <span
                                  style={{
                                    fontSize: "0.8rem",
                                    alignSelf: "center",
                                  }}
                                >
                                  US {item.taglia}
                                </span>

                                <div className="taglie-per-custom-modal-child">
                                  <div className="right-box-noblue">
                                    {
                                      this.state.taglieVisualizzaLotti[
                                        this.findReferenceIndex(item.taglia)
                                      ]?.numero
                                    }
                                  </div>
                                  <span
                                    className="right-box-noblue"
                                    style={{ fontSize: "0.7rem" }}
                                  >
                                    {t("qty")}
                                  </span>
                                  <div className="right-box-blue">
                                    {item.numero}
                                  </div>
                                </div>
                              </div>

                              <div className="taglie-per-custom-modal-child">
                                <div className="right-box-noblue">
                                  {
                                    this.state.taglieVisualizzaLotti[
                                      this.findReferenceIndex(item.taglia)
                                    ]?.prezzo
                                  }
                                </div>
                                <span
                                  className="right-box-noblue"
                                  style={{ fontSize: "0.7rem" }}
                                >
                                  {t("price")}
                                </span>
                                <div className="right-box-blue">
                                  {item.prezzo}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div className="mvl-forte">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {this.state.taglieVisualizzaLotti.length >
                      this.state.taglieVisualizzaLottiCustom.length && (
                      <p>
                        {t("size_removed")} <b>{this.getDifferenceofSizes()}</b>
                      </p>
                    )}
                    <div className="mvl-forte">
                      <p className="mvl-price">
                        {
                          //Traduzione
                        }{" "}
                        <div className="prezzi-nuovo-lotto-2">
                          <p
                            class="text-right-two-lotti"
                            style={{
                              width: "100%",
                              marginRight: "6%",
                              fontSize: "initial",
                            }}
                          >
                            {t("tot_pay")}:
                            <b>
                              {this.state.prodottoModalPropostaVendite.prezzo +
                                " €"}
                            </b>
                            <br />
                            {!this.state.prodottoModalPropostaVendite
                              .ivaMargine ? (
                              t("Iva") + "22%: "
                            ) : (
                              <>{t("marginal_vat_checked") + ":"}</>
                            )}
                            <b>
                              {this.calcIva(
                                this.state.prodottoModalPropostaVendite
                              ) + " €"}
                            </b>
                            <br />
                            {t("shipping_fee")}:
                            <b>
                              {this.calcFeeSped(
                                this.state.prodottoModalPropostaVendite
                              ) + " €"}
                            </b>
                            <br />
                            {t("transaction_fees")}:
                            <b>
                              {this.calcWKfee(
                                this.state.prodottoModalPropostaVendite
                              ) + " €"}
                            </b>
                            <br />
                            {t("tot_pay_recived")}:
                            <b>
                              {this.totale(
                                this.state.prodottoModalPropostaVendite
                              ) + " €"}
                            </b>
                          </p>
                        </div>
                      </p>
                      <p className="mvl-price">
                        {
                          //Traduzione
                        }{" "}
                        <div className="prezzi-nuovo-lotto-2">
                          <p
                            class="text-right-lotto"
                            style={{
                              width: "100%",
                              marginRight: "6%",
                              fontSize: "initial",
                            }}
                          >
                            {t("tot_pay")}:
                            <b>{this.state.prodottoModalLotto.prezzo + " €"}</b>
                            <br />
                            {!this.state.prodottoModalLotto.ivaMargine ? (
                              t("Iva") + "22%: "
                            ) : (
                              <>{t("marginal_vat_checked") + ":"}</>
                            )}
                            <b>
                              {this.calcIva(this.state.prodottoModalLotto) +
                                " €"}
                            </b>
                            <br />
                            {t("shipping_fee")}:
                            <b>
                              {this.calcFeeSped(this.state.prodottoModalLotto) +
                                " €"}
                            </b>
                            <br />
                            {t("transaction_fees")}:
                            <b>
                              {this.calcWKfee(this.state.prodottoModalLotto) +
                                " €"}
                            </b>
                            <br />
                            {t("tot_pay_recived")}:
                            <b>
                              {this.totale(this.state.prodottoModalLotto) +
                                " €"}
                            </b>
                          </p>
                        </div>
                      </p>
                    </div>

                    <div className="footer-button-custom">
                      <div display="flex" flexDirection="column">
                        <div>
                          <button
                            disabled={this.state.loading}
                            onClick={() => {
                              if (
                                this.state.prodottoModalLotto.quantita -
                                  this.state.prodottoModalPropostaVendite
                                    .quantita <=
                                  4 ||
                                this.state.prodottoModalLotto.quantita -
                                  this.state.prodottoModalPropostaVendite
                                    .quantita >
                                  4
                              ) {
                                this.onPressAccettaCustom();
                              } else {
                                alert(t("remaining_lot"));
                              }
                            }}
                            className="accetta-btn-custom"
                          >
                            {t("accept")}
                          </button>
                          <button
                            disabled={this.state.loading}
                            className="rifiuta-btn-custom"
                            onClick={() => this.onPressRifiutaCustom()}
                          >
                            {t("reject")}
                          </button>
                        </div>
                        {this.state.prodottoModalLotto.quantita -
                          this.state.prodottoModalPropostaVendite.quantita <=
                        4 ? (
                          <label
                            style={{
                              marginBottom: "2%",
                              textDecoration: "underline",
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            {t("no_quantity_remaining")}
                          </label>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginLeft: "9px",
                              gap: "5px",
                              width: "100%",
                            }}
                          >
                            <label
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {t("relist_lot")}
                            </label>

                            <Switch
                              onClick={() =>
                                this.setState({
                                  selectListed: !this.state.selectListed,
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              className="custom-modal-new"
              size={"xl"}
              show={this.state.showNewLotto}
              onHide={() => this.setState({ showNewLotto: false })}
            >
              <Modal.Header closeButton>
                <Modal.Title>{t("new_stock")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  {t("new_price")}: {this.state.nuovoPrezzoLotto}€
                </p>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "20% 20% 20% 20% 20%",
                  }}
                >
                  {this.state.taglieVisualizzaLotti.map((item, index) => {
                    const tagliaCustom =
                      this.state.taglieVisualizzaLottiCustom.find(
                        (f) => f.taglia === item.taglia
                      );
                    console.log("TAGLIA CUSTOM in modal", tagliaCustom);
                    let nuovoNumero = 0;
                    nuovoNumero = tagliaCustom?.numero
                      ? item.numero - tagliaCustom?.numero
                      : item.numero;

                    return (
                      <>
                        {nuovoNumero > 0 && (
                          <div className="taglia-nuovolotto-newbox">
                            <div className="nuovo-lotto-container">
                              <select
                                value={item.taglia}
                                disabled={!this.state.editStock}
                                style={{ fontSize: "0.8rem", marginRight: 5 }}
                              >
                                {this.lottiDefault.map((item1, indx) => (
                                  <option
                                    key={
                                      "taglia" + "_" + item1.taglia + "_" + indx
                                    }
                                    value={item1.taglia}
                                  >
                                    US{" " + item1.taglia}
                                  </option>
                                ))}
                              </select>
                              <div className="nuovo-lotto-taglia-counter">
                                <input
                                  disabled={!this.state.editStock}
                                  type="number"
                                  min="0"
                                  value={nuovoNumero}
                                />
                              </div>
                            </div>
                            <div className="nuovo-lotto-container-ai">
                              <input
                                id={index}
                                type="number"
                                min="0"
                                value={item.prezzo}
                                placeholder="Prezzo"
                                disabled={!this.state.editStock}
                              />
                              <p
                                style={{
                                  fontSize: "0.6rem",
                                  textAlign: "center",
                                  verticalAlign: "center",
                                  margin: 0,
                                  marginLeft: 3,
                                  height: "fit-content",
                                }}
                              >
                                {" "}
                                €
                              </p>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              className="custom-modal-2"
              size={"xl"}
              show={this.state.showModalLotto}
              onHide={() => this.onHideModal()}
            >
              <div className="body-modal-view-lotto">
                <div
                  className="mvl-header"
                  style={{ justifyContent: "space-between" }}
                >
                  <p className="mvl-title">
                    {t("stock")} n {this.state.prodottoModalLotto?.id}
                  </p>
                  <div className="edit_average_price_vendite">
                    {this.state.editStock && (
                      <input
                        placeholder={t("average_price")}
                        type="number"
                        min="0"
                        onChange={(e) => {
                          this.updateAllPrices(e.target.value);
                        }}
                      />
                    )}
                    <p
                      className={
                        this.state.editStock
                          ? "edit-label-caseActive cursor-p"
                          : "edit-label cursor-p"
                      }
                      onClick={() =>
                        this.onPressEdit(this.state.prodottoModalLotto?.id)
                      }
                    >
                      {t("edit")}
                    </p>
                  </div>
                </div>
                <div
                  className="nuovolotto-div-tuttofortissimo"
                  style={{ backgroundColor: "#f5f5f5" }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="content-griglia-tuttofortissimo-flex-start">
                      {this.state.taglieVisualizzaLotti.map((item, index) => (
                        <>
                          <div className="taglia-nuovolotto">
                            <div className="nuovo-lotto-container">
                              <select
                                value={item.taglia}
                                disabled={!this.state.editStock}
                                style={{ fontSize: "0.8rem", marginRight: 5 }}
                                onChange={(e) =>
                                  this.onChangeTagliaEdit(
                                    item,
                                    "taglia",
                                    e.target.value,
                                    "taglia" + "_" + index + "_",
                                    index
                                  )
                                }
                              >
                                {this.lottiDefault.map((item1, indx) => (
                                  <option
                                    key={
                                      "taglia" + "_" + item1.taglia + "_" + indx
                                    }
                                    value={item1.taglia}
                                  >
                                    US{" " + item1.taglia}
                                  </option>
                                ))}
                              </select>
                              <div className="nuovo-lotto-taglia-counter">
                                <input
                                  disabled={!this.state.editStock}
                                  type="number"
                                  min="0"
                                  value={item.numero ? item.numero : 0}
                                  onChange={(e) =>
                                    this.onChangeTagliaEdit(
                                      item,
                                      "quantita",
                                      e.target.value,
                                      "taglia" + "_" + index + "_",
                                      index
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="nuovo-lotto-container-ai">
                              <input
                                id={index}
                                type="number"
                                min="0"
                                value={item.prezzo}
                                placeholder="Prezzo"
                                disabled={!this.state.editStock}
                                onChange={(e) =>
                                  this.onChangeTagliaEdit(
                                    item,
                                    "prezzo",
                                    e.target.value,
                                    "taglia" + "_" + index + "_",
                                    index
                                  )
                                }
                              />
                              <p
                                style={{
                                  fontSize: "0.6rem",
                                  textAlign: "center",
                                  verticalAlign: "center",
                                  margin: 0,
                                  marginLeft: 3,
                                  height: "fit-content",
                                }}
                              >
                                {" "}
                                €
                              </p>
                            </div>
                            {this.state.editStock && (
                              <p
                                onClick={() => this.onClickDeleteSize(item)}
                                style={{
                                  fontSize: "0.7rem",
                                  textAlign: "center",
                                  margin: 0,
                                  color: "#8C0001",
                                }}
                                className="cursor-p"
                              >
                                {t("delete")}
                              </p>
                            )}
                          </div>
                        </>
                      ))}
                      {this.state.editStock && (
                        <div
                          onClick={() => this.addEmptySize()}
                          className="btn-add-new-taglia"
                        >
                          <p
                            className="cursor-p"
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#0A09A2",
                            }}
                          >
                            {t("add_another_size")}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mvl-footer-margin">
                  <p className="mvl-price">
                    {t("total_price")}{" "}
                    {this.checkEdit()
                      ? this.state.prodottoModalLotto?.prezzo + " €"
                      : t("payout_will_be_up")}
                  </p>
                  {!this.checkEdit() !== 0 && (
                    <button
                      onClick={() => this.onClickSaveEdit()}
                      className="mvl-buttonSaveEdit"
                    >
                      {t("save")}
                    </button>
                  )}
                </div>
              </div>
            </Modal>

            <Modal
              size={"xl"}
              show={this.state.visualizzatore}
              onHide={() => this.onHideVisualizzatore()}
            >
              <div className="body-modal-view-lotto">
                <div className="mvl-header">
                  <p className="mvl-title">
                    {t("stock_n")} {this.state.prodottoVisualizzatore.id}
                  </p>
                </div>
                <div
                  className="nuovolotto-div-tuttofortissimo"
                  style={{ backgroundColor: "#f5f5f5" }}
                >
                  <div className="container-per-griglia">
                    <div className="content-griglia-tuttofortissimo-flex-start">
                      {this.state.taglieVisualizzatore.map((item, index) => {
                        console.log("taglia visualizzatore", item);
                        return (
                          <div className="taglia-nuovo-lotto-new">
                            <div className="taglie-per-custom-modal">
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                  alignSelf: "center",
                                }}
                              >
                                US {item.taglia}
                              </span>

                              <div className="taglie-per-custom-modal-child">
                                <span
                                  className="right-box-noblue"
                                  style={{ fontSize: "0.7rem" }}
                                >
                                  {t("qty")}
                                </span>
                                <div className="right-box-blue">
                                  {item.numero}
                                </div>
                              </div>
                            </div>

                            <div className="taglie-per-custom-modal-child">
                              <span
                                className="right-box-noblue"
                                style={{ fontSize: "0.7rem" }}
                              >
                                {t("price")}
                              </span>
                              <div className="right-box-blue">
                                {item.prezzo}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="mvl-fortissimo">
                  <p className="mvl-price">
                    {
                      //Traduzione
                    }{" "}
                    <div className="prezzi-nuovo-lotto-2">
                      <p
                        class="text-right-lotto"
                        style={{
                          width: "100%",
                          marginRight: "6%",
                          fontSize: "initial",
                        }}
                      >
                        {t("tot_pay")}:
                        <b>{this.state.prodottoVisualizzatore.prezzo + " €"}</b>
                        <br />
                        {!this.state.prodottoVisualizzatore.ivaMargine ? (
                          t("Iva") + "22%: "
                        ) : (
                          <>{t("marginal_vat_checked") + ":"}</>
                        )}
                        <b>
                          {this.calcIva(this.state.prodottoVisualizzatore) +
                            " €"}
                        </b>
                        <br />
                        {t("shipping_fee")}:
                        <b>
                          {this.calcFeeSped(this.state.prodottoVisualizzatore) +
                            " €"}
                        </b>
                        <br />
                        {t("transaction_fees")}:
                        <b>
                          {this.calcWKfee(this.state.prodottoVisualizzatore) +
                            " €"}
                        </b>
                        <br />
                        {t("tot_pay_recived")}:
                        <b>
                          {this.totale(this.state.prodottoVisualizzatore) +
                            " €"}
                        </b>
                      </p>
                    </div>
                  </p>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props.translation;
    console.log("taglieVisualizzaLotti", this.state.taglieVisualizzaLotti);
    console.log(
      "taglieVisualizzaLottiCustom",
      this.state.taglieVisualizzaLottiCustom
    );
    return (
      <>
        {this.props.desktop ? <this.DesktopRender /> : <this.MobileRender />}
        <Modal
          style={{ marginTop: 200 }}
          size={"m"}
          show={this.state.modalConfermaEdit}
          onHide={this.onHideModalConfermaEdit}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "1.2rem" }}>
              {t("are_you_sure_update_stock")} <br /> {t("new_tot")}{" "}
              {this.state.recapEdit?.nuovo + "€"}
            </p>
            <p style={{ fontSize: "0.9rem", marginBottom: 20 }}>
              {t("with_5_trans")} <br />
              <b style={{ fontSize: "1rem" }}>
                {" "}
                {t("new_tot_is")} {this.state.recapEdit?.payout + "€"}
              </b>
            </p>

            <button
              onClick={() => this.onPressModalEditConfirm()}
              className="invia-proposta confirm-cust"
            >
              {t("confirm")}
            </button>
            <button
              onClick={this.onHideModalConfermaEdit}
              className="chiudi-proposta"
            >
              {t("cancel")}
            </button>
          </div>
        </Modal>
        <Modal
          style={{ marginTop: 200 }}
          size={"m"}
          show={this.state.modalConfermaAccetta}
          onHide={this.onHideModalConfermaAccetta}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "1.2rem" }}>
              {t("accept_pro")} {this.state.modalConfirmData?.nuovo + "€"}{" "}
              {t("for_your_stock")}
            </p>
            <p style={{ fontSize: "0.9rem", marginBottom: 20 }}>
              {t("with_5_trans")} <br />
              <b style={{ fontSize: "1rem" }}>
                {" "}
                {t("new_tot_is")} {this.state.modalConfirmData?.payout + "€"}
              </b>
            </p>

            <button
              onClick={() => this.accettaFinal(this.state.propostaScelta)}
              className="invia-proposta"
            >
              {t("confirm")}
            </button>
            <button
              onClick={this.onHideModalConfermaAccetta}
              className="chiudi-proposta"
            >
              {t("cancel")}
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  HeaderMobileProfile: {
    flexDirection: "row",
    width: "90%",
    height: "fit-content",
    paddingVertical: 13,
    borderBottomColor: "#7A79CD",
    flexGrow: "none",
    borderBottomWidth: 1.5,
  },
  containerProposte: {
    width: "90%",
    marginTop: 10,
    paddingBottom: 8,
    marginVertical: 20,
    flexDirection: "row",
    borderBottomColor: "#7A79CD",
    borderBottomWidth: 1.5,
  },

  containerLabelProdotto: {
    width: "60%",
  },

  titleContProd: {
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
  containerShipping: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 10,
    alignItems: "baseline",
  },
  labelModalTools: {
    fontSize: "1.1rem",
    fontWeight: "800",
    marginBottom: 6,
  },
  buttonModalTools: {
    paddingVertical: 18,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderTopWidth: 1,
    borderColor: "#DEE4FD",
  },
  labelBtnTools: {
    fontWeight: "700",
    color: "#0A09A2",
  },
  NoboldLabel: {
    fontSize: "0.9rem",
  },
  boldLabel: {
    fontWeight: 700,
    fontSize: "0.9rem",
    marginTop: "5%",
  },
  mobileCompletaCheckoutButton: {
    borderWidth: 0,
    backgroundColor: "#0A09A2",
    color: "#fff",
    borderRadius: 8,
  },
});

export default function (props) {
  const desktop = useDesktopChecker();
  const translation = useTranslation();
  return <Vendite {...props} desktop={desktop} translation={translation} />;
}
