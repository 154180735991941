import React, { Component } from "react";
import { View, Text, TouchableOpacity, StyleSheet, Image } from "react-native";
import { useNavigate, useParams } from "react-router-dom";
import HeaderMobile from "../components/HeaderMobile";
import Carousel from "../home/Carousel";
import ModalVisualizzaProdotto from "./ModalVIsualizzaProdotto";
import * as api from "../api/service";
import CONSTANTS from "../../CONSTANTS";
import "./style.css";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import axios from "axios";
import LOGOUTFUNCTION from "../../LOGOUTFUNCTION";

class SingleProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modal: false,
      drawer: false,
      singleProd: [],
      prodId: "",
      loading: false,
      relProd: [],
      lottoProd: [],
      modalMakeProposal: false,
      itemMakeProposal: {},
      proposalPrice: 0,
      dataProposal: "",
    };
  }
  onPressModalCancel = () => {
    this.setState({ modal: false });
  };
  onPressAggiungiModel = () => {
    this.setState({ modal: true });
  };
  onPressItem = (item, type) => {};

  componentDidMount = async () => {
    this.setState({ loading: true }, () => {
      console.log("loading is true");
    });
    const { id } = this.props.params;
    console.log("this is your id: ", id);
    this.setState({ prodId: id });
    const singleProd = await api.singleProd(id);
    this.setState({ singleProd: singleProd });

    const relatedProd = await api.relatedProd(id);
    this.setState({ relProd: relatedProd });

    const lottoProdCall = await api.lottoProd(id).catch((e) => console.log(e));
    this.setState({ lottoProd: lottoProdCall });

    console.log("related prod: ", this.state.relProd);
    console.log(singleProd);
    console.log("lotto prod: ", this.state.lottoProd);

    this.setState({ loading: false }, () => {
      console.log("loading is false");
    });
  };
  onClickListaLotto = () => {
    this.props.navigate("/sell", {
      state: { pos: 1, prodotto: this.state.singleProd },
    });
  };
  onClickModalMakeProp = (item) => {
    this.setState({ itemMakeProposal: item, modal: false }, () => {
      this.setState({ modalMakeProposal: true });
    });
  };
  inviaProposta = async (e) => {
    e.preventDefault();
    const body = {
      offerta: parseInt(this.state.proposalPrice),
      dataScadenza: this.state.dataProposal,
      ProdottoId: parseInt(this.state.itemMakeProposal.ProdottoId),
      LottoId: this.state.itemMakeProposal.id,
      AccountId: localStorage.getItem(CONSTANTS.ID),
      StatoId: 6,
    };
    console.log(body);
    await axios
      .post(CONSTANTS.API_URL + "/offertaFatta/", body, {
        HeaderMobiles: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY
          )}`,
        },
      })
      .then((res) => {
        if (res.data.error) {
          LOGOUTFUNCTION();
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  setDateOffer = (e) => {
    var date = new Date();
    console.log(date.getDate());
    date.setDate(+date.getDate() + e);
    var dateString = date.toISOString().split("T")[0];
    console.log(dateString);
    this.setState({ dataProposal: dateString });
  };

  render() {
    const { t } = this.props.translation;
    console.log(this.state.prodId);

    return (
      <div className="Container-Common" style={{ flexDirection: "column" }}>
        <View style={style.container}>
          <HeaderMobile context="Home" />
          <View style={{ width: "90%", marginTop: 10 }}>
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  marginRight: 10,
                  backgroundColor: "#0a09a2",
                  color: "white",
                  paddingHorizontal: 15,
                  paddingVertical: 10,
                }}
              >
                100% {t("auth")}
              </Text>
              <Text
                style={{
                  marginRight: 10,
                  backgroundColor: "#0a09a2",
                  color: "white",
                  paddingHorizontal: 15,
                  paddingVertical: 10,
                }}
              >
                100% {t("new")}
              </Text>
            </View>
          </View>

          <Text
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              textAlign: "left",
              width: "90%",
              marginTop: 10,
            }}
          >
            {this.state.singleProd.nome}
          </Text>
          <Text style={{ fontSize: "1rem", textAlign: "left", width: "90%" }}>
            {this.state.singleProd.modello}
          </Text>
          <Image
            source={{
              uri: this.state.singleProd.imageProdotto,
            }}
            style={{ height: 300, width: "80%", resizeMode: "contain" }}
          />
          <View style={{ width: "90%", flexDirection: "row" }}>
            <View style={{ width: "60%" }}>
              <Text
                style={{ fontSize: "0.9rem", textAlign: "left", width: "90%" }}
              >
                {t("best_stock")}
              </Text>
              <Text
                style={{
                  fontSize: "1.1rem",
                  textAlign: "left",
                  width: "50%",
                  fontWeight: "bold",
                }}
              >
                {parseInt(this.state.singleProd.prezzo)} €
              </Text>
            </View>
            <View style={{ width: "40%" }}>
              {this.state.lottoProd.length === 0 ? (
                <Text style={{ fontSize: "1.2rem" }}>{t("no_stock")}</Text>
              ) : (
                <TouchableOpacity
                  style={{
                    backgroundColor: "#0A09A2",
                    textAlign: "center",
                    paddingVertical: 5,
                    borderRadius: 5,
                  }}
                  onPress={this.onPressAggiungiModel}
                >
                  <Text style={{ color: "white" }}>{t("see_stocks")}</Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View style={{ width: "90%" }}>
            <TouchableOpacity
              onPress={() => this.onClickListaLotto()}
              style={{
                borderColor: "#0A09A2",
                borderWidth: 1.5,
                width: "40%",
                textAlign: "center",
                borderRadius: 5,
                marginTop: 20,
                paddingVertical: 4,
              }}
            >
              <Text style={{ color: "#0A09A2" }}>{t("batch_list")}</Text>
            </TouchableOpacity>
          </View>
          <Text
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              textAlign: "left",
              width: "90%",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {t("product_det")}
          </Text>
          <View style={style.containerDettaglio}>
            <Text
              style={{
                fontSize: "1rem",
                marginTop: 10,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {t("realease_date")}:
            </Text>
            <Text
              style={{
                fontSize: "1rem",
                marginTop: 10,
                textTransform: "uppercase",
              }}
            >
              {this.state.singleProd.dettaglio}
            </Text>
          </View>
          <View style={style.containerDettaglio}>
            <Text
              style={{
                fontSize: "1rem",
                marginTop: 10,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Colorway:
            </Text>
            <Text
              style={{
                fontSize: "1rem",
                marginTop: 10,
                textTransform: "uppercase",
                textAlign: "right",
              }}
            >
              {this.state.singleProd.colore}
            </Text>
          </View>
          <View style={style.containerDettaglio}>
            <Text
              style={{
                fontSize: "1rem",
                marginTop: 10,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Style
            </Text>
            <Text
              style={{
                fontSize: "1rem",
                marginTop: 10,
                textTransform: "uppercase",
              }}
            >
              {this.state.singleProd.style}
            </Text>
          </View>

          <Text
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              textAlign: "left",
              width: "90%",
              marginVertical: 20,
            }}
          >
            Related Product
          </Text>

          <Carousel
            onPressItem={this.onPressItem}
            context="RelatedProduct"
            data={this.state.relProd}
          />
        </View>
        <Footer />
        <ModalVisualizzaProdotto
          modalAdd={this.state.modal}
          clickMakeProposal={this.onClickModalMakeProp}
          onModalDismiss={this.onPressModalCancel}
        />

        <Modal
          centered
          show={this.state.modalMakeProposal}
          onHide={() =>
            this.setState({ itemMakeProposal: {}, modalMakeProposal: false })
          }
          dialogClassName="aggiungi-modello-modal"
        >
          <div className="modal-HeaderMobile-text">
            <p className="modello-title-modal">{t("make_proposal")}</p>
            <label className="acquista-subito">
              {t("buy_it_now")}: {this.state.itemMakeProposal.prezzo}€
            </label>
            <p className="modello-sub-title-modal">{t("your_prop")}:</p>
            <div className="input-modal-proposta-prod">
              <form
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
                onSubmit={(e) => {
                  this.inviaProposta(e);
                }}
              >
                <label className="text-bar-proposta-input">
                  <input
                    type="text"
                    placeholder={t("price")}
                    className="text-bar-input"
                    style={{ border: 0 }}
                    onChange={(e) => {
                      this.setState({ proposalPrice: e.target.value });
                    }}
                    required
                  />
                </label>
                <select
                  id="Durata"
                  required
                  className="input-bar"
                  onChange={(e) => this.setDateOffer(e.target.value)}
                >
                  <option value="">{t("val_off")}</option>
                  <option value="30">30</option>
                  <option value="60">60</option>
                  <option value="90">90</option>
                  <option value="120">120</option>
                </select>

                <div className="modal-footer-button-proposta">
                  <button type="submit" className="invia-proposta">
                    {t("submit_proposal")}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* <Link to={"/lotto-custom-one/" + id + "/" + lottoSelezionato.id}>
          {t("create_custom")}
        </Link> */}
          <div className="modal-footer-button">
            <button
              className="chiudi-proposta"
              onClick={() => this.setState({ modalMakeProposal: false })}
            >
              {t("cancel")}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: "15%",
  },

  containerDettaglio: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "90%",
    paddingRight: "5%",
  },
});

export default function (props) {
  const t = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  return (
    <SingleProduct
      translation={t}
      {...props}
      params={params}
      navigate={navigate}
    />
  );
}
