const CONSTANTS = {
  ACCESS_TOKEN_KEY: "ACCESS_TOKEN_KEY",
  NOME: "NOME",
  COGNOME: "COGNOME",
  ID: "ID",
  EMAIL: "EMAIL",
  P_IVA: "P_IVA",
  R_SOCIALE: "R_SOCIALE",
  NAZIONE: "NAZIONE",
  EMAILRESETPASSWORD: "EMAILRESETPASSWORD",
  USER_LOCATION: "USER_LOCATION",
  DEFAULT_CITY_LOCATION: {
    latitude: 40.870598,
    longitude: 14.238643,
  },
  PRICESPEDIZIONE: 10,
  DEFAULT_MAX_DISTANCE: 5000,
  DEFAULT_PROFILE_IMAGE_URL:
    "https://bootdey.com/img/Content/avatar/avatar6.png",
  DEFAULT_DATE_FORMAT: "YYYY-MM-DD",
  DEFAULT_DATEPROFILE_FORMAT: "DD-MM-YYYY",
  DEFAULT_DATETIME_FORMAT: "YYYY-MM-DD hh:mm:ss",
  // PAUSEPAY_URL:{
  //   'test':'https://test-api.pausepay.it',
  //   'prod':'https://api.pausepay.it'
  // },
  // API_URL: "http://localhost:3001",
  //API_URL:'http://192.168.1.209:3001',
  //API_URL2: 'http://localhost:3002',
  //API_URL2: 'https://app.wholekicks.eu',

  // API_URL: "https://app.wholekicks.eu/be",

  API_URL: process.env.REACT_APP_API_URL,

  //URL_RESET_PASSWORD: "https://ext01.tecnosoftgroup.eu:52109",
  URL_RESET_PASSWORD: process.env.REACT_APP_RESET_PASSWORD_API_URL,
  // URL_RESET_PASSWORD: "http://localhost:3000",

  // API_URL : 'http://192.168.1.209:3001',
  // API_URL:'https://ext01.tecnosoftgroup.eu:52107',
  //UTILS
  dimensions: { height: window.innerHeight, width: window.innerWidth },

  NEXI_DIVISA: "EUR",

  NEXI_MAC_SECRET_KEY_PROD: "C8WYm5M6Hu247JEVFus3LksW1M6xh55SB744S4x5",
  NEXI_XPAY_ALIAS_PROD: "payment_3777713",

  NEXI_MAC_SECRET_KEY_TEST: "QEHA1C98CIUAKUINU1GWD5H9V3PTFBQI",
  NEXI_XPAY_ALIAS_TEST: "ALIAS_WEB_00076994",
};

export default CONSTANTS;
