import React from "react";
import {
  FacebookIcon,
  EmailIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import { Modal } from "react-bootstrap";
import "./style.css";

function ModalShare({ visible, setvisibleFun, shareUrl }) {
  return (
    <Modal
      centered
      show={visible}
      onHide={() => setvisibleFun(false)}
      dialogClassName="aggiungi-modello-modal-social"
    >
      <div className="inner-container-social">
        <EmailShareButton className="button-social" url={shareUrl}>
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
        <FacebookShareButton className="button-social" url={shareUrl}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <TwitterShareButton className="button-social" url={shareUrl}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton className="button-social" url={shareUrl}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <TelegramShareButton className="button-social" url={shareUrl}>
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
        <div className="button-social">Copia link</div>
      </div>
    </Modal>
  );
}

export default ModalShare;
