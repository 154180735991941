import React, { Component } from "react";
import HeaderMobile from "../../components/HeaderMobile";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
} from "react-native";
import { withTranslation } from "react-i18next";
import "./style.css";
import { changeMyIban } from "./FunctionProfile";

class ModificaIban extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldIban: "",
      newIban: "",
      confIban: "",
    };
  }
  render() {
    const { t } = this.props;
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Others" title={t("edit_iban")} />
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
            }}
          >
            <Text
              style={{
                textAlign: "center",
                fontSize: "1.2rem",
                marginTop: 10,
                marginBottom: "20%",
              }}
            >
              {t("fill_fields")}
            </Text>
            <form className="form-modifica">
              {/* <View
                style={{
                  width: "90%",
                  borderWidth: 1,
                  borderColor: "#7A79CD",
                  borderRadius: 7,
                  marginVertical: 10,
                  padding: 10,
                }}
              >
                <TextInput
                  placeholder="Old Iban"
                  style={{
                    width: "100%",
                    fontSize: "1.2rem",
                  }}
                />
                </View>*/}
              <View
                style={{
                  width: "90%",
                  borderWidth: 1,
                  borderColor: "#7A79CD",
                  borderRadius: 7,
                  marginVertical: 10,
                  padding: 10,
                }}
              >
                <TextInput
                  style={{
                    width: "100%",
                    fontSize: "1.2rem",
                  }}
                  placeholder="New Iban"
                  onChange={(e) => {
                    this.setState({ newIban: e.target.value });
                  }}
                />
              </View>
              <View
                style={{
                  width: "90%",
                  borderWidth: 1,
                  borderColor: "#7A79CD",
                  borderRadius: 7,
                  marginVertical: 10,
                  padding: 10,
                }}
              >
                <TextInput
                  style={{
                    width: "100%",
                    fontSize: "1.2rem",
                  }}
                  placeholder="Confirm Iban"
                  onChange={(e) => {
                    this.setState({ confIban: e.target.value });
                  }}
                />
              </View>
              <TouchableOpacity
                style={style.btnConf}
                onPress={() =>
                  changeMyIban(this.state.newIban, this.state.confIban)
                }
              >
                <Text style={style.btnText}>Confirm</Text>
              </TouchableOpacity>
            </form>
          </View>
        </View>
      </div>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "80%",
  },
  btnConf: {
    width: "90%",
    borderRadius: 7,
    backgroundColor: "#0A09A2",
    paddingVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  btnText: {
    color: "white",
    fontSize: "1.1rem",
  },
});

export default withTranslation()(ModificaIban);
