import axios from "axios";
import CONSTANTS from "./CONSTANTS";
export const sendMail = async (body) => {
  console.log(body);
  await axios
    .post(CONSTANTS.API_URL + "/mail/registration", body)
    .then((res) => {
      console.log("success");
      console.log(res);
    })
    .catch((e) => {
      console.log(e.message);
    });
};
