import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableWithoutFeedback,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
  Keyboard,
  TextInput,
  StyleSheet,
  Modal,
} from "react-native";

import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate, useParams } from "react-router-dom";
import { colors, dim, isLittleDev } from "../components/utils/constants";
import * as api from "../api/service";
import CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Accordion } from "react-bootstrap";
import "./style.css";
import {
  isAutenticated,
  getCallerName,
} from "../../components/utils/validatorActions";

const ModalSearch = ({
  modalAdd,
  onModalDismiss,
  utente,
  onPressVisualizzaLottiListati,
  onPressVisualizzatore,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [prodotti, setProdotti] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {}, [prodotti]);
  const searchFunctionKeyUp = async (parola) => {
    if (parola === "") {
      setProdotti([]);
    } else {
      await axios
        .get(
          CONSTANTS.API_URL +
            "/prodotto/lotto-listato-search/" +
            parola +
            "/" +
            utente,
        )
        .then((prodotti) => {
          console.log(prodotti);
          setProdotti(prodotti.data);
        });
    }
  };
  const closeModal = (prodotto) => {
    onModalDismiss();
    onPressVisualizzaLottiListati(prodotto);
  };
  const deleteLotto = async (id, key) => {
    const isAvailable = await axios.get(
      CONSTANTS.API_URL + "/lotto/lotto-is-available/" + id,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            CONSTANTS.ACCESS_TOKEN_KEY,
          )}`,
        },
      },
    );
    console.log("isAv", isAvailable);
    if (isAvailable.data) {
      axios
        .delete(CONSTANTS.API_URL + "/lotto/delete-lotto/" + id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              CONSTANTS.ACCESS_TOKEN_KEY,
            )}`,
          },
        })
        .then((prodottoRicevuto) => {
          const prodNew = prodotti.filter((p) => p.id != id);
          console.log("Eccolo", prodNew);
          setProdotti(prodNew);
        });
    } else {
      alert(t("you_not_delete"));
    }
  };
  return (
    <>
      {!loading && (
        <>
          <Modal
            visible={modalAdd}
            transparent
            animationType="slide"
            className="modale-ricerca-vendite"
          >
            <View
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!isLittleDev() && (
                <TouchableWithoutFeedback onPress={onModalDismiss}>
                  <View style={{ height: "6%", width: "100%" }} />
                </TouchableWithoutFeedback>
              )}
              <ScrollView
                style={{
                  height: isLittleDev() ? "100%" : "94%",
                  maxHeight: "70vh",
                  width: "70vw",
                  backgroundColor: "#fff",
                  borderRadius: isLittleDev() ? 15 : 30,
                  shadowRadius: 10,
                  shadowOpacity: 0.5,
                  paddingHorizontal: 10,
                  paddingVertical: 20,
                }}
                contentContainerStyle={{
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <View style={{ width: "100%", alignItems: "flex-end" }}>
                  <TouchableOpacity onPress={onModalDismiss}>
                    <Text style={{ fontWeight: "bold", color: colors.blue }}>
                      {t("close")}
                    </Text>
                  </TouchableOpacity>
                </View>
                <div>
                  <label className="search-bar">
                    <SearchIcon className="search-icon" />

                    <input
                      type="search"
                      placeholder={t("search_stock")}
                      onKeyUp={(e) => searchFunctionKeyUp(e.target.value)}
                      className="search-bar-input"
                    />
                  </label>
                </div>
                {prodotti.length > 0 ? (
                  prodotti.map((prodotto, key) => {
                    return (
                      <div
                        key={prodotto.id + "_" + key}
                        className="account-list"
                      >
                        <div className="account-list-detail">
                          <div className="scarpa-img-list">
                            <img src={prodotto.imageProdotto} alt="scarpa" />
                          </div>
                          <div className="modello-account-detail">
                            <p className="title-modello">{t("prod")}</p>
                            <p className="modello-mod">{prodotto.nome}</p>
                            <p className="tag-modello">{prodotto.style}</p>
                          </div>
                          <div className="n-lotto-detail">
                            <p className="title-div">{t("stock_n")}</p>
                            <p className="modello-mod stock-n-account">
                              {prodotto.id}
                            </p>
                          </div>
                          <div className="qty-account-detail">
                            <p className="title-div">
                              {t("qty")}
                              {}
                            </p>
                            <p className="qty-div">{prodotto.quantita}</p>
                          </div>
                          <div className="proposta-account-detail">
                            <p className="title-div">{t("price")}</p>
                            <p className="qty-div">
                              {" "}
                              <b>
                                {parseFloat(prodotto.prezzo)
                                  .toFixed(2)
                                  .toString()
                                  .replace(".", ",") + " €"}
                              </b>
                            </p>
                            <div>
                              <button
                                className="accetta-proposta-btn"
                                onClick={() => closeModal(prodotto)}
                              >
                                {t("see_more")}
                              </button>
                              <button
                                className="rifiuta-proposta-btn"
                                onClick={() => deleteLotto(prodotto.id, key)}
                              >
                                {t("delete")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </ScrollView>
            </View>
          </Modal>
        </>
      )}
    </>
  );
};

const style = StyleSheet.create({
  containerView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  textBold: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  textNotBold: {
    fontSize: "1rem",
    marginLeft: 5,
  },
});

export default ModalSearch;
