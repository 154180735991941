import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import * as api from "../api/service";

const DrawerContent = () => {
  const [brands, setbrands] = useState([]);

  useEffect(() => {
    const popBrand = getBrand();
    console.log(popBrand);
    setbrands(popBrand);
  }, []);

  const getBrand = async () => {
    const brands = await api.popBrand();

    return brands;
  };

  return (
    <View style={style.container}>
      <Accordion flush>
        <Accordion.Item eventKey="0" className="accordion-item-style">
          <Accordion.Header className="accordionHeader">
            Brand più popolari
          </Accordion.Header>
          <Accordion.Body>
            {/* {brands.map(item=>{
                            return(<TouchableOpacity style={style.buttonAccordionChild}>
                                Brand 1
                            </TouchableOpacity>)
                        })} */}
            <TouchableOpacity style={style.buttonAccordionChild}>
              Brand 1
            </TouchableOpacity>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="accordion-item-style">
          <Accordion.Header className="accordionHeader">About</Accordion.Header>
          <Accordion.Body>
            <TouchableOpacity style={style.buttonAccordionChild}>
              <Text>Autenticazione</Text>
            </TouchableOpacity>
            <TouchableOpacity style={style.buttonAccordionChild}>
              <Text>Guida all’acquisto</Text>
            </TouchableOpacity>
            <TouchableOpacity style={style.buttonAccordionChild}>
              <Text>Guida alla vendita</Text>
            </TouchableOpacity>
            <TouchableOpacity style={style.buttonAccordionChild}>
              <Text>How it works</Text>
            </TouchableOpacity>
            <TouchableOpacity style={style.buttonAccordionChild}>
              <Text>Azienda</Text>
            </TouchableOpacity>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </View>
  );
};
const style = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
  },
  buttonAccordionChild: {
    paddingVertical: 10,
  },
});
export default DrawerContent;
