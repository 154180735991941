import React, {
  Component,
  useEffect,
  useState,
  useReducer,
  PureComponent,
} from "react";
import logo from "../../assets/wk-logo.png";
import { Link, Navigate, useParams, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import "../../styles/NuovoLotto.css";
import ListaLottoFourth from "./ListaLottoFourthOld";
import { Location } from "react-router-dom";
import axios from "axios";
import CONSTANTS from "../../CONSTANTS";
import {
  lottoDefault,
  lottoVettore,
} from "../../components/utils/defaultObject";
import { useTranslation } from "react-i18next";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import scarpaVuota from "../../assets/wk_products_not_found.png";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Image,
  StyleSheet,
  ScrollView,
} from "react-native";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";
import { colors } from "../../pagesMobile/components/utils/constants";
import { Switch } from "@mui/material";

const ListaLottoThird = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [prodotto, setprodotto] = useState({});
  const [lotti, setlotti] = useState(lottoVettore);
  const [lottoAppoggio, setlottoAppoggio] = useState(lottoDefault);
  const [totale, settotale] = useState(0);
  const [shipfee, setshipfee] = useState(0);
  const [qty, setqty] = useState(0);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [wkfee, setwkfee] = useState(0);
  const desktop = useDesktopChecker();
  const { t } = useTranslation();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isLoading, setIsloading] = useState(false);
  const [italy, setItaly] = useState(false);
  const [iva, setIva] = useState(0);
  const [isVatMargin, setIsVatMargin] = useState(false);

  const nazione = localStorage.getItem(CONSTANTS.NAZIONE);

  const clearForm = () => {
    setlotti(lottoVettore);
    setlottoAppoggio(lottoDefault);
    console.log("LOTTO DEFAULT VETTORE", lottoVettore);
    console.log("LOTTO DEFAULT OGGETTO", lottoDefault);
  };

  const updateValueArray = (taglia, value, field) => {
    console.log("value");
    let array = [...lotti];
    let index = array.findIndex((a) => a.taglia === taglia);
    let item = { ...array[index] };
    item[field] =
      value === "NaN" || value === "" ? 0 : Number.parseFloat(value);
    array[index] = item;
    setlotti(array);
  };

  function calcPrezSpedizione() {
    let sum = 0;
    for (let i = 0; i < lotti.length; i++) {
      sum += lotti[i].quantita;
    }
    const module = sum % 6;
    let division = sum / 6;
    division = Math.round(division - 0.5);
    if (module > 0) {
      return (division + 1) * CONSTANTS.PRICESPEDIZIONE;
    } else {
      return division * CONSTANTS.PRICESPEDIZIONE;
    }
  }

  function sumAllPricesLotto() {
    let sum = 0;
    for (let i = 0; i < lotti.length; i++) {
      sum += lotti[i].prezzo * lotti[i].quantita;
    }
    return sum;
  }

  function sumAllQtyLotto() {
    let sum = 0;
    for (let i = 0; i < lotti.length; i++) {
      sum += lotti[i].quantita;
    }
    return sum;
  }
  function updateAllPrices(price) {
    console.log("PREZZO TOT: ", price);
    let array = [...lotti];
    let lottoApp = { ...lottoAppoggio };
    for (let i = 0; i < lotti.length; i++) {
      let item = { ...array[i] };
      item.prezzo = price;
      array[i] = item;
      lottoApp[array[i].taglia].prezzo = price;
    }
    console.log("ARRAY", array);
    setlotti(array);
    setlottoAppoggio(lottoApp);
  }
  function calcoloIva() {
    setIva((totale - shipfee - wkfee) * 0.22);
  }

  function calcoloIvaNew(tot) {
    return !isVatMargin ? (nazione === "Italy" ? tot * 0.22 : 0) : 0;
  }

  useEffect(() => {
    setIsOnline(navigator.onLine);
    console.log("ciaoooo", isOnline);
    if (isOnline == true) {
      console.log("eccomi", isOnline);
      console.log(lotti);
      const somma = sumAllPricesLotto();
      const estShip = calcPrezSpedizione();
      const fee = somma * 0.025;
      //const fee = 0
      const qty = sumAllQtyLotto();
      settotale(somma);
      setshipfee(estShip);
      setwkfee(fee);
      //setwkfee(0);
      setqty(qty);
      if (nazione) {
        console.log("Utente:", nazione.slice(0, 2).toString());
        //0 da sostituire con fee
        nazione === "Italy"
          ? setIva((somma - estShip - fee) * 0.22)
          : setIva(0);
        console.log("IVA USE: ", iva);
      } else {
        console.log(localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY, ""));
        window.sessionStorage.removeItem("accessToken");
        window.sessionStorage.removeItem("nama");
        localStorage.clear();
        window.location.href = "/";
      }
    } else {
      setIsloading(true);
      window.alert(t("wrong_connection"));
    }
  }, [lottoAppoggio]);

  useEffect(() => {
    init();
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
    return () => {
      setlottoAppoggio(lottoDefault);
      setlotti(lottoVettore);
    };
  }, []);
  const init = async () => {
    await axios
      .get(CONSTANTS.API_URL + "/prodotto/productById/" + params.id)
      .then(async (res) => {
        console.log("res", res.data);
        setprodotto(res.data);
      });
  };
  const onClickIsVatMargin = (e) => {
    console.log("VALUE: ", e);
    setIsVatMargin(e);
    if (e) {
      console.log("Non CalcoloIVA");
      setIva(0);
    } else {
      console.log("CalcoloIVA");
      if (nazione === "Italy") {
        calcoloIva();
      } else {
        setIva(0);
      }
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="div-logo-form">{window.location.reload()}</div>
      ) : (
        <>
          {desktop ? (
            <>
              <div className="container-form-check">
                <div className="div-logo-form">
                  <Link to="/">
                    <img className="logo-login" src={logo} alt="logo" />
                  </Link>
                </div>
                <div className="checkout-form-div-speciale">
                  {" "}
                  <div className="image-prod-in-lotto-centrato">
                    <img
                      src={prodotto.imageProdotto}
                      alt="immagine-prod"
                      onError={(e) => {
                        e.target.src = scarpaVuota;
                      }}
                    />

                    {/* <p>{this.state.prodotto.modello}</p> */}
                  </div>
                  <p className="checkout-title"> {t("new_stock")} </p>
                  <p>{t("ins_size")}</p>
                  <div className="prezzi-nuovo-lotto-3">
                    <p>{t("choose_price")}:</p>
                    <input
                      type="number"
                      min="0"
                      placeholder={t("average_price") + "*"}
                      onChange={(e) => {
                        updateAllPrices(e.target.value);
                      }}
                    />
                    <p className="vat_excluded">{"*" + t("vat_excluded")}</p>
                  </div>
                  <div className="griglia-container-centramento">
                    <div className="nuovolotto-div-tuttoforte griglia-div-taglie">
                      {lotti.map((item, index) => {
                        return (
                          <div className="taglia-nuovolotto-centrata">
                            <div className="nuovo-lotto-container">
                              <span>US {item.taglia}</span>
                              <div className="nuovo-lotto-taglia-counter">
                                <input
                                  type="number"
                                  min="0"
                                  value={item.quantita}
                                  onChange={(e) => {
                                    setlottoAppoggio({
                                      ...lottoAppoggio,
                                      [item.taglia]: {
                                        ...[item.taglia],
                                        quantita: e.target.value,
                                      },
                                    });
                                    updateValueArray(
                                      item.taglia,
                                      e.target.value,
                                      "quantita"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="nuovo-lotto-container-ai">
                              <input
                                type="number"
                                min="0"
                                value={item.prezzo}
                                step="0"
                                placeholder={t("average_price") + "*"}
                                onChange={(e) => {
                                  setlottoAppoggio({
                                    ...lottoAppoggio,
                                    [item.taglia]: {
                                      ...[item.taglia],
                                      prezzo: parseInt(e.target.value),
                                    },
                                  });
                                  updateValueArray(
                                    item.taglia,
                                    parseInt(e.target.value),
                                    "prezzo"
                                  );
                                }}
                              />
                              <p
                                style={{
                                  fontSize: "0.6rem",
                                  textAlign: "center",
                                  verticalAlign: "center",
                                  margin: 0,
                                  marginLeft: 3,
                                  height: "fit-content",
                                }}
                              >
                                {" "}
                                €
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="prezzi-nuovo-lotto"></div>
                  <div className="prezzi-nuovo-lotto-2">
                    {!(totale === 0 || totale === NaN) && (
                      <p
                        className="text-right-lotto"
                        style={{ width: "100%", marginRight: "6%" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            backgroundColor: "transparent",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <Switch
                            onClick={(e) =>
                              onClickIsVatMargin(e.target.checked)
                            }
                          />
                          <span style={{ marginLeft: 5 }}>
                            {t("marginal_vat")}
                          </span>
                        </div>
                        {t("total")}
                        <b>
                          {" "}
                          {parseFloat(totale)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",") + " €"}
                        </b>
                        <br />
                        {t("shipping_fee")} ({t("pair_of_shoes")}):{" "}
                        <b>
                          {" "}
                          {"-" +
                            parseFloat(shipfee)
                              .toFixed(2)
                              .toString()
                              .replace(".", ",") +
                            " €"}
                        </b>
                        <br />
                        {t("transaction_fees")} (2,5%):
                        <b>
                          {" "}
                          {"-" +
                            parseFloat(wkfee)
                              .toFixed(2)
                              .toString()
                              .replace(".", ",") +
                            " €"}
                        </b>
                        <br />
                        {!isVatMargin ? (
                          <div>
                            V.A.T. 22%{" "}
                            <b>
                              {" "}
                              {"+" +
                                parseFloat(iva)
                                  .toFixed(2)
                                  .toString()
                                  .replace(".", ",") +
                                " €"}
                            </b>
                          </div>
                        ) : (
                          <> {t("marginal_vat_checked")}: ✔</>
                        )}
                        <br />
                        {t("tot_pay")}:{" "}
                        <b>
                          {!isVatMargin
                            ? (
                                totale -
                                shipfee -
                                wkfee +
                                (nazione === "Italy"
                                  ? calcoloIvaNew(totale - shipfee - wkfee)
                                  : 0)
                              )
                                .toFixed(2)
                                .toString()
                                .replace(".", ",") + " €"
                            : (totale - shipfee - wkfee)
                                .toFixed(2)
                                .toString()
                                .replace(".", ",") + " €"}
                        </b>
                        <br />
                      </p>
                    )}
                    {(totale === 0 || totale === NaN) && (
                      <p
                        className="text-right-lotto"
                        style={{ width: "100%", marginRight: "6%" }}
                      >
                        {t("check_fields")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="btn-footer-checkout">
                  {
                    <div
                      onClick={() => {
                        if (
                          totale &&
                          totale - (wkfee + shipfee) > 0 &&
                          qty > 4
                        ) {
                          console.log("totale", totale);
                          const lv = lotti;
                          const ld = lottoAppoggio;
                          clearForm();
                          console.log("lottoState", lottoAppoggio);
                          console.log("lottoVettState", lotti);
                          console.log("IVA: ", iva);
                          navigate("/lista-lotto-fourth/" + params.id, {
                            state: {
                              lotti: lotti.filter(
                                (t) => t.prezzo > 0 && t.quantita > 0
                              ),
                              lottoAppoggio: lottoAppoggio,
                              total: totale,
                              fees: { wk: wkfee, ship: shipfee, iva: iva },
                              ivaMargine: isVatMargin,
                              qty: qty,
                              payout: parseFloat(
                                totale -
                                  (wkfee + shipfee) +
                                  calcoloIvaNew(totale - (wkfee + shipfee))
                              ).toFixed(2),
                              payin: parseFloat(
                                totale +
                                  (wkfee +
                                    shipfee +
                                    calcoloIvaNew(totale + wkfee + shipfee))
                              ).toFixed(2),
                            },
                          });
                        } else {
                          //Traduzione
                          alert(t("check_entered_correctly"));
                        }
                      }}
                    >
                      <button className="btn-footer-checkout-third">
                        {t("next")}
                      </button>
                    </div>
                    // <Link
                    //   onClick={()=>{
                    //     setlottoAppoggio(lottoDefault)
                    //     setlotti(lottoVettore)

                    //   }}
                    //   replace
                    //   to={"/lista-lotto-fourth/" + params.id}
                    //   state={{
                    //     lotti: lotti,
                    //     lottoAppoggio: lottoAppoggio,
                    //     total: totale,
                    //     fees: { wk: wkfee, ship: shipfee },
                    //     qty: qty,
                    //   }}
                    // >
                    //   {t('next')}
                    // </Link>
                  }
                </div>
              </div>
              <Footer />
            </>
          ) : (
            <View style={style.container}>
              <HeaderMobile context="Home" />
              {/* <Menu /> */}
              <View
                style={{
                  width: "90%",
                  height: "75%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: 10,
                }}
              >
                <View
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div onClick={this.onClickBack}>Indietro</div> */}
                  <p style={{ fontWeight: "bold", width: "100%" }}>
                    {t("add_size")}
                  </p>
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      marginBottom: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={prodotto.imageProdotto}
                      style={{
                        width: "30%",
                        aspectRatio: 1,
                        resizeMode: "contain",
                        borderRadius: 7,
                        backgroundColor: "white",
                      }}
                      onError={(e) => {
                        e.target.src = scarpaVuota;
                      }}
                    />
                    <View style={{ width: "60%", marginLeft: 16 }}>
                      <Text style={{ fontWeight: "bold" }}>
                        {prodotto.nome}
                      </Text>
                      <Text>{prodotto.modello}</Text>

                      <Text>
                        {t("qty")}: {qty}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <Text>{t("choose_price")}</Text>
                    <View
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <TextInput
                        onChange={(e) => updateAllPrices(e.target.value)}
                        style={{
                          borderWidth: 1,
                          borderColor: "black",
                          padding: 5,
                        }}
                        placeholder={t("average_price") + "*"}
                      />
                      <Text
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        *{t("vat_excluded")}
                      </Text>
                    </View>
                  </View>
                  <View style={{ height: 25 }} />
                  <ScrollView
                    style={{
                      height: "100%",
                      paddingBottom: "60%",
                      width: "80%",
                    }}
                  >
                    {lotti.map((lotto) => (
                      <View
                        key={lotto.taglia}
                        style={{
                          width: "100%",
                          backgroundColor: "#f5f5f5",
                          borderRadius: 7,
                          padding: 10,
                          marginVertical: 5,
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            backgroundColor: "white",
                            borderRadius: 7,
                            padding: 5,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              width: "100%",
                              backgroundColor: "white",

                              borderRadius: 7,
                            }}
                          >
                            <View style={{ width: "50%", float: "left" }}>
                              <Text
                                style={{ fontSize: "1.3rem", fontWeight: 500 }}
                              >
                                US {lotto.taglia}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "50%",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <TouchableOpacity
                                style={{ marginRight: 10, fontSize: "1.3rem" }}
                                onPress={() => {
                                  setlottoAppoggio({
                                    ...lottoAppoggio,
                                    [lotto.taglia]: {
                                      ...[lotto.taglia],
                                      quantita:
                                        lottoAppoggio[lotto.taglia].quantita > 0
                                          ? lottoAppoggio[lotto.taglia]
                                              .quantita - 1
                                          : lottoAppoggio[lotto.taglia]
                                              .quantita,
                                    },
                                  });

                                  updateValueArray(
                                    lotto.taglia,
                                    lottoAppoggio[lotto.taglia].quantita > 0
                                      ? lottoAppoggio[lotto.taglia].quantita - 1
                                      : lottoAppoggio[lotto.taglia].quantita,
                                    "quantita"
                                  );
                                }}
                              >
                                <Text style={{ fontSize: "1.3rem" }}>-</Text>
                              </TouchableOpacity>
                              <TextInput
                                style={{
                                  width: "30%",
                                  fontSize: "1.3rem",
                                  borderWidth: 1,
                                  borderColor: "c4c4c4",
                                  textAlign: "center",
                                }}
                                value={
                                  lotto.quantita === "NaN" ? 0 : lotto.quantita
                                }
                                onChange={(e) => {
                                  setlottoAppoggio({
                                    ...lottoAppoggio,
                                    [lotto.taglia]: {
                                      ...[lotto.taglia],
                                      quantita: e.target.valueOf,
                                    },
                                  });

                                  updateValueArray(
                                    lotto.taglia,
                                    e.target.value,
                                    "quantita"
                                  );
                                }}
                              />
                              <TouchableOpacity
                                style={{ marginLeft: 10 }}
                                onPress={() => {
                                  setlottoAppoggio({
                                    ...lottoAppoggio,
                                    [lotto.taglia]: {
                                      ...[lotto.taglia],
                                      quantita:
                                        lottoAppoggio[lotto.taglia].quantita +
                                        1,
                                    },
                                  });

                                  updateValueArray(
                                    lotto.taglia,
                                    lottoAppoggio[lotto.taglia].quantita + 1,
                                    "quantita"
                                  );
                                }}
                              >
                                <Text style={{ fontSize: "1.3rem" }}>+</Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                          <View style={{ marginTop: 10 }}>
                            <input
                              placeholder={t("price")}
                              style={{
                                width: "100%",
                                fontSize: "1.1rem",
                                paddingVertical: 5,
                                borderWidth: 1,
                                borderColor: "c4c4c4",
                                textAlign: "left",
                                paddingLeft: 10,
                              }}
                              min={"0"}
                              value={lotto.prezzo === "NaN" ? 0 : lotto.prezzo}
                              onChange={(e) => {
                                setlottoAppoggio({
                                  ...lottoAppoggio,
                                  [lotto.taglia]: {
                                    ...[lotto.taglia],
                                    prezzo:
                                      e.target.value.length === 0
                                        ? 0
                                        : e.target.value,
                                  },
                                });
                                updateValueArray(
                                  lotto.taglia,
                                  e.target.value,
                                  "prezzo"
                                );
                              }}
                            />
                            {console.log(lotto.prezzo)}
                          </View>
                        </View>
                      </View>
                    ))}
                    <TouchableOpacity
                      onPress={() => {
                        if (
                          totale &&
                          totale -
                            (wkfee + shipfee) +
                            calcoloIvaNew(totale - (wkfee + shipfee)) >
                            0 &&
                          qty > 4
                        ) {
                          console.log(totale);
                          const c = lotti.filter(
                            (t) => t.prezzo > 0 && t.quantita > 0
                          );
                          clearForm();
                          const stateT = {
                            lotti: c,
                            total: totale,
                            fees: { wk: wkfee, ship: shipfee, iva: iva },
                            ivaMargine: isVatMargin,
                            qty: qty,
                            payout: parseFloat(
                              totale -
                                (wkfee + shipfee) +
                                calcoloIvaNew(totale - (wkfee + shipfee))
                            )
                              .toFixed(2)
                              .toString(),

                            payin: parseFloat(
                              totale +
                                (wkfee +
                                  shipfee +
                                  calcoloIvaNew(totale + wkfee + shipfee))
                            ).toFixed(2),
                          };
                          console.log("STATET", stateT);
                          navigate("/lista-lotto-fourth/" + params.id, {
                            replace: "true",
                            state: stateT,
                          });
                        } else {
                          //Traduzione
                          alert(t("check_entered_correctly_4_paia"));
                        }
                      }}
                      style={{
                        width: "100%",
                        height: 40,
                        backgroundColor: "#0a09a2",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 7,
                        marginTop: 30,
                      }}
                    >
                      <Text style={style.titleBtn}>{t("next")}</Text>
                    </TouchableOpacity>
                  </ScrollView>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  backgroundColor: "#f5f5f5",
                  position: "fixed",
                  borderWidth: 0.2,
                  borderColor: "#a0a0a0",
                  shadowRadius: 4,
                  shadowOpacity: 0.2,
                  bottom: 0,
                  height: "15%",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <View
                  style={{
                    height: "85%",
                    paddingLeft: "5%",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <div
                      style={{
                        backgroundColor: "transparent",
                        marginTop: 0,
                      }}
                    >
                      <Switch
                        onClick={(e) => onClickIsVatMargin(e.target.checked)}
                      />
                      <span
                        style={{
                          color: "#313131",
                          fontWeight: "bold",
                          fontSize: "0.7rem",
                        }}
                      >
                        {t("marginal_vat")}
                      </span>
                    </div>
                    <Text
                      style={{
                        color: "#313131",
                        fontWeight: "bold",
                        fontSize: "0.7rem",
                      }}
                    >
                      {t("transaction_fees")} (2,5%)
                    </Text>
                    <Text style={{ color: "#313131" }}>
                      -{wkfee.toFixed(2)} €
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        color: "#313131",
                        fontWeight: "bold",
                        fontSize: "0.7rem",
                      }}
                    >
                      {t("estimated_shipping_fee")}
                    </Text>
                    <Text style={{ color: "#313131", fontSize: "0.7rem" }}>
                      -{shipfee.toFixed(2)} €
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        color: "#313131",
                        fontWeight: "bold",
                        fontSize: "0.7rem",
                      }}
                    >
                      {isVatMargin
                        ? t("marginal_vat_checked") + ": ✔"
                        : "V.A.T. 22%"}
                    </Text>
                    <Text style={{ color: "#313131", fontSize: "0.7rem" }}>
                      +{iva?.toFixed(2)} €
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    height: "85%",
                    paddingRight: "5%",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text
                      style={{
                        color: "#313131",
                        fontWeight: "bold",
                        fontSize: "0.7rem",
                        marginTop: "3%",
                      }}
                    >
                      {t("total")}
                    </Text>
                    <Text style={{ color: "#313131", fontSize: "0.7rem" }}>
                      {totale.toFixed(2)} €
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        color: "#313131",
                        fontWeight: "bold",
                        fontSize: "0.7rem",
                      }}
                    >
                      {t("tot_pay")}:
                    </Text>
                    <Text style={{ color: "#313131", fontSize: "0.7rem" }}>
                      {(
                        totale -
                        (shipfee + wkfee) +
                        calcoloIvaNew(totale - (shipfee + wkfee))
                      ).toFixed(2)}{" "}
                      €
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}
        </>
      )}
    </>
  );
};

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  board: {
    height: 160,
    width: "100%",
    backgroundColor: colors.secondary,
    padding: 30,
  },
  containerRule: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "8%",
    width: "90%",
    marginHorizontal: "5%",
  },
  imageRule: {
    width: 60,
    height: 60,
    backgroundColor: "#e4e4e4",
    marginRight: 10,
  },
  textRule: {
    fontSize: "1.1rem",
  },
  btnContinua: {
    backgroundColor: "#0A09A2",
    marginTop: "1%",
    width: "100%",
    padding: 10,
    textAlign: "center",
    borderRadius: 4,
  },

  btnText: {
    color: "#fff",
    fontSize: "1.1rem",
    fontWeight: "bold",
  },

  inputCerca: {
    borderWidth: 1,
    borderColor: "#f5f5f5",
    width: "100%",
    height: 30,
    marginTop: 10,
    paddingHorizontal: 15,
    paddingVertical: 20,
  },

  btnCrea: {
    width: "90%",
    height: 40,
    backgroundColor: "#0a09a2",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 7,
    marginBottom: "5%",
    position: "absolute",
  },
  titleBtn: {
    color: "white",
    fontSize: 20,
  },
  btnAltroLotto: {
    backgroundColor: "transparent",
    marginTop: "1%",
    width: "100%",
    padding: 10,
    textAlign: "center",
    borderRadius: 4,
  },

  btnTextAltro: {
    color: "#0a09a2",
    fontSize: 15,
    fontWeight: 600,
  },
  containerLottoListato: {
    width: "100%",
    backgroundColor: "#f5f5f5",
    padding: 5,
    borderRadius: 7,
    marginTop: 20,
  },
});

export default ListaLottoThird;
