import React, { Component, useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../../styles/CheckoutStyles.css";
import logo from "../../assets/wk-logo.png";
import axios from "axios";
import CONSTANTS from "../../CONSTANTS";
import Footer from "../../components/Footer";
import Timer from "../../components/Timer";
import { useTranslation } from "react-i18next";
import pausepayLogo from "../../assets/pausepay.png";
import { createOrder } from "../../components/utils/pausePayRequest";
import { isBuye, sendMailNotification } from "../chiamateApi/ApiProdotto";
import YourCheckoutComponent from "./NexiCheckoutComponent";
import { loadXPay, XPayProvider } from "react-xpay-build";
import CryptoJS from "crypto-js";
import { Toast, Spinner } from "react-bootstrap";
import scarpaVuota from "../../assets/wk_products_not_found.png";

class CheckoutThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lotto: {},
      vendite: {},
      pagamento: {},
      prodotto: {},
      imgeRicevuta: {},
      pausepayItem: {},
      loading: true,
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
      fileRicevuta: "",
      showToast: false,
      nexiError: "",
      selectedPaymentMethod: "",
      loadingCreditCard: false,
      //nexi
      importo: 0,
      order: {},
      codiceTransazione: "",
    };
  }
  componentDidMount = async () => {
    console.log("props", this.props);
    this.setState({ loading: true });
    console.log("PROPS:", this.props.location.state);
    this.setState({
      vendite: this.props.location.state.v,
      pagamento: this.props.location.state.p,
      prodotto: this.props.location.state.prodotto,
      lotto: this.props.location.state.lotto,
    });
    await axios
      .get(CONSTANTS.API_URL + "/lotto/lottoId/" + this.props.params.id)
      .then(async (res) => {
        this.setState(
          {
            lotto: res.data,
          },
          () => {
            const PausePayObject = {
              amount: parseFloat(
                Number.parseFloat(this.state.lotto?.payin).toFixed(2)
              ),
              number: this.props.params.id,
              // number:this.props.params.id,
              issueDate: new Date(),
              description: "Stock of " + this.state.prodotto.nome,
              remittance: "Payment for Stock n° " + this.props.params.id,
              buyerInfo: {
                vatCode: localStorage.getItem(CONSTANTS.P_IVA),
                email: localStorage.getItem(CONSTANTS.EMAIL),
                pec: localStorage.getItem(CONSTANTS.EMAIL),
                name: localStorage.getItem(CONSTANTS.R_SOCIALE),
              },
              okRedirect: "https://app.wholekicks.eu/checkout-fourth",
              koRedirect: "https://app.wholekicks.eu/checkoutpauseko",
              // okRedirect: "http://localhost:3000/checkout-fourth",
              // koRedirect: "http://localhost:3000/checkoutpauseko",
              allowToEditRemittance: false,
              allowSCTPayment: false,
              items: [
                {
                  description:
                    this.state.prodotto.nome +
                    " " +
                    this.state.prodotto.modello,
                  quantity: this.state.vendite.qta,
                  amount: parseFloat(
                    (
                      Number.parseFloat(this.state.lotto?.payin).toFixed(2) /
                      Number.parseFloat(this.state.vendite.qta).toFixed(2)
                    ).toFixed(2)
                  ),
                },
              ],
            };
            this.setState({ pausepayItem: PausePayObject });
            this.setState({ loading: false });
          }
        );
      });
    // {
    //   "amount": l’importo totale del carrello, IVA inclusa,
    //   "number": il numero dell’ordine,
    //   "issueDate": data creazione dell’ordine (oggi),
    //   "description":descrizione della merce acquistata,
    //   "remittance": causale del bonifico BUYER,
    //   "okRedirect": redirect in caso di successo,
    //   "koRedirect": redirect in caso di esito negativo,
    //   “allowToEditRemittance": rendi la causale del bonifico
    //   modificabile dal BUYER?,
    //   "buyerInfo": {
    //   "name" : ragione Sociale dell’acquirente,
    //   "vatCode": p.iva dell’acquirente,
    //   "email" : email dell’acquirente,
    //   "pec" : pec dell’acquirente
    //   },
    //   "items": [
    //   “description": nome del prodotto nel carrello,
    //   "quantity" : quantità del prodotto,
    //   "amount" : prezzo unitario del prodotto (IVA inclusa)
    //   ],
    //   “allowSCTPayment": se PausePay non è disponibile, offri
    //   comunque la possibilità di fare un bonifico
    //   classico non dilazionato
    //  }
    // await axios
    //   .get(
    //     CONSTANTS.API_URL +
    //       "/prodotto/productById/" +
    //       this.state.lotto.ProdottoId
    //   )
    //   .then(async (res) => {
    //     console.log("res", res.data);
    //     this.setState({
    //       prodotto: res.data,
    //     });
    //   });

    // const body = {
    //   prezzo: this.state.lotto.prezzo,
    //   qta: this.state.lotto.quantita,
    //   carta: "",
    //   AccountId: localStorage.getItem(CONSTANTS.ID),
    //   ProdottoId: this.state.lotto.ProdottoId,
    //   StatoPagamentoId: 1,
    //   LottoId: this.state.lotto.id,
    // };
    // const body2 = {
    //   offerta: this.state.lotto.prezzo,
    //   qta: this.state.lotto.quantita,
    //   carta: "",
    //   AccountId: this.state.lotto.AccountId,
    //   ProdottoId: this.state.lotto.ProdottoId,
    //   StatoId: 1,
    //   LottoId: this.state.lotto.id,
    // };
    // this.setState(
    //   {
    //     vendite: body2,
    //     pagamento: body,
    //   },
    //   () => {
    //     this.setState({ loading: false });
    //   }
    // );

    //INIZIALIZZAZIONE NEXI
    //NEXI PAYMENT
    // const sdkLoader = loadXPay({
    //   alias: CONSTANTS.NEXI_XPAY_ALIAS_PROD,
    //   isProduction: true,
    // });
    const chiaveSegreta = CONSTANTS.NEXI_MAC_SECRET_KEY_PROD;

    const codiceTransazione =
      "pay-0" +
      this.props.params.id.toString() +
      "-" +
      localStorage.getItem(CONSTANTS.ID) +
      "-" +
      Date.now().toString();

    console.log("lotto", this.state.lotto);
    const importo = parseFloat((this.state.lotto?.payin).toFixed(2)) * 100;

    console.log("this.state.lotto", this.state.lotto);

    const stringToHash = `codTrans=${codiceTransazione}divisa=${CONSTANTS.NEXI_DIVISA}importo=${importo}${chiaveSegreta}`;
    const mac = CryptoJS.SHA1(stringToHash).toString();

    console.log("importo", importo);
    console.log("codiceTransazione", codiceTransazione);
    const order = {
      amount: importo,
      transactionId: codiceTransazione,
      currency: CONSTANTS.NEXI_DIVISA,
      timeStamp: Date.now(),
      mac: mac,
      url:
        "http://test.wholekicks.eu/checkout-third/" +
        this.props.params.id.toString(), //Unused
      urlBack: "http://test.wholekicks.eu/checkoutnexiko", //Unused
    };

    this.setState({
      order: order,
      importo: importo,
      codiceTransazione: codiceTransazione,
    });
  };

  postLotto = async () => {
    const body = {
      idStato: 1,
      idOrdine: this.props.params.id,
    };

    await axios
      .post(CONSTANTS.API_URL + "/pagamento/Stato", body, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        console.log("success");
        console.log(res);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  pausePayFunction = async () => {
    console.log("gg", this.state.vendite.LottoId);
    const body = {
      forThem: this.state.pausepayItem,
      forUs: {
        vendita: this.state.vendite,
        pagamento: { ...this.state.pagamento, isOfferta: 0 },
        prodotto: this.state.prodotto,
        idLotto: this.state.vendite.LottoId,
      },
    };

    const comprato = await isBuye(this.state.vendite.LottoId, this.state.token);
    console.log("COMPRATO: ", comprato);
    if (comprato === "Il prodott è stato acquistato da qualcun altro") {
      alert("E' stato acquistato da qualcun altro");
    } else {
      const res = await createOrder(body);
      this.setState({ loading: true });
      if (res) {
        window.location.href = res.data.url;
        return null;
      }
    }
  };
  postLotto1 = async () => {
    console.log("SONO QUI*********");
    console.log(this.state.pagamento);
    let formData = new FormData();
    formData.append("AccountIdVenditore", this.state.vendite.AccountId);
    formData.append("AccountIdAcquirente", this.state.pagamento.AccountId);
    formData.append("LottoId", this.state.pagamento.LottoId);
    formData.append("ProdottoId", this.state.pagamento.ProdottoId);
    formData.append("StatoPagamentoId", this.state.pagamento.StatoPagamentoId);
    formData.append("carta", this.state.pagamento.carta);
    formData.append("prezzo", this.state.vendite.offerta);
    formData.append("qta", this.state.pagamento.qta);
    formData.append("imagePagamentoRicevuto", this.state.imgeRicevuta);
    formData.append("autoSped", this.state.pagamento.autoSped);
    console.log(formData);

    await axios
      .post(CONSTANTS.API_URL + "/pagamento/", formData, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then(async (res) => {
        console.log("success");
        console.log(res);
        if (res.data === "Il prodott è stato acquistato da qualcun altro") {
          alert("The lot was purchased by someone else");
        } else {
          await axios
            .get(
              CONSTANTS.API_URL +
                "/account/utente/" +
                localStorage.getItem(CONSTANTS.ID),
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    CONSTANTS.ACCESS_TOKEN_KEY
                  )}`,
                },
              }
            )
            .then(async (res) => {
              const objMail = {
                prodotto: this.state.prodotto,
                email: res.data.email,
                lotto: this.state.lotto.id,
                idMailVendita: 8,
                idMailAcquisto: 7,
              };
              await sendMailNotification(objMail);
            });
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  openNexiPage = (htmlResponse) => {
    // Crea un nuovo parser HTML
    const parser = new DOMParser();
    // Parsa l'HTML ricevuto dalla risposta
    const parsedHtml = parser.parseFromString(htmlResponse, "text/html");

    // Ottieni l'elemento form
    const formElement = parsedHtml.querySelector("form");

    // Se non è presente alcun elemento form, esci dalla funzione
    if (!formElement) {
      console.error("Form element not found");
      return;
    }

    // Creazione del form dinamico
    const form = document.createElement("form");
    form.method = formElement.method;
    form.action = formElement.action;

    // Estrazione di tutti gli input, select e textarea dall'HTML e aggiunta al nuovo form
    const inputs = formElement.querySelectorAll("input, select, textarea");
    inputs.forEach((input) => {
      const dynamicInput = document.createElement("input");
      dynamicInput.type = "hidden";
      dynamicInput.name = input.name;
      dynamicInput.value = input.value;
      form.appendChild(dynamicInput);
    });

    // Aggiunta del nuovo form al DOM e invio
    document.body.appendChild(form);
    console.log("form", form);
    form.submit();
  };

  //OLD
  // nonceHandler = async (_nonceResp) => {
  //   console.log("onNonceEvent fired, resp: ", _nonceResp);

  //   if (_nonceResp.esito === "OK") {
  //     const body = {
  //       importo: this.state.importo,
  //       codiceTransazione: this.state.codiceTransazione,
  //       xpayNonce: _nonceResp.xpayNonce,
  //       AccountIdVenditore: this.state.vendite.AccountId,
  //       AccountIdAcquirente: this.state.pagamento.AccountId,
  //       LottoId: this.state.pagamento.LottoId,
  //       ProdottoId: this.state.pagamento.ProdottoId,
  //       StatoPagamentoId: this.state.pagamento.StatoPagamentoId,
  //       carta: this.state.pagamento.carta,
  //       prezzo: this.state.vendite.offerta,
  //       qta: this.state.pagamento.qta,
  //       // imagePagamentoRicevuto: this.state.imgeRicevuta,
  //       autoSped: this.state.pagamento.autoSped,
  //       isOfferta: 0,
  //     };

  //     await axios
  //       .post(CONSTANTS.API_URL + "/nexi/pay", body, {
  //         headers: {
  //           Authorization: `Bearer ${this.state.token}`,
  //         },
  //       })
  //       .then(async (res) => {
  //         console.log("credit card payment success", res);
  //         this.props.navigate("/checkout-fourth");
  //       })
  //       .catch((e) => {
  //         console.log("error", e.response);

  //         this.props.navigate("/checkoutnexiko");
  //       });
  //   } else {
  //     this.props.navigate("/checkoutnexiko");
  //   }
  // };

  handleMethodChange = (method) => {
    if (method === "Carta di Credito") {
      this.setState({ loadingCreditCard: true });
      setTimeout(() => {
        this.setState({ loadingCreditCard: false });
      }, 5000);
    }
    this.setState({ selectedPaymentMethod: method });
  };

  nexiFunction = async () => {
    console.log("nexi");

    const body = {
      nexi: {
        importo: this.state.importo,
        codTrans: this.state.codiceTransazione,
        LottoId: this.state.pagamento.LottoId,
      },
      pagamento: {
        AccountId: this.state.pagamento.AccountId,
        LottoId: this.state.pagamento.LottoId,
        ProdottoId: this.state.pagamento.ProdottoId,
        StatoPagamentoId: this.state.pagamento.StatoPagamentoId,
        carta: this.state.pagamento.carta,
        prezzo: parseInt(this.state.vendite.offerta),
        qta: this.state.pagamento.qta,
        imagePagamentoRicevuto: null,
        isOfferta: 0,
        autoSped: this.state.pagamento.autoSped,
        nexi: this.state.codiceTransazione,
      },
      vendita: {
        offerta: parseInt(this.state.vendite.offerta),
        ProdottoId: this.state.pagamento.ProdottoId,
        AccountId: this.state.vendite.AccountId,
        StatoId: this.state.pagamento.StatoPagamentoId,
        LottoId: this.state.pagamento.LottoId,
        isOfferta: 0,
      },
    };

    try {
      const response = await axios.post(
        CONSTANTS.API_URL + "/nexi/create",
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      );

      console.log("credit card payment success", response.data);

      this.openNexiPage(response.data);
    } catch (error) {
      console.log("error", error);
      // Gestisci l'errore del pagamento
      this.props.navigate("/checkoutnexiko");
    }
  };

  render() {
    const { t } = this.props.translation;

    return (
      <>
        {this.state.loading ? (
          <>Loading</>
        ) : (
          <>
            <div className="container-form-check">
              <div className="div-logo-form">
                <Link to="/">
                  <img className="logo-login" src={logo} alt="logo" />
                </Link>
              </div>
              <div></div>
              <div className="checkout-form-div">
                <p className="checkout-title">Checkout</p>
                <span className="checkout-sub-title">{t("pay_info")}</span>
                <span className="checkout-sub-sub-total">
                  {t("tot_stock")}:
                </span>

                <span className="checkout-total-4">
                  {parseFloat(this.state.lotto?.payin)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",") + " €"}
                </span>

                <img
                  src={this.state.prodotto.imageProdotto}
                  onError={(e) => {
                    e.target.src = scarpaVuota;
                  }}
                  alt="scarpa-immagine"
                  className="checkout-image-prod"
                />
                <p>{this.state.prodotto.nome}</p>
                <p>{this.state.prodotto.modello}</p>
                <div
                  style={{ flexDirection: "column", width: "auto", gap: 10 }}
                >
                  <label style={{ fontSize: "24px", fontWeight: "400" }}>
                    {t("payment_method")}
                  </label>

                  <div
                    style={{
                      width: "100%",
                      height: "55px",
                      backgroundColor: "white",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5%",
                      border:
                        this.state.selectedPaymentMethod === "Carta di Credito"
                          ? "2px solid blue"
                          : "2px solid black",
                      borderRadius: "7px",
                      justifyContent: "center",
                      cursor: "pointer",
                      color:
                        this.state.selectedPaymentMethod === "Carta di Credito"
                          ? "blue"
                          : "black",
                    }}
                    // onClick={() => handleMethodChange("Carta di Credito")}
                    onClick={this.nexiFunction}
                  >
                    {/* <input
                      type="radio"
                      value="Carta di Credito"
                      checked={
                        this.state.selectedPaymentMethod === "Carta di Credito"
                      }
                      onChange={() => handleMethodChange("Carta di Credito")}
                    /> */}
                    <span>{t("credit_card")}</span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "55px",
                      backgroundColor: "white",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5%",
                      border:
                        this.state.selectedPaymentMethod === "Bonifico bancario"
                          ? "2px solid blue"
                          : "2px solid black",
                      borderRadius: "7px",
                      justifyContent: "center",
                      cursor: "pointer",
                      color:
                        this.state.selectedPaymentMethod === "Bonifico bancario"
                          ? "blue"
                          : "black",
                    }}
                    onClick={() => this.handleMethodChange("Bonifico bancario")}
                  >
                    {/* <input
                      type="radio"
                      value="Bonifico bancario"
                      checked={
                        this.state.selectedPaymentMethod === "Bonifico bancario"
                      }
                      onChange={() => handleMethodChange("Bonifico bancario")}
                    /> */}
                    <span>{t("bank_transfer")}</span>
                  </div>
                  {Number.parseFloat(this.state.lotto?.payin) >= 500 &&
                    Number.parseFloat(this.state.lotto?.payin) <= 20000 && (
                      <div
                        style={{
                          width: "100%",
                          height: "55px",
                          backgroundColor: "white",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5%",
                          border: "2px solid black",
                          borderRadius: "7px",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        // onClick={() => handleMethodChange("Pausepay")}
                        onClick={async () => await this.pausePayFunction()}
                      >
                        {/* <input
                          type="radio"
                          value="Pausepay"
                          checked={
                            this.state.selectedPaymentMethod === "Pausepay"
                          }
                          onChange={() => handleMethodChange("Pausepay")}
                        /> */}

                        <img src={pausepayLogo} style={{ height: "30px" }} />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "12px",
                          }}
                        >
                          <span>{t("pausepay_text_1")}</span>
                          <span>{t("pausepay_text_2")}</span>
                        </div>
                      </div>
                    )}
                </div>

                {this.state.selectedPaymentMethod != "" && (
                  <>
                    {this.state.selectedPaymentMethod ===
                      "Bonifico bancario" && (
                      <>
                        <div className="checkout-iban-div">
                          <span>{t("complete_buy")}:</span>

                          <p>Bank: Bper Banca</p>
                          <p>IBAN: IT84K0538703410000003423434</p>
                          <p>Name: WHOLE KICKS S.R.L.</p>
                        </div>
                        <div className="carica-ricevuta-bonifico">
                          <span>
                            <label for="ricevuta" className="choose-file">
                              {t("upload_receipt")}
                            </label>
                          </span>
                          <label for="ricevuta">
                            {this.state.fileRicevuta === ""
                              ? ""
                              : this.state.fileRicevuta}
                          </label>

                          <input
                            name="ricevuta"
                            id="ricevuta"
                            className="ricevuta-to-hide"
                            type="file"
                            style={{ visibility: "hidden" }}
                            placeholder="Upload Receipt"
                            accept=".pdf"
                            onChange={(e) => {
                              console.log(e.target.value);
                              let trimValue = e.target.value.split("\\");
                              console.log(trimValue);
                              this.setState({
                                fileRicevuta: trimValue[trimValue.length - 1],
                                imgeRicevuta: e.target.files[0],
                              });
                            }}
                          />
                        </div>
                      </>
                    )}

                    {Number.parseFloat(this.state.lotto?.payin) >= 500 &&
                      Number.parseFloat(this.state.lotto?.payin) <= 20000 && (
                        <>
                          {/* {" "}
                          <p>OR</p> */}
                          {this.state.selectedPaymentMethod === "Pausepay" && (
                            // <button
                            //   style={{
                            //     backgroundColor: "#049370",
                            //     color: "white",
                            //     borderRadius: "8px",
                            //     border: 0,
                            //     padding: "6px 15px",
                            //   }}
                            //   onClick={async () =>
                            //     await this.pausePayFunction()
                            //   }
                            // >
                            //   {t("continue")}
                            // </button>
                            <div
                              onClick={async () =>
                                await this.pausePayFunction()
                              }
                              style={{ height: "auto", width: "20%" }}
                            >
                              <img
                                src={pausepayLogo}
                                style={{ width: "100%", height: "auto" }}
                              />
                            </div>
                          )}
                        </>
                      )}

                    {/* OLD
                    {this.state.selectedPaymentMethod ===
                        "Carta di Credito" && (
                        <XPayProvider
                          isProduction={true}
                          sdk={sdkLoader}
                          apiKey={CONSTANTS.NEXI_XPAY_ALIAS_PROD}
                          order={order}
                          nonceHandler={nonceHandler}
                        >
                          <YourCheckoutComponent />
                        </XPayProvider>
                      )} */}
                  </>
                )}
              </div>
              <div className="btn-footer-checkout check-tre">
                <Link
                  to="/checkout-fourth"
                  className={
                    this.state.fileRicevuta === ""
                      ? "pointer-link-none"
                      : "pointer-link-auto"
                  }
                  onClick={() => this.postLotto1()}
                >
                  {t("confirm")}
                </Link>
              </div>
            </div>
            <Footer />
            <Toast show={this.state.showToast} className="toast-error">
              <span style={{ textTransform: "capitalize" }}>
                {this.state.nexiError}
              </span>
            </Toast>
          </>
        )}
      </>
    );
  }
}
export default function (props) {
  const params = useParams();
  const location = useLocation();
  const t = useTranslation();
  const navigate = useNavigate();

  return (
    <CheckoutThree
      translation={t}
      props={props}
      params={params}
      location={location}
      navigate={navigate}
    />
  );
}
