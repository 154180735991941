import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import scarpa from "../../assets/image-scarpa.png";
import scarpaVuote from "../../assets/wk_products_not_found.png";
import Footer from "../../components/Footer";
import CONSTANTS from "../../CONSTANTS";
import {
  Modal,
  Accordion,
  Tooltip,
  OverlayTrigger,
  CloseButton,
  Alert,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/wk-logo.png";
import LOGOUTFUNCTION from "../../LOGOUTFUNCTION";
import ShareIcon from "@mui/icons-material/Share";
import scrollIcon from "../../assets/scroll-icon.png";
import "../../styles/SingleProduct.css";
import SizeGuide from "../../components/SizeGuide";
import {
  getCallerName,
  isAutenticated,
} from "../../components/utils/validatorActions";
import ModalShare from "../../components/ModalShare";
import { useTranslation } from "react-i18next";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import { View, Text, Image, TouchableOpacity, StyleSheet } from "react-native";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";
import Carousel from "../../pagesMobile/home/Carousel";
import ModalVisualizzaProdotto from "../../pagesMobile/singleProduct/ModalVIsualizzaProdotto";
import {
  getLotto,
  getTag,
  getLottoLimit,
  pagamentoFindLotto,
  getProductPrezzoMigliore,
  getProductById,
  getTaglia,
  postOffertaProdotto,
  sendMailNotification,
} from "../chiamateApi/ApiProdotto";
import { getUser, sendMail } from "../chiamateApi/ApiModifyUser";
import { mailBody } from "../utils/MailUtils";
import { TAGLIE } from "../../components/utils/defaultObject";
import SliderFiltroTaglie from "../../components/utils/SliderFiltroTaglie";
import UploadExcell from "../../components/utils/UploadExcell";
import { Source } from "@mui/icons-material";

function SingleProd() {
  let { id } = useParams();
  let token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY);
  let IdUtente = localStorage.getItem(CONSTANTS.ID);
  let email = localStorage.getItem(CONSTANTS.EMAIL);
  const [singleProduct, setSingleProduct] = useState({});
  const [lottoProduct, setLottoProduct] = useState([]);
  const [lottoProductAll, setLottoProductAll] = useState([]);
  const [TagProduct, setTagProduct] = useState([]);
  const [showModal, setmodal] = useState(false);
  const [showModalAcquista, setmodalAcquista] = useState(false);
  const [showModalLista, setmodalLista] = useState(false);
  const [showConfermaProposta, setShowConfermaProposta] = useState(false);
  const [dateToday, setDateToday] = useState({});
  const [price, setPrice] = useState({});
  const [lottoSelezionato, setLotto] = useState({});
  const [taglia, setTaglia] = useState({});
  const [showAltriLotti, setShowAltriLotti] = useState(false);
  const [valore, setValore] = useState(false);
  const [accordionAttivo, setAccordionAttivo] = useState(null);
  const [PrezzoMigliore, setPrezzoMigliore] = useState({});
  const [Utente, setUtente] = useState(0);
  const [visible, setvisible] = useState(false);
  const [bidValidity, setBidValidity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sizeGuide, setsizeGuide] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isLoading, setIsloading] = useState(false);
  const [lottoPayment, setLottoPayment] = useState([]);
  const [trovato, setTrovato] = useState(false);
  const emailUser = localStorage.getItem(CONSTANTS.EMAIL);
  const [filtro, setFiltro] = useState("US");

  const desktop = useDesktopChecker();

  const { t } = useTranslation();

  useEffect(() => {
    setIsOnline(navigator.onLine);
    console.log("ciaoooo", isOnline);
    if (isOnline == true) {
      console.log("eccomi", isOnline);
      init();
    } else {
      setIsloading(true);
      window.alert(t("wrong_connection"));
    }
  }, []);

  const init = async () => {
    await refresh(id);
    const lottoLimit = await getLottoLimit(id);
    const lotto = await getLotto(id);
    const tag = await getTag(id);

    console.log(tag.data);
    const pagamentoLotto = await pagamentoFindLotto(id, token);
    console.log("Pagamento", pagamentoLotto);

    setLottoProduct(lottoLimit.data);
    setTagProduct(tag.data);
    pagamentoLotto.length > 0
      ? setLottoPayment(pagamentoLotto)
      : setLottoPayment([]);

    const dato = lotto.data.filter((data) => data.numero != 0);
    setLottoProductAll(dato);

    console.log("valore interessante: ", lottoProduct);
  };
  useEffect(() => {
    if (accordionAttivo !== null) {
    } else if (accordionAttivo === null) {
      const lottoVuoto = {};
      setLotto(lottoVuoto);
    }
  }, [accordionAttivo]);

  const verifiyPayment = (lottoId) => {
    if (lottoPayment.find((element) => element.LottoId === lottoId)) {
      return true;
    } else {
      return false;
    }
  };
  const setDateOffer = (e) => {
    console.log(e.target.value);
    setBidValidity(e.target.value);
    var date = new Date();
    console.log(date.getDate());
    date.setDate(+date.getDate() + +e.target.value);
    var dateString = date.toISOString().split("T")[0];
    console.log(dateString);
    setDateToday(dateString);
  };
  const refresh = async (id) => {
    await getProductById(id).then((res) => {
      console.log("*******Prodotto", res.data);
      setSingleProduct(res.data);
    });

    const prezzoMigliore = await getProductPrezzoMigliore(id);
    setPrezzoMigliore(prezzoMigliore.data);
  };
  const inviaProposta = async (e) => {
    e.preventDefault();
    setLoading(true);
    const body = {
      offerta: parseInt(price),
      dataScadenza: dateToday,
      ProdottoId: parseInt(id),
      LottoId: lottoSelezionato.id,
      AccountId: IdUtente,
      StatoId: 6,
    };
    console.log(body);
    await postOffertaProdotto(body, token)
      .then(async (res) => {
        if (res.data.error) {
          LOGOUTFUNCTION();
        }
        await getUser(IdUtente, token).then(async (res) => {
          console.log("RES2", res);

          console.log("EMAIL", body);

          console.log(res.data);
          const objMail = {
            prodotto: singleProduct,
            email: res.email,
            lotto: lottoSelezionato.id,
            idMailVendita: 11,
            idMailAcquisto: 12,
          };
          await sendMailNotification(objMail);
        });

        setLoading(false);
        modalHandler();
      })
      .catch((e) => {
        setLoading(false);
        console.log(e.message);
      });
  };

  const modalHandler = () => {
    setmodal(false);
    setShowConfermaProposta(true);
  };

  const onPressLotto = async (lotto) => {
    setLotto(lotto);

    await getTaglia(lotto.id).then((res) => {
      const dato = res.data.filter((data) => data.numero !== 0);
      setTaglia(dato);
    });

    setValore(true);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("already_verified")}
    </Tooltip>
  );
  const onClickSizeGuide = () => {
    setsizeGuide(!sizeGuide);
  };
  const onPressCarouselProd = (id) => {
    window.refresh("http://localhost:3000/product/" + id);
  };
  //sizeGuideFunction filtra le taglie in base al filtro inserito
  const sizeGuideFunction = (filtro, taglia) => {
    const posizione = TAGLIE.US.indexOf(taglia);
    console.log("TAGLIE: ", TAGLIE[filtro][posizione]);
    return TAGLIE[filtro][posizione];
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("xlsFile", selectedFile);
    formData.append("accountId", IdUtente);
    formData.append("prodottoId", id);

    await axios
      .post(CONSTANTS.API_URL + "/taglia/carica-dati", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async () => {
        setShowModalExcel(false);
        refresh();
      })
      .catch((e) => {
        setMessaggioErrore(e.response.data.error);
      });

    // Aggiungi qui eventuali azioni dopo il caricamento del file
  };

  //EXCEL MODAL
  const [selectedFile, setSelectedFile] = useState(null);
  const [messaggioErrore, setMessaggioErrore] = useState("");

  const [showModalExcel, setShowModalExcel] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDownloadTemplate = async () => {
    try {
      const downloadUrl = CONSTANTS.API_URL + "/taglia/download"; // Modifica con l'endpoint appropriato per il download del file

      const response = await axios.get(downloadUrl, {
        responseType: "blob", // Imposta il tipo di risposta come 'blob'
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Crea un URL temporaneo per il file blob e crea un link per il download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template-batch.xls"); // Sostituisci 'nome_del_tuo_template.xls' con il nome del tuo file
      document.body.appendChild(link);
      link.click();

      // Pulisci l'URL temporaneo dopo il download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Errore nel download del template:", error);
      setMessaggioErrore("Errore nel download del template");
    }
  };

  const handleCloseExcelModal = () => {
    setShowModalExcel(false);
    setMessaggioErrore("");
  };

  const handleShowExcelModal = () => {
    setmodalLista(false);
    setShowModalExcel(true);
  };

  return (
    <>
      {desktop ? (
        <>
          <Header />
          <div className="container">
            <div className="centopercento">
              <div>100% {t("auth")}</div>
              <div>100% {t("new")}</div>
            </div>

            <div className="container-scarpa">
              <div>
                {" "}
                <img
                  src={singleProduct.imageProdotto}
                  alt="scarpa"
                  onError={(e) => {
                    e.target.src = scarpaVuote;
                    e.target.className = "no-product-image";
                  }}
                />
              </div>
              <div className="scarpa-detail">
                <div className="modello-style">
                  <span style={{ fontSize: "2rem", fontWeight: 650 }}>
                    {singleProduct.nome}
                  </span>
                  <p
                    style={{ textAlign: "right", margin: 0 }}
                    onClick={() => {
                      setvisible(true);
                    }}
                  >
                    <ShareIcon className="share-icon" />
                    <br />
                    {/* <span style={{ fontSize: "0.8rem", color: "#4d4d4d" }}>
                       {singleProduct.dettaglio}
                     </span> */}
                  </p>
                </div>

                {lottoProduct.length > 0 ? (
                  <div className="lotto-div">
                    <table>
                      {lottoProduct.map((lotto, key) => {
                        const prezzo = lotto.prezzo / lotto.quantita;
                        const prezzoFinale = parseFloat(prezzo).toFixed(2);
                        const finalPrice = prezzoFinale
                          .toString()
                          .replace(".", ",");

                        return (
                          <tbody key={key}>
                            <button
                              onClick={() => onPressLotto(lotto)}
                              className="btn-accordion-table"
                            >
                              <Accordion
                                flush
                                activeKey={accordionAttivo}
                                onSelect={(e) => {
                                  setAccordionAttivo(e);

                                  setTimeout(() => setValore(true), 1000);
                                }}
                              >
                                <Accordion.Item eventKey={key}>
                                  <Accordion.Header className="tr-head-prodotto">
                                    <tr className="tr-prodotto" key={key}>
                                      <td>
                                        {t("stock")} n{" "}
                                        <b className="lotto-number-sing-prod">
                                          {lotto.id}
                                        </b>
                                      </td>
                                      <td>
                                        {t("qty")}{" "}
                                        <span className="total-price-span">
                                          <b>{lotto.quantita}</b>
                                        </span>
                                      </td>
                                      <td>
                                        {t("average_price_two")}
                                        <span className="total-price-span">
                                          <b>{finalPrice}€</b>
                                        </span>
                                      </td>
                                      <td>
                                        {t("total_price")}{" "}
                                        <span className="total-price-span">
                                          <b>
                                            {parseFloat(lotto.prezzo)
                                              .toFixed(2)
                                              .toString()
                                              .replace(".", ",") + " €"}
                                          </b>
                                        </span>
                                      </td>
                                      {lotto.AccountId === 2 ||
                                      lotto.AccountId === 22 ? (
                                        <td className="td-logo-wk">
                                          <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip}
                                          >
                                            <img
                                              src={logo}
                                              alt="wholekicks-logo"
                                              className="logo-wk-prod"
                                            />
                                          </OverlayTrigger>
                                        </td>
                                      ) : (
                                        <></>
                                      )}
                                    </tr>
                                  </Accordion.Header>
                                  <Accordion.Body
                                    className={
                                      accordionAttivo !== null
                                        ? "accordion-body-custom"
                                        : "accordion-body-not-selected"
                                    }
                                  >
                                    {/*compoenente slider per i bottoni filtrati per taglia */}
                                    <SliderFiltroTaglie
                                      setFiltro={setFiltro}
                                      filtro={filtro}
                                    />
                                    <div className="taglie-container">
                                      {valore !== false && taglia.length > 0 ? (
                                        taglia.map((ti, key) => {
                                          return (
                                            <button
                                              key={ti.id}
                                              className="taglia-detail dettaglio-taglie-in-accordion"
                                            >
                                              <span className="taglia-inside-numero">
                                                {filtro +
                                                  " " +
                                                  sizeGuideFunction(
                                                    filtro,
                                                    ti.taglia
                                                  )}
                                              </span>
                                              <span className="qty-inside-numero">
                                                {t("qty")} {ti.numero}
                                              </span>
                                            </button>
                                          );
                                        })
                                      ) : (
                                        <p>{t("no_stock")}</p>
                                      )}
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </button>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                ) : (
                  <p>{t("no_stock")}</p>
                )}

                {lottoProductAll.length === 0 ? (
                  <p className="lotto-text-hidden">
                    <button className="lotto-text-hidden" disabled={true}>
                      {t("see_other_stock")}{" "}
                    </button>
                  </p>
                ) : (
                  <>
                    <p className="lotto-text">
                      <button
                        className="lotto-text"
                        onClick={() => setShowAltriLotti(true)}
                      >
                        {t("see_other_stock")}{" "}
                      </button>
                    </p>
                    <div className="box-for-sizeguide">
                      <SizeGuide state={sizeGuide} />
                      <button
                        className="lotto-text-guida"
                        onClick={() => onClickSizeGuide()}
                        style={{ display: "none" }}
                      >
                        {t("size_guide")}
                      </button>
                    </div>
                  </>
                )}

                <div className="lotto-buttons-div">
                  <div className="buttons-div">
                    <button
                      id="bottone-acquista"
                      className="acquista"
                      disabled={accordionAttivo !== null ? false : true}
                      onClick={() => {
                        if (isAutenticated()) {
                          if (
                            getCallerName() ===
                            lottoSelezionato.AccountId.toString()
                          ) {
                            alert(t("your_stock"));
                          } else {
                            setmodalAcquista(true);
                          }
                        } else {
                          alert(t("login_check"));
                        }
                      }}
                    >
                      {t("buy_stock")}
                    </button>
                    <button
                      className="proposta"
                      disabled={accordionAttivo !== null ? false : true}
                      onClick={() => {
                        if (isAutenticated()) {
                          if (
                            getCallerName() ===
                            lottoSelezionato.AccountId.toString()
                          ) {
                            alert(t("your_stock_offer"));
                          } else {
                            setmodal(true);
                          }
                        } else {
                          alert(t("login_check"));
                        }
                      }}
                    >
                      {t("make_proposal")}
                    </button>
                  </div>
                  <div className="p-div">
                    {lottoProductAll.length === 0 ? (
                      <div style={{ visibility: "hidden" }}>
                        <span>{t("best_stock")}:</span>
                        <span className="best-stock-price-label">
                          {parseFloat(PrezzoMigliore?.prezzo)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",") + " €"}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span>{t("best_stock")}:</span>
                        <span className="best-stock-price-label">
                          {parseFloat(PrezzoMigliore?.prezzo)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",") + " €"}
                        </span>
                      </div>
                    )}

                    <button
                      className="lista-lotto"
                      onClick={() => {
                        if (isAutenticated()) {
                          setmodalLista(true);
                        } else {
                          alert(t("login_check"));
                        }
                      }}
                    >
                      {t("batch_list")}
                    </button>
                    {/* <UploadExcell
                      accountId={IdUtente}
                      prodottoId={id}
                      refresh={init}
                      onOpen={() => setmodalLista(false)}
                    /> */}
                  </div>
                </div>
                <div class="pausepay-wanna_pay_in_90days pausepay_class"></div>
              </div>
            </div>
            <div className="descrizione-lotto-container">
              <p className="title-descrizione"> {t("product_det")} </p>
              <p className="descrizione">{singleProduct.dettaglioColli} </p>
              <p className="descrizione">
                {" "}
                <span className="span-int">{t("brand")}: </span>
                <span>{singleProduct.marca}</span>{" "}
              </p>
              <p className="descrizione">
                {" "}
                <span className="span-int">{t("model")}: </span>
                <span>{singleProduct.modello}</span>{" "}
              </p>
              <p className="descrizione">
                {" "}
                <span className="span-int">Style: </span>
                <span>{singleProduct.style}</span>{" "}
              </p>
              <p className="descrizione">
                {" "}
                <span className="span-int">Colorway: </span>{" "}
                <span>{singleProduct.colore}</span>{" "}
              </p>
              <p className="descrizione">
                {" "}
                <span className="span-int">{t("release_date")}: </span>{" "}
                <span>{singleProduct.dettaglio} </span>
              </p>
            </div>
            <div className="descrizione-lotto-container">
              <p className="title-descrizione">{t("related_products")}</p>
              <div className="container-relatedProducts">
                <div className="new-in-prodotti-relatedProduct-gridded">
                  {TagProduct.map((prodotto, key) => {
                    const prezzo = prodotto.prezzo;
                    const prezzoFinale = parseFloat(prezzo).toFixed(2);
                    const finalPrice = prezzoFinale
                      .toString()
                      .replace(".", ",");
                    if (key < 10) {
                      return (
                        <Link
                          key={key}
                          className="product-gridded"
                          to={`/product/${prodotto.id}`}
                          onClick={() =>
                            window.refresh(
                              "http://localhost:3000/product/" + prodotto.id
                            )()
                          }
                        >
                          <div key={key}>
                            <div
                            // className="sneaker-cell"
                            >
                              {" "}
                              <img
                                className="product-gridded-image"
                                src={prodotto.imageProdotto}
                                onError={(e) => {
                                  e.target.src = scarpaVuote;
                                }}
                                alt="scarpa immagine"
                              />
                              {finalPrice !== "NaN" ? (
                                <div className="order-align-text">
                                  <span className="title-sn-homepage">
                                    {prodotto.nome}
                                  </span>
                                  <div
                                    className="sneaker-prezzo-lotto-div"
                                    style={{ flexDirection: "column" }}
                                  >
                                    <p
                                      style={{ margin: 0, fontSize: "0.8rem" }}
                                    >
                                      {t("average_price_two")}
                                    </p>
                                    <span className="prezzo-prod">
                                      € {finalPrice}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="order-align-text">
                                  <span className="title-sn-homepage">
                                    {prodotto.nome}{" "}
                                  </span>
                                  <p
                                    style={{
                                      margin: 0,
                                      fontSize: "0.8rem",
                                      alignSelf: "end",
                                    }}
                                  >
                                    {t("no_stock")}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </Link>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <Modal
            centered
            show={showAltriLotti}
            onHide={() => setShowAltriLotti(false)}
            dialogClassName="vedi-altri-lotto-modal"
          >
            <h2>{t("all_stock")}</h2>
            <div className="div-container-vedi-altri-lotti-table">
              <table>
                {lottoProductAll.map((lotto, key) => {
                  const prezzo = lotto.prezzo / lotto.quantita;
                  const prezzoFinale = parseFloat(prezzo).toFixed(2);
                  const finalPrice = prezzoFinale.toString().replace(".", ",");
                  return (
                    <tbody key={key}>
                      <button
                        onClick={() => onPressLotto(lotto)}
                        className="btn-accordion-table"
                      >
                        <Accordion
                          flush
                          onSelect={(e) => {
                            setAccordionAttivo(e);
                            setTimeout(() => setValore(true), 1000);
                          }}
                        >
                          <Accordion.Item eventKey={key}>
                            <Accordion.Header className="tr-head-prodotto">
                              <tr className="tr-prodotto" key={key}>
                                <td>
                                  {t("stock")} n <span>{lotto.id}</span>
                                </td>
                                <td>
                                  Qty{" "}
                                  <span>
                                    {" "}
                                    <b>{lotto.quantita}</b>
                                  </span>
                                </td>
                                <td>
                                  {t("average_price_two")}
                                  <span>
                                    {" "}
                                    <b>{finalPrice}€</b>
                                  </span>
                                </td>
                                <td>
                                  {t("total_price")}{" "}
                                  <span className="total-price-span">
                                    <b>
                                      {parseFloat(lotto.prezzo)
                                        .toFixed(2)
                                        .toString()
                                        .replace(".", ",") + " €"}
                                    </b>
                                  </span>
                                </td>
                                {lotto.AccountId === 2 ? (
                                  <td className="td-logo-wk">
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip}
                                    >
                                      <img
                                        src={logo}
                                        alt="wholekicks-logo"
                                        className="logo-wk-prod"
                                      />
                                    </OverlayTrigger>
                                  </td>
                                ) : lotto.AccountId === IdUtente ? (
                                  <td className="td-logo-wk">
                                    <label> {t("your_stock_text")}</label>
                                  </td>
                                ) : (
                                  <></>
                                )}
                                {verifiyPayment(lotto.id) == true ? (
                                  <td className="td-logo-wk">
                                    <b style={{ color: "blue" }}>{t("Sold")}</b>
                                  </td>
                                ) : lotto.AccountId === IdUtente ? (
                                  <td className="td-logo-wk">
                                    <label> {t("your_stock_text")}</label>
                                  </td>
                                ) : (
                                  <></>
                                )}
                              </tr>
                            </Accordion.Header>

                            <Accordion.Body className="accordion-body-custom custom-vedi-altre-taglie">
                              {/*compoenente slider per i bottoni filtrati per taglia */}
                              <SliderFiltroTaglie
                                setFiltro={setFiltro}
                                filtro={filtro}
                              />

                              <div className="taglie-container-altri-lotti">
                                {valore !== false && taglia.length > 0 ? (
                                  taglia.map((ti, key) => {
                                    return (
                                      <button
                                        key={key}
                                        className="taglia-detail dettaglio-in-accordion-taglia-modal"
                                      >
                                        <span>
                                          {" "}
                                          {filtro +
                                            " " +
                                            sizeGuideFunction(
                                              filtro,
                                              ti.taglia
                                            )}
                                        </span>
                                        <span>
                                          {t("qty")} {ti.numero}
                                        </span>
                                      </button>
                                    );
                                  })
                                ) : (
                                  <p>{t("no_stock")}</p>
                                )}
                              </div>
                              {verifiyPayment(lotto.id) == false ? (
                                <div className="buttons-div vedi-altri-lotti-bottoni">
                                  <button
                                    id="bottone-acquista"
                                    className="acquista"
                                    onClick={() => {
                                      if (isAutenticated()) {
                                        if (
                                          getCallerName() ===
                                          lottoSelezionato.AccountId.toString()
                                        ) {
                                          alert(t("your_stock"));
                                        } else {
                                          setmodalAcquista(true);
                                        }
                                      } else {
                                        alert(t("login_check"));
                                      }
                                    }}
                                  >
                                    {t("buy_stock")}
                                  </button>
                                  <button
                                    className="proposta"
                                    onClick={() => {
                                      if (isAutenticated()) {
                                        if (
                                          getCallerName() ===
                                          lottoSelezionato.AccountId.toString()
                                        ) {
                                          alert(t("your_stock_offer"));
                                        } else {
                                          setmodal(true);
                                        }
                                      } else {
                                        alert(t("login_check"));
                                      }
                                    }}
                                  >
                                    {t("make_proposal")}
                                  </button>
                                </div>
                              ) : (
                                <></>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </button>
                    </tbody>
                  );
                })}
              </table>
            </div>
            <div className="modal-footer-button vedi-altri-lotti-btn">
              <button
                className="chiudi-proposta"
                onClick={() => setShowAltriLotti(false)}
              >
                {t("cancel")}
              </button>
            </div>
          </Modal>
        </>
      ) : (
        <div className="Container-Common" style={{ flexDirection: "column" }}>
          <View style={style.container}>
            <HeaderMobile context="Home" />
            <View style={{ width: "90%", marginTop: 10 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    marginRight: 10,
                    backgroundColor: "#0a09a2",
                    color: "white",
                    paddingHorizontal: 15,
                    paddingVertical: 10,
                  }}
                >
                  100% {t("auth")}
                </Text>
                <Text
                  style={{
                    marginRight: 10,
                    backgroundColor: "#0a09a2",
                    color: "white",
                    paddingHorizontal: 15,
                    paddingVertical: 10,
                  }}
                >
                  100% {t("new")}
                </Text>
              </View>
            </View>

            <Text
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                textAlign: "left",
                width: "90%",
                marginTop: 10,
              }}
            >
              {singleProduct.nome}
            </Text>
            <Text style={{ fontSize: "1rem", textAlign: "left", width: "90%" }}>
              {singleProduct.modello}
            </Text>
            <img
              src={singleProduct.imageProdotto}
              onError={(e) => {
                e.target.src = scarpaVuote;
              }}
              style={{ height: 300, width: "80%", resizeMode: "contain" }}
            />
            <View style={{ width: "90%", flexDirection: "row" }}>
              {singleProduct.prezzo && (
                <View style={{ width: "60%" }}>
                  <Text
                    style={{
                      fontSize: "0.9rem",
                      textAlign: "left",
                      width: "90%",
                    }}
                  >
                    {t("best_stock")}
                  </Text>
                  <Text
                    style={{
                      fontSize: "1.1rem",
                      textAlign: "left",
                      width: "50%",
                      fontWeight: "bold",
                    }}
                  >
                    {parseInt(singleProduct.prezzo)} €
                  </Text>
                </View>
              )}
              <View style={{ width: "40%" }}>
                {lottoProduct.length === 0 ? (
                  <Text style={{ fontSize: "1.2rem" }}>{t("no_stock")}</Text>
                ) : (
                  <TouchableOpacity
                    style={{
                      backgroundColor: "#0A09A2",
                      textAlign: "center",
                      paddingVertical: 5,
                      borderRadius: 5,
                    }}
                    onPress={() => setShowAltriLotti(true)}
                  >
                    <Text style={{ color: "white" }}>{t("see_stocks")}</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
            <View style={{ width: "90%" }}>
              <TouchableOpacity
                onPress={onClickSizeGuide}
                style={{
                  backgroundColor: "#0A09A2",
                  borderColor: "#0A09A2",
                  borderWidth: 1.5,
                  width: "40%",
                  textAlign: "center",
                  borderRadius: 5,
                  marginTop: 20,
                  paddingVertical: 4,
                  display: "none",
                }}
              >
                <Text style={{ color: "#fff" }}>{"Size Guide"}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  if (isAutenticated()) {
                    setmodalLista(true);
                  } else {
                    alert(t("login_check"));
                  }
                }}
                style={{
                  borderColor: "#0A09A2",
                  borderWidth: 1.5,
                  width: "40%",
                  textAlign: "center",
                  borderRadius: 5,
                  marginTop: 20,
                  paddingVertical: 4,
                }}
              >
                <Text style={{ color: "#0A09A2" }}>{t("batch_list")}</Text>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                textAlign: "left",
                width: "90%",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              {t("product_det")}
            </Text>
            <View style={style.containerDettaglio}>
              <Text
                style={{
                  fontSize: "1rem",
                  marginTop: 10,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {t("release_date")}:
              </Text>
              <Text
                style={{
                  fontSize: "1rem",
                  marginTop: 10,
                  textTransform: "uppercase",
                }}
              >
                {singleProduct.dettaglio}
              </Text>
            </View>
            <View style={style.containerDettaglio}>
              <Text
                style={{
                  fontSize: "1rem",
                  marginTop: 10,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                Colorway:
              </Text>
              <Text
                style={{
                  fontSize: "1rem",
                  marginTop: 10,
                  textTransform: "uppercase",
                  textAlign: "right",
                }}
              >
                {singleProduct.colore}
              </Text>
            </View>
            <View style={style.containerDettaglio}>
              <Text
                style={{
                  fontSize: "1rem",
                  marginTop: 10,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                Style
              </Text>
              <Text
                style={{
                  fontSize: "1rem",
                  marginTop: 10,
                  textTransform: "uppercase",
                }}
              >
                {singleProduct.style}
              </Text>
            </View>

            <Text
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                textAlign: "left",
                width: "90%",
                marginVertical: 20,
              }}
            >
              {t("related_products")}
            </Text>

            <Carousel
              onPressItem={onPressCarouselProd}
              context="RelatedProduct"
              data={TagProduct}
            />
          </View>
          <Footer />
          <ModalVisualizzaProdotto
            modalAdd={showAltriLotti}
            token={token}
            clickMakeProposal={(lotto) => {
              setShowAltriLotti(false);
              setmodal(true);
              setLotto(lotto);
            }}
            onModalDismiss={() => setShowAltriLotti(false)}
            setFiltro={setFiltro}
            filtro={filtro}
            sizeGuideFunction={sizeGuideFunction}
          />
          {sizeGuide && (
            <View
              style={{
                position: "fixed",
                top: 0,
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#11111160",
              }}
            >
              <TouchableOpacity
                onPress={onClickSizeGuide}
                style={{
                  backgroundColor: "#fff",
                  padding: 5,
                  borderRadius: 8,
                  alignSelf: "flex-end",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 5,
                }}
              >
                <CloseButton color="#fff" />
              </TouchableOpacity>
              <SizeGuide state={sizeGuide} />
            </View>
          )}
        </div>
      )}
      <Modal
        centered
        show={showModalLista}
        onHide={() => setmodalLista(true)}
        dialogClassName="aggiungi-modello-modal"
      >
        <div className="modal-header-text">
          <label className="acquista-subito">{t("batch_pr_sub")}</label>
        </div>
        <div className="modal-footer-button">
          <Link to={"/lista-lotto-third/" + id}>
            <button
              className="invia-proposta"
              onClick={() => setmodalLista(false)}
            >
              {t("batch_list")}
            </button>
          </Link>
          {/* <UploadExcell
            accountId={IdUtente}
            prodottoId={id}
            refresh={init}
            onOpen={() => setmodalLista(false)}
          /> */}
          <div style={{ width: "50%" }}>
            <Button
              variant="primary"
              onClick={handleShowExcelModal}
              style={{ fontSize: 18, width: "100%" }}
            >
              {t("excel_batch_list")}
            </Button>
          </div>
          <button
            className="chiudi-proposta"
            onClick={() => setmodalLista(false)}
          >
            {t("cancel")}
          </button>
        </div>
      </Modal>
      <Modal
        centered
        show={showModal}
        onHide={() => setmodal(true)}
        dialogClassName="aggiungi-modello-modal"
      >
        <div className="modal-header-text">
          <p className="modello-title-modal">{t("make_proposal")}</p>
          <label className="acquista-subito">
            {t("buy_it_now")}:{" "}
            <span className="best-stock-price-label">
              {lottoSelezionato.prezzo}€
            </span>
          </label>
          <p>{t("or")}</p>
          <p className="modello-sub-title-modal">{t("your_prop")}:</p>
          <div className="input-modal-proposta-prod">
            <form
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
              onSubmit={(e) => {
                if (!bidValidity) {
                  alert(t("please_insert_bid_validity"));
                  e.preventDefault();
                } else {
                  inviaProposta(e);
                }
              }}
            >
              <label className="text-bar-proposta-input">
                <input
                  type="text"
                  placeholder={t("price")}
                  className="text-bar-input"
                  style={{ border: 0 }}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                  required
                />
              </label>
              <select
                id="Durata"
                required
                className="input-bar"
                onChange={setDateOffer}
              >
                <option value="">{t("val_off")}</option>
                <option value="30">30</option>
                <option value="60">60</option>
                <option value="90">90</option>
                <option value="120">120</option>
              </select>

              <div className="modal-footer-button-proposta">
                <button
                  type="submit"
                  disabled={loading}
                  className="invia-proposta"
                >
                  {t("submit_proposal")}
                </button>
              </div>
            </form>
          </div>
        </div>
        <Link to={"/lotto-custom-one/" + id + "/" + lottoSelezionato.id}>
          {t("create_custom")}
        </Link>
        <div className="modal-footer-button">
          <button className="chiudi-proposta" onClick={() => setmodal(false)}>
            {t("cancel")}
          </button>
        </div>
      </Modal>

      <Modal
        centered
        show={showConfermaProposta}
        onHide={() => setShowConfermaProposta(false)}
        dialogClassName="aggiungi-modello-modal"
      >
        <div className="modal-header-text">
          <p className="modello-title-modal"> {t("prop_sent")} </p>
          <p className="modello-sub-title-modal-proposta">
            {t("prop_sent_sub")}
          </p>
        </div>
        <div className="modal-footer-button">
          <Link to={"/account/acquisti"} className="invia-proposta">
            {t("check_prop")}
          </Link>
          <button
            className="chiudi-proposta"
            onClick={() => setShowConfermaProposta(false)}
          >
            {t("cont_shop")}
          </button>
        </div>
      </Modal>

      <Modal
        centered
        animation={true}
        show={showModalAcquista}
        onHide={() => setmodalAcquista(true)}
        dialogClassName="aggiungi-modello-modal"
      >
        <div className="modal-header-text">
          <p className="modello-title-modal">
            {t("buy")}:{" "}
            <span className="best-stock-price-label-now">
              {" "}
              {lottoSelezionato.prezzo}€
            </span>
          </p>
          <label className="acquista-subito">{t("buy_pr_sub")}</label>
        </div>
        <div className="modal-footer-button">
          <Link
            to={"/checkout-first/" + lottoSelezionato.id + "/" + id}
            className="invia-proposta-modale-sing-prod"
          >
            <button onClick={() => setmodalAcquista(false)}>{t("buy")}</button>
          </Link>

          <button
            className="chiudi-proposta"
            onClick={() => setmodalAcquista(false)}
          >
            {t("cancel")}
          </button>
        </div>
      </Modal>
      {/* MODALE PROPOSTA */}
      <ModalShare
        visible={visible}
        setvisibleFun={setvisible}
        shareUrl={window.location}
      />
      {/* Modale Lista un lotto  */}
      <Modal show={showModalExcel} onHide={handleCloseExcelModal}>
        <Modal.Header closeButton>
          <Modal.Title> {t("upload_excel_file")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" accept=".xls" onChange={handleFileChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDownloadTemplate}>
            {t("download_template")}
          </Button>
          <Button variant="primary" onClick={handleUpload}>
            {t("upload_excel")}
          </Button>
          {messaggioErrore !== "" && (
            <label style={{ width: "100%", color: "red", paddingLeft: "18%" }}>
              {messaggioErrore}
            </label>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: "15%",
  },

  containerDettaglio: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "90%",
    paddingRight: "5%",
  },
});

export default SingleProd;
