import React, { Component } from "react";
import Header from "../components/Header";
import axios from "axios";
import CONSTANTS from "../CONSTANTS";
import { Link } from "react-router-dom";
import "../styles/Home.css";
import logo from "../assets/wk-logo.png";
import { withTranslation } from "react-i18next";

class Vendi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaProdotti: [],
      checked: false,
      checked_2: false,
      checked_3: false,
    };
  }

  componentDidMount() {
    axios.get(CONSTANTS.API_URL + "/prodotto/rand").then((prodottoRicevuto) => {
      this.setState({
        listaProdotti: prodottoRicevuto.data,
      });
    });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container-form-check">
          <div className="div-logo-form">
            <Link to="/">
              <img className="logo-login" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="checkout-form-div conferma-prima-di-listare">
            <p className="checkout-title">{t("confirm_stock")}</p>
            <p>{t("conf_stock_1")}:</p>
            <div>
              <div
                className={
                  this.state.checked
                    ? "check-conferma-lotto"
                    : "check-conferma-lotto-unchecked"
                }
                onClick={() => {
                  this.setState({ checked: !this.state.checked });
                  console.log(this.state.checked);
                }}
              ></div>
              <p>{t("conf_stock_2")}</p>
            </div>
            <div>
              <div
                className={
                  this.state.checked_2
                    ? "check-conferma-lotto"
                    : "check-conferma-lotto-unchecked"
                }
                onClick={() => {
                  this.setState({ checked_2: !this.state.checked_2 });
                  console.log(this.state.checked);
                }}
              ></div>
              <p>{t("conf_stock_3")}</p>
            </div>
            <div>
              <div
                className={
                  this.state.checked_3
                    ? "check-conferma-lotto"
                    : "check-conferma-lotto-unchecked"
                }
                onClick={() => {
                  this.setState({ checked_3: !this.state.checked_3 });
                  console.log(this.state.checked);
                }}
              ></div>
              <p> {t("conf_stock_4")}</p>
            </div>
          </div>
          <div
            className={
              this.state.checked && this.state.checked_2 && this.state.checked_3
                ? "btn-footer-checkout-link-vendi"
                : "btn-footer-checkout-link-vendi-none"
            }
            onClick={
              this.state.checked && this.state.checked_2 && this.state.checked_3
                ? ""
                : () => {
                    alert(t("check_options"));
                  }
            }
          >
            <Link
              className={
                this.state.checked &&
                this.state.checked_2 &&
                this.state.checked_3
                  ? ""
                  : "link-to-checkout-pointer-none"
              }
              to="/lista-lotto-second"
            >
              {t("next")}
            </Link>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(Vendi);
