import React, { Component } from "react";
import { Text, View, StyleSheet } from "react-native";
import HeaderMobile from "../components/HeaderMobile";

export class Product extends Component {
  render() {
    return (
      <div className="Container-Common">
        <View style={style.container}>
          <HeaderMobile context="Home" />
        </View>
      </div>
    );
  }
}

const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

export default Product;
