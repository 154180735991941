import React from "react";
import {
  View,
  Text,
  Modal,
  TouchableWithoutFeedback,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
} from "react-native";
import { colors, isLittleDev } from "../components/utils/constants";
import CONSTANTS from "../../CONSTANTS";
import { useTranslation } from "react-i18next";
import "./style.css";
import { Switch } from "@mui/material";

const ModalCustomViewer = ({
  modalAdd,
  onModalDismiss,
  lotto,
  riferimento,
  taglie,
  t_riferimento,
  diff_sizes,
  accept,
  reject,
  typePage,
  selectListed,
  onChangeSelectListed,
}) => {
  const { t } = useTranslation();
  const findReferenceIndex = (taglia) => {
    const index = t_riferimento.findIndex((a) => a.taglia === taglia);
    return index;
  };
  const nazione = localStorage.getItem(CONSTANTS.NAZIONE);

  const calcFeeSped = (lotto) => {
    const module = lotto.quantita % 6;
    let division = lotto.quantita / 6;
    division = Math.round(division - 0.5);

    if (module > 0) {
      return (division + 1) * CONSTANTS.PRICESPEDIZIONE;
    } else {
      return division * CONSTANTS.PRICESPEDIZIONE;
    }
  };
  const calcWKfee = (lotto) => {
    return lotto?.prezzo * 0.025;
    //return 0
  };
  const calcIva = (lotto) => {
    if (nazione === "Italy" && !lotto.ivaMargine)
      return (lotto.prezzo - (calcFeeSped(lotto) + calcWKfee(lotto))) * 0.22;
    else {
      return 0;
    }
  };
  const totale = (lotto) => {
    let totale = 0;
    if (typePage === "Vendita") {
      console.log("totale Vendita", totale);
      totale =
        lotto.prezzo - (calcFeeSped(lotto) + calcWKfee(lotto)) + calcIva(lotto);
    } else {
      console.log("totale Acquisto", totale);

      totale =
        lotto.prezzo + (calcFeeSped(lotto) + calcWKfee(lotto)) + calcIva(lotto);
    }
    return totale;
  };
  return (
    <>
      <Modal visible={modalAdd} transparent animationType="slide">
        <View
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          {!isLittleDev() && (
            <TouchableWithoutFeedback onPress={onModalDismiss}>
              <View style={{ height: "6%", width: "100%" }} />
            </TouchableWithoutFeedback>
          )}
          <ScrollView
            style={{
              height: isLittleDev() ? "100%" : "94%",
              width: "100%",
              backgroundColor: "#fff",
              borderTopRightRadius: isLittleDev() ? 15 : 30,
              borderTopLeftRadius: isLittleDev() ? 15 : 30,
              shadowRadius: 10,
              shadowOpacity: 0.5,
              paddingHorizontal: 10,
              paddingTop: 20,
            }}
            contentContainerStyle={{
              width: "100%",
              alignItems: "center",
            }}
          >
            <View style={{ width: "100%", alignItems: "flex-end" }}>
              <TouchableOpacity onPress={() => onModalDismiss()}>
                <Text style={{ fontWeight: "bold", color: colors.blue }}>
                  {t("close")}
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Text
                style={{
                  width: "70%",
                  textAlign: "center",
                  alignSelf: "center",
                  fontWeight: "400",
                  fontSize: "1.2rem",
                }}
              >
                {t("avb_stock")}
              </Text>
              {/* <TouchableOpacity
                    style={{
                      width: "90%",
                      textAlign: "left",
                      alignSelf: "center",
                      fontWeight: "600",
                      fontSize: "0.8rem",
                      color: colors.blue,
                    }}
                  >
                    <Text style={{ color: "white" }}>{t("order_by")}</Text>
                  </TouchableOpacity> */}
              <View
                style={{
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div className="scroll-div-mobile">
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      borderBottomColor: colors.blue,
                      borderBottomWidth: 1.5,
                      paddingTop: 5,
                      paddingBottom: 10,
                    }}
                  >
                    <View>
                      <Text style={style.textBold}>
                        Reference {t("stock")} n° {riferimento.id}
                      </Text>
                      <View style={style.containerView}>
                        <Text
                          style={{
                            textAlign: "center",
                            fontWeight: "700",
                            width: "50%",
                            fontSize: "0.6rem",
                          }}
                        >
                          {t("average_price")}
                        </Text>
                        <Text style={style.textNotBold}>
                          {parseInt(lotto.prezzo / lotto.quantita)} €
                        </Text>
                      </View>
                    </View>
                    <View>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={style.textBold}>Qty:</Text>
                        <Text style={style.textNotBold}>{lotto.quantita}</Text>
                      </View>
                      <View style={style.containerView}>
                        <Text style={style.textBold}>{t("total_price")}</Text>
                        <Text style={style.textNotBold}>{lotto.prezzo}€</Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      backgroundColor: "#f5f5f5",
                      marginTop: 2,
                      borderRadius: 12,
                      width: "100%",
                      padding: 6,
                      flexDirection: "row",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {taglie?.map((t, index) => (
                      <View
                        style={{
                          borderWidth: 0.5,
                          borderColor: "black",
                          width: "45%",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          paddingVertical: 2,
                          paddingHorizontal: "2%",
                          borderRadius: 7,
                          backgroundColor: "white",
                          margin: 5,
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 3,
                            textAlign: "center",
                          }}
                        >
                          <Text style={{ textAlign: "center", width: 40 }}>
                            {
                              t_riferimento[findReferenceIndex(t.taglia)]
                                ?.taglia
                            }
                          </Text>
                          <Text
                            style={{
                              textAlign: "center",
                              width: 40,
                              fontWeight: "700",
                            }}
                          >
                            US
                          </Text>
                          <Text
                            style={{
                              backgroundColor: colors.blue,
                              padding: 4,
                              borderRadius: 5,
                              width: 40,
                              textAlign: "center",
                              color: "#fff",
                            }}
                          >
                            {t.taglia}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 3,
                          }}
                        >
                          <Text style={{ textAlign: "center", width: 40 }}>
                            {
                              t_riferimento[findReferenceIndex(t.taglia)]
                                ?.numero
                            }
                          </Text>
                          <Text
                            style={{
                              textAlign: "center",
                              width: 40,
                              fontWeight: "700",
                            }}
                          >
                            Qty
                          </Text>
                          <Text
                            style={{
                              backgroundColor: colors.blue,
                              padding: 4,
                              borderRadius: 5,
                              width: 40,
                              textAlign: "center",
                              color: "#fff",
                            }}
                          >
                            {t.numero}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 3,
                          }}
                        >
                          <Text style={{ textAlign: "center", width: 40 }}>
                            {
                              t_riferimento[findReferenceIndex(t.taglia)]
                                ?.prezzo
                            }
                          </Text>
                          <Text
                            style={{
                              textAlign: "center",
                              width: 40,
                              fontWeight: "700",
                            }}
                          >
                            €
                          </Text>
                          <Text
                            style={{
                              backgroundColor: colors.blue,
                              padding: 4,
                              borderRadius: 5,
                              width: 40,
                              textAlign: "center",
                              color: "#fff",
                            }}
                          >
                            {t.prezzo}
                          </Text>
                        </View>
                      </View>
                    ))}
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                      paddingHorizontal: "5%",
                      paddingTop: 20,
                    }}
                  >
                    <View style={{ width: "60%" }}>
                      {t_riferimento.length > taglie.length && (
                        <p style={{ fontSize: "0.8rem" }}>
                          {t("size_removed")} <b>{diff_sizes}</b>
                        </p>
                      )}
                    </View>
                  </View>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <View
                      style={{
                        flexDirection: "column",
                        width: "50%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <View style={{ flexDirection: "row", width: "100%" }}>
                        <Text style={style.textBold}>{t("shipping_fee")}</Text>
                        <Text style={style.textNotBold}>
                          {calcFeeSped(riferimento)}€
                        </Text>
                      </View>
                      <View style={style.containerView}>
                        <Text style={style.textBold}>
                          {t("transaction_fees")}
                        </Text>

                        <Text style={style.textNotBold}>
                          {calcWKfee(riferimento)}€
                        </Text>
                      </View>
                      <View style={style.containerView}>
                        <Text style={style.textBold}>
                          {!riferimento.ivaMargine ? (
                            t("Iva") + "22%: "
                          ) : (
                            <>{t("marginal_vat_checked") + ":"}</>
                          )}
                        </Text>
                        <Text style={style.textNotBold}>
                          {calcIva(riferimento)}€
                        </Text>
                      </View>
                      <View style={style.containerView}>
                        <Text style={style.textBold}>{t("total_price")}</Text>
                        <Text style={style.textNotBold}>
                          {totale(riferimento)}€
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        width: "50%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        border: "2px solid rgb(10, 9, 162)",
                        borderRadius: "5px",
                      }}
                    >
                      <View style={{ flexDirection: "row", width: "100%" }}>
                        <Text style={style.textBold}>{t("shipping_fee")}</Text>
                        <Text style={style.textNotBold}>
                          {calcFeeSped(lotto)}€
                        </Text>
                      </View>
                      <View style={style.containerView}>
                        <Text style={style.textBold}>
                          {t("transaction_fees")}
                        </Text>

                        <Text style={style.textNotBold}>
                          {calcWKfee(lotto)}€
                        </Text>
                      </View>
                      <View style={style.containerView}>
                        <Text style={style.textBold}>
                          {" "}
                          {!lotto.ivaMargine ? (
                            t("Iva") + "22%: "
                          ) : (
                            <>{t("marginal_vat_checked") + ":"}</>
                          )}
                        </Text>
                        <Text style={style.textNotBold}>{calcIva(lotto)}€</Text>
                      </View>
                      <View style={style.containerView}>
                        <Text style={style.textBold}>{t("total_price")}</Text>
                        <Text style={style.textNotBold}>{totale(lotto)}€</Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "40%",
                      alignSelf: "flex-end",
                      marginLeft: "auto",
                    }}
                  >
                    <button
                      style={{
                        borderWidth: 0,
                        backgroundColor: "#0A09A2",
                        fontSize: "0.8rem",
                        alignSelf: "flex-start",
                        width: "40%",
                        paddingTop: "2%",
                        paddingBottom: "2%",
                        color: "#fff",
                        marginBottom: 12,
                        marginTop: "3%",
                        borderRadius: 6,
                      }}
                      onClick={() => accept()}
                    >
                      {t("accept")}
                    </button>
                    <button
                      style={{
                        borderWidth: 0,
                        backgroundColor: "#f00",
                        fontSize: "0.8rem",
                        alignSelf: "flex-start",
                        width: "40%",
                        paddingTop: "2%",
                        paddingBottom: "2%",
                        color: "#fff",
                        marginBottom: 12,
                        marginTop: "3%",
                        borderRadius: 6,
                      }}
                      onClick={() => reject()}
                    >
                      {t("reject")}
                    </button>
                  </View>
                  {riferimento.quantita - lotto.quantita <= 4 ? (
                    <label
                      style={{
                        marginBottom: "2%",
                        textDecoration: "underline",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {t("no_quantity_remaining")}
                    </label>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: "9px",
                        gap: "5px",
                        width: "100%",
                      }}
                    >
                      <label
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {t("relist_lot")}
                      </label>

                      <Switch
                        onClick={() => onChangeSelectListed(!selectListed)}
                      />
                    </div>
                  )}
                </div>
              </View>
              <View style={{ height: isLittleDev() ? 20 : 30 }} />
            </View>
          </ScrollView>
        </View>
      </Modal>
    </>
  );
};

const style = StyleSheet.create({
  containerView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  textBold: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  textNotBold: {
    fontSize: "1rem",
    marginLeft: 5,
  },
});

export default ModalCustomViewer;
