import React, { Component } from "react";
import Header from "../components/Header";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import CONSTANTS from "../CONSTANTS";
import Filters from "../components/Filters";
import { DataArraySharp } from "@mui/icons-material";
import { Modal, Accordion, Tooltip, OverlayTrigger } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import logo from "../assets/wk-logo.png";
import PageItem from "react-bootstrap/PageItem";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import useDesktopChecker from "../components/utils/useDesktopChecker";
import HeaderMobile from "../pagesMobile/components/HeaderMobile";
import { View, Text, StyleSheet } from "react-native";
import Menu from "../pagesMobile/home/Menu";
import ProductGrid from "../pagesMobile/newin/ProductGrid";
import { colors } from "../pagesMobile/components/utils/constants";
import scarpaVuota from "../assets/wk_products_not_found.png";
class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      listaProdotti: [],
      ListaProdottiDaFiltrare: [],
      listaPiuCercati: [],
      PagFilter: [],
      prezzo1: 0,
      prezzo2: 0,
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
      listaMarca: [],
      filtri: { prezzo: "0", marca: [] },
      array: [],
      modalShow: false,
      statoProd: 1,
      count: 2,
      countList: 2,
      numberElem: 2,
      Prezzo: 0,
      Marche: 0,
      limit: 0,
      nProd: 30,
      offset: 0,
      elementPerPage: 30,
      page: 1,
      utente: "",
      totalPage: 0,
      countFilter: [],
      timing: false,
      loading: false,
    };
    this.prezzoDispatcher = {
      0: { prezzo1: 0, prezzo2: 1000000 },
      1: { prezzo1: 0, prezzo2: 100 },
      2: { prezzo1: 100, prezzo2: 200 },
      3: { prezzo1: 200, prezzo2: 300 },
      4: { prezzo1: 300, prezzo2: 400 },
      5: { prezzo1: 400, prezzo2: 500 },
      6: { prezzo1: 500, prezzo2: 100000 },
    };
  }
  componentDidMount = async () => {
    const word = this.props.params.value;
    this.setState({ loading: true }, async () => {
      if (word) {
        await axios
          .get(
            CONSTANTS.API_URL +
              "/prodotto/search/" +
              word +
              "/" +
              this.state.nProd +
              "/0"
          )
          .then((res) => {
            this.setState(
              {
                listaProdotti: res.data.prodottoConteggio,
                conteggio: res.data.conteggio,
                totalPage: Math.round(
                  res.data.conteggio / this.state.nProd + 0.5
                ),
              },
              () => {
                this.setState({ loading: false });
                console.log("totalp", this.state.totalPage);
              }
            );
            console.log(res.data);
          })
          .catch((e) => {
            console.log(e.message);
          });
      } else {
        this.setState({
          listaProdotti: [],
        });
      }
    });
  };

  upgradeClick = async (data) => {
    console.log(data);
    const body = {
      ProdottoId: data,
    };
    await axios
      .post(CONSTANTS.API_URL + "/piucercate/click", body)
      .then((res) => {
        console.log(res.data.successo);
        this.routeChange("registrazione/conferma-registrazione");
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  filtriFunction = async (prezzo, marche, utente, stato) => {
    console.log(stato);
    const indexOfLastTodo = 1 * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    console.log("indici", this.state.elementPerPage, indexOfFirstTodo);

    this.setState({
      offset: indexOfLastTodo,
      limit: indexOfFirstTodo,
      statoProd: stato,
    });

    const body = {
      marche: marche,
      prezzo: this.prezzoDispatcher[prezzo],
      utente: utente,
      offset: 30,
      limit: 0,
    };
    console.log(body);
    await axios
      .post(CONSTANTS.API_URL + "/prodotto/filter", body)
      .then((res) => {
        console.log(res.data);
        const countPage = res.data.countFinalFilter / this.state.elementPerPage;
        this.setState({
          ListaProdottiDaFiltrarePage: res.data.prodottos,
          statoProd: 2,
          Prezzo: prezzo,
          Marche: marche,
          Utente: utente,
          countFilter: Array(parseInt(countPage) + 1),
        });
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  handlePageClickFilter = async (page) => {
    const indexOfLastTodo = page * this.state.elementPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.elementPerPage;
    console.log("indici", this.state.elementPerPage, indexOfFirstTodo);
    this.setState({
      offset: indexOfLastTodo,
      limit: indexOfFirstTodo,
    });
    const body = {
      marche: this.state.Marche,
      prezzo: this.prezzoDispatcher[this.state.Prezzo],
      utente: this.state.utente,
      offset: indexOfLastTodo,
      limit: indexOfFirstTodo,
    };
    const prodotti = await axios.post(
      CONSTANTS.API_URL + "/prodotto/filter",
      body
    );
    console.log("prodotti", prodotti);

    this.setState({
      page: page,
      ListaProdottiDaFiltrarePage: prodotti.data.prodottos,
    });
  };
  handlePageClick = async (page) => {
    this.setState({ page: page }, async () => {
      const lim =
        window.innerWidth >= 900 ? 0 : this.state.nProd * this.state.page - 30;
      let prodotti = null;
      prodotti = await axios.get(
        CONSTANTS.API_URL +
          "/prodotto/search/" +
          this.props?.params?.value +
          "/" +
          this.state.nProd * this.state.page +
          "/" +
          lim
      );
      this.setState({
        listaProdotti: prodotti.data.prodottoConteggio,
      });
    });
    window.innerWidth >= 900 ? console.log(" ") : window.scrollTo(0, 0);
  };

  MobileRender = () => {
    const { t } = this.props.translation;
    return (
      <>
        <div className="Container-Common">
          <View style={style.container}>
            <HeaderMobile context="Home" />
            <Menu />
            <View style={style.board}>
              <Text
                style={{
                  fontSize: "1.3rem",
                  color: colors.blue,
                  fontWeight: "700",
                  width: "90%",
                  textAlign: "left",
                }}
              >
                Search for "{this.props.params?.value}"
              </Text>
            </View>
            <ProductGrid newInTotal={this.state.listaProdotti} />
            <Pagination>
              {this.state.page > 1 && (
                <Pagination.Item onClick={() => this.handlePageClick(1)}>
                  1
                </Pagination.Item>
              )}
              {this.state.page > 1 && <Pagination.Ellipsis />}
              {this.state.page - 1 > 1 && (
                <Pagination.Item
                  active={this.state.page === this.state.page - 1}
                  key={"_pagination_" + this.state.page - 1}
                  onClick={() => this.handlePageClick(this.state.page - 1)}
                >
                  {this.state.page !== 1 ? this.state.page - 1 : null}
                </Pagination.Item>
              )}
              <Pagination.Item
                active={this.state.page === this.state.page}
                key={"_pagination_" + this.state.page}
                onClick={() => this.handlePageClick(this.state.page)}
              >
                {this.state.page}
              </Pagination.Item>
              {this.state.page < this.state.totalPage && (
                <Pagination.Item
                  active={this.state.page + 1 === this.state.page}
                  key={"_pagination_" + this.state.page + 1}
                  onClick={() => this.handlePageClick(this.state.page + 1)}
                >
                  {this.state.page + 1}
                </Pagination.Item>
              )}
              {this.state.page === 1 && (
                <>
                  <Pagination.Ellipsis />
                  <Pagination.Item
                    key={"_pagination_" + this.state.page + 1}
                    onClick={() => this.handlePageClick(this.state.totalPage)}
                  >
                    {this.state.totalPage}
                  </Pagination.Item>
                </>
              )}
            </Pagination>
            <Footer />
          </View>
        </div>
      </>
    );
  };
  DesktopRender = () => {
    const { t } = this.props.translation;

    return (
      <>
        {this.state.loading === true ? (
          <Loading />
        ) : (
          <div>
            <Header />

            <div className="container">
              <div className="filtri-container">
                {/* <Filters
                filterPrezzo={this.filtriFunction}
                marca={this.state.listaMarca}
                filtriAttuali={this.state.filtri}
              /> */}
                <p className="label-search-res">
                  {t("search_for")} "{this.props.params?.value}"
                </p>
              </div>
              <div className="new-in-prodotti-wrapped-gridded">
                {this.state.statoProd === 2 &&
                this.state.ListaProdottiDaFiltrarePage
                  ? this.state.ListaProdottiDaFiltrarePage.map(
                      (prodotto, key) => {
                        if (prodotto?.id) {
                          const prezzo = prodotto.prezzo;
                          const prezzoFinale = parseFloat(prezzo).toFixed(2);
                          const finalPrice = prezzoFinale
                            .toString()
                            .replace(".", ",");

                          return (
                            <Link
                              className="product-gridded"
                              to={`/product/${prodotto.id}`}
                              onClick={() => this.upgradeClick(prodotto.id)}
                            >
                              <div key={key}>
                                <div
                                //  className="sneaker-cell"
                                >
                                  {" "}
                                  <img
                                    src={prodotto.imageProdotto}
                                    onError={(e) => {
                                      e.target.src = scarpaVuota;
                                    }}
                                    alt="scarpa immagine"
                                    className="product-gridded-image"
                                  />
                                  {finalPrice !== "NaN" ? (
                                    <div className="order-align-text">
                                      <span className="title-sn-homepage">
                                        {prodotto.nome}
                                      </span>
                                      <div
                                        className="sneaker-prezzo-lotto-div"
                                        style={{ flexDirection: "column" }}
                                      >
                                        <p
                                          style={{
                                            margin: 0,
                                            fontSize: "0.8rem",
                                          }}
                                        >
                                          {t("average_price_two")}
                                        </p>
                                        <span className="prezzo-prod">
                                          € {finalPrice}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="order-align-text">
                                      <span className="title-sn-homepage">
                                        {prodotto.nome}{" "}
                                      </span>
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "0.8rem",
                                          alignSelf: "end",
                                        }}
                                      >
                                        {t("no_stock")}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Link>
                          );
                        }
                      }
                    )
                  : this.state.listaProdotti.map((prodotto, key) => {
                      if (prodotto?.id) {
                        const prezzo = prodotto.prezzo;
                        const prezzoFinale = parseFloat(prezzo).toFixed(2);
                        const finalPrice = prezzoFinale
                          .toString()
                          .replace(".", ",");

                        return (
                          <Link
                            className="product-gridded"
                            to={`/product/${prodotto.id}`}
                            onClick={() => this.upgradeClick(prodotto.id)}
                          >
                            <div key={key}>
                              <div
                              // className="sneaker-cell"
                              >
                                {" "}
                                <img
                                  src={prodotto.imageProdotto}
                                  onError={(e) => {
                                    e.target.src = scarpaVuota;
                                  }}
                                  alt="scarpa immagine"
                                  className="product-gridded-image"
                                />
                                {finalPrice !== "NaN" ? (
                                  <div className="order-align-text">
                                    <span className="title-sn-homepage">
                                      {prodotto.nome}
                                    </span>
                                    <div
                                      className="sneaker-prezzo-lotto-div"
                                      style={{ flexDirection: "column" }}
                                    >
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: "0.8rem",
                                        }}
                                      >
                                        {t("average_price_two")}
                                      </p>
                                      <span className="prezzo-prod">
                                        € {finalPrice}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="order-align-text">
                                    <span className="title-sn-homepage">
                                      {prodotto.nome}{" "}
                                    </span>
                                    <p
                                      style={{
                                        margin: 0,
                                        fontSize: "0.8rem",
                                        alignSelf: "end",
                                      }}
                                    >
                                      {t("no_stock")}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Link>
                        );
                      }
                    })}

                <Modal
                  centered
                  show={this.state.modalShow}
                  onHide={() => this.setState({ modalShow: false })}
                  dialogClassName="aggiungi-modello-modal"
                >
                  <div className="modal-header-text">
                    <p className="modello-title-modal">{t("no_stock")}</p>
                  </div>
                  <div className="modal-footer-button">
                    <button
                      className="chiudi-proposta"
                      onClick={() => this.setState({ modalShow: false })}
                    >
                      Close
                    </button>
                  </div>
                </Modal>
              </div>
              {this.state.statoProd === 2 ? (
                <Pagination>
                  {this.state.page > 1 && (
                    <Pagination.Prev
                      onClick={() =>
                        this.handlePageClickFilter(this.state.page - 1)
                      }
                    />
                  )}
                  {this.state.page > 1 && (
                    <Pagination.Item
                      onClick={() => this.handlePageClickFilter(1)}
                    >
                      1
                    </Pagination.Item>
                  )}
                  {this.state.page > 1 && <Pagination.Ellipsis />}
                  {this.state.page - 1 > 1 && (
                    <Pagination.Item
                      active={this.state.page === this.state.page - 1}
                      key={"_pagination_" + this.state.page - 1}
                      onClick={() =>
                        this.handlePageClickFilter(this.state.page - 1)
                      }
                    >
                      {this.state.page !== 1 ? this.state.page - 1 : null}
                    </Pagination.Item>
                  )}

                  <Pagination.Item
                    active={this.state.page === this.state.page}
                    key={"_pagination_" + this.state.page}
                    onClick={() => this.handlePageClickFilter(this.state.page)}
                  >
                    {this.state.page}
                  </Pagination.Item>
                  {this.state.page != this.state.countFilter.length && (
                    <Pagination.Item
                      active={this.state.page + 1 === this.state.page}
                      key={"_pagination_" + this.state.page + 1}
                      onClick={() =>
                        this.handlePageClickFilter(this.state.page + 1)
                      }
                    >
                      {this.state.page + 1}
                    </Pagination.Item>
                  )}
                  {this.state.page + 1 < this.state.countFilter.length && (
                    <Pagination.Ellipsis />
                  )}
                  {this.state.page + 1 < this.state.countFilter.length && (
                    <Pagination.Item
                      onClick={() =>
                        this.handlePageClickFilter(
                          this.state.countFilter.length
                        )
                      }
                    >
                      {this.state.countFilter.length}
                    </Pagination.Item>
                  )}
                  {this.state.page < this.state.countFilter.length && (
                    <Pagination.Next
                      onClick={() =>
                        this.handlePageClickFilter(this.state.page + 1)
                      }
                      disabled={
                        this.state.page + 1 === this.state.countFilter.length ||
                        this.state.page === this.state.countFilter.length ||
                        this.state.page > this.state.countFilter.length
                      }
                    />
                  )}
                </Pagination>
              ) : (
                <Pagination>
                  {this.state.page > 1 && (
                    <Pagination.Item onClick={() => this.handlePageClick(1)}>
                      1
                    </Pagination.Item>
                  )}
                  {this.state.page > 1 && <Pagination.Ellipsis />}
                  {this.state.page - 1 > 1 && (
                    <Pagination.Item
                      active={this.state.page === this.state.page - 1}
                      key={"_pagination_" + this.state.page - 1}
                      onClick={() => this.handlePageClick(this.state.page - 1)}
                    >
                      {this.state.page !== 1 ? this.state.page - 1 : null}
                    </Pagination.Item>
                  )}
                  <Pagination.Item
                    active={this.state.page === this.state.page}
                    key={"_pagination_" + this.state.page}
                    onClick={() => this.handlePageClick(this.state.page)}
                  >
                    {this.state.page}
                  </Pagination.Item>
                  {this.state.page < this.state.totalPage && (
                    <Pagination.Item
                      active={this.state.page + 1 === this.state.page}
                      key={"_pagination_" + this.state.page + 1}
                      onClick={() => this.handlePageClick(this.state.page + 1)}
                    >
                      {this.state.page + 1}
                    </Pagination.Item>
                  )}
                  {this.state.page === 1 && (
                    <>
                      <Pagination.Ellipsis />
                      <Pagination.Item
                        key={"_pagination_" + this.state.page + 1}
                        onClick={() =>
                          this.handlePageClick(this.state.totalPage)
                        }
                      >
                        {this.state.totalPage}
                      </Pagination.Item>
                    </>
                  )}
                </Pagination>
              )}
            </div>
            <Footer />
          </div>
        )}
      </>
    );
  };
  render() {
    return (
      <>{this.props.desktop ? <this.DesktopRender /> : <this.MobileRender />}</>
    );
  }
}
const style = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  board: {
    height: 80,
    width: "100%",

    padding: 30,
  },
});
export default function (props) {
  const t = useTranslation();
  const params = useParams();
  const desktop = useDesktopChecker();
  return (
    <SearchResult
      {...props}
      params={params}
      translation={t}
      desktop={desktop}
    />
  );
}
