import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import useReactPath from "../components/useReactPath";
import Home from "../pages/Home";
import About from "../pages/About";
import Account from "../pages/Account";
import NewIn from "../pages/NewIn";
import ShopAll from "../pages/ShopAll";
import Vendi from "../pages/Vendi";
import Acquisti from "../pages/accountPages/Acquisti";
import Impostazioni from "../pages/accountPages/Impostazioni";
import Storico from "../pages/accountPages/Storico";
import Vendite from "../pages/accountPages/Vendite";
import LoginForm from "../components/login-form/LoginForm";
import ConfermaRegistrazione from "../components/login-form/ConfermaRegistrazione";
import SingleProd from "../pages/product/SingleProd";
import RegistrazioneClass from "../components/login-form/RegistrazioneClass";
import PageNotFound from "../pages/PageNotFound";
import CheckoutOne from "../pages/checkoutPages/CheckoutOne";
import CheckoutTwo from "../pages/checkoutPages/CheckoutTwo";
import CheckoutThree from "../pages/checkoutPages/CheckoutThree";
import CheckoutFour from "../pages/checkoutPages/CheckoutFour";
import ListaLottoSecond from "../pages/listaLottoPages/ListaLottoSecond";
import ListaLottoThird from "../pages/listaLottoPages/ListaLottoThird";
import ListaLottoFourth from "../pages/listaLottoPages/ListaLottoFourth";
import ListaLottoFive from "../pages/listaLottoPages/ListaLottoFive";
import ListaLottoSix from "../pages/listaLottoPages/ListaLottoSix";
import MostClicked from "../pages/MostClicked";
import ModificaPassword from "../pages/accountPages/ModificaPassword";
import ModificaEmail from "../pages/accountPages/ModificaEmail";
import ModificaIban from "../pages/accountPages/ModificaIban";
import ModificaSpedizione from "../pages/accountPages/ModificaSpedizione";
import PasswordDimenticata from "../pages/PasswordDimenticata";
import PasswordDimenticataMobile from "../pages/PasswordDimenticataMobile";
import EliminaAccount from "../pages/accountPages/EliminaAccount";
import ReimpostaPassword from "../pages/ReimpostaPassword";
import GuardRoute from "./GuardRoute";
import CONSTANTS from "../CONSTANTS";
import ScrollToTop from "../components/ScrollToTop";
import axios from "axios";
import LOGOUTFUNCTION from "../LOGOUTFUNCTION";
import LottoCustomOne from "../pages/listaLottoPages/LottoCustomOne";
import LottoCustomTwo from "../pages/listaLottoPages/LottoCustomTwo";
import SearchResult from "../pages/SearchResult";
import ShopAllNoReplace from "../pages/ShopAllNoReplace";
import NewInNoReplace from "../pages/NewInNoReplace";
import WhatsappContact from "../components/WhatsappContact";
import { isNotMobile } from "../pagesMobile/components/utils/constants";
import EliminaAccountMobile from "../pages/accountPages/EliminaAccountMobile";
import Terms from "../pages/Terms";
import Cookie from "../pages/Cookie";
import Privacy from "../pages/Privacy";
import CheckoutPause from "../pages/checkoutPages/CheckoutPause";
import CheckoutPauseKo from "../pages/checkoutPages/CheckoutPauseKo";
import CheckoutNexiKo from "../pages/checkoutPages/CheckoutNexiKo";
import TerminiPause from "../pages/TerminiPause";
import BannerComponent from "./BannerComponent";
import NexiProcessing from "../pages/checkoutPages/NexiProcessing";
import SocialRedirect from "../pages/SocialRedirect";

const AppContent = () => {
  const [auth, setauth] = useState(
    localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY) ? true : false
  );

  const path = useReactPath();

  useEffect(() => {
    init();
  }, [path]);
  const init = async () => {
    console.log("API_URL: " + CONSTANTS.API_URL);
    if (localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY)) {
      const res = await axios.post(CONSTANTS.API_URL + "/token/check", {
        token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
      });
      if (res.data.token) {
        console.log(res.data);
        console.log("OPS");
        return;
      } else {
        console.log("OPS1");
        LOGOUTFUNCTION();
        setauth(false);
      }
    } else {
      setauth(false);
    }
    setauth(localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY) ? true : false);
  };

  return (
    <div>
      <Router>
        <Suspense fallback={<Loader />} />
        <ScrollToTop />
        <BannerComponent />
        <Routes>
          {/* <Route path="/" element={<LoginForm />} /> */}
          <Route path="/" element={<Home />} />

          <Route path="/about" element={<About />} />
          <Route path="/social-redirect" element={<SocialRedirect />} />
          <Route
            path="/account"
            element={<GuardRoute component={<Account />} auth={auth} />}
          ></Route>
          <Route
            path="/account/acquisti"
            element={<GuardRoute component={<Acquisti />} auth={auth} />}
          />
          <Route
            path="/account/impostazioni"
            element={<GuardRoute component={<Impostazioni />} auth={auth} />}
          />
          <Route
            path="/account/storico"
            element={<GuardRoute component={<Storico />} auth={auth} />}
          />
          <Route
            path="/account/vendite"
            element={<GuardRoute component={<Vendite />} auth={auth} />}
          />
          <Route
            path="/account/impostazioni/modifica-password"
            element={
              <GuardRoute component={<ModificaPassword />} auth={auth} />
            }
          />
          <Route
            path="/account/impostazioni/modifica-email"
            element={<GuardRoute component={<ModificaEmail />} auth={auth} />}
          />
          <Route
            path="/account/impostazioni/modifica-attivita"
            element={<GuardRoute component={<EliminaAccount />} auth={auth} />}
          />
          <Route
            path="/account/impostazioni/modifica-iban"
            element={<GuardRoute component={<ModificaIban />} auth={auth} />}
          />
          <Route
            path="/account/impostazioni/modifica-spedizione"
            element={
              <GuardRoute component={<ModificaSpedizione />} auth={auth} />
            }
          />
          <Route
            path="/new-in/:page"
            element={<GuardRoute component={<NewInNoReplace />} auth={auth} />}
          />
          <Route
            path="/shop-all/:page"
            element={
              <GuardRoute component={<ShopAllNoReplace />} auth={auth} />
            }
          />
          <Route
            path="/search-page/:value"
            element={<GuardRoute component={<SearchResult />} auth={auth} />}
          />
          <Route
            path="/most"
            element={<GuardRoute component={<MostClicked />} auth={auth} />}
          />
          <Route
            path="/vendi"
            element={<GuardRoute component={<Vendi />} auth={auth} />}
          />
          <Route
            path="/product/:id"
            element={<GuardRoute component={<SingleProd />} auth={auth} />}
          />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/registrazione" element={<RegistrazioneClass />} />
          <Route
            path="/registrazione/conferma-registrazione"
            element={<ConfermaRegistrazione />}
          />
          <Route
            path="/checkout-first/:id/:idProd"
            element={<GuardRoute component={<CheckoutOne />} auth={auth} />}
          />
          <Route
            path="/checkout-second/:id/:idProd"
            element={<GuardRoute component={<CheckoutTwo />} auth={auth} />}
          />
          <Route
            path="/checkout-third/:id"
            element={<GuardRoute component={<CheckoutThree />} auth={auth} />}
          />
          <Route
            path="/checkout-fourth"
            element={<GuardRoute component={<CheckoutFour />} auth={auth} />}
          />
          <Route
            path="/checkoutpause"
            element={<GuardRoute component={<CheckoutPause />} auth={auth} />}
          />
          <Route
            path="/checkoutpauseko"
            element={<GuardRoute component={<CheckoutPauseKo />} auth={auth} />}
          />
          <Route
            path="/checkout-nexi-processing"
            element={<GuardRoute component={<NexiProcessing />} auth={auth} />}
          />
          <Route
            path="/checkoutnexiko"
            element={<GuardRoute component={<CheckoutNexiKo />} auth={auth} />}
          />
          <Route
            path="/lista-lotto-second"
            element={
              <GuardRoute component={<ListaLottoSecond />} auth={auth} />
            }
          />
          <Route
            path="/lista-lotto-third/:id"
            element={<GuardRoute component={<ListaLottoThird />} auth={auth} />}
          />

          <Route
            path="/lista-lotto-fourth/:id"
            element={
              <GuardRoute component={<ListaLottoFourth />} auth={auth} />
            }
          />
          <Route
            path="/lista-lotto-five/:id"
            element={<GuardRoute component={<ListaLottoFive />} auth={auth} />}
          />
          <Route
            path="/lista-lotto-six/:id"
            element={<GuardRoute component={<ListaLottoSix />} auth={auth} />}
          />
          <Route
            path="/lista-lotto-five/:id"
            element={<GuardRoute component={<ListaLottoFive />} auth={auth} />}
          />
          <Route
            path="/lista-lotto-six/:id"
            element={<GuardRoute component={<ListaLottoSix />} auth={auth} />}
          />
          <Route
            path="/lotto-custom-one/:idProd/:id"
            element={<GuardRoute component={<LottoCustomOne />} auth={auth} />}
          />
          <Route
            path="/lotto-custom-two/"
            element={<GuardRoute component={<LottoCustomTwo />} auth={auth} />}
          />
          <Route
            path="/password-dimenticata"
            element={<PasswordDimenticata />}
          />
          <Route
            path="/password-mobile-dimenticata"
            element={<PasswordDimenticataMobile />}
          />
          <Route path="/reimposta-password/*" element={<ReimpostaPassword />} />
          <Route
            path="/account/impostazioni/modifica-attivita-mobile/:id"
            element={<EliminaAccountMobile />}
          />
          <Route path="/termini-condizioni" element={<Terms />} />
          <Route path="/termini-pausepay" element={<TerminiPause />} />
          <Route path="/cookie" element={<Cookie />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
      <WhatsappContact />
    </div>
  );
};
const Loader = () => <div>loading...</div>;

export default AppContent;
