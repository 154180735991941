import React, { useState, useEffect } from "react";
import { Route, Navigate, Prompt } from "react-router-dom";
import "./style.css";
import wklogo from "../assets/logone-uolkicks.png";

const GuardRoute = ({ component, auth }) => {
  const [bool, setbool] = useState(true);
  useEffect(() => {
    if (auth) {
      return;
    } else {
      setTimeout(() => {
        setbool(false);
      }, 2000);
    }
  }, []);
  if (auth) {
    return component;
  } else {
    return (
      <>
        {bool && (
          <div className="container-redirect">
            <img
              src={wklogo}
              alt="wholekicks logo"
              className="image-redirect"
            />
            <p>
              You must log in for this functionality you will be redirected to
              the home
            </p>
          </div>
        )}
        {!bool && <Navigate to="/login" replace />}
      </>
    );
  }
};

export default GuardRoute;
