import React, { Component } from "react";
import logo from "../../assets/wk-logo.png";
import { Link, useParams } from "react-router-dom";
import scarpaImg from "../../assets/image-scarpa.png";
import Footer from "../../components/Footer";
import CONSTANTS from "../../CONSTANTS";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { isAutenticated } from "../../components/utils/validatorActions";
import scarpaVuota from "../../assets/wk_products_not_found.png"

class ListaLottoSecond extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY),
      prodotti: [],
      prodottiInit: [],
      id: 0,
      showModal: false,
      showPropostaConfermata: false,
      valore: 0,
      proposta: {
        nome: "",
        modello: "",
        colorazione: "",
        style: "",
        marca: "",
      },
    };
  }

  // async componentDidMount() {
  //   await axios
  //     .get(CONSTANTS.API_URL + "/piucercate")
  //     .then((prodottoRicevuto) => {
  //       this.setState({
  //         prodottiInit: prodottoRicevuto.data,
  //         prodotti: prodottoRicevuto.data,
  //       });

  //       console.log("piu cercate", prodottoRicevuto.data);
  //     });
  // }
  onSubmitProposta = async (e) => {
    console.log(this.state.proposta);
    const data = this.state.proposta;

    await axios
      .post(CONSTANTS.API_URL + "/proposta", data, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        console.log("brv");

        this.setState({ showModal: false });
        this.setState({ showPropostaConfermata: true });
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  onClickSubmitAnewProposal = () => {
    if (isAutenticated()) {
      this.setState({ showModal: true });
    } else {
      alert("");
    }
  };

  searchFunctionKeyUp = async (word) => {
    console.log("word", word);
    if (word) {
      await axios
        .get(CONSTANTS.API_URL + "/prodotto/search/" + word + "/0/9")
        .then((res) => {
          this.setState({ prodotti: res.data.prodottoConteggio });
        })
        .catch((e) => {
          console.log(e.message);
        });
    } else {
      if (word.length === 0) {
        this.setState({
          prodotti: this.state.prodottiInit,
        });
      } else {
        this.setState({
          prodotti: [],
        });
      }
    }
  };

  render() {
    const { t } = this.props.translation;
    console.log(this.state.id, this.state.valore);
    return (
      <>
        <div className="container-form-check">
          <div className="div-logo-form">
            <Link to="/">
              <img className="logo-login" src={logo} alt="logo" />
            </Link>
          </div>
          <p className="checkout-title"> {t("new_stock")}</p>
          <div className="v1-checkout-div-parent">
            <div className="v1-checkout-div-child">
              <span>
                <p>{t("search_prod")}</p>
                <input
                  type="text"
                  id="myInput"
                  onKeyUp={(e) => this.searchFunctionKeyUp(e.target.value)}
                  placeholder={t("search_sell")}
                  name="search"
                  className="v1-cerca-lotto"
                />
              </span>
            </div>{" "}
            <div className="v1-checkout-div-child v1-checkout-bottom">
              <div id="myUL" className="v1-nome-contenitore">
                {this.state.prodotti.length > 0 ? (
                  this.state.prodotti.map((prodotto, index) => {
                    if (prodotto?.id) {
                      return (
                        <div
                          className={
                            this.state.id === prodotto.id
                              ? "riepilogo-lotto v1-scarpa-da-vendere scarpe-da-vendere-clicked"
                              : "riepilogo-lotto v1-scarpa-da-vendere"
                          }
                          onClick={(e) =>
                            this.setState({ id: prodotto.id, valore: 1 })
                          }
                        >
                          <img
                            src={prodotto.imageProdotto}
                            className="scarpa-da-vendere-img"
                            alt="img-search-sell"
                            onError={(e) => {
                              e.target.src = scarpaVuota;
                            }}
                          />
                          <div id="myLi" value={prodotto.nome}>
                            <a
                              className="v1-nome-elemento"
                              id="myA"
                              value={prodotto.id}
                            >
                              <p style={{ margin: 0 }}>
                                {prodotto.nome},{prodotto.style}
                              </p>
                              {prodotto.colore}
                            </a>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <p>{t("no_product")}</p>
                )}
              </div>
            </div>
            <div onClick={this.onClickSubmitAnewProposal}>
              <p>
                {t("cant_find_prod")}{" "}
                <b style={{ color: "#0a09a2" }}> {t("submit_proposal")}</b>
              </p>
            </div>
          </div>

          <div className="btn-footer-checkout">
            {this.state.valore === 1 ? (
              <Link replace to={"/lista-lotto-third/" + this.state.id}>
                {t("next")}
              </Link>
            ) : (
              <button className="btn-footer-checkout-disabled" disabled>
                {t("next")}
              </button>
            )}
          </div>
        </div>
        <Footer />
        <Modal
          centered
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          dialogClassName="aggiungi-modello-modal"
        >
          <div className="modal-header-text">
            <p className="modello-title-modal">{t("add_new_model")}</p>
            <p className="modello-text-modal">{t("add_model_sub_text")}</p>
            <div className="input-modal-home">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.onSubmitProposta(e);
                }}
              >
                <label className="text-bar">
                  <input
                    required
                    name="nome"
                    onChange={(e) =>
                      this.setState({
                        proposta: {
                          ...this.state.proposta,
                          nome: e.target.value,
                        },
                      })
                    }
                    type="text"
                    placeholder={t("name")}
                    className="text-bar-input text-inp"
                  />
                </label>
                {/* <label className="text-bar">
                      <input
                        required
                        name="modello"
                        onChange={(e) =>
                          this.setState({
                            proposta: {
                              ...this.state.proposta,
                              modello: e.target.value,
                            },
                          })
                        }
                        type="text"
                        placeholder={t("model")}
                        className="text-bar-input text-inp"
                      />
                    </label> */}
                <label className="text-bar">
                  <input
                    required
                    name="colorazione"
                    type="text"
                    placeholder={t("color")}
                    className="text-bar-input text-inp"
                    onChange={(e) =>
                      this.setState({
                        proposta: {
                          ...this.state.proposta,
                          colorazione: e.target.value,
                        },
                      })
                    }
                  />
                </label>
                <label className="text-bar">
                  <input
                    required
                    name="style"
                    type="text"
                    placeholder="SKU"
                    className="text-bar-input text-inp"
                    onChange={(e) =>
                      this.setState({
                        proposta: {
                          ...this.state.proposta,
                          style: e.target.value,
                        },
                      })
                    }
                  />
                </label>
                <label className="text-bar">
                  <input
                    required
                    name="marca"
                    type="text"
                    placeholder={t("brand")}
                    className="text-bar-input text-inp"
                    onChange={(e) =>
                      this.setState({
                        proposta: {
                          ...this.state.proposta,
                          marca: e.target.value,
                        },
                      })
                    }
                  />
                </label>
                <button
                  type="submit"
                  className="invia-proposta modal-invia-prop"
                >
                  {t("submit_proposal")}
                </button>
              </form>
              <button
                className="chiudi-proposta"
                onClick={() => this.setState({ showModal: false })}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
          <div className="modal-footer-button"></div>
        </Modal>
        <Modal
          centered
          show={this.state.showPropostaConfermata}
          onHide={() => this.showPropostaConfermata(false)}
          dialogClassName="aggiungi-modello-modal"
        >
          <div className="modal-header-text">
            <p className="modello-title-modal">{t("prop_sent")}</p>
            <p className="modello-sub-title-modal-proposta">
              {t("prop_sent_text")}
            </p>
          </div>
          <div className="modal-footer-button">
            <button
              className="chiudi-proposta"
              onClick={() => this.setState({ showPropostaConfermata: false })}
            >
              {t("keep_on_shopping")}
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

export default function (props) {
  const params = useParams();
  const t = useTranslation();
  return <ListaLottoSecond translation={t} props={props} params={params} />;
}
