import React, { useEffect, useState } from "react";
import { Modal, Toast } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../styles/ModificaPage.css";
import "./eliminaAccountMobile.css";
import { useTranslation } from "react-i18next";

import useDesktopChecker from "../../components/utils/useDesktopChecker";

function EliminaAccountMobile() {
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const params = useParams();
  const userId = params.id;
  const { t } = useTranslation();
  const desktop = useDesktopChecker();
  const navigate = useNavigate();
  const handleClickDisattiva = () => {
    setShowPasswordInput(true);
  };
  const handleClickHide = () => {
    setShowPasswordInput(false);
  };
  return (
    <div className="container-account-general">
      <div className="container-account-route">
        <div className="modifica-password-container">
          <p className="modifica-pass-title">{t("edit_attivita")}</p>
          <p className="modifica-pass-subtitle delete-account-text">
            {t("disclaimer_delete_account")}
          </p>
          <button onClick={handleClickDisattiva} className="delete-account">
            {t("delete_account")}
          </button>
          <Modal
            size="md"
            show={showPasswordInput}
            style={{ height: "100%" }}
            onHide={handleClickHide}
          >
            <div className="modal_body_elimina_account">
              <p style={{ width: "90%", textAlign: "center" }}>
                {t("disclaimer_need_pass")}
              </p>
              <div className="delete-acc-input">
                <input type="password" />
              </div>
              <button
                onClick={() => {
                  window.ReactNativeWebView.postMessage("DELETED");
                }}
                className="delete-acc-button"
              >
                {t("confirm")}
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default EliminaAccountMobile;
