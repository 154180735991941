import React, { Component } from "react";
import "../../styles/AccountDetails.css";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import Header from "../../components/Header";
import AccountRoutes from "../../components/AccountRoutes";
import useDesktopChecker from "../../components/utils/useDesktopChecker";
import HeaderMobile from "../../pagesMobile/components/HeaderMobile";

class Impostazioni extends Component {
  render() {
    const { t } = this.props.t;
    return (
      <div className="container-account-general">
        {this.props.desktop && <Header />}
        {!this.props.desktop && (
          <HeaderMobile context="Others" title={"Settings"} />
        )}
        <div className="container-account-route">
          {this.props.desktop && <AccountRoutes />}

          <div className="right-column-account">
            <div className="account-list">
              <div className="account-i-detail">
                <p>{t("security")}</p>
                <div>
                  <div className="p-impostazioni">
                    <span className="i-title">{t("change_pass")}</span>
                  </div>
                  <div className="button-impostazioni">
                    <Link to="modifica-password">
                      <button>{t("edit")}</button>
                    </Link>
                  </div>
                </div>
                {/* <div>
              <div className="p-impostazioni">
                <span className="i-title">{t("two_fact")}</span>
              </div>
              <div className="button-impostazioni">
                <Link to="#">
                  <button>{t("edit")}</button>
                </Link>
              </div>
            </div> */}
                <div>
                  <div className="p-impostazioni">
                    <span className="i-title">{t("change_email")}</span>
                  </div>
                  <div className="button-impostazioni">
                    <Link to="modifica-email">
                      <button>{t("edit")}</button>
                    </Link>
                  </div>
                </div>
                <div>
                  <div className="p-impostazioni">
                    <span className="i-title">{t("edit_attivita")}</span>
                  </div>
                  <div className="button-impostazioni">
                    <Link to="modifica-attivita">
                      <button>{t("edit")}</button>
                    </Link>
                  </div>
                </div>
              </div>

              {/* GESTIONE ACQUISTI */}
              <div className="account-i-detail">
                <p>{t("pur_man")}</p>
                <div>
                  <div className="p-impostazioni">
                    <span className="i-title">{t("ship_add")}</span>
                  </div>
                  <div className="button-impostazioni">
                    <Link to="modifica-spedizione">
                      <button>{t("edit")}</button>
                    </Link>
                  </div>
                </div>
              </div>
              {/* INFORMAZIONI AZIENDA */}
              <div className="account-i-detail">
                <p>{t("comp_info")}</p>
                {/* <div>
              <div className="p-impostazioni">
                <span className="i-title">{t("vat_number")}</span>
              </div>
              <div className="button-impostazioni">
                <Link to="#">
                  <button>{t("edit")}</button>
                </Link>
              </div>
            </div> */}
                <div>
                  <div className="p-impostazioni">
                    <span className="i-title">Iban</span>
                  </div>
                  <div className="button-impostazioni">
                    <Link to="modifica-iban">
                      <button>{t("edit")}</button>
                    </Link>
                  </div>
                </div>
                {/*<div>
              <div className="p-impostazioni">
                <span className="i-title">{t("ship_info")}</span>
              </div>
              <div className="button-impostazioni">
                <Link to="modifica-spedizione">
                  <button>{t("edit")}</button>
                </Link>
              </div>
          </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function (props) {
  const t = useTranslation();
  const desktop = useDesktopChecker();
  return <Impostazioni {...props} t={t} desktop={desktop} />;
}
